import React, { useEffect, useState } from "react";
import { getThreadListCity } from "../../util/APIUtils";
import Thread from "../thread/Thread";
import "./Thread-List.scss";

function ThreadList(props) {
  const [threadList, setThreadList] = useState([]);
  let [updateThread, setUpdateThread] = useState(null);
  const [searchInput, setSearchInput] = useState({ search: "" });
  const [keywords, setKeywords] = useState(null);
  const searchString = props?.searchString;

  useEffect(() => {
    getThreadListCity(props.cityId, searchString, keywords).then((items) => {
      setThreadList(items);
    });
  }, [props?.cityId, props.status, updateThread, searchString, keywords]);

  const fncStatus = (values) => {
    setUpdateThread(values);
  };

  const handleChange = (key, value) => {
    setSearchInput({
      ...searchInput,
      [key]: value,
    });
  };

  const handleSubmit = (evt) => {
    evt.preventDefault();
    setKeywords(searchInput.search);
  };

  return (
    <div className="container thread-list-container">
      <form onSubmit={handleSubmit}>
        <div className="row mx-3 form-group thread-list-viewer">
          <input
            type="text"
            placeholder="Search a question"
            className="col-10 form-control"
            autoComplete="off"
            name="search"
            id="search"
            value={searchInput?.search}
            onChange={(e) => handleChange("search", e.target.value)}
          />
          <div className="col-2 d-flex align-items-center justify-content-center">
            <button type="submit" className="btn btn-light">
              <i className="fa fa-search mr-2"></i>Search
            </button>
          </div>
        </div>
      </form>
      {threadList?.map((item, key) => (
        <Thread
          article={item}
          key={key}
          cityId={props.cityId}
          fncStatus={fncStatus}
          currentUser={props.currentUser}></Thread>
      ))}
    </div>
  );
}

export default ThreadList;
