import React from "react";
import { Modal } from "react-bootstrap";
import { useVerified } from "../../context/UserContext";
import { hideEmailCharacters } from "../../util/Utils";
import "./Email-verification-modal.scss";
const icon = "/assets/img/email-verification/email-verification-icon.png";

const EmailVerificationModal = (props) => {
  const { isShow, handleVerificationModal, title, description, email, footer } =
    props;
  const { currentUser } = useVerified();

  return (
    <div>
      <Modal
        {...props}
        size="lg"
        show={isShow}
        onHide={() => handleVerificationModal(false)}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="email-verification-modal"
      >
        <Modal.Header
          className="email-verification-modal-header pt-0"
          closeButton
        ></Modal.Header>
        <Modal.Body>
          <div className="email-verification-modal-body">
            <div className="">
              <img src={icon} alt="icon" />
            </div>
            <div className="email-verification-modal-topic">{title}</div>
            <div className="email-verification-modal-description">
              {description} {email}
            </div>
            <div className="email-verification-modal-description">{footer}</div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default EmailVerificationModal;
