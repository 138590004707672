import React from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import "./Confirmation-Modal.scss";

const confirmationModal = (props) => {
  const { open, isDeleting, body, action, onAction, onClose } = props;

  return (
    <Modal
      centered
      show={open}
      onHide={onClose}
      backdrop="static"
      className="confirmation-modal"
      contentClassName="confirmation-modal-content"
      dialogClassName="confirmation-modal-dialog">
      <Modal.Header className="confirmation-modal-header" closeButton />
      <Modal.Body className="confirmation-modal-body">
        <div>{body}</div>
      </Modal.Body>
      <Modal.Footer className="confirmation-modal-footer">
        <Button
          className="mr-4 button"
          variant="outline-primary"
          onClick={onClose}>
          Cancel
        </Button>
        <Button className="button" variant="primary" onClick={onAction}>
          {isDeleting ? (
            <>
              <span
                className="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"></span>{" "}
              {action}
            </>
          ) : (
            action
          )}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default confirmationModal;
