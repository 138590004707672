import { useEffect, useState } from "react";
import { API_BASE_URL } from "../../constants";

const QuestionItem = (props) => {
  const {
    id,
    isChecked,
    selectAllQuestions,
    checkedQuestions,
    user,
    title,
    text,
    waited,
    updateCheckedQuestions,
    handleReviewAction,
    openRejectionModal,
    reviewMode,
  } = props;
  const [waitTime, setWaitTime] = useState("");

  const calculateWaitTime = () => {
    if (waited > 1440) {
      const days = parseInt(waited / 1440);
      setWaitTime(days + (days === 1 ? " Day" : " Days"));
    } else if (waited > 60) {
      const hr = parseInt(waited / 60);
      const min = waited % 60;
      setWaitTime(hr + ":" + min.toString().padStart(2, "0") + " Hrs");
    } else {
      setWaitTime(waited + " Mins");
    }
  };

  useEffect(() => {
    calculateWaitTime();
  }, [waited]);

  return (
    <>
      <div className="questions-flex-item-checkbox questions-flex-item-baseline">
        <i
          className={`${
            isChecked ? "fas fa-check-square" : "far fa-square"
          } fa-lg`}
          onClick={() => updateCheckedQuestions(id)}></i>
      </div>
      <div className="questions-flex-item-user questions-flex-item-baseline">
        {user?.image || user?.imageUrl ? (
          <img
            className="questions-flex-item-user-profile-picture"
            src={
              user?.image
                ? `${API_BASE_URL}/v1/users/${user.id}/image`
                : user?.imageUrl
            }
            alt="profile pic"
          />
        ) : (
          <div className="questions-flex-item-user-profile-text">
            <span>
              {user?.userName
                ? user?.userName[0].toUpperCase()
                : user?.name && user?.name[0].toUpperCase()}
            </span>
          </div>
        )}
        <div className="questions-flex-item-user-name">
          {user?.userName ? user?.userName : user?.name}
        </div>
      </div>
      <div className="questions-flex-item-question">
        <div
          className={`questions-flex-item-${
            reviewMode === "Questions" ? "question-title" : "answer-title"
          }`}>
          {title}
        </div>
        <div
          className={`questions-flex-item-${
            reviewMode === "Questions" ? "question-text" : "answer-text"
          }`}>
          {text}
        </div>
      </div>
      <div className="questions-flex-item-waited questions-flex-item-baseline">
        <div
          className={`questions-flex-item-waited-text ${
            waited > 1440 ? "questions-flex-item-waited-text-days" : ""
          }`}>
          {waitTime}{" "}
        </div>
      </div>
      <div className="questions-flex-item-action questions-flex-item-baseline">
        <button
          className={`questions-flex-item-action-button questions-flex-item-action-button-approve ${
            selectAllQuestions || checkedQuestions.length > 1
              ? "questions-flex-item-action-button-approve-blurred"
              : ""
          } `}
          onClick={() => handleReviewAction(id, true)}
          disabled={selectAllQuestions || checkedQuestions.length > 1}>
          <i className="fas fa-check" />
          Approve
        </button>
        <button
          className={`questions-flex-item-action-button questions-flex-item-action-button-reject ${
            selectAllQuestions || checkedQuestions.length > 1
              ? "questions-flex-item-action-button-reject-blurred"
              : ""
          }`}
          onClick={() => openRejectionModal(id, title, text, true)}
          disabled={selectAllQuestions || checkedQuestions.length > 1}>
          <i className="fas fa-times" />
          Reject
        </button>
      </div>
    </>
  );
};

export default QuestionItem;
