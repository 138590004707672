import React from "react";
import Carousel from "react-bootstrap/Carousel";
import "./About-us-carousel.scss";

function AboutUsCarousel() {
  return (
    <div className="about-us-carousel">
      <div className="about-us-carousel-title">Testimonials</div>
      <Carousel>
        <Carousel.Item className="about-us-carousel-slide">
          <Carousel.Caption>
            <div className="about-us-carousel-slide-text">
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s, when an unknown printer took a galley of
              type and scrambled it to make a type specimen book.
            </div>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item className="about-us-carousel-slide">
          <Carousel.Caption>
            <div className="about-us-carousel-slide-text">
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s, when an unknown printer took a galley of
              type and scrambled it to make a type specimen book.
            </div>
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>
    </div>
  );
}

export default AboutUsCarousel;
