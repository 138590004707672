import Card from "react-bootstrap/Card";
import "./Empty-Card.scss";
import { schedulerCallFilterList } from "../../constants/scheduleConstants";
import { schedulerStatus } from "../../constants/scheduleConstants";

const EmptyCard = ({ selectedPage }) => {
  let message;

  const selectedPageName = (status) => {
    return schedulerCallFilterList?.find((item) => item?.value === status)
      ?.title;
  };
  switch (selectedPage) {
    case selectedPageName(schedulerStatus.CANCELLED):
      message = "You don't have any cancelled calls.";
      break;
    case selectedPageName(schedulerStatus.DECLINED):
      message = "You don't have any declined calls.";
      break;
    case selectedPageName(
      schedulerStatus.RESCHEDULED + "," + schedulerStatus.SUGGESTED
    ):
      message = "You don't have any rescheduled calls.";
      break;
    case selectedPageName(schedulerStatus.SCHEDULED):
      message = "You don't have any pending calls.";
      break;
    case selectedPageName(
      schedulerStatus.CONFIRMED + "," + schedulerStatus.APPROVED
    ):
      message = "You don't have any confirmed calls.";
      break;
    default:
      message = "You don't have any scheduled calls.";
  }

  return (
    <Card className="empty-scheduled-call">
      <Card.Body>{message}</Card.Body>
    </Card>
  );
};

export default EmptyCard;
