import _ from "lodash";
import { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import AvailabilityTimeSelector from "../availability-selector/Availability-Time-Selector";
import "./Availability-Selector.scss";

const AvailabilitySelector = ({
  wday,
  openDateOverrides,
  addNewAvailabilityTime,
  deleteAvailabilityTime,
  handleTimeChange,
  handleDayAvailability,
  isLimitReached,
  validateInputs,
  validateInputsOverrides,
  isOverride,
  setIsOverrideDayAvailable,
  isScheduledCallAvailable,
  availabilityDatesAndTimes,
  isEdit,
  isError,
}) => {
  const [isDayAvailable, setIsDayAvailable] = useState(wday.available);
  const dayName = _.capitalize(wday.dayOfWeek);
  const handleIsDayAvailable = (checked) => {
    setIsDayAvailable(checked);
    setIsOverrideDayAvailable(checked);
  };

  useEffect(() => {
    setIsDayAvailable(wday.available);
  }, [wday.available]);

  return (
    <>
      <div>
        <Form>
          <div
            className={`${openDateOverrides && "d-block"}
          availability-container`}
          >
            {!openDateOverrides ? (
              <>
                <div style={{ minWidth: "130px" }}>
                  <Form.Check
                    type="switch"
                    id={wday.dayOfWeek}
                    className="custom-switch"
                    name={dayName}
                    label={
                      <span
                        className={
                          wday.available ? "" : "custom-switch-disabled"
                        }
                      >
                        {dayName}
                      </span>
                    }
                    checked={wday.available}
                    onChange={(e) => {
                      handleDayAvailability(wday.dayOfWeek, e.target.checked);
                    }}
                  />
                </div>
                <div className="dropdown-divider divider-mob"></div>
              </>
            ) : (
              <div style={{ minWidth: "130px" }}>
                <Form.Check
                  type="switch"
                  id={isDayAvailable}
                  className={`${
                    isScheduledCallAvailable
                      ? "d-none"
                      : "custom-switch pb-4 mt-4 ml-4"
                  }`}
                  name={isDayAvailable}
                  label={
                    <span
                      className={isDayAvailable ? "" : "custom-switch-disabled"}
                    >
                      {isDayAvailable ? "Available" : "Unavailable"}
                    </span>
                  }
                  checked={isDayAvailable}
                  onChange={(e) => {
                    handleIsDayAvailable(e.target.checked);
                  }}
                />
              </div>
            )}
            <div className="time-selector-container pb-2">
              {openDateOverrides ? (
                <div>
                  {isDayAvailable ? (
                    <div className="time-selector-container">
                      {wday.segments.map((time, i) => (
                        <AvailabilityTimeSelector
                          key={i}
                          availabilityId={wday.id}
                          availabilityTime={time}
                          type = {wday.type}
                          dayName={wday.dayOfWeek ? wday.dayOfWeek : wday.date}
                          segmentId={i}
                          addNewAvailabilityTime={addNewAvailabilityTime}
                          deleteAvailabilityTime={deleteAvailabilityTime}
                          handleTimeChange={handleTimeChange}
                          isLimitReached={isLimitReached}
                          isDayAvailable={isDayAvailable}
                          validateInputs={validateInputs}
                          validateInputsOverrides={validateInputsOverrides}
                          isOverride={isOverride}
                          isError={isError}
                        />
                      ))}
                    </div>
                  ) : (
                    <div className="time-selector-container mt-8">
                      <div className="modal-body-date-overrides-middle-content-right-title w-10 pl-7 pr-1">
                        Please turn on the availability toggle to include your
                        available times.
                      </div>
                    </div>
                  )}
                </div>
              ) : (
                <div
                  className={`${
                    !wday.available && "disabledBlur pb-1"
                  } w-100 time-wrap`}
                >
                  <div className="time-selector-container">
                    {wday.segments.map((time, i) => (
                      <AvailabilityTimeSelector
                        key={i}
                        availabilityId={wday.id}
                        availabilityTime={time}
                        dayName={wday.dayOfWeek}
                        segmentId={i}
                        addNewAvailabilityTime={addNewAvailabilityTime}
                        deleteAvailabilityTime={deleteAvailabilityTime}
                        handleTimeChange={handleTimeChange}
                        isLimitReached={isLimitReached}
                        isDayAvailable={wday.available}
                        validateInputs={validateInputs}
                        validateInputsOverrides={validateInputsOverrides}
                        isOverride={isOverride}
                        wday={wday}
                      />
                    ))}
                  </div>
                </div>
              )}
            </div>
          </div>
        </Form>
      </div>
    </>
  );
};

export default AvailabilitySelector;
