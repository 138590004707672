import { useEffect, useState } from "react";
import { Container, Dropdown, Spinner } from "react-bootstrap";
import EventItem from "../../components/event-item/Event-Item";
import AddNewEventModal from "../../components/event-modals/Add-Event-Modal";
import { getAllEventsByDisplay } from "../../util/APIUtils";
import { DisplayNoContent } from "../../components/no-content/No-Content";
import { utilSort } from "../../util/UtilSort";
import "./Events.scss";
import { USER_ROLE } from "../../util/Utils";

const Events = (props) => {
  const [allEventData, setAllEventData] = useState([]);
  const [eventsData, setEventsData] = useState([]);
  const [mode, setMode] = useState("ongoing");
  const [sortBy, setSortBy] = useState("Recently added");
  const [modalIsOpen, setModalIsOpen] = useState({
    addEventModal: false,
    editEventModal: false,
  });
  const editEventInitialState = {
    name: null,
    image: null,
    cities: [],
    startDate: null,
    endDate: null,
    description: null,
  };
  const [editEvent, setEditEvent] = useState(editEventInitialState);

  useEffect(() => {
    refreshAllEventData();
  }, []);

  const convertDate = (date) => {
    const dateObj = new Date(date);
    const year = dateObj.getFullYear();
    const month = dateObj.getMonth() + 1;
    const day = dateObj.getDate();
    return `${year}-${month < 10 ? "0" + month : month}-${
      day < 10 ? "0" + day : day
    }`;
  };

  useEffect(() => {
    let copyOfEventData = allEventData.slice();
    const currentDate = new Date();
    switch (mode) {
      case "ongoing":
        copyOfEventData = copyOfEventData.filter((value) => {
          const eventStartDate = new Date(value.startDate);
          const eventEndDate = new Date(value.endDate);
          return (
            convertDate(eventStartDate) <= convertDate(currentDate) &&
            convertDate(currentDate) <= convertDate(eventEndDate)
          );
        });
        break;
      case "upcoming":
        copyOfEventData = copyOfEventData.filter((value) => {
          const eventStartDate = new Date(value.startDate);
          return currentDate < eventStartDate;
        });
        break;
      case "past":
        copyOfEventData = copyOfEventData.filter((value) => {
          const eventEndDate = new Date(value.endDate);
          return convertDate(eventEndDate) < convertDate(currentDate);
        });
        break;
      default:
        break;
    }

    switch (sortBy) {
      case "Recently added":
        copyOfEventData = utilSort(copyOfEventData, "createdAt", -1);
        break;
      case "Oldest events":
        copyOfEventData = utilSort(copyOfEventData, "createdAt", 1);
        break;
      default:
        break;
    }
    setEventsData(copyOfEventData);
  }, [allEventData, mode, sortBy]);

  const refreshAllEventData = () => {
    getAllEventsByDisplay(true).then((data) => {
      setAllEventData(data);
    });
  };

  const toggleModal = (eventModal) => {
    setModalIsOpen((prev) => ({
      ...prev,
      [eventModal]: !prev[eventModal],
    }));
  };

  return (
    props.currentUser.role === USER_ROLE.SUPER_ADMIN && (
      <>
        <AddNewEventModal
          show={modalIsOpen.addEventModal}
          toggleModal={toggleModal}
          editEvent={editEvent}
          setEditEvent={setEditEvent}
          editEventInitialState={editEventInitialState}
          refresh={refreshAllEventData}
        />
        <Container className="events">
          <div className="top-component">
            <div className="first-row">
              <div className="left">
                {["ongoing", "upcoming", "past"].map((modeOption, index) => (
                  <button
                    key={index}
                    className={`button ${
                      mode === modeOption ? "button-active" : ""
                    }`}
                    onClick={() => setMode(modeOption)}>
                    <span className="left-mode">{modeOption}</span>
                  </button>
                ))}
              </div>
              <div className="right">
                <span className="right-mode">{mode}</span> events
              </div>
            </div>
            <div className="second-row">
              <div className="left">
                <div className="sort-title">Sort by:</div>
                <Dropdown className="sorting-options">
                  <Dropdown.Toggle className="sorting-options-toggle">
                    {sortBy}
                    <i className="fas fa-caret-down"></i>
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="sorting-options-menu">
                    {["Recently added", "Oldest events"].map(
                      (sortOption, index) => (
                        <Dropdown.Item
                          key={index}
                          className="sorting-options-item"
                          onClick={() => setSortBy(sortOption)}>
                          {sortOption}
                        </Dropdown.Item>
                      )
                    )}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
              <div className="right">
                <button onClick={() => toggleModal("addEventModal")}>
                  <i className="fas fa-plus fa-lg" />
                  Add new event
                </button>
              </div>
            </div>
          </div>
          <hr className="separator mx-0 px-0 pb-0  mt-0" />
          {allEventData.length ? (
            <div className="bottom-component">
              <div className="events-content">
                <div className="events-header">
                  <div className="events-header-truncate" />
                  <div className="events-header-image">Image</div>
                  <div className="events-header-name">Name</div>
                  <div className="events-header-cities">Cities</div>
                  <div className="events-header-description">Description</div>
                  <div className="events-header-duration">Duration</div>
                  <div className="events-header-action">Action</div>
                </div>
                {eventsData.length === 0 && (
                  <DisplayNoContent
                    text={`There are no ${mode} events to display.`}
                    type="event"
                  />
                )}
                {eventsData.map((event) => (
                  <EventItem
                    event={event}
                    setEditEvent={setEditEvent}
                    toggleModal={toggleModal}
                    refresh={refreshAllEventData}
                  />
                ))}
              </div>
            </div>
          ) : (
            <div className="events-spinner">
              <Spinner animation="border" variant="primary" />
            </div>
          )}
        </Container>
      </>
    )
  );
};

export default Events;
