import React, { useEffect, useState } from "react";
import FriendsPost from "../../components/friends-post/Friends-Post";
import FriendsCarosel from "../../components/friends-carousel/Friends-Carousel";
import FriendPageLeftPanel from "../../components/friends-page-left-panel/Friend-Page-Left-Panel.js";
import {
  getAllCommentByUserId,
  getProfileById,
  getCurrentUser,
  getProfile,
} from "../../util/APIUtils";
import { notificationManage } from "../../util/NotificationUtils";
import { utilSortDate } from "../../util/UtilSort";
import { Dropdown, Spinner, Pagination  } from "react-bootstrap";
import { toUpperCamelCase } from "../../util/Utils";
import { ACCESS_TOKEN } from "../../constants";
import "./Friend.scss";
import { DisplayNoContent } from "../../components/no-content/No-Content";

const filterOptionArr = [
  {
    id: 0,
    name: "Latest",
    value: "Latest",
  },
  {
    id: 1,
    name: "Oldest",
    value: "Oldest",
  },
  {
    id: 2,
    name: "Top rated",
    value: "Top rated",
  },
];

const Friend = (props) => {
  const [commentData, setCommentData] = useState([]);
  const [userDetails, setUserDetails] = useState({
    user: [],
    keys: [],
    values: [],
  });
  const [dropdownValue, setDropdownValue] = useState("Latest");
  const [filterData, setFilterData] = useState([]);
  const [isUpdate, setIsUpdate] = useState(false);
  const [isDataLoad, setIsDataLoad] = useState(true);
  const [currentProfile, setCurrentProfile] = useState();
  const [currentPage, setCurrentPage] = useState(1);
const [postsPerPage] = useState(10); 

const indexOfLastPost = currentPage * postsPerPage;
const indexOfFirstPost = indexOfLastPost - postsPerPage;
const currentPosts = filterData.slice(indexOfFirstPost, indexOfLastPost);
const totalPages = Math.ceil(filterData.length / postsPerPage);

const paginate = (pageNumber) => {
  setCurrentPage(pageNumber);
};


  const onListSortChange = (order) => {
    setDropdownValue(order);
  };

  const handleDataUpdate = () => {
    setIsUpdate(!isUpdate);
  };

  useEffect(() => {
    if (!currentProfile) {
      if (localStorage.getItem(ACCESS_TOKEN)) {
        getCurrentUser().then((currentUser) => {
          getProfile(currentUser.id).then((currentUserProfile) => {
            setCurrentProfile(currentUserProfile);
          });
        }).catch(console.warn);
      }
    }
  }, [currentProfile]);

  useEffect(() => {
    getProfileById(props.match.params.id)
      .then((res) => {
        setUserDetails({
          user: res,
          keys: Object.keys(res.ratings),
          values: Object.values(res.ratings),
          commentCounts: Object.values(res.commentCounts),
        });
      })
      .catch((err) => {
        notificationManage("danger", "User retrievals failed");
      });
  }, [props]);

  useEffect(() => {
    getAllCommentByUserId(props.match.params.id)
      .then((res) => {
        const filterCommentData = res.filter((item) =>
          (item.type === "question") & currentProfile?.id
            ? item.createdBy.id === currentProfile.id ||
              item.status === "approved"
            : item.status === "approved"
        );
        getCurrentUser()
          .then((user) => {
            setCommentData(filterUnapprovedPosts(filterCommentData, user.id));
          })
          .catch(() => {
            setCommentData(filterUnapprovedPosts(filterCommentData, null));
          });
      })
      .catch((err) => {
        err?.then((resp) => {
          notificationManage("danger", "Post Data retrievals failed");
        });
      });
  }, [props, isUpdate]);

  useEffect(() => {
    setIsDataLoad(true);
    setTimeout(() => {
      setIsDataLoad(false);
    }, 4000);
  }, [props]);

  const utilSortInteractions = (array, key, order) => {
    return array.sort((o1, o2) => {
      const obj1 = o1[key]?.filter((e) => {
        return e.type === "up_vote";
      }).length;
      const obj2 = o2[key]?.filter((e) => {
        return e.type === "up_vote";
      }).length;
      return obj1 < obj2 ? -order : obj1 > obj2 ? order : 0;
    });
  };

  useEffect(() => {
    if (commentData.length) {
      setFilterData(utilSortDate(commentData, "createdAt", -1));
    }
  }, [commentData]);

  useEffect(() => {
    if (commentData) {
      let commentDataCopy = [...commentData];
      switch (dropdownValue) {
        case "Latest":
          setFilterData(utilSortDate(commentDataCopy, "createdAt", -1));
          break;
        case "Oldest":
          setFilterData(utilSortDate(commentDataCopy, "createdAt", 1));
          break;
        case "Top rated":
          setFilterData(
            commentDataCopy.sort((commentA, commentB) => {
              return (
                commentA.interactions.filter((value) => {
                  return value.type === "up_vote";
                }).length -
                commentB.interactions.filter((value) => {
                  return value.type === "up_vote";
                }).length
              );
            })
          );
          break;
        default:
          notificationManage("danger", `Unknown type: ${dropdownValue}`);
          break;
      }
    }
  }, [dropdownValue, commentData]);

  const filterUnapprovedPosts = (posts, id) =>
    posts.filter((post) => {
      return post.comments
        ? post.comments[0].createdBy.id === id ||
            post.comments[0].status === "approved"
        : post.comments[0].status === "approved";
    });
  return (
    <section>
      <div className="friends-profile container">
        <div className="friends-slider">
          <div className="friends-title">
            <p>Friends from {userDetails.user.cityName}</p>
          </div>
          <div className="friends-slider">
            <FriendsCarosel
              cityId={userDetails.user.cityId}
              friendId={userDetails.user.id}
              currentUserDetails={currentProfile}
              {...props}
            />
          </div>
        </div>

        <div className="friends-content">
          <div className="left-panel friends-content-information">
            <div>
              <FriendPageLeftPanel
                userCity={userDetails.user.cityName}
                userName={
                  userDetails.user.userName
                    ? userDetails.user.userName
                    : userDetails.user.name
                }
                description={userDetails.user.description}
                profilePic={userDetails.user.imageUrl}
                profilePic2={userDetails.user.image}
                cityId={userDetails.user.cityId}
                userId={userDetails.user.id}
                year={userDetails.user.years}
                followingList={currentProfile?.following}
                currentProfileUser={currentProfile?.id}
                keys={userDetails.keys}
                values={userDetails.values}
                commentCount={userDetails.commentCounts}
              />
            </div>
          </div>
          <div className="right-panel friends-content-information-col">
            {!isDataLoad && (
              <div className="right-panel-action-bar">
                <div className="right-panel-action-bar-left">
                  <div className="right-panel-action-bar-username">
                    <p>
                      {currentProfile?.id === userDetails.user.id
                        ? "My answers"
                        : (userDetails.user.userName
                            ? userDetails.user.userName.split(" ")[0]
                            : userDetails.user.name.split(" ")[0]) +
                          "'s answers"}
                    </p>
                  </div>

                  <div className="select-wrapper">
                    <Dropdown className="post-dropdown">
                      <Dropdown.Toggle className="post-dropdown-toggle">
                        {dropdownValue}
                      </Dropdown.Toggle>
                      <Dropdown.Menu className={"post-dropdown-menu"}>
                        {filterOptionArr.map((menuItem, index) => {
                          return (
                            <Dropdown.Item
                              key={index}
                              className={"post-dropdown-options"}
                              value={menuItem.value}
                              onClick={() => onListSortChange(menuItem.value)}>
                              {menuItem.name}
                            </Dropdown.Item>
                          );
                        })}
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </div>
              </div>
            )}

            {isDataLoad ? (
              <div className="friend-spinner">
                <Spinner animation="border" variant="primary" />
              </div>
            ) : (
              <div className="right-panel-post-thread">
                
                {filterData?.length === 0 && (
                  <DisplayNoContent
                    text={
                      currentProfile?.id === props.match.params.id
                        ? "You have not answered any questions yet."
                        : `${
                            userDetails?.user.userName
                              ? userDetails?.user.userName?.split(" ")[0]
                              : userDetails?.user.name?.split(" ")[0]
                          } have not answered any questions yet.`
                    }
                    type="friend-post"
                  />
                )}
                {!isDataLoad &&
                      currentPosts?.map((item) => {
                    return (
                      <div
                        key={item?.id}
                        className="right-panel-post-thread-item">
                        <FriendsPost
                          userId={props.match.params.id}
                          id={item?.id}
                          author={
                            item?.createdBy?.userName
                              ? item?.createdBy?.userName
                              : item?.createdBy?.name
                          }
                          datePost={item?.createdAt}
                          title={item?.title}
                          text={item?.text}
                          comments={item?.comments}
                          currentUserDetails={currentProfile}
                          handleDataUpdate={() => {
                            handleDataUpdate();
                          }}
                          cityId={item?.cityId}
                        />
                      </div>
                    );
                  })}
              </div>
            )}
           <div className="pagination-container">
  <Pagination>
    <Pagination.Prev
      onClick={() => paginate(currentPage - 1)}
      disabled={currentPage === 1}
    />
    {Array.from({ length: totalPages }).map((_, index) => (
      <Pagination.Item
        key={index}
        active={index + 1 === currentPage}
        onClick={() => paginate(index + 1)}
      >
        {index + 1}
      </Pagination.Item>
    ))}
    <Pagination.Next
      onClick={() => paginate(currentPage + 1)}
      disabled={currentPage === totalPages}
    />
  </Pagination>
</div>

          </div>
        </div>
      </div>
    </section>
  );
};

export default Friend;
