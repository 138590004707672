export const utilSort = (array, key, order) => {
  return array.sort((o1, o2) => {
    const obj1 = o1[key];
    const obj2 = o2[key];
    return obj1 < obj2 ? -order : obj1 > obj2 ? order : 0;
  });
};

export const utilSortArray = (array, key, order) => {
  return array.sort((o1, o2) => {
    const obj1 = o1[key].length;
    const obj2 = o2[key].length;
    return obj1 < obj2 ? -order : obj1 > obj2 ? order : 0;
  });
};

export const utilSortDate = (array, key, order) => {
  return array.sort((o1, o2) => {
    const obj1 = new Date(o1[key]);
    const obj2 = new Date(o2[key]);
    return obj1 < obj2 ? -order : obj1 > obj2 ? order : 0;
  });
};
