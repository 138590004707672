import { Button } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import "./Already-Signed-Up-Modal.scss";

const AlreadySignedUp = (props) => {
  const { show, onHide, logout } = props;
  return (
    <Modal
      show={show}
      onHide={onHide}
      centered
      className="already-signed-up"
      contentClassName="already-signed-up-content">
      <Modal.Header closeButton className="already-signed-up-header" />
      <Modal.Body className="already-signed-up-body">
        <div>Already Signed Up!</div>
        <div>
          You have already Signed Up as a newcomer. To create a new account, you
          need to Sign Out
        </div>
        <div>Do you need to Sign Out?</div>
        <div className="button-row">
          <Button onClick={() => logout()}>Sign out</Button>
          <Button onClick={onHide}>Go back</Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default AlreadySignedUp;
