import React, { useState, useEffect, useRef } from "react";
import { getCitiesForInquiries, postInquiry } from "../../util/APIUtils";
import { notificationManage } from "../../util/NotificationUtils";
import { Typeahead } from "react-bootstrap-typeahead";
import { emailRegex } from "../../util/Regex";
import { useHistory } from "react-router";
import { capitalizeFirstLetter, scrollTop } from "../../util/Utils";
import "./Contact-us.scss";
import { ENV, ENVIROMENTS } from "../../constants";
import { MetaTags } from "react-meta-tags";

const ContactUs = () => {
  const history = useHistory();
  const typeaheadRef = useRef(null);
  const initialFormState = {
    inquiry: {
      name: "",
      email: "",
      city: "",
      message: "",
    },
    errors: {
      name: null,
      email: null,
      city: null,
      message: null,
    },
  };
  const [formValue, setFormValue] = useState(initialFormState.inquiry);
  const [errors, setErrors] = useState(initialFormState.errors);
  const [cities, setCities] = useState([]);

  const handleFormValidation = () => {
    let hasError;
    Object.keys(formValue).forEach((key) => {
      if (key !== "city" && !validateInput(key, formValue[key])) {
        hasError = true;
      }
    });
    return !hasError;
  };

  const handleTypeahead = (key, value) => {
    const city =
      typeof value[0] === "object" && value[0] ? value[0].city : value[0];
    handleChange(key, city);
  };

  const handleChange = (key, value) => {
    setFormValue((prevState) => ({
      ...prevState,
      [key]: value,
    }));
    validateInput(key, value);
  };

  const validateInput = (key, value) => {
    setErrors((prev) => ({
      ...prev,
      [key]: "",
    }));

    if (!value) {
      setErrors((prev) => ({
        ...prev,
        [key]: `${capitalizeFirstLetter(key)} is required!`,
      }));
      return false;
    } else if (value && key === "email" && emailRegex.test(value) === false) {
      setErrors((prev) => ({
        ...prev,
        [key]: `${capitalizeFirstLetter(key)} is Invalid!`,
      }));
      return false;
    }

    return true;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (handleFormValidation()) {
      postInquiry(formValue)
        .then(() => {
          notificationManage(
            "success",
            "Message sent Successfully. Our team will get back to you soon."
          );
          setFormValue(initialFormState.inquiry);
          typeaheadRef.current.clear();
        })
        .catch((err) => {
          err?.then((resp) => {
            notificationManage(
              "danger",
              resp.message !== "No message available"
                ? resp.message
                : "Inquiry post failed"
            );
          });
        });
    }
  };

  useEffect(() => {
    getCitiesForInquiries()
      .then((res) => {
        setCities(res);
      })
      .catch(() => {
        notificationManage("danger", "City list retrieval failed");
      });
  }, []);

  useEffect(() => {
    scrollTop();
  }, []);

  return (
    <div className="contact-us">
      <div className="contact-us-topic">Contact Us</div>
      <div className="contact-us-description">
        For all enquiries, please message us using the form below.
      </div>
      <div className="contact-us-form">
        <form onSubmit={handleSubmit}>
          <div className="contact-us-form-input">
            <label>Name *</label>
            <input
              type="text"
              name="name"
              value={formValue.name}
              placeholder="e.g: Steve Rogers"
              onChange={(e) => handleChange("name", e.target.value)}
            />
            <div className="contact-us-form-error-message">
              {errors.name !== null && errors.name}
            </div>
          </div>
          <div className="contact-us-form-flex">
            <div className="contact-us-form-input">
              <label>Email *</label>
              <input
                type="text"
                name="email"
                value={formValue.email}
                placeholder="e.g: steve@limarktech.com"
                onChange={(e) => handleChange("email", e.target.value)}
              />
              <div className="contact-us-form-error-message">
                {errors.email !== null && errors.email}
              </div>
            </div>
            <div
              className={`contact-us-form-input`}>
              <label>Your city</label>

              <Typeahead
                allowNew={true}
                ref={typeaheadRef}
                id="city"
                className="contact-us-form-input-typeahead"
                labelKey="city"
                options={cities}
                onChange={(selected) => handleTypeahead("city", selected)}
                placeholder="--Select city--"
              />

              <div className="contact-us-form-error-message">
                {errors.city !== null && errors.city}
              </div>
            </div>
          </div>
          <div className="contact-us-form-input">
            <label>Message *</label>
            <textarea
              name="message"
              id="message"
              value={formValue.message}
              className="contact-us-form-input-textarea"
              placeholder="Your message"
              onChange={(e) =>
                handleChange("message", e.target.value)
              }></textarea>
            <div className="contact-us-form-error-message">
              {errors.message !== null && errors.message}
            </div>
          </div>
          <button className="contact-us-submit btn btn-primary" type="submit">Send</button>
        </form>
      </div>
      {ENV === ENVIROMENTS.PRODUCTION && (
        <MetaTags>
          <title>Contact Us | Move2NewCity</title>
          <meta
            name="description"
            content="Fill out and submit the inquiry form and a friendly member of our team will get in touch with you soon for support"
          />
          <meta
            name="keywords"
            content="contact us, move2newcity, forms, VIC, SA, NSW, QLD, australia"
          />
        </MetaTags>
      )}
    </div>
  );
};

export default ContactUs;
