import { useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import { PlusLg, Trash } from "react-bootstrap-icons";
import { getAvailableTimeList } from "../../../util/CommonUtils";
import "./Availability-Time-Selector.scss";

const AvailabilityTimeSelector = (props) => {
  const {
    addNewAvailabilityTime,
    availabilityTime,
    availabilityId,
    deleteAvailabilityTime,
    type,
    handleTimeChange,
    isLimitReached,
    isDayAvailable,
    dayName,
    validateInputs,
    validateInputsOverrides,
    isOverride,
    isError,
    wday,
  } = props;
  const [availableTimes, setAvailableTimes] = useState([]);

  useEffect(() => {
    isOverride ? validateInputsOverrides(type) : validateInputs();
  }, [availabilityTime, wday, isError]);

  useEffect(() => {
    setAvailableTimes(getAvailableTimeList());
  }, []);

  return (
    <>
      <div className="availability-time-selector-main w-100">
        <div
          className={`${
            !isDayAvailable && "mob-view"
          } availability-time-selector-container my-2 w-100`}
        >
          <div
            className={`${
              !isOverride
                ? "dropdown-custom-col"
                : "dropdown-custom-col-override ml-2"
            }`}
          >
            <div className="dropdown-custom">
              <Dropdown
                className={`w-100 form-control p-0  ${
                  availabilityTime?.error || availabilityTime?.appointmentsError
                    ? "availability-border-change"
                    : ""
                }`}
                id="exampleFormControlSelect1"
                defaultActiveKey={
                  (
                    availableTimes.find(
                      (timeSlots) =>
                        timeSlots?.value === availabilityTime?.startTime
                    ) || {}
                  ).value
                }
              >
                <Dropdown.Toggle
                  className={`form-control-toggle w-100 text-left ${
                    availabilityTime?.error ||
                    availabilityTime?.appointmentsError
                      ? "availability-border-change"
                      : ""
                  }`}
                  disabled={!isDayAvailable}
                >
                  {
                    (
                      availableTimes.find(
                        (timeSlots) =>
                          timeSlots?.value === availabilityTime?.startTime
                      ) || {}
                    ).value
                  }
                </Dropdown.Toggle>
                <Dropdown.Menu className="form-control-toggle-menu w-100">
                  {availableTimes.map((time, index) => (
                    <>
                      <Dropdown.Item
                        key={index}
                        className="form-control-toggle-option"
                        onClick={() => {
                          availabilityTime?.startEnd !== time?.value &&
                            handleTimeChange(
                              dayName,
                              availabilityTime?.atid,
                              "startTime",
                              time?.value,
                              type
                            );
                        }}
                        disabled={time.value >= availabilityTime.endTime}
                      >
                        <p className="form-control-toggle-text">
                          {time?.value && time?.value.split(" ")[0]}
                        </p>{" "}
                        {time?.value && time?.value.split(" ")[1]}
                      </Dropdown.Item>
                      <div className="d-flex justify-content-center">
                        <div className="form-control-toggle-dropdown-divider dropdown-divider"></div>
                      </div>
                    </>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
          <p className="setting-page-dropdown-space px-1">to</p>
          <div
            className={`${
              !isOverride
                ? "dropdown-custom-col"
                : "dropdown-custom-col-override"
            }`}
          >
            <div className="dropdown-custom">
              <Dropdown
                className={`w-100 form-control p-0  ${
                  availabilityTime?.error || availabilityTime?.appointmentsError
                    ? "availability-border-change"
                    : ""
                }`}
                id="exampleFormControlSelect1"
                defaultActiveKey={
                  (
                    availableTimes?.find(
                      (timeSlots) =>
                        timeSlots?.value === availabilityTime?.endTime
                    ) || {}
                  )?.value
                }
              >
                <Dropdown.Toggle
                  className={`form-control-toggle w-100 text-left ${
                    availabilityTime.error ? "availability-border-change" : ""
                  }`}
                  disabled={!isDayAvailable}
                >
                  {
                    (
                      availableTimes?.find(
                        (timeSlots) =>
                          timeSlots?.value === availabilityTime?.endTime
                      ) || {}
                    )?.value
                  }
                </Dropdown.Toggle>
                <Dropdown.Menu className="form-control-toggle-menu w-100">
                  {availableTimes?.map((time, index) => (
                    <>
                      <Dropdown.Item
                        key={index}
                        className="form-control-toggle-option"
                        onClick={() => {
                          availabilityTime?.startTime !== time?.value &&
                            handleTimeChange(
                              dayName,
                              availabilityTime?.atid,
                              "endTime",
                              time?.value,
                              type
                            );
                        }}
                        disabled={time?.value <= availabilityTime?.startTime}
                      >
                        {time.value}
                      </Dropdown.Item>
                      <div className="d-flex justify-content-center">
                        <div className="form-control-toggle-dropdown-divider dropdown-divider"></div>
                      </div>
                    </>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
          <div>
            <div className={`${isOverride && "w-10"}`}>
              {availabilityTime?.atid === 1 ? (
                isDayAvailable ? (
                  <button
                    type="button"
                    disabled={isLimitReached}
                    className={`btn btn-primary btn-block ${
                      isOverride
                        ? "btn-add-new-override ml-4 mr-7"
                        : "btn-add-new"
                    } d-flex align-items-center align-content-center justify-content-center`}
                    onClick={() => {
                      addNewAvailabilityTime(dayName);
                    }}
                  >
                    <PlusLg className="bi bi-calendar btn-plus-icon"></PlusLg>
                    <span className="btn-add-new-text">
                      {!isOverride && "Add new"}
                    </span>
                  </button>
                ) : (
                  <div
                    className={`${!isDayAvailable && "btn-add-new-disabled"}`}
                  ></div>
                )
              ) : isDayAvailable ? (
                <button
                  type="button"
                  className={`btn btn-primary ${
                    isOverride
                      ? "delete-button-override mr-7 ml-4"
                      : "delete-button"
                  }`}
                  onClick={() => {
                    deleteAvailabilityTime(
                      availabilityId,
                      availabilityTime?.atid,
                      type
                    );
                  }}
                >
                  <Trash className="bi bi-trash-fill trash-icon"></Trash>
                </button>
              ) : (
                <div
                  className={`${!isDayAvailable && "btn-add-new-disabled"}`}
                ></div>
              )}
            </div>
          </div>
        </div>
        <div>
          {availabilityTime?.error && (
            <p className="availability-time-selector-invalid-text">
              Times overlap with another set of times
            </p>
          )}
          {availabilityTime?.appointmentsError && (
            <p className="availability-time-selector-invalid-text">
              Appointment conflict with unspecified availability
            </p>
          )}
        </div>
      </div>
    </>
  );
};

export default AvailabilityTimeSelector;
