import Cookies from "js-cookie";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import {
  Container,
  Dropdown,
  OverlayTrigger,
  Spinner,
  Tooltip,
} from "react-bootstrap";
import { Calendar3 } from "react-bootstrap-icons";
import { Typeahead } from "react-bootstrap-typeahead";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { SCHEDULER_NAVIGATOR } from "../../constants";
import {
  localStorageScheduleCall,
  panelState,
} from "../../constants/scheduleConstants";
import {
  createAppointments,
  getCurrentUser,
  getProfileById,
  getSchedularUser,
} from "../../util/APIUtils";
import { formatDateTime } from "../../util/Formatter";
import { notificationManage } from "../../util/NotificationUtils";
import { ScheduleCallData, selectDuration } from "../../util/ScheduleCallData";
import timeZones from "../../util/TimeZones";
import { truncateName } from "../../util/Utils";
import AppointmentCalendar from "../appointment-calendar/Appointment-Calendar";
import ContributionDetails from "../contribution-details-modal/ContributionDetails";
import FriendDescription from "../friend-description-modal/FriendDescription";
import "./Friend-Schedule.scss";
import FriendProfile from "./friend-Profile/FriendProfile";

const FriendSchedule = () => {
  const { friendId } = useParams();
  const { appointmentId } = useParams();
  const [scheduleCall, setScheduleCall] = useState(ScheduleCallData);
  const [friendProfile, setFriendProfile] = useState({});
  const [selectedDuration, setSelectedDuration] = useState("30 minutes");
  const [note, setNote] = useState("");
  const [selectedTimeZoneNew, setSelectedTimeZoneNew] = useState(
    Cookies.get("timeZone")
  );
  const [friendTimeZone, setFriendTimeZone] = useState("");
  const [selectedNewDate, setSelectedNewDate] = useState("");
  const [selectedTimeNew, setSelectedTimeNew] = useState("");
  const [userDetails, setUserDetails] = useState({});
  const [isSchedule, setIsSchedule] = useState(true);
  const [isReschedule, setIsReschedule] = useState(true);
  const [reSchedule, setReschedule] = useState({});
  const [currentUserId, setCurrentUserId] = useState("");
  const [scheduleDate, setScheduleDate] = useState("");
  const [loading, setLoading] = useState(false);
  const selectDurationNew = useRef(selectDuration);
  const [resourcesCallDurations, setResourcesCallDurations] = useState([]);
  const setSelectedTime = useRef(selectedTimeNew);
  const location = useLocation();
  const history = useHistory();
  const [showDetails, setShowDetails] = useState(false);
  const [showContributingDetails, setShowContributingDetails] = useState(false);
  const [windowWidth, setWindowWidth] = useState({
    width: window.innerWidth,
  });
  const [friendSchedularId, setFriendSchedularId] = useState("");

  useEffect(() => {
    setSelectedTimeZoneNew(
      timeZones.filter(
        (timeZone) =>
          timeZone?.value ===
          new Date()?.toString()?.split("(")[1]?.split(")")[0]
      )[0]?.utc[0]
    );
  }, []);

  useEffect(() => {
    friendSchedularId && getResourcesCallDurations(friendSchedularId);
  }, [friendSchedularId]);

  useEffect(() => {
    setSelectedTime.current = selectedTimeNew;
  }, [selectedTimeNew]);

  useEffect(() => {
    setNote(
      `Hi ${friendProfile.userName} I would like to know more about ${friendProfile.cityName}`
    );
  }, [friendProfile]);

  const getResourcesCallDurations = async (resourceId) => {
    let response = await getSchedularUser(
      resourceId,
      SCHEDULER_NAVIGATOR.RESOURCES
    );

    if (response) {
      let { appointmentDuration } = response;
      let durationData = [];
      if (appointmentDuration) {
        appointmentDuration.length > 0 &&
          appointmentDuration.map((duration) => {
            return durationData.push(`${duration} mins`);
          });
      } else {
        durationData = selectDuration;
      }
      setResourcesCallDurations(durationData);
    }
  };

  const handleShowDetails = () => {
    setShowDetails(!showDetails);
  };

  const handleShowContributingDetails = () => {
    setShowContributingDetails(!showContributingDetails);
  };

  const getHours = (time, dayStatus) => {
    const [hours, minutes] = time.split(":");
    if (dayStatus === "PM" && hours !== "12") {
      return parseInt(hours) + 12;
    } else if (dayStatus === "AM" && hours === "12") {
      return 0;
    } else {
      return parseInt(hours);
    }
  };

  const getSelectedDateTime = (selectedNewDate, selectedTime) => {
    const date = new Date(selectedNewDate);
    const [time, dayStatus] = selectedTime.split(" ");
    const [hours, minutes] = time.split(":");
    return new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate(),
      getHours(time, dayStatus),
      parseInt(minutes),
      0,
      0
    );
  };

  const setSelectedDate = () => {
    try {
      const selectedDateTimeNew = getSelectedDateTime(
        selectedNewDate,
        setSelectedTime.current
      );
      return selectedDateTimeNew;
    } catch (e) {
      notificationManage("danger", "Please select date and time");
    }
  };

  useEffect(() => {
    getProfileById(friendId)
      .then((res) => {
        setUserDetails({
          user: res,
          keys: Object.keys(res.ratings),
          values: Object.values(res.ratings),
          commentCounts: Object.values(res.commentCounts),
          description: res.description,
        });
      })
      .catch((err) => {
        err?.then((resp) => {
          notificationManage("danger", "User retrievals failed");
        });
      });
  }, []);

  useEffect(() => {
    setScheduleCall(ScheduleCallData);
    if (isSchedule) {
      getProfileById(friendId)
        .then((response) => {
          setFriendProfile(response);
          setFriendSchedularId(response.schedularId);
        })
        .catch((error) => {
          console.error("Error while getting friend profile: ", error);
        });
    } else {
      getCurrentUser()
        .then((response) => {
          setSelectedTimeZoneNew(response?.timeZone);
        })
        .catch((error) => {
          console.error("Error while getting current user: ", error);
        });
    }
  }, [isSchedule, appointmentId]);

  useEffect(() => {
    const path = location.pathname.split("/");
    setIsSchedule(path[path.length - 1] !== "re-schedule");
    setIsReschedule(path[path.length - 1] === "re-schedule");
  }, [location.pathname]);

  useEffect(() => {
    if (isSchedule) {
      getProfileById(friendId).then((response) => {
        setFriendTimeZone(response?.integration?.msbooking?.customerTimeZone);
        setFriendProfile(response);
        setFriendSchedularId(response?.schedulerId);
      });
    }
  }, [isSchedule, friendId]);

  const getTimeDifference = (startTimestamp, endTimestamp) => {
    const diff = new Date(endTimestamp) - new Date(startTimestamp);
    const diffInMinutes = diff / 1000 / 60;
    if (diffInMinutes < 60) {
      return `${Math.floor(diffInMinutes)} mins`;
    } else {
      return `${Math.floor(diffInMinutes / 60)} hour`;
    }
  };

  const handleDurationChange = (e) => {
    setSelectedDuration(e);
  };
  const handleTimeZoneChange = (selected) => {
    if (selected && selected?.length > 0) {
      const selectedTimeZone = selected[0]?.utc;
      setSelectedTimeZoneNew(selectedTimeZone);
    }
  };

  const handleNoteChange = (e) => {
    setNote(e.target.value);
  };

  const convertDate = (date) => {
    const year = date.getFullYear();
    const month = ("0" + (date.getMonth() + 1)).slice(-2);
    const day = ("0" + date.getDate())?.slice(-2);
    const hours = ("0" + date.getHours())?.slice(-2);
    const minutes = ("0" + date.getMinutes())?.slice(-2);
    const seconds = ("0" + date.getSeconds())?.slice(-2);
    const offset = date.getTimezoneOffset();
    const offsetSign = offset > 0 ? "+" : "-";
    const offsetHours = ("0" + Math.floor(Math.abs(offset) / 60))?.slice(-2);
    const offsetMinutes = ("0" + (Math.abs(offset) % 60))?.slice(-2);
    return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.0000000${offsetSign}${offsetHours}:${offsetMinutes}`;
  };

  const getTimeZone = (date) => {
    return date.toString().split("(")[1].split(")")[0];
  };

  useEffect(() => {
    getCurrentUser()
      .then((response) => {
        setCurrentUserId(response?.schedulerId);
        setSelectedTimeZoneNew(response?.timeZone);
        Cookies.set("timeZone", response?.timeZone);
      })
      .catch((error) => {
        notificationManage("danger", "Please login to your account");
      });
  }, [currentUserId]);

  const getAttendeeTimeZone = () => {
    const date = new Date();
    return date.toString().split("(")[1].split(")")[0];
  };

  const getEndDateTime = (duration) => {
    const selectedDate = setSelectedDate();
    let date = new Date(selectedDate.getTime());
    let durationInMs;
    if (duration.split(" ")[1] === "mins") {
      durationInMs = parseInt(duration.split(" ")[0]) * 60 * 1000;
    } else {
      durationInMs = parseInt(duration.split(" ")[0]) * 60 * 60 * 1000;
    }
    date.setTime(date.getTime() + durationInMs);
    return convertDate(date);
  };

  const timeZoneValue = (text, utc) => {
    return text + " " + utc;
  };

  const handleScheduleCall = async () => {
    let isError = false;
    const checkValues = () => {
      if (
        !selectedNewDate ||
        !selectedTimeNew ||
        !selectedDuration ||
        !selectedTimeZoneNew ||
        !note
      ) {
        isError = true;
        return notificationManage("danger", "Please fill all the fields");
      }
    };
    isSchedule && checkValues();

    if (isSchedule && !isError) {
      setLoading(true);
      let formatCalendarData = formatDateTime(selectedNewDate, "YYYY-MM-DD");
      let startDateTime = formatDateTime(
        `${formatCalendarData} ${selectedTimeNew}`,
        "YYYY-MM-DDTHH:mm:ss"
      );
      var endDate = moment(`${formatCalendarData} ${selectedTimeNew}`);
      selectedDuration &&
        endDate.add(parseInt(selectedDuration.split(" ")[0]), "minutes");
      let endDateTime = formatDateTime(endDate, "YYYY-MM-DDTHH:mm:ss");

      const appointment = {
        consumerId: currentUserId,
        resourceId: friendSchedularId,
        availabilityId: null,
        serviceId: null,
        startDateTime: startDateTime,
        endDateTime: endDateTime,
        // TODO Add the remainder type and the minutes
        reminders: [],
        description: note,
        title: "Meeting",
      };
      createAppointments(appointment, friendSchedularId, selectedTimeZoneNew)
        .then(() => {
          setLoading(false);
          notificationManage("success", "Appointment scheduled successfully!");
          localStorage.setItem(
            localStorageScheduleCall.rightPanelState,
            panelState.SCHEDULE_CALL
          );
          history.push("/profile");
        })
        .catch((error) => {
          setLoading(false);
          console.error(error);
        });
    }
  };

  const truncateDescription = (description) => {
    return truncateName(description, 100);
  };

  return (
    <div className="friend-schedule-call">
      <Container className="main">
        <div className="friend-schedule-call-title-container">
          <h1 className="friend-schedule-call-title">
            {isSchedule ? "Schedule" : "Reschedule"} a call with{" "}
            {friendProfile.userName}
          </h1>
        </div>
        <p className="left-panel-header-text friend-schedule-call-title-about">
          About:
        </p>
        <div className="friend-schedule-call-container">
          <div className="friend-schedule-call-container-friend-profile">
            <div className="friend-schedule-call-container-friend-profile-container">
              <FriendProfile
                scheduleCall={scheduleCall}
                friendProfile={friendProfile}
                isStatusPreview={true}
                isFriend={false}
                centerItems
              />
            </div>
            <div className="friend-schedule-call-container-left-side">
              <div className="mobile-container">
                <FriendDescription
                  userDetails={userDetails}
                  showDetails={showDetails}
                  truncateDescription={truncateDescription}
                  handleShowDetails={handleShowDetails}
                  windowWidth={windowWidth.width}
                />
              </div>
              <div className="contribution-container">
                <div className="left-panel-header-text-2">
                  Contributing in :{" "}
                </div>
                <div className="main-card-3">
                  <ContributionDetails
                    userDetails={userDetails}
                    showContributingDetails={showContributingDetails}
                    handleShowContributingDetails={
                      handleShowContributingDetails
                    }
                    windowWidth={windowWidth.width}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="friend-schedule-call-container-friend-schedule">
            <div className="friend-schedule-call-container-friend-schedule-note">
              <div className="friend-schedule-call-container-friend-schedule-note-text">
                <h1 className="friend-schedule-call-container-friend-schedule-note-text-content">
                  Message to {friendProfile.userName} *
                </h1>
              </div>
              <div className="friend-schedule-call-container-friend-schedule-note-input">
                <textarea
                  type="text"
                  className="friend-schedule-call-container-friend-schedule-note-text-input"
                  placeholder="Enter your message here"
                  onChange={handleNoteChange}
                  value={note}
                  maxLength={1000}
                />
                <text className="friend-schedule-call-container-friend-schedule-note-text-input-note">
                  Max: 1000 characters
                </text>
              </div>
            </div>
            <div className="friend-schedule-call-container-friend-select-area">
              <div className="friend-schedule-call-container-friend-select-area-duration">
                <div className="ml-0 drop-space">
                  <div className="edit-profile-settings-select-time-zone-subtitle">
                    <h1 className="edit-profile-settings-select-time-zone-subtitle-content">
                      Set estimated call duration *{" "}
                    </h1>
                    {/* TODO: will be used after payment integration is done */}
                    {/* <div className="call-duration-icon fa fa-info-circle"></div> */}
                  </div>
                  <div className="form-group">
                    <Dropdown
                      className="w-100 form-control p-0"
                      id="exampleFormControlSelect1"
                    >
                      <Dropdown.Toggle className="form-control-toggle w-100 text-left">
                        {selectedDuration}
                      </Dropdown.Toggle>
                      <Dropdown.Menu className="drop w-100">
                        {resourcesCallDurations.length > 0 &&
                          resourcesCallDurations.map((time, index) => (
                            <>
                              <Dropdown.Item
                                key={index}
                                className="form-control-toggle-option"
                                onClick={() => {
                                  handleDurationChange(time);
                                }}
                              >
                                {time}
                              </Dropdown.Item>
                              {resourcesCallDurations.length > 0 &&
                                resourcesCallDurations.length - 1 > index && (
                                  <Dropdown.Divider className="w-100 d" />
                                )}
                            </>
                          ))}
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </div>
              </div>
              <div className="friend-schedule-call-container-friend-select-area-timezone">
                <div className="select-time-zone-space ml-3">
                  <div className="edit-profile-settings-select-time-zone-subtitle">
                    <h1 className="edit-profile-settings-select-time-zone-subtitle-content">
                      Select your time zone *
                    </h1>
                  </div>
                  <OverlayTrigger
                    placement="top"
                    overlay={
                      <Tooltip className="tooltip-timezone">
                        If you want to change the timezone, please change it
                        from the Edit account page
                      </Tooltip>
                    }
                    disabled={selectedTimeZoneNew !== ""}
                  >
                    <div className="form-group">
                      <Typeahead
                        className="time-zonal-friend"
                        defaultInputValue={selectedTimeZoneNew}
                        onChange={(selected) => handleTimeZoneChange(selected)}
                        disabled={selectedTimeZoneNew !== ""}
                        labelKey="text"
                        renderMenuItemChildren={(option, props) => (
                          <div className="option-menu">
                            {truncateName(
                              timeZoneValue(option?.text, option?.utc),
                              45
                            )}
                          </div>
                        )}
                        placeholder="Search a timezone"
                        options={timeZones?.flatMap((time) =>
                          time?.utc?.map((timeUtc) => ({
                            text: time?.text,
                            utc: timeUtc,
                          }))
                        )}
                        selectHintOnEnter={true}
                      />
                    </div>
                  </OverlayTrigger>
                </div>
              </div>
            </div>
            <div className="friend-schedule-call-container-friend-select-date-time">
              <div className="friend-schedule-call-container-friend-select-date-time-title">
                <div className="friend-schedule-call-container-friend-select-date-time-title-main">
                  <h1 className="friend-schedule-call-container-friend-select-date-time-title-main-content">
                    Select date and time *
                  </h1>
                </div>
                <div className="friend-schedule-call-container-friend-select-date-time-content">
                  <AppointmentCalendar
                    setSelectedNewDate={setSelectedNewDate}
                    setSelectedTimeNew={setSelectedTimeNew}
                    selectedTimeZoneNew={selectedTimeZoneNew}
                    friendTimeZone={friendTimeZone}
                    scheduleDate={scheduleDate}
                    friendId={friendId}
                    friendSchedularId={friendSchedularId}
                    selectDurationNew={selectedDuration}
                  />
                </div>
              </div>
              <div className="friend-schedule-call-container-friend-select-date-time-button mb-3">
                <div className="friend-schedule-call-container-friend-select-date-time-container ">
                  <div className="friend-schedule-call-container-friend-select-date-time-border"></div>
                  <button
                    className="btn btn-primary friend-schedule-a-call-button"
                    onClick={handleScheduleCall}
                  >
                    <Calendar3 className="bi bi-calendar3"></Calendar3>
                    {loading && (
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        aria-hidden="true"
                        className="mr-2 pr-1"
                      />
                    )}
                    {isSchedule ? "Schedule" : "Reschedule"} a call
                  </button>
                  <div className="friend-schedule-call-container-friend-agreement mb-5">
                    <div className="friend-schedule-call-container-friend-agreement-text align-items-center">
                      <div>
                        By scheduling a call you agree with our{" "}
                        <a href="/terms-and-conditions">
                          <span className="friend-schedule-call-container-friend-agreement-text-highlight ">
                            Terms & Conditions
                          </span>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default FriendSchedule;
