import React, { useEffect, useState } from "react";
import { createThreadForCity, getCategoryList } from "../../util/APIUtils";
import { notificationManage } from "../../util/NotificationUtils";
import "./Create-Thread.scss";

function CreateThread(props) {
  const [thread, setThread] = useState({
    id: null,
    title: "",
    text: "",
    tag: "",
  });
  const [createThread, setCreateThread] = useState(false);
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    getCategoryList()
      .then((res) => {
        setCategories(res);
      })
      .catch((err) => {
        err?.then((resp) => {
          notificationManage("danger", "Category list list retreival failed");
        });
      });
  }, []);

  const handleChange = (key, value) => {
    setThread({
      ...thread,
      [key]: value,
    });
  };

  const onCreateThreadClick = (value) => {
    setCreateThread(value);
  };

  const handleSubmit = (evt) => {
    evt.preventDefault();

    const dataSet = {
      cityId: props.cityId,
      title: thread.title,
      text: thread.text,
      tags: [thread.tag],
    };

    createThreadForCity(dataSet)
      .then((response) => {
        setCreateThread(false);
        setThread({ id: null, title: "", text: "", tag: "" });
        props.fncStatus(response);
        notificationManage("success", "New question posted");
      })
      .catch((err) => {
        err?.then((resp) => {
          notificationManage("danger", "New question save failed");
        });
      });
  };

  return (
    <div className="col-md-12 create-thread-sectionn">
      {!createThread && (
        <div className="col-md-12 d-flex justify-content-end">
          <button
            className="btn btn-light btn-ask-question"
            onClick={(e) => {
              onCreateThreadClick(true);
            }}>
            <i className="fa fa-plus mr-2"></i>Ask a question
          </button>
        </div>
      )}
      {createThread && (
        <div>
          <div className="d-flex justify-content-start">
            <h4>Create Thread</h4>
          </div>
          <form onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-md-12 form-group">
                <label className="col-12">Title</label>
                <input
                  type="text"
                  required
                  placeholder="Enter title"
                  className="col-12 mx-2 form-control"
                  autoComplete="off"
                  name="titles"
                  id="titles"
                  value={thread?.title}
                  onChange={(e) => handleChange("title", e.target.value)}
                />
              </div>
              <div className="col-md-12 form-group">
                <label className="col-12">Text</label>
                <textarea
                  type="text"
                  placeholder="Enter text"
                  className="col-12 mx-2 form-control"
                  autoComplete="off"
                  name="desc"
                  id="desc"
                  value={thread?.text}
                  onChange={(e) =>
                    handleChange("text", e.target.value)
                  }></textarea>
              </div>
              <div className="col-md-12 form-group">
                <label className="col-12">Tag</label>
                <select
                  className="col-12 mx-2 form-control"
                  autoComplete="off"
                  onChange={(e) => handleChange("tag", e.target.value)}
                  name="tags"
                  id="tags">
                  <option value="null"></option>
                  {categories.map((item, key) => {
                    return (
                      <option value={item.tag} key={key}>
                        {item.title}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
            <div className="row d-flex justify-content-center mt-3">
              <button type="submit" className="btn btn-primary">
                Create
              </button>
              <button
                type="button"
                className="btn btn-secondary ml-2"
                onClick={(e) => {
                  onCreateThreadClick(false);
                }}>
                Cancel
              </button>
            </div>
          </form>
        </div>
      )}
    </div>
  );
}

export default CreateThread;
