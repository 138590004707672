import moment from "moment";
import { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import CalendarModal from "../../components/calendar/CalendarModal";
import { schedulerActions } from "../../constants/scheduleConstants";
import { ReactComponent as CloseIcon } from "../../img/closeIcon.svg";
import { getAvailableTimeSlots, getCurrentUser } from "../../util/APIUtils";
import { formatDateTime } from "../../util/Formatter";
import { notificationManage } from "../../util/NotificationUtils";
import "./ScheduleCalendar.scss";

const ScheduleCalendar = ({
  displayStatus = false,
  resourceID,
  modalTitle,
  modalSubTitle,
  buttonTitle,
  tenantId,
  duration,
  onClose,
  onSubmit = null,
}) => {
  const { rescheduleAndSuggest } = schedulerActions;
  const [calendarDate, setCalendarDate] = useState(new Date());
  const [selectedTime, setSelectedTime] = useState("");
  const [timeSlots, setTimeSlots] = useState([]);
  const [loading, setLoading] = useState(false);
  const [timeZone, setTimeZone] = useState("");
  const [schedularId, setSchedularId] = useState("");
  const [emptyTimeSlots, setEmptyTimeSlots] = useState(false);

  useEffect(() => {
    calendarDate && displayStatus && setTimeSlotsData(calendarDate);
  }, [calendarDate, displayStatus]);

  useEffect(() => {
    getCurrentUser().then((response) => {
      setTimeZone(response.timeZone);
      setSchedularId(response.schedulerId);
    }).catch(console.warn);
  }, []);

  useEffect(() => {
    if (!displayStatus) {
      setCalendarDate(new Date());
      setSelectedTime("");
      setEmptyTimeSlots(false);
    }
  }, [displayStatus]);

  const setTimeSlotsData = async (calendarDateData) => {
    let notification = {
      type: "",
      msg: "",
      status: false,
    };
    try {
      setLoading(true);
      let formatCalendarData = formatDateTime(calendarDateData, "YYYY-MM-DD");
      let responseResourceTimeSlots = await getAvailableTimeSlots(
        resourceID,
        formatCalendarData,
        formatCalendarData,
        duration,
        timeZone
      );
      let slotsInDate = responseResourceTimeSlots;
      if (slotsInDate) {
        setTimeSlots(slotsInDate?.days[0]?.slots);
        setEmptyTimeSlots(slotsInDate?.days?.length === 0);
      }
    } catch (error) {
      notification = {
        status: true,
        type: "danger",
        msg: "Get Resource Time Slots Error Please Try Again",
      };
    }
    notification.status &&
      notificationManage(notification.type, notification.msg);
    setLoading(false);
  };

  const onActionHandle = (type) => {
    if (type === rescheduleAndSuggest) {
      if (selectedTime && calendarDate) {
        if (selectedTime) {
          let formatCalendarData = formatDateTime(calendarDate, "YYYY-MM-DD");
          let startDateTime = formatDateTime(
            `${formatCalendarData} ${selectedTime}`,
            "YYYY-MM-DDTHH:mm:ss"
          );
          var endDatetime = moment(`${formatCalendarData} ${selectedTime}`);
          endDatetime.add(duration, "minutes");
          let updatedEndDateTime = formatDateTime(
            endDatetime,
            "YYYY-MM-DDTHH:mm:ss"
          );
          let dateTimeObject = {
            startDateTime: startDateTime,
            endDateTime: updatedEndDateTime,
          };
          onSubmit(dateTimeObject);
        }
      }
    }
  };

  return (
    <Modal
      centered
      backdrop="static"
      show={displayStatus}
      onHide={() => onClose()}
      className="scheduler-calendar-modal"
    >
      <Modal.Header className="modal-header-close-btn">
        <Modal.Title className="modal-title-calender">{modalTitle}</Modal.Title>
        <button className="close" onClick={() => onClose()}>
          <CloseIcon />
        </button>
      </Modal.Header>

      <Modal.Body className="scheduling-model">
        <div className="modal-body-container">
          <div className="modal-body-title">{modalSubTitle}</div>
          <div class="modal-body-calendar-container">
            <div className="schedule-calendar-content">
              <div className="schedule-calendar-content-calendar">
                <CalendarModal
                  setCalendarDate={setCalendarDate}
                  friendId={resourceID}
                  setSelectedTime={setSelectedTime}
                />
              </div>
              <div className="schedule-calendar-content-time-slots">
                <div className="schedule-calendar-content-time-slots-title">
                  <p>Choose available time slot</p>
                </div>
                {loading ? (
                  <SkeletonTheme color="#DEDEDE" highlightColor="#D2DDEE">
                    <div className="scheduler-time-slots-skelton">
                      <p className="scheduler-time-slots-skelton-line">
                        <Skeleton className="scheduler-time-slots-skelton-line-inside" />
                      </p>
                      <p className="scheduler-time-slots-skelton-line">
                        <Skeleton className="scheduler-time-slots-skelton-line-inside" />
                      </p>
                      <p className="scheduler-time-slots-skelton-line pb-5">
                        <Skeleton className="scheduler-time-slots-skelton-line-inside" />
                      </p>
                    </div>
                  </SkeletonTheme>
                ) : (
                  <div
                    className={`schedule-calendar-content-time-slots-times ${
                      emptyTimeSlots
                        ? "schedule-calendar-content-time-slots-times-hide-grid"
                        : ""
                    }`}
                  >
                    {emptyTimeSlots ? (
                      <p className="schedule-calendar-content-time-slots-times-text">
                        Sorry, there are no available timeslots for this day.
                      </p>
                    ) : (
                      timeSlots?.map((timeSlot) => (
                        <button
                          key={timeSlot}
                          className={`btn time-slot-table-cell ${
                            selectedTime === timeSlot
                              ? "schedule-calendar-selectedTimeSlot"
                              : ""
                          }`}
                          onClick={() => setSelectedTime(timeSlot)}
                          disabled={
                            moment().tz(timeZone).format("YYYY-MM-DD") ===
                              moment(calendarDate).format("YYYY-MM-DD") &&
                            moment().tz(timeZone).format("HH:mm") >
                              timeSlot?.slice(0, 5)
                          }
                        >
                          {timeSlot?.slice(0, 5)}
                        </button>
                      ))
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div class="schedule-calendar-button-container">
            <Button
              className="btn common-button common-button-confirm-schedule"
              onClick={() => onActionHandle(rescheduleAndSuggest)}
              disabled={!selectedTime}
            >
              {buttonTitle}
            </Button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ScheduleCalendar;
