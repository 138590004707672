import Cookies from "js-cookie";
import { useEffect, useState } from "react";
import {
  Button,
  FormControl,
  Modal,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { Typeahead } from "react-bootstrap-typeahead";
import InputGroup from "react-bootstrap/InputGroup";
import { ACCESS_TOKEN, BASE_URL } from "../../constants";
import { useVerified } from "../../context/UserContext";
import {
  createQuestionByCityAndParentId,
  getCategoryList,
  getCities,
  getCurrentUser,
  getProfile,
} from "../../util/APIUtils";
import { isJWTExpired } from "../../util/JWTUtils";
import { notificationManage } from "../../util/NotificationUtils";
import { errVal, hideEmailCharacters } from "../../util/Utils";
import EmailVerificationModal from "../email-verification-modal/Email-verification-modal";
import PopupLogin from "../popup-login/Popup-Login";
import QuestionConfirmationModel from "../question-confirmation-modal/Question-Confirmation-Modal";
import SuccessfulSignUp from "../successful-sign-up-modal/SuccessfulSignUp";
import "./Ask-Question-Modal.scss";

export const AskAQuestionModal = (props) => {
  const setNewQuestionPosted = props.setNewQuestionPosted;
  const buttonName = props.buttonName;
  const setShowAlertBox = props.setShowAlertBox;
  const [categories, setCategories] = useState([]);
  const [cities, setCities] = useState([]);
  const [showQuestionModal, setShowQuestionModal] = useState(false);
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [question, setQuestion] = useState({
    title: "",
    text: "",
    category: "",
    cityId: null,
    city: null,
  });
  const [searchString, setSearchString] = useState();
  const [cityIndex, setCityIndex] = useState(
    cities.findIndex((value) => {
      return props.cityId === value.id;
    })
  );
  const { currentUser } = useVerified();
  const [requireVerification, setRequireVerification] = useState(false);
  const [currentUserProfile, setCurrentUserProfile] = useState(null);
  const [showSuccessfulLogin, setShowSuccessfulLogin] = useState(false);
  const accessToken = localStorage.getItem(ACCESS_TOKEN);
  const [isDisabled, setIsDisabled] = useState(false);

  useEffect(() => {
    localStorage.getItem(ACCESS_TOKEN) &&
      getCurrentUser().then((user) => {
        getProfile(user.id).then((currentUserProfile) => {
          setCurrentUserProfile(currentUserProfile);
          if (sessionStorage.getItem("ask-a-question") === "true") {
            setShowAlertBox(!!currentUserProfile?.isFriend);
            setRequireVerification(!currentUserProfile?.emailVerified);
            setShowQuestionModal(
              !currentUserProfile?.isFriend && currentUserProfile?.emailVerified
            );
          }
        });
      }).catch(console.warn);
  }, [props]);

  useEffect(() => {
    getCategoryList()
      .then((res) => {
        const sortedCategories = res?.sort((a, b) =>
          a.title.localeCompare(b.title)
        );
        const otherIndex = sortedCategories?.findIndex(
          (item) => item.title === "Other"
        );
        if (otherIndex !== -1) {
          const otherCategory = sortedCategories?.splice(otherIndex, 1)[0];
          sortedCategories?.push(otherCategory);
        }
        setCategories(sortedCategories);
      })
      .catch(() => {
        notificationManage("danger", "Category list retrieval failed");
      });
  }, []);

  useEffect(() => {
    getCities()
      .then((data) => {
        let newCityIndex = data.findIndex((value) => props.cityId === value.id);
        setCities(data);
        setCityIndex(newCityIndex);
      setQuestion((prev) => ({
          ...prev,
          city: data[newCityIndex]?.name,
          cityId: data[newCityIndex]?.id,
          category: searchString,
        }));
      })
      .catch(() => {
        notificationManage("danger", "City retrieval failed");
      });
  }, [props.cityId]);

  const handleQuestionTitleChange = (e) => {
    e.preventDefault();
    setQuestion((prev) => ({
      ...prev,
      title: e.target.value,
    }));
  };

  const checkIsSessionTimeOut = () => {
    if (accessToken && isJWTExpired(accessToken)) {
      localStorage.removeItem(ACCESS_TOKEN);
      return true;
    }
    return false;
  };

  useEffect(() => {
    if (checkIsSessionTimeOut()) {
      window.location.href = `${BASE_URL}/login`;
    }
  });

  const handleQuestionDescriptionChange = (e) => {
    e.preventDefault();
    setQuestion((prev) => ({
      ...prev,
      text: e.target.value,
    }));
  };

  const handleCitySelect = (value) => {
    if (value[0]) {
      setQuestion((prev) => ({
        ...prev,
        cityId: value[0].id,
        city: value[0].name,
      }));
    } else {
      setQuestion((prev) => ({
        ...prev,
        cityId: null,
        city: null,
      }));
    }
  };

  useEffect(() => {
    if (localStorage.getItem(ACCESS_TOKEN)) {
      const isEmpty = Cookies.get("isEmpty");
      if (!errVal.includes(isEmpty) && JSON.parse(isEmpty) === true) {
        setIsDisabled(true);
      }
    }
  }, []);

  const checkValidation = () => {
    return (
      question.cityId &&
      question.title &&
      question.category &&
      question.category.length > 0
    );
  };

  const handleConfirmationSubmit = () => {
    if (checkValidation()) {
      const formData = new FormData();
      formData.append("cityId", question.cityId);
      formData.append("title", question.title);
      formData.append("text", question.text);
      formData.append(
        "tags",
        question.category.map((value) => {
          return value.toUpperCase();
        })
      );
      setShowQuestionModal(false);
      sessionStorage.removeItem("ask-a-question");
      createQuestionByCityAndParentId(formData)
        .then(() => {
          notificationManage(
            "success",
            "Successfully submitted, pending approval"
          );
          setQuestion((prev) => ({
            ...prev,
            title: "",
            text: "",
            category: "",
          }));
          Array.from(document.querySelectorAll("textarea")).forEach(
            (textarea) => (textarea.value = "")
          );
          setNewQuestionPosted(true);
          setSearchString(null);
        })
        .catch(() => {
          notificationManage("danger", "Something went wrong");
        });
    } else {
      notificationManage("warning", "Please fill in all the fields");
    }
  };

  const handleHideVerificationModal = () => {
    setRequireVerification(!requireVerification);
    sessionStorage.removeItem("ask-a-question");
  };

  const handleAskQuestionSubmit = () => {
    if (checkValidation()) {
      setShowConfirmationModal(true);
    } else {
      notificationManage("warning", "Please fill in all the fields");
    }
  };

  const handleAskQuestionClose = () => {
    setQuestion((prev) => ({
      ...prev,
      title: "",
      text: "",
    }));
    setShowQuestionModal(false);
    sessionStorage.removeItem("ask-a-question");
  };

  return (
    <>
      {(currentUserProfile?.isFriend === false || !currentUserProfile) && (
        <OverlayTrigger
          placement={"auto"}
          delay={{ show: 0, hide: 400 }}
          overlay={
            <Tooltip
              className={`${isDisabled ? "tooltip-extra-info" : "d-none"}`}
            >
              {isDisabled &&
                "You can't perform this action because you have not completed your profile"}
            </Tooltip>
          }
        >
          <div>
            <Button
              className="question-button mt-3 py-1 px-4"
              disabled={isDisabled}
              variant="primary"
              onClick={() =>
                getCurrentUser()
                  .then((currentUser) => {
                    if (!currentUser.emailVerified) {
                      setRequireVerification(true);
                    } else {
                      setShowQuestionModal(true);
                    }
                  })
                  .catch(() => {
                    sessionStorage.setItem("ask-a-question", true);
                    setShowLoginModal(true);
                  })
              }
            >
              {buttonName}
            </Button>
          </div>
        </OverlayTrigger>
      )}
      <PopupLogin
        show={showLoginModal}
        onHide={setShowLoginModal}
        name="Sign In"
        onSuccessfulSignIn={() => {
          getCurrentUser().then((data) => {
            getProfile(data.id).then((userProfile) => {
              if (userProfile.loginCount === 1) {
                setShowSuccessfulLogin(true);
              } else {
                if (userProfile.isFriend) {
                  setShowAlertBox(true);
                } else if (!userProfile.emailVerified) {
                  setRequireVerification(true);
                } else {
                  setShowQuestionModal(true);
                }
              }
            });
          }).catch(console.warn);
        }}
        reloadCurrentUser={props.reloadCurrentUser}
        isFriendDefaultChecked={false}
      />
      <SuccessfulSignUp
        show={showSuccessfulLogin}
        onHide={() => {
          setShowSuccessfulLogin(false);
          setShowQuestionModal(true);
        }}
      />
      <EmailVerificationModal
        isShow={requireVerification}
        handleVerificationModal={() => {
          handleHideVerificationModal();
        }}
        title="Verify your Email"
        description="Almost there! We have sent a verification email to"
        email={currentUser && hideEmailCharacters(currentUser.email)}
        footer="You need to verify your email address to use the services offered by Move2NewCity."
      />
      <QuestionConfirmationModel
        showConfirmationModal={showConfirmationModal}
        setShowConfirmationModal={setShowConfirmationModal}
        questionTitle={question.title}
        handleConfirmationSubmit={handleConfirmationSubmit}
      />
      <Modal
        show={showQuestionModal}
        onHide={() => handleAskQuestionClose()}
        dialogClassName="question"
        className="question-modal"
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Header className="question-header" closeButton></Modal.Header>
        <Modal.Body className="show-grid px-3 py-0">
          <div className="question-content">
            <div className="question-content-header">
              <h1 className="question-content-title">Ask a question</h1>
              <div className="underline ml-1">
                <hr className="underline-hr" />
              </div>
            </div>
            <p className="input-title">Question *</p>
            <InputGroup>
              <FormControl
                placeholder="e.g: Type your question here"
                className="question-input question-input-title"
                as="textarea"
                aria-label="With textarea"
                onChange={(e) => {
                  handleQuestionTitleChange(e);
                }}
              />
            </InputGroup>
            <p className="input-title">Description</p>
            <InputGroup>
              <FormControl
                placeholder="e.g: Explain your question"
                className="question-input question-input-description"
                as="textarea"
                aria-label="With textarea"
                onChange={(e) => {
                  handleQuestionDescriptionChange(e);
                }}
              />
            </InputGroup>
            <p className="category-title">
              Under what category does your question fall? *
            </p>
            <div className="category-div mt-1 row mb-3">
              {categories.map((item, key) => {
                return (
                  <div
                    key={key}
                    className={`${
                      searchString?.some((tag) => tag === item.tag)
                        ? "active"
                        : ""
                    } my-2 py-2 px-3 category`}
                    onClick={(e) => {
                      let newSearchString = [];
                      if (searchString) {
                        newSearchString = searchString?.slice(0);
                      }
                      if (searchString?.some((tag) => tag === item?.tag)) {
                        newSearchString = newSearchString?.filter((value) => {
                          return value !== item?.tag;
                        });
                      } else {
                        newSearchString.push(item?.tag);
                      }
                      setSearchString(newSearchString);
                      setQuestion((prev) => ({
                        ...prev,
                        category: newSearchString,
                      }));
                    }}
                  >
                    <span>{item?.title}</span>
                  </div>
                );
              })}
            </div>
            <p className="city-title">City *</p>
            <div>
              <Typeahead
                id="search-city"
                labelKey="name"
                dropup
                options={cities}
                onChange={(selected) => handleCitySelect(selected)}
                placeholder="Search a city"
                className="city-input-question"
                defaultSelected={cities.slice(cityIndex, cityIndex + 1)}
                clearButton
              />
            </div>
            <Button
              className="question-submit mb-2"
              onClick={(e) => {
                handleAskQuestionSubmit();
              }}
            >
              Submit
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
