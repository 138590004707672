import {
  ACCESS_TOKEN,
  API_BASE_URL,
  BASE_URL,
  REFRESH_TOKEN,
  SCHEDULER_NAVIGATOR,
} from "../constants";
import { checkRefreshToken, isJWTExpired } from "./JWTUtils";
import { matchPath } from "react-router-dom";

const isAccessOpen = (pathname) => {
  [
    "/commiunity/:id",
    "/thread/:id",
    "/city/:id",
    "/city-history/:id",
    "/recover/reset-password/:token",
    "/activate-account/:token",
    "/friends/:id",
  ].forEach((path) => {
    if (matchPath(pathname, path)) {
      return true;
    }
  });
  return [
    "/become-a-friend",
    "/social-signup",
    "/oauth2/redirect",
    "/terms-and-conditions",
    "/how-it-works",
    "/request-demo",
    "/about-us",
    "/contact-us",
    "/account-role-exist",
    "/social-login-exist",
    "/privacy-policy",
  ].includes(pathname);
};

const ACCESS = Object.freeze({
  AUTH: "authenticated",
  OPEN: "open",
});

const request = (options, formData, isImage) => {
  const headers = new Headers();
  !formData && headers.append("Content-Type", "application/json");
  const accessToken = localStorage.getItem(ACCESS_TOKEN);
  if (!options.ACCESS) console.error("Access definition not found.")
  if (options.access === ACCESS.AUTH) {
    if (accessToken && !isJWTExpired(accessToken)) {
      headers.append("Authorization", "Bearer " + accessToken);
      if (options?.customHeader) {
        options.customHeader?.forEach((e) => {
          headers.append(e?.key, e?.value);
        });
      }
      if (checkRefreshToken(accessToken)) {
        getNewAccessToken(headers);
      }
    } else if (accessToken) {
      if (
        window.location.pathname !== "/" &&
        window.location.pathname !== "/login"
      ) {
        window.location.pathname = isAccessOpen(window.location.pathname) ? '/' : '/login'
      }
    }
  }
  const defaults = { headers: headers };
  options = Object.assign({}, defaults, options);
  return fetch(options?.url, options)
    .then((response) => {
      // Skip the jsonify for the login API, since we need to handle the auth states
      if (response?.url?.includes("/api/auth/login")) {
        return response;
      }
      if (response?.url?.includes("/api/auth/signup")) {
        return response;
      }
      if (response?.url?.includes("/api/v1/recover/reset-password")) {
        return response;
      }
      if (response?.status === 204) {
        return response;
      }
      const json = response.json();
      if (!response.ok) {
        return Promise.reject(json);
      } else if (options?.method === "DELETE" || isImage) {
        return true;
      }
      return json;
    })
    .catch((error) => {
      if (error instanceof Promise) {
        error?.then((response) => {
          if (
            response?.status === 500 &&
            window.location.pathname !== "/server-error"
          ) {
            console.error("Server Error: ",response)
            window.location.pathname = "/server-error";
          }
        });
      }
    });
};

const getNewAccessToken = (headers) => {
  const refreshToken = localStorage.getItem(REFRESH_TOKEN);
  let refreshOptions = {
    method: "POST",
    body: JSON.stringify({ refreshToken: refreshToken }),
  };
  const defaults = { headers: headers };
  refreshOptions = Object.assign({}, defaults, refreshOptions);
  return fetch(API_BASE_URL + "/auth/refresh", refreshOptions).then(
    (response) => {
      const json = response.json();
      json.then((accessTokenResponse) => {
        localStorage.setItem(ACCESS_TOKEN, accessTokenResponse.accessToken);
      });
    }
  );
};

export const getCurrentUser = () => {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  } else if (isJWTExpired(localStorage.getItem(ACCESS_TOKEN))) {
    return Promise.reject("Access token expired.");
  }
  return request({
    url: API_BASE_URL + "/v1/users/me",
    method: "GET",
    access: ACCESS.AUTH,
  });
};

export const login = (loginRequest) => {
  return request({
    url: API_BASE_URL + "/auth/login",
    method: "POST",
    body: JSON.stringify(loginRequest),
    access: ACCESS.OPEN,
  });
};

export const signup = (signupRequest) => {
  return request({
    url: API_BASE_URL + "/auth/signup",
    method: "POST",
    body: JSON.stringify(signupRequest),
    access: ACCESS.OPEN,
  });
};

export const socialSignup = (token, timeZone, isFriend) => {
  return request({
    url:
      API_BASE_URL +
      `/auth/social-signup?token=${token}&timeZone=${timeZone}&isFriend=${isFriend}`,
    method: "POST",
    access: ACCESS.OPEN,
  });
};

export const activateAccount = (token) => {
  return request({
    url: API_BASE_URL + "/v1/user-accounts/" + token + "/activate",
    method: "PUT",
    access: ACCESS.AUTH,
  });
};

export const getCities = () => {
  return request({
    url: API_BASE_URL + "/v1/cities",
    method: "GET",
    access: ACCESS.OPEN,
  });
};

export const deleteCity = (id) => {
  return request({
    url: API_BASE_URL + "/v1/cities/" + id + "/state",
    method: "PUT",
    access: ACCESS.AUTH,
  });
};

export const getCity = (id) => {
  return request({
    url: API_BASE_URL + "/v1/cities/" + id,
    method: "GET",
    access: ACCESS.OPEN,
  });
};

export const getThreadListCity = (cityId, tagsList, keywords) => {
  const queryParams =
    (tagsList?.length > 0 ? "?tags=" + tagsList : "") +
    (keywords != null
      ? (tagsList?.length > 0 ? "&" : "?") + "keyword=" + keywords
      : "");
  return request({
    url: API_BASE_URL + "/v1/posts/city/" + cityId + queryParams,
    method: "GET",
    access: ACCESS.OPEN,
  });
};

export const addCity = (city) => {
  return request(
    {
      url: API_BASE_URL + "/v1/cities/",
      method: "POST",
      body: city,
      access: ACCESS.AUTH,
    },
    true
  );
};

export const editCity = (id, city) => {
  return request({
    url: API_BASE_URL + "/v1/cities/" + id,
    method: "PUT",
    body: JSON.stringify(city),
    access: ACCESS.AUTH,
  });
};

export const editCityMedia = (id, file, type) => {
  return request(
    {
      url: `${API_BASE_URL}/v1/cities/${id}/${type}`,
      method: "PUT",
      body: file,
      access: ACCESS.AUTH,
    },
    true,
    true
  );
};

export const deleteCityBanner = (id, index) => {
  return request({
    url: `${API_BASE_URL}/v1/cities/${id}/banner/${index}`,
    method: "DELETE",
    access: ACCESS.AUTH,
  });
};

export const createThreadForCity = (data) => {
  return request({
    url: API_BASE_URL + "/v1/posts",
    method: "POST",
    body: JSON.stringify(data),
    access: ACCESS.AUTH,
  });
};

export const voteForPostById = (postId, data) => {
  return request({
    url: API_BASE_URL + "/v1/interactions/post/" + postId,
    method: "POST",
    body: JSON.stringify(data),
    access: ACCESS.AUTH,
  });
};

export const getPostById = (postId) => {
  return request({
    url: API_BASE_URL + "/v1/posts/" + postId,
    method: "GET",
    access: ACCESS.OPEN,
  });
};

export const createCommentForParent = (data) => {
  return request(
    {
      url: API_BASE_URL + "/v1/posts",
      method: "POST",
      body: data,
      access: ACCESS.AUTH,
    },
    true
  );
};

export const deletePostById = (id) => {
  return request({
    url: API_BASE_URL + "/v1/posts/" + id,
    method: "DELETE",
    access: ACCESS.AUTH,
  });
};

export const EditPostById = (postId, data) => {
  return request({
    url: API_BASE_URL + "/v1/posts/" + postId,
    method: "PUT",
    body: JSON.stringify(data),
    access: ACCESS.AUTH,
  });
};

export const getUsers = (role) => {
  return request({
    url: API_BASE_URL + "/v1/users/" + role,
    method: "GET",
    access: ACCESS.OPEN,
  });
};

export const addUser = (user) => {
  return request(
    {
      url: API_BASE_URL + "/v1/users/",
      method: "POST",
      body: user,
      access: ACCESS.AUTH,
    },
    true
  );
};

export const editUser = (id, data) => {
  return request(
    {
      url: API_BASE_URL + "/v1/users/" + id,
      method: "PUT",
      body: data,
      access: ACCESS.AUTH,
    },
    true
  );
};

export const getProfile = (id) => {
  return request({
    url: API_BASE_URL + "/v1/users/" + id + "/profile",
    method: "GET",
    access: ACCESS.OPEN,
  });
};

export const editProfile = (id, file) => {
  return request(
    {
      url: `${API_BASE_URL}/v1/users/${id}/profile`,
      method: "PUT",
      body: file,
      access: ACCESS.AUTH,
    },
    true
  );
};

export const getCityBannerByIndex = (cityId, index) => {
  return request({
    url: API_BASE_URL + "/v1/cities/" + cityId + "/banner/" + index,
    method: "GET",
    access: ACCESS.OPEN,
  });
};

export const getPostCountByCityId = (cityId) => {
  return request({
    url: API_BASE_URL + "/v1/posts/count/city/" + cityId,
    method: "GET",
    access: ACCESS.OPEN,
  });
};

export const saveArticle = (article) => {
  return request({
    url: API_BASE_URL + "/v1/articles",
    method: "POST",
    body: JSON.stringify(article),
    access: ACCESS.AUTH,
  });
};

export const getAllArticles = () => {
  return request({
    url: API_BASE_URL + "/v1/articles?display=true",
    method: "GET",
    access: ACCESS.OPEN,
  });
};

export const getArticleByCityAndCategory = (cityId, categories) => {
  const queryParams = categories?.length > 0 ? "?categories=" + categories : "";
  return request({
    url: API_BASE_URL + "/v1/articles/" + cityId + queryParams,
    method: "GET",
    access: ACCESS.OPEN,
  });
};

export const editArticle = (articleId, data) => {
  return request({
    url: `${API_BASE_URL}/v1/articles/${articleId}`,
    method: "PUT",
    body: JSON.stringify(data),
    access: ACCESS.AUTH,
  });
};

export const getCategoryList = () => {
  return request({
    url: API_BASE_URL + "/v1/categories",
    method: "GET",
    access: ACCESS.OPEN,
  });
};

export const getFollowingFriendsByCity = (cityId) => {
  return request({
    url: API_BASE_URL + "/v1/cities/" + cityId + "/following-friends",
    method: "GET",
    access: ACCESS.OPEN,
  });
};

export const getFriendsByCityId = (cityId) => {
  return request({
    url: API_BASE_URL + "/v1/cities/" + cityId + "/friends",
    method: "GET",
    access: ACCESS.OPEN,
  });
};

export const getProfileById = (userId) => {
  return request({
    url: API_BASE_URL + "/v1/users/" + userId + "/profile",
    method: "GET",
    access: ACCESS.OPEN,
  });
};

export const getQuestionsByCityId = (cityId, tagsList, keywords) => {
  const queryParams =
    (tagsList?.length > 0 ? "?tags=" + tagsList : "") +
    (keywords != null
      ? (tagsList?.length > 0 ? "&" : "?") + "keyword=" + keywords
      : "");
  return request({
    url: API_BASE_URL + "/v1/posts/city/" + cityId + "/questions" + queryParams,
    method: "GET",
    access: ACCESS.OPEN
  });
};

export const followFriend = (friendId) => {
  return request({
    url: API_BASE_URL + "/v1/users/" + friendId + "/follow",
    method: "PUT",
    access: ACCESS.AUTH,
  });
};

export const unfollowFriend = (friendId) => {
  return request({
    url: API_BASE_URL + "/v1/users/" + friendId + "/unfollow",
    method: "PUT",
    access: ACCESS.AUTH,
  });
};

export const createQuestionByCityAndParentId = (data) => {
  return request(
    {
      url: API_BASE_URL + "/v1/posts?type=QUESTION",
      method: "POST",
      body: data,
      access: ACCESS.AUTH,
    },
    true
  );
};

export const getAllCommentByUserId = (id) => {
  return request({
    url: API_BASE_URL + "/v1/posts/user/" + id + "/comments",
    method: "GET",
    access: ACCESS.OPEN,
  });
};

export const isUsernameExists = (userName) => {
  return request({
    url: API_BASE_URL + "/v1/users/exists/" + userName,
    method: "GET",
    access: ACCESS.OPEN,
  });
};

export const notificationDisplayValueReSetter = () =>
  request({
    url: `${API_BASE_URL}/v1/notifications`,
    method: "PUT",
    access: ACCESS.AUTH,
  });

export const seenNotificationUpdate = (notificationId) =>
  request({
    url: `${API_BASE_URL}/v1/notifications/${notificationId}/status`,
    method: "PUT",
    access: ACCESS.AUTH,
  });

export const SuspendUser = (userId) => {
  return request({
    url: API_BASE_URL + "/v1/users/" + userId + "/suspend",
    method: "PUT",
    access: ACCESS.AUTH,
  });
};

export const deleteAdminById = (userId) => {
  return request({
    url: API_BASE_URL + "/v1/users/" + userId + "/delete",
    method: "PUT",
    access: ACCESS.AUTH,
  });
};

export const reviewPost = (id, isApproved, rejectMessageBody) => {
  return request({
    url: API_BASE_URL + "/v1/posts/" + id + "/status?postStatus=" + isApproved,
    method: "PUT",
    body: JSON.stringify(rejectMessageBody),
    access: ACCESS.AUTH,
  });
};

export const getAllQuestionsByStatus = (statusType) => {
  return request({
    url: API_BASE_URL + "/v1/posts/?status=" + statusType,
    method: "GET",
    access: ACCESS.OPEN,
  });
};

export const updatePostById = (id, data) => {
  return request({
    url: API_BASE_URL + "/v1/posts/" + id,
    method: "PUT",
    body: JSON.stringify(data),
    access: ACCESS.AUTH,
  });
};

export const getInquiries = () => {
  return request({
    url: API_BASE_URL + "/v1/inquiries",
    method: "GET",
    access: ACCESS.AUTH,
  });
};

export const createEvent = (data) => {
  return request(
    {
      url: `${API_BASE_URL}/v1/events`,
      method: "POST",
      body: data,
      access: ACCESS.AUTH,
    },
    true
  );
};

export const getAllEventsByDisplay = (isDisplay) => {
  return request({
    url: `${API_BASE_URL}/v1/events?display=${isDisplay}`,
    method: "GET",
    access: ACCESS.OPEN,
  });
};

export const getAllEventsByCityIdAndDisplay = (cityId) => {
  return request({
    url: `${API_BASE_URL}/v1/events/${cityId}`,
    method: "GET",
    access: ACCESS.OPEN,
  });
};

export const editEvent = (id, data) => {
  return request(
    {
      url: `${API_BASE_URL}/v1/events/${id}`,
      method: "PUT",
      body: data,
      access: ACCESS.AUTH,
    },
    true
  );
};

export const deleteEvent = (id) => {
  return request({
    url: `${API_BASE_URL}/v1/events/${id}`,
    method: "DELETE",
    access: ACCESS.AUTH,
  });
};

export const getImageForEvent = (id) => {
  return request({
    url: `${API_BASE_URL}/v1/events/${id}/image`,
    method: "GET",
    access: ACCESS.OPEN,
  });
};

export const postInquiry = (data) => {
  return request({
    url: API_BASE_URL + "/v1/inquiries",
    method: "POST",
    body: JSON.stringify(data),
    access: ACCESS.OPEN,
  });
};

export const replyToInquiry = (id, data) => {
  return request({
    url: API_BASE_URL + "/v1/inquiries/" + id,
    method: "PUT",
    body: JSON.stringify(data),
    access: ACCESS.AUTH,
  });
};

export const getInquiryById = (id) => {
  return request({
    url: API_BASE_URL + "/v1/inquiries/" + id,
    method: "GET",
    access: ACCESS.AUTH,
  });
};

export const getAllInquiries = () => {
  return request({
    url: API_BASE_URL + "/v1/inquiries",
    method: "GET",
    access: ACCESS.AUTH,
  });
};

export const getCitiesForInquiries = () => {
  return request({
    url: API_BASE_URL + "/v1/inquiries/suggest-cities",
    method: "GET",
    access: ACCESS.OPEN,
  });
};

export const requestPasswordReset = (email) => {
  return request({
    url: `${API_BASE_URL}/v1/recover/forgot-password`,
    method: "POST",
    body: email,
    access: ACCESS.OPEN,
  });
};

export const getPasswordResetToken = (token) => {
  return request({
    url: `${API_BASE_URL}/v1/recover/reset-password/${token}`,
    method: "GET",
    access: ACCESS.OPEN,
  });
};

export const changePassword = (password, token) => {
  return request({
    url: `${API_BASE_URL}/v1/recover/reset-password/${token}`,
    method: "PUT",
    body: password,
  });
};

export const getNotifications = (id, page) => {
  return request({
    url: API_BASE_URL + "/v1/notifications/" + id + "/" + page,
    method: "GET",
    access: ACCESS.AUTH,
  });
};

export const getUnreadNotificationCount = (id) => {
  return request({
    url: API_BASE_URL + "/v1/notifications/" + id + "/count",
    method: "GET",
    access: ACCESS.AUTH,
  });
};

export const unSubscribeEmail = (id) => {
  return request({
    url: API_BASE_URL + "/v1/users/email-unsubscribe/" + id,
    method: "PUT",
    access: ACCESS.OPEN,
  });
};

export const changeUserType = (id, isFriend) => {
  return request({
    url: `${API_BASE_URL}/v1/users/${id}/friend-status`,
    method: "PUT",
    body: isFriend,
    access: ACCESS.AUTH,
  });
};

export const postDemoRequest = (email) => {
  return request({
    url: API_BASE_URL + "/v1/demo-request",
    method: "POST",
    body: JSON.stringify(email),
    access: ACCESS.OPEN,
  });
};

export const getLocInfo = (lat, lng) => {
  return request({
    url: `${API_BASE_URL}/v1/analytics/search?latitude=${lat}&longitude=${lng}`,
    method: "GET",
    access: ACCESS.AUTH,
  });
};

export const postAnalytics = (data) => {
  return request({
    url: `${API_BASE_URL}/v1/analytics`,
    method: "POST",
    body: JSON.stringify(data),
    access: ACCESS.OPEN,
  });
};

export const createAppointments = (
  data,
  schedularId,
  timeZone = "Asia/Colombo"
) => {
  return request({
    url: `${API_BASE_URL}/v1/scheduler/appointments?action=${SCHEDULER_NAVIGATOR.SCHEDULE}`,
    method: "POST",
    body: JSON.stringify(data),
    customHeader: [
      { key: "X-TimeZone", value: timeZone },
      { key: "X-User", value: schedularId },
    ],
    access: ACCESS.AUTH,
  });
};

export const rescheduleSuggestAppointments = (
  data,
  action,
  appointmentId,
  schedularId,
  schedularNavigator,
  timeZone = "Asia/Colombo"
) => {
  return request({
    url: `${API_BASE_URL}/v1/scheduler/appointments/${appointmentId}/${action}?action=${schedularNavigator}`,
    method: "PUT",
    body: JSON.stringify(data),
    customHeader: [
      { key: "X-TimeZone", value: timeZone },
      { key: "X-User", value: schedularId },
    ],
    access: ACCESS.AUTH,
  });
};

export const updateOverridesAvailability = (
  data,
  schedularId,
  date,
  timeZone = "Asia/Colombo"
) => {
  return request({
    url: `${API_BASE_URL}/v1/scheduler/availabilities/${schedularId}/${date}`,
    method: "PUT",
    body: JSON.stringify(data),
    customHeader: [{ key: "X-TimeZone", value: timeZone }],
    access: ACCESS.AUTH,
  });
};

export const getAvailability = (schedularId) => {
  return request({
    url: `${API_BASE_URL}/v1/scheduler/availability/${schedularId}`,
    method: "GET",
    access: ACCESS.AUTH,
  });
};

export const deleteOverridesAvailability = (schedularId, date) => {
  return request({
    url: `${API_BASE_URL}/v1/scheduler/availabilities/${schedularId}/${date}`,
    method: "DELETE",
    access: ACCESS.AUTH,
  });
};

export const getSchedularUser = (schedularId, schedularNavigator) => {
  return request({
    url: `${API_BASE_URL}/v1/scheduler/users/${schedularId}?action=${schedularNavigator}`,
    method: "GET",
    access: ACCESS.AUTH,
  });
};

export const fetchAllAppointments = (
  schedularId,
  date,
  upcoming,
  isFriend,
  page,
  size,
  sort,
  timeZone = "Asia/Colombo",
  status
) => {
  return request({
    url: `${API_BASE_URL}/v1/scheduler/appointments?schedulerId=${schedularId}&isFriend=${isFriend}&page=${page}&sort=${sort}&date=${date}&upcoming=${upcoming}&size=${size}&status=${status}`,
    method: "GET",
    customHeader: [{ key: "X-TimeZone", value: timeZone }],
    access: ACCESS.AUTH,
  });
};

export const getAvailableTimeSlots = (
  schedularId,
  start,
  end,
  duration,
  timeZone
) => {
  return request({
    url: `${API_BASE_URL}/v1/scheduler/slots/${schedularId}?start=${start}&end=${end}&duration=${duration}`,
    method: "GET",
    customHeader: [{ key: "X-TimeZone", value: timeZone }],
    access: ACCESS.AUTH,
  });
};

export const fetchAppointmentById = (id, timeZone) => {
  return request({
    url: `${API_BASE_URL}/v1/scheduler/appointments/${id}`,
    method: "GET",
    customHeader: [{ key: "X-TimeZone", value: timeZone }],
    access: ACCESS.AUTH,
  });
};

export const updateSchedularUser = (
  data,
  schedularId,
  schedularNavigator,
  timeZone = "Asia/Colombo"
) => {
  return request({
    url: `${API_BASE_URL}/v1/scheduler/users/${schedularId}?action=${schedularNavigator}`,
    method: "PUT",
    body: JSON.stringify(data),
    customHeader: [{ key: "X-TimeZone", value: timeZone }],
    access: ACCESS.AUTH,
  });
};

export const updateAvailability = (
  data,
  schedularId,
  timeZone = "Asia/Colombo"
) => {
  return request({
    url: `${API_BASE_URL}/v1/scheduler/availabilities/${schedularId}`,
    method: "PUT",
    body: JSON.stringify(data),
    customHeader: [{ key: "X-TimeZone", value: timeZone }],
    access: ACCESS.AUTH,
  });
};
