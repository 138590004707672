import React, { useEffect, useState } from "react";
import {
  deletePostById,
  EditPostById,
  voteForPostById,
} from "../../util/APIUtils";
import CreateComment from "../create-comment/Create-Comment";
import ThreadComment from "../thread-comment/Thread-Comment";
import "./Thread.scss";

function Thread(props) {
  let upvoteCount = 0;
  let downVoteCount = 0;
  const [article, setArticle] = useState(props?.article);
  const [editMode, setEditMode] = useState(false);
  const [viewComments, setViewComments] = useState(false);
  const [addComment, setAddComment] = useState(false);
  let timeDiffernce = 0;

  useEffect(() => {
    setArticle(props?.article);
    calclateInteractions();
    setAddComment(false);
  }, [props?.article]);

  const msDiff =
    new Date().getTime() - new Date(props?.article?.createdAt).getTime(); //Future date - current date
  const minutesDiff = Math.floor(msDiff / (1000 * 60));
  if (minutesDiff > 60) {
    const hourDiff = Math.trunc(minutesDiff / 60);
    if (hourDiff > 24) {
      timeDiffernce = Math.trunc(hourDiff / 24) + " days ago";
    } else {
      timeDiffernce = hourDiff + " hours ago";
    }
  } else {
    timeDiffernce = minutesDiff + " minutes ago";
  }

  const showCommentsSection = () => {
    setViewComments(!viewComments);
  };

  const voteForPost = (type) => {
    voteForPostById(article?.id, { type: type }).then((response) => {
      props.fncStatus(response);
    });
  };

  const calclateInteractions = () => {
    article?.interactions?.forEach((interaction) => {
      if (interaction.type === "up_vote") {
        upvoteCount++;
      } else if (interaction.type === "down_vote") {
        downVoteCount++;
      }
    });
  };

  calclateInteractions();

  const deleteThread = () => {
    deletePostById(article.id).then((response) => {
      props.fncStatus(response);
    });
  };

  const fncStatus = (values) => {
    props.fncStatus(values);
  };

  const handleChange = (key, value) => {
    setArticle({
      ...article,
      [key]: value,
    });
  };

  const handleSubmit = (evt) => {
    evt.preventDefault();
    const tempArticle = {
      title: article.title,
      text: article.text,
    };
    EditPostById(article.id, tempArticle).then((response) => {
      props.fncStatus(response);
      setEditMode(false);
    });
  };

  const showAddCommentSection = () => {
    setAddComment(!addComment);
  };

  return (
    <div className="thread-container thread mb-3">
      {!editMode && (
        <div className="row">
          <div className="col-md-12 my-2">
            <div className="col-md-12 ">
              <div className="row">
                <div className="col-md-8">
                  <span className="article-title d-flex align-items-center">
                    <i className="fa fa-crosshairs mr-2"></i>
                    {article?.title}
                    {props.currentUser?.id === article?.createdBy?.id && (
                      <div>
                        <button
                          type="button"
                          className="btn btn-light mr-1"
                          onClick={(e) => {
                            setEditMode(true);
                          }}>
                          <i className="fa fa-edit"></i>
                        </button>
                      </div>
                    )}
                  </span>
                </div>
                <div className="col-md-4 d-flex justify-content-end">
                  <span className="article-time">Posted {timeDiffernce}</span>
                </div>
              </div>
            </div>
            <div className="col-md-12 ">
              <span>{article?.text}</span>
            </div>
            <div className="col-md-12 ">
              <div className="row d-flex align-items-center mt-3">
                <div className="col-md-6  d-flex">
                  <div className="mr-2">
                    <button
                      type="button"
                      className="btn btn-light"
                      onClick={(e) => {
                        voteForPost("up_vote");
                      }}>
                      <i className="fas fa-thumbs-up icon-thumbs-up"></i>
                    </button>
                    {upvoteCount > 0 && (
                      <span className="ml-1 icon-thumbs-up">{upvoteCount}</span>
                    )}
                  </div>
                  <div className="mr-2">
                    <button
                      type="button"
                      className="btn btn-light"
                      onClick={(e) => {
                        voteForPost("down_vote");
                      }}>
                      <i className="fas fa-thumbs-down"></i>
                    </button>
                    {downVoteCount > 0 && (
                      <span className="ml-1 icon-vote-count">
                        {downVoteCount}
                      </span>
                    )}
                  </div>
                </div>
                <div className="col-md-6 d-flex justify-content-end align-items-center">
                  <span className="thread-created-user">
                    By {article?.createdBy?.name}
                  </span>
                  <button
                    type="button"
                    className={
                      addComment
                        ? "btn btn-light mr-1 active"
                        : "btn btn-light mr-1"
                    }
                    onClick={(e) => {
                      showAddCommentSection();
                    }}
                    tooltip="Add Comment">
                    <i className="fa fa-plus"></i>
                  </button>
                  {article?.comments?.length > 0 && (
                    <div>
                      <button
                        type="button"
                        className={
                          viewComments
                            ? "btn btn-light mr-1 active"
                            : "btn btn-light"
                        }
                        onClick={(e) => {
                          showCommentsSection();
                        }}>
                        <i className="fa fa-comments"></i>
                      </button>
                      <span className="icon-vote-count mr-1">
                        {article?.comments?.length}
                      </span>
                    </div>
                  )}
                  {props.currentUser?.id === article?.createdBy?.id && (
                    <div>
                      <button
                        type="button"
                        className="btn btn-light"
                        onClick={(e) => {
                          deleteThread(article);
                        }}>
                        <i className="fa fa-trash"></i>
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          {viewComments && (
            <div className="col-md-12">
              <div className="comments-header">Answers</div>
              <ThreadComment
                comments={article?.comments}
                cityId={props.cityId}
                parentId={article?.id}
                fncStatus={fncStatus}></ThreadComment>
            </div>
          )}
          {addComment && (
            <div className="col-md-12">
              <CreateComment
                cityId={props.cityId}
                parentId={article?.id}
                fncStatus={fncStatus}></CreateComment>
            </div>
          )}
        </div>
      )}
      {editMode && (
        <div>
          <form onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-md-12 mx-2 form-group">
                <label className="col-12">Title</label>
                <input
                  type="text"
                  required
                  placeholder="Enter title"
                  className="form-control"
                  autoComplete="off"
                  name="titles"
                  id="titles"
                  value={article?.title}
                  onChange={(e) => handleChange("title", e.target.value)}
                />
              </div>
              <div className="col-md-12 mx-2 form-group">
                <label className="col-12">Text</label>
                <textarea
                  type="text"
                  required
                  placeholder="Enter text"
                  className="form-control"
                  autoComplete="off"
                  name="desc"
                  id="desc"
                  value={article?.text}
                  onChange={(e) =>
                    handleChange("text", e.target.value)
                  }></textarea>
              </div>
            </div>
            <div className="row d-flex justify-content-center mt-3">
              <button type="submit" className="btn btn-primary">
                Update
              </button>
              <button
                type="button"
                className="btn btn-secondary ml-2"
                onClick={(e) => {
                  setEditMode(false);
                }}>
                Cancel
              </button>
            </div>
          </form>
        </div>
      )}
    </div>
  );
}

export default Thread;
