import Pusher from "pusher-js";
import { createRef, useEffect, useRef, useState } from "react";
import { Spinner } from "react-bootstrap";
import ReactGA from "react-ga4";
import { hotjar } from "react-hotjar";
import { Route, Switch, useHistory, useLocation } from "react-router-dom";
import SockJsClient from "react-stomp";
import "./App.scss";
import AccountRoleExist from "./components/account-already-exist-for-role/Account-Already-Exist-For-Role";
import Footer from "./components/footer/Footer";
import FriendSchedule from "./components/friend-schedule-call/FriendSchedule";
import Header from "./components/header/Header";
import SocialLoginExist from "./components/social-login-existing-account/Social-Login-Existing-Account";
import UnsubscribeEmailOption from "./components/unsubscribe-email-option/Unsubscribe-Email-Option";
import UsersForm from "./components/users-form/Users-Form";
import {
  ACCESS_TOKEN,
  API_BASE_URL,
  ENV,
  ENVIROMENTS,
  HOTJAR_CODE,
  HOTJAR_VERSION,
  REFRESH_TOKEN,
  USER_IDENTIFIER,
} from "./constants";
import { UserProvider } from "./context/UserContext";
import NotFound from "./layouts/404/NotFound";
import AboutUs from "./layouts/about-us/About-us";
import AdminInquiry from "./layouts/admin-inquiry/Admin-Inquiry";
import ArticleCreator from "./layouts/article-creator/Article-Creator";
import Articles from "./layouts/articles/Articles";
import BecomeFriend from "./layouts/become-friend/Become-Friend";
import Cities from "./layouts/cities/Cities";
import CityForm from "./layouts/city-form/City-Form";
import City from "./layouts/city/City";
import ContactUs from "./layouts/contact-us/Contact-us";
import Events from "./layouts/events/Events";
import Friend from "./layouts/friend/Friend";
import Home from "./layouts/home/Home";
import HowItWorks from "./layouts/how-it-works/How-it-works";
import ManageUsers from "./layouts/manage-users/Manage-Users";
import PrivacyPolicy from "./layouts/privacy-policy/Privacy-Policy";
import RequestDemo from "./layouts/request-demo/Request-Demo";
import ResetPassword from "./layouts/reset-password/Reset-Password";
import ReviewPosts from "./layouts/review/ReviewPosts";
import TermsAndConditions from "./layouts/terms-and-conditions/Terms-And-Conditions";
import ManageThread from "./layouts/thread/Manage-Thread/Manage-Threads";
import ViewThread from "./layouts/thread/View-Therad/View-Thread";
import OAuth2RedirectHandler from "./layouts/user/oauth2/OAuth2RedirectHandler";
import Profile from "./layouts/user/profile/Profile";
import PrivateRoute from "./routes/PrivateRoute";
import { getCurrentUser } from "./util/APIUtils";
import { notificationManage } from "./util/NotificationUtils";
import Cookies from "js-cookie";
import { v4 as uuid4 } from "uuid";

function App(props) {
  const history = useHistory();
  const [currentUserState, setCurrentUserState] = useState(undefined);
  const prevUserState = usePrevious(currentUserState);
  const [sockClientConnectable, setSockClientConnectable] = useState(true);
  const [allowRedirect, setAllowRedirect] = useState(true);
  const [isDataLoad, setIsDataLoad] = useState(true);
  let sockRef = createRef(null);
  const location = useLocation();
  const pagesWithoutFooter = ["/meetings", "/email-unsubscribe", "/microsoft"];
  const [isFooterVisible, setIsFooterVisible] = useState(true);
  const userIdentifier = Cookies.get(USER_IDENTIFIER);

  useEffect(() => {
    // Initialize Analytics Integrations
    if (ENV === ENVIROMENTS.PRODUCTION) {
      ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS);
      ReactGA.send(document.location.pathname);
    }
    hotjar.initialize(HOTJAR_CODE, HOTJAR_VERSION);

    // Set application cookies
    Cookies.set("isEmpty", JSON.stringify(false));
    if (
      userIdentifier === undefined ||
      userIdentifier === null ||
      userIdentifier === ""
    ) {
      Cookies.set(USER_IDENTIFIER, uuid4());
    }
  }, []);

  useEffect(() => {
    pagesWithoutFooter.forEach((i) => {
      if (location.pathname.toString().includes(i)) {
        setIsFooterVisible(false);
      }
    });
  }, [location.pathname]);

  const loadCurrentlyLoggedInUser = () => {
    getCurrentUser()
      .then((response) => {
        setCurrentUserState(response);
      })
      .catch((error) => {
        console.warn(error)
      })
      .finally(() => {
        setIsDataLoad(false)
        if (userIdentifier !== "" && currentUserState?.id) {
          Cookies.set(USER_IDENTIFIER, currentUserState?.id);
        }
      });
  };

  const handleLogout = () => {
    localStorage.removeItem(ACCESS_TOKEN);
    localStorage.removeItem(REFRESH_TOKEN);
    setCurrentUserState(undefined);
    setSockClientConnectable(false);
  };

  useEffect(() => {
    // Add User Identifier
    if (currentUserState && currentUserState?.email && hotjar.initialize()) {
      hotjar.identify(currentUserState?.email);
    }
  }, [currentUserState]);

  useEffect(() => {
    loadCurrentlyLoggedInUser();
  }, [location.pathname]);

  useEffect(() => {
    if (
      prevUserState !== currentUserState &&
      currentUserState === undefined &&
      window.location.pathname !== "/account-role-exist" &&
      window.location.pathname !== "/social-login-exist" &&
      window.location.pathname !== "/privacy-policy"
    ) {
      history.push("/");
    }
  }, [prevUserState, currentUserState, history]);

  useEffect(() => {
    if (Cookies.get("resetPassword")) {
      window.location.pathname = "/login"
      Cookies.remove("resetPassword");
    }
  }, [location.pathname]);

  useEffect(() => {
    if (
      currentUserState &&
      currentUserState.id &&
      sockRef &&
      sockRef.state &&
      sockRef.state.connected
    ) {
      try {
        sockRef.sendMessage("/app/online", JSON.stringify(currentUserState.id));
      } catch (err) {
        notificationManage("danger", "Something went wrong!");
      }
    }
  }, [currentUserState, sockRef]);

  var pusher = new Pusher("7cf763c5e57691bf3876", {
    cluster: "ap2",
  });

  useEffect(() => {
    if (location.search) {
      const params = new URLSearchParams(location.search);
      const status = params.get("status");
      const appointment = params.get("appointment");
      if (
        status &&
        appointment &&
        !localStorage.getItem("accessToken") &&
        !["/", "/login"].includes(window.location.pathname)
      ) {
        Cookies.set(
          "scheduleLocation",
          `/profile?status=${status}&appointment=${appointment}`
        );
        history.push(`/login`);
      }
    }
  }, [location.search, currentUserState, history]);

  let channel = pusher.subscribe("m2nc-city");
  channel.bind("citySelect", function (data) {
    history.push(`/city/${data.id}`);
  });

  let channel2 = pusher.subscribe("m2nc-other");
  channel2.bind("becomeFriend", function (data) {
    history.push(`/profile`);
  });
  channel2.bind("askQuestion", function (data) {});

  return (
    <>
      {isDataLoad ? (
        <div className="app-loading-spinner">
          <Spinner animation="border" variant="primary" />
        </div>
      ) : (
        <div className="main">
          <UserProvider>
            <div className="wrapper">
              {!location.pathname.toString().includes("/email-unsubscribe") && (
                <Header
                  logout={handleLogout}
                  currentUser={currentUserState}
                  allowRedirect={allowRedirect}
                  setAllowRedirect={setAllowRedirect}
                />
              )}
              {sockClientConnectable && (
                <SockJsClient
                  url={`${API_BASE_URL}/presence`}
                  topics={["/user/queue/online"]}
                  ref={(client) => {
                    sockRef = client;
                  }}
                  onDisconnect={() => setSockClientConnectable(true)}
                  debug={false}
                />
              )}
              <Switch>
                <Route exact path="/" component={Home}></Route>
                <PrivateRoute
                  path="/cities"
                  component={Cities}
                  currentUser={currentUserState}
                  reloadCurrentUser={loadCurrentlyLoggedInUser}
                />
                <PrivateRoute
                  path="/add-city"
                  component={CityForm}
                  currentUser={currentUserState}
                  reloadCurrentUser={loadCurrentlyLoggedInUser}
                />
                <PrivateRoute
                  path="/edit-city/:cityId/"
                  component={CityForm}
                  currentUser={currentUserState}
                  reloadCurrentUser={loadCurrentlyLoggedInUser}
                />
                <PrivateRoute
                  path="/profile"
                  component={Profile}
                  allowRedirect={allowRedirect}
                  setAllowRedirect={setAllowRedirect}
                  currentUser={currentUserState}
                  reloadCurrentUser={loadCurrentlyLoggedInUser}
                ></PrivateRoute>
                <PrivateRoute
                  path="/create-friend-profile"
                  component={Profile}
                  allowRedirect={allowRedirect}
                  setAllowRedirect={setAllowRedirect}
                  currentUser={currentUserState}
                  reloadCurrentUser={loadCurrentlyLoggedInUser}
                ></PrivateRoute>
                <PrivateRoute
                  path="/create-profile"
                  component={Profile}
                  allowRedirect={allowRedirect}
                  setAllowRedirect={setAllowRedirect}
                  currentUser={currentUserState}
                  reloadCurrentUser={loadCurrentlyLoggedInUser}
                ></PrivateRoute>
                <Route
                  path="/become-a-friend"
                  render={(props) => (
                    <BecomeFriend {...props} logout={handleLogout} />
                  )}
                ></Route>
                <Route
                  path="/friends/:id"
                  component={Friend}
                  currentUser={currentUserState}
                  reloadCurrentUser={loadCurrentlyLoggedInUser}
                  {...props}
                ></Route>
                <Route
                  path="/signup"
                  render={(props) => (
                    <Home currentUser={currentUserState} {...props} />
                  )}
                ></Route>
                <Route
                  path="/activate-account/:token"
                  render={(props) => (
                    <Home currentUser={currentUserState} {...props} />
                  )}
                ></Route>
                <Route
                  path="/login"
                  render={(props) => (
                    <Home currentUser={currentUserState} {...props} />
                  )}
                ></Route>
                <Route
                  path="/social-signup"
                  render={(props) => (
                    <Home currentUser={currentUserState} {...props} />
                  )}
                ></Route>
                <Route
                  path="/recover/reset-password/:token"
                  component={ResetPassword}
                  {...props}
                ></Route>
                <Route
                  path="/oauth2/redirect"
                  component={OAuth2RedirectHandler}
                ></Route>
                <Route
                  path="/city/:id"
                  render={(props) => (
                    <City
                      {...props}
                      currentUser={currentUserState}
                      reloadCurrentUser={loadCurrentlyLoggedInUser}
                    />
                  )}
                ></Route>
                <Route
                  path="/city-history/:id"
                  render={(props) => (
                    <City
                      {...props}
                      currentUser={currentUserState}
                      reloadCurrentUser={loadCurrentlyLoggedInUser}
                    />
                  )}
                ></Route>
                <Route
                  path="/community/:id"
                  component={ManageThread}
                  currentUser={currentUserState}
                  {...props}
                ></Route>
                <Route
                  path="/thread/:id"
                  component={ViewThread}
                  currentUser={currentUserState}
                  {...props}
                ></Route>
                <Route
                  path="/contact-us"
                  component={ContactUs}
                  currentUser={currentUserState}
                ></Route>
                <Route
                  path="/about-us"
                  component={AboutUs}
                  currentUser={currentUserState}
                ></Route>
                <Route
                  path="/request-demo"
                  component={RequestDemo}
                  currentUser={currentUserState}
                ></Route>
                <Route
                  path="/how-it-works"
                  component={HowItWorks}
                  currentUser={currentUserState}
                ></Route>
                <Route path="/privacy-policy" component={PrivacyPolicy}></Route>
                <Route
                  path="/terms-and-conditions"
                  component={TermsAndConditions}
                  currentUser={currentUserState}
                ></Route>
                <PrivateRoute
                  path="/users"
                  component={ManageUsers}
                  currentUser={currentUserState}
                  reloadCurrentUser={loadCurrentlyLoggedInUser}
                />
                <Route
                  path="/email-unsubscribe/:id"
                  component={UnsubscribeEmailOption}
                ></Route>
                <PrivateRoute
                  path="/add-user"
                  component={UsersForm}
                  currentUser={currentUserState}
                  reloadCurrentUser={loadCurrentlyLoggedInUser}
                />
                <PrivateRoute
                  path="/edit-user/:userId/"
                  component={UsersForm}
                  currentUser={currentUserState}
                  reloadCurrentUser={loadCurrentlyLoggedInUser}
                />
                <PrivateRoute
                  path="/editor"
                  component={ArticleCreator}
                  currentUser={currentUserState}
                  reloadCurrentUser={loadCurrentlyLoggedInUser}
                />
                <PrivateRoute
                  path="/edit-article/:articleId/"
                  component={ArticleCreator}
                  currentUser={currentUserState}
                  reloadCurrentUser={loadCurrentlyLoggedInUser}
                />
                <PrivateRoute
                  path="/articles"
                  component={Articles}
                  currentUser={currentUserState}
                  reloadCurrentUser={loadCurrentlyLoggedInUser}
                />
                <PrivateRoute
                  path="/edit-friend"
                  component={Profile}
                  currentUser={currentUserState}
                  reloadCurrentUser={loadCurrentlyLoggedInUser}
                />
                <PrivateRoute
                  path="/review"
                  component={ReviewPosts}
                  currentUser={currentUserState}
                  reloadCurrentUser={loadCurrentlyLoggedInUser}
                />
                <PrivateRoute
                  path="/events"
                  component={Events}
                  currentUser={currentUserState}
                  reloadCurrentUser={loadCurrentlyLoggedInUser}
                />
                <PrivateRoute
                  path="/inquiries"
                  component={AdminInquiry}
                  currentUser={currentUserState}
                  reloadCurrentUser={loadCurrentlyLoggedInUser}
                />
                <PrivateRoute
                  path="/friend/:friendId/schedule"
                  component={FriendSchedule}
                  currentUser={currentUserState}
                  reloadCurrentUser={loadCurrentlyLoggedInUser}
                />
                <PrivateRoute
                  path="/friend/:friendId/:appointmentId/re-schedule"
                  component={FriendSchedule}
                  currentUser={currentUserState}
                  reloadCurrentUser={loadCurrentlyLoggedInUser}
                />
                <Route
                  path="/account-role-exist"
                  component={AccountRoleExist}
                  {...props}
                ></Route>
                <Route
                  path="/social-login-exist"
                  component={SocialLoginExist}
                  {...props}
                ></Route>
                <Route component={NotFound}></Route>
              </Switch>
            </div>
          </UserProvider>
          {isFooterVisible && <Footer />}
        </div>
      )}
    </>
  );
}

const usePrevious = (value) => {
  const ref = useRef();
  // Store current value in ref
  useEffect(() => {
    ref.current = value;
  }, [value]); // Only re-run if value changes
  // Return previous value (happens before update in useEffect above)
  return ref.current;
};

export default App;
