import Image from "react-bootstrap/Image";
import { API_BASE_URL } from "../../../constants";
import { truncateName } from "../../../util/Utils";
import "./Friend-Profile.scss";

const FriendProfile = ({
  friendProfile,
  isStatusPreview,
  isFriend = true,
  centerItems,
}) => {
  return (
    <div className="friend-profile">
      <div className="friend-profile-image-box">
        <div
          className={`friend-profile-imageWrap ${
            centerItems && " friend-profile-imageWrap-center"
          }`}
        >
          {friendProfile.imageUrl || friendProfile.image ? (
            <Image
              className="friend-profile-image"
              width="70"
              height="70"
              src={
                friendProfile.image !== null
                  ? `${API_BASE_URL}/v1/users/${friendProfile.id}/image`
                  : friendProfile.imageUrl
              }
              roundedCircle
            />
          ) : (
            <div className={"friend-profile-text-avatar-box"}>
              <span>
                {friendProfile.userName
                  ? friendProfile.userName[0].toUpperCase()
                  : friendProfile.name && friendProfile.name[0].toUpperCase()}
              </span>
            </div>
          )}
        </div>
        <div className="friend-profile-image-detail-box">
          <p
            className={`friend-profile-title ${
              centerItems && "friend-profile-title-center"
            }`}
          >
            {truncateName(friendProfile.userName, 20)}
          </p>
          {!isFriend && (
            <>
              <p
                className={`friend-profile-subtitle-city subtitle pb-0 ${
                  centerItems && `friend-profile-subtitle-center`
                }`}
              >
                {friendProfile.cityName}
              </p>
              <p
                className={`friend-profile-subtitle-spentTime subtitle pb-0 mb-2 ${
                  centerItems && `friend-profile-subtitle-center`
                }`}
              >
                Time spent-{friendProfile.years} years
              </p>
            </>
          )}

          {isStatusPreview && (
            <div
              className={`friend-profile-status-dot-wrap ${
                friendProfile.isOnline ? "online" : "offline"
              } d-flex m-auto d-flex align-items-center`}
            >
              <p className="friend-profile-subtitle-status subtitle pb-0">
                {friendProfile.isOnline ? "Online" : "Offline"}
              </p>
              <div
                className={`friend-profile-status-dot ${
                  friendProfile.isOnline ? "online" : "offline"
                }`}
              ></div>
            </div>
          )}
          {/* TODO: will integrate the payment methods in upcoming versions */}
          {/* <p className="friend-profile-subtitle-min subtitle pb-0">
            {scheduleCall.minCost}/min
          </p> */}
        </div>
      </div>
    </div>
  );
};

export default FriendProfile;
