import { Dropdown, DropdownButton } from "react-bootstrap";
import { useLocation } from "react-router-dom";

const ScheduleCallHeader = ({ dataList, selectedValue, handleAction }) => {
  const viewAll = () => {
    if (window.location.search) {
      window.location.href = window.location.href?.split("?")[0];
    }
  };

  return (
    <>
      <div className="scheduled-calls-header-container">
        <div className="scheduled-calls-title-container">
          <h1 className="scheduled-calls-title">Scheduled calls</h1>
        </div>
        <div className="scheduled-calls-filter-container">
          {!window.location.search ? (
            <div className="select-wrapper">
              <h1 className="scheduled-calls-filter-container-title">
                Filter by
              </h1>
              <Dropdown className="post-dropdown">
                <Dropdown.Toggle className="post-dropdown-toggle">
                  {selectedValue ? selectedValue.title : "Select..."}
                </Dropdown.Toggle>
                <Dropdown.Menu className="post-dropdown-menu">
                  {dataList.map((listItem, index) => (
                    <Dropdown.Item
                      key={index}
                      className={
                        selectedValue && selectedValue.value === listItem.value
                          ? "dropdown-item-selected post-dropdown-options"
                          : "post-dropdown-options"
                      }
                      value={listItem.value}
                      onClick={() => handleAction(listItem.value)}
                    >
                      {listItem?.title}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            </div>
          ) : (
            <button
              className="scheduled-calls-filter-container btn-primary"
              onClick={viewAll}
            >
              View all calls
            </button>
          )}
        </div>
      </div>
    </>
  );
};

export default ScheduleCallHeader;
