import _ from "lodash";
import { useEffect, useRef, useState } from "react";
import { CloseButton, Spinner } from "react-bootstrap";
import { PlusLg, Trash } from "react-bootstrap-icons";
import { Typeahead } from "react-bootstrap-typeahead";
import { v4 as uuidv4 } from "uuid";
import {
  AVAILABILITY_DAY,
  AVAILABILITY_WEEK,
  SCHEDULER_NAVIGATOR
} from "../../constants";
import {
  deleteOverridesAvailability,
  getAvailability,
  getCurrentUser,
  getSchedularUser,
  updateAvailability,
  updateOverridesAvailability,
  updateSchedularUser
} from "../../util/APIUtils";
import {
  CommonUtils,
  DAY_ORDER,
  getAvailableTimeList,
} from "../../util/CommonUtils";
import { notificationManage } from "../../util/NotificationUtils";
import timeZones from "../../util/TimeZones";
import { convertDate, truncateName } from "../../util/Utils";
import DateOverridesModal from "../date-overrides-modal/Date-Overrides-Modal";
import "./Profile-Settings.scss";
import AvailabilitySelector from "./availability/Availability-Selector";
import { CheckboxDropdown } from "./custom-call-duration-dropdown/Custom-Call-Duration-Dropdown";

let waitingQueue = null;
let notificationQueue = []

const ProfileSettings = () => {
  const [isShowSpinner, setIsShowSpinner] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [openDateOverrides, setOpenDateOverrides] = useState(false);
  const [availabilityDetails, setAvailabilityDetails] = useState([]);
  const [availabilityDatesAndTimes, setAvailabilityDatesAndTimes] = useState(
    []
  );
  const [dateOverridesDatesAndTimesNew, setDateOverridesDatesAndTimesNew] =
    useState([]);
  const [dateOverridesDatesAndTimes, setDateOverridesDatesAndTimes] = useState(
    []
  );
  const [overrideDates, setOverrideDates] = useState([]);
  const selectedTimeZoneNew = useRef("");
  const [callDurationList, setCallDurationList] = useState([]);
  const [isValuegot, setIsValuegot] = useState(false);
  const [userSchedularId, setUserSchedularId] = useState("");
  const captureAction = useRef(null);
  const [isOverride, setIsOverride] = useState(false);
  const [durationValues, setDurationValues] = useState([]);
  const [durationValuesState, setDurationValuesState] = useState([]);
  const [isOverrideDayAvailable, setIsOverrideDayAvailable] = useState(true);
  const [userProfile, setUserProfile] = useState();
  const [isError, setIsError] = useState(false);
  const [isValuePassed, setIsValuePassed] = useState(false);
  const [durationValueStateStable, setDurationValueStateStable] = useState([]);
  const [scheduledCallsArr, setScheduledCallsArr] = useState([]);
  const [appointmentError, setAppointmentError] = useState(false);
  const [newSelectedTimeZone, setNewSelectedTimeZone] = useState("");
  const isButtonCalled = useRef();

  useEffect(() => {
    setDurationValuesState(
      durationValues.map((duration) => parseInt(duration))
    );
    autoSaveDuration();
  }, [durationValues]);

  useEffect(() => {
    if (localStorage.getItem("rightPanelState") !== "schedule-call") {
      if (window.location.search) {
        window.location.href = window.location.href?.split("?")[0];
      }
    }
  }, []);

  const autoSaveDuration = () => {
    const isEquals = _.isEqual(durationValuesState, durationValueStateStable);
    if (isValuePassed && !isEquals) {
      autoSaveButtonHandle();
    }
  };

  useEffect(() => {
    if (dateOverridesDatesAndTimesNew.length === 0) {
      let tempAvailabilityDatesAndTimes = [...availabilityDatesAndTimes];
      let tempDateOverridesDatesAndTimesNew = [];
      tempAvailabilityDatesAndTimes.forEach((item) => {
        tempDateOverridesDatesAndTimesNew.push({
          dayOfWeek: item.dayOfWeek,
          available: item.available,
          segments: [
            {
              atid: 1,
              startTime: item.segments[0].startTime,
              endTime: item.segments[0].endTime,
              error: false,
              appointmentsError: false,
            },
          ],
        });
      });
      setDateOverridesDatesAndTimesNew(tempDateOverridesDatesAndTimesNew);
    }
  }, [dateOverridesDatesAndTimesNew]);

  const addNewAvailabilityTime = (id) => {
    let tempAvailabilityDatesAndTimes = [...availabilityDatesAndTimes];
    const obj = _.find(tempAvailabilityDatesAndTimes, { dayOfWeek: id });
    const index = _.indexOf(tempAvailabilityDatesAndTimes, obj);
    const uuid = uuidv4();
    if (tempAvailabilityDatesAndTimes[index].segments.length <= 2) {
      tempAvailabilityDatesAndTimes[index].segments.push({
        atid: uuid,
        startTime: getNextTimeSlot(
          tempAvailabilityDatesAndTimes[index].segments[
            tempAvailabilityDatesAndTimes[index].segments.length - 1
          ].endTime,
          1
        ),
        endTime: getNextTimeSlot(
          tempAvailabilityDatesAndTimes[index].segments[
            tempAvailabilityDatesAndTimes[index].segments.length - 1
          ].endTime,
          2
        ),
        error: false,
        appointmentsError: false,
      });
      setAvailabilityDatesAndTimes(tempAvailabilityDatesAndTimes);
      autoSaveButtonHandle();
    }
  };

  const addNewAvailabilityTimeOverrides = (id) => {
    let tempAvailabilityDatesAndTimesNew = [...dateOverridesDatesAndTimesNew];
    let obj = _.find(tempAvailabilityDatesAndTimesNew, { dayOfWeek: id });
    let index = _.indexOf(tempAvailabilityDatesAndTimesNew, obj);
    const uuid = uuidv4();
    if (obj === undefined) {
      obj = _.find(dateOverridesDatesAndTimes, { date: id });
      index = _.indexOf(dateOverridesDatesAndTimes, obj);
      tempAvailabilityDatesAndTimesNew = [...dateOverridesDatesAndTimes];
      if (tempAvailabilityDatesAndTimesNew[index].segments.length <= 2) {
        tempAvailabilityDatesAndTimesNew[index].segments.push({
          atid: uuid,
          startTime: getNextTimeSlot(
            tempAvailabilityDatesAndTimesNew[index].segments[
              tempAvailabilityDatesAndTimesNew[index].segments.length - 1
            ].endTime,
            1
          ),
          endTime: getNextTimeSlot(
            tempAvailabilityDatesAndTimesNew[index].segments[
              tempAvailabilityDatesAndTimesNew[index].segments.length - 1
            ].endTime,
            2
          ),
          error: false,
          appointmentsError: false,
        });
        setDateOverridesDatesAndTimes(tempAvailabilityDatesAndTimesNew);
      }
    } else {
      if (tempAvailabilityDatesAndTimesNew[index].segments.length <= 2) {
        tempAvailabilityDatesAndTimesNew[index].segments.push({
          atid: uuid,
          startTime: getNextTimeSlot(
            tempAvailabilityDatesAndTimesNew[index].segments[
              tempAvailabilityDatesAndTimesNew[index].segments.length - 1
            ].endTime,
            1
          ),
          endTime: getNextTimeSlot(
            tempAvailabilityDatesAndTimesNew[index].segments[
              tempAvailabilityDatesAndTimesNew[index].segments.length - 1
            ].endTime,
            2
          ),
          error: false,
          appointmentsError: false,
        });
        setDateOverridesDatesAndTimesNew(tempAvailabilityDatesAndTimesNew);
      }
    }
  };

  const validateInputsOverrides = (type) => {
    let isFoundError = false;
    let tempTypeDatesAndTimes =
      type === AVAILABILITY_DAY
        ? dateOverridesDatesAndTimes
        : dateOverridesDatesAndTimesNew;
    const tempAvailabilityDatesAndTimes = tempTypeDatesAndTimes.map(
      (dateTime) => {
        const updatedDateTime = { ...dateTime };
        updatedDateTime.segments.reverse();
        updatedDateTime.segments.forEach((timeRange) => {
          const overlappingSegments = updatedDateTime.segments.filter(
            (otherTimeRange) => {
              return (
                timeRange !== otherTimeRange &&
                timeRange.startTime < otherTimeRange.endTime &&
                otherTimeRange.startTime < timeRange.endTime
              );
            }
          );
          if (overlappingSegments.length > 0) {
            timeRange.error = true;
            updatedDateTime.error = true;
            isFoundError = true;
          } else {
            timeRange.error = false;
          }
        });
        updatedDateTime.segments.reverse();
        return updatedDateTime;
      }
    );
    type === AVAILABILITY_DAY
      ? setDateOverridesDatesAndTimes(tempAvailabilityDatesAndTimes)
      : setDateOverridesDatesAndTimesNew(tempAvailabilityDatesAndTimes);
    return isFoundError;
  };

  const validateAppointments = (appointments, segments) => {
    let isFoundError = false;
    let coveredAppointments = new Set();
    coveredAppointments.clear();
    let appointmentTemp = [...appointments];
    segments.forEach((dateTime) => {
      const updatedDateTime = { ...dateTime };
      updatedDateTime.segments.reverse();

      updatedDateTime.segments.forEach((timeRange) => {
        timeRange.appointmentsError = false;
        const segmentStartTime = timeRange?.startTime;
        const segmentEndTime = timeRange?.endTime;

        appointmentTemp?.forEach((appointment, index, appointmentTempCopy) => {
          if (coveredAppointments && coveredAppointments.size > 0) {
            coveredAppointments?.forEach((item) => {
              appointment.content?.forEach((itemAppointment) => {
                appointmentTempCopy = appointmentTempCopy?.content?.filter(
                  (item) => item.id !== appointment?.id
                );
              });
            });
          }

          if (appointmentTempCopy?.length > 0) {
            appointmentTemp = [...appointmentTempCopy];
          }

          const { content } = appointment;
          let hasValidAppointment = false;

          content.forEach((item) => {
            const appointmentStartTime =
              item?.startDateTime.split("T")[1].split(":")[0] +
              ":" +
              item?.startDateTime.split("T")[1].split(":")[1];
            const appointmentEndTime =
              item?.endDateTime.split("T")[1].split(":")[0] +
              ":" +
              item?.endDateTime.split("T")[1].split(":")[1];

            if (
              appointmentStartTime >= segmentStartTime &&
              appointmentEndTime <= segmentEndTime
            ) {
              hasValidAppointment = true;
              coveredAppointments.add(item);
              return;
            }
          });

          const appointmentTempLengths = appointmentTemp?.map(
            (item) => item?.content?.length
          );
          const appointmentTempLength = appointmentTempLengths?.reduce(
            (a, b) => a + b,
            0
          );

          const checkLength =
            coveredAppointments?.size === appointmentTempLength;

          if (checkLength && hasValidAppointment) {
            timeRange.appointmentsError = false;
            updatedDateTime.appointmentsError = false;
            isFoundError = false;
            return false;
          } else if (!checkLength) {
            timeRange.appointmentsError = true;
            updatedDateTime.appointmentsError = true;
            isFoundError = true;
            return true;
          }
        });
      });

      updatedDateTime.segments.forEach((timeRange) => {
        timeRange.appointmentsError = isFoundError;
      });
      updatedDateTime?.segments.reverse();
    });

    return isFoundError;
  };

  const handleLeaveModal = () => {
    getBookingConfiguration();
  };

  const handleApplyDateOverrides = (filteredNewData, calendarDate, type) => {
    let hasError = false;
    let tempTypeDatesAndTimes =
      type === AVAILABILITY_DAY
        ? dateOverridesDatesAndTimes
        : dateOverridesDatesAndTimesNew;
    hasError = tempTypeDatesAndTimes.some((day) =>
      day.segments.some((segment) => segment?.error)
    );
    if (
      !validateInputsOverrides() &&
      !hasError &&
      !validateAppointments(scheduledCallsArr, filteredNewData)
    ) {
      setIsValuegot(true);
      setOpenDateOverrides(false);
      setAppointmentError(false);
      overrideDates.forEach((item) => {
        updateOverridesAvailability(
          {
            type: AVAILABILITY_DAY,
            date: convertDate(item),
            available: isOverrideDayAvailable,
            segments: filteredNewData[0].segments,
            wday: "",
          },
          userProfile?.schedulerId,
          convertDate(item),
          selectedTimeZoneNew?.current
        )
          .then((res) => {
            getBookingConfiguration();
            setIsOverrideDayAvailable(true);
            notificationManage(
              "success",
              "Override Dates Applied Successfully"
            );
          })
          .catch((err) => {
            console.error("err", err);
            notificationManage(
              "danger",
              "Something Went Wrong. Override Dates Not Applied"
            );
          });
      });
    }
  };

  const getNextTimeSlot = (timeStr, minutesToBeAdded) => {
    const timeList = getAvailableTimeList();
    const obj = _.find(timeList, { value: timeStr });
    const index = _.indexOf(timeList, obj);
    return timeList[
      timeList.length > index + minutesToBeAdded
        ? index + minutesToBeAdded
        : index
    ].value;
  };

  const deleteAvailabilityTime = (pId, atid) => {
    let tempAvailabilityDatesAndTimes = [...availabilityDatesAndTimes];
    tempAvailabilityDatesAndTimes = tempAvailabilityDatesAndTimes.map(
      (item) => {
        if (item.id === pId) {
          return {
            ...item,
            segments: item.segments.filter((item) => item.atid !== atid),
          };
        }
        return item;
      }
    );
    setIsEdit(false);
    setAvailabilityDatesAndTimes(tempAvailabilityDatesAndTimes);
  };

  const deleteAvailabilityTimeOverrides = (pId, atid, type) => {
    let tempAvailabilityDatesAndTimes =
      type === AVAILABILITY_DAY
        ? [...dateOverridesDatesAndTimes]
        : [...dateOverridesDatesAndTimesNew];
    tempAvailabilityDatesAndTimes = tempAvailabilityDatesAndTimes.map(
      (item) => {
        if (item.id === pId) {
          return {
            ...item,
            segments: item.segments.filter((item) => item.atid !== atid),
          };
        }
        return item;
      }
    );
    type === AVAILABILITY_DAY
      ? setDateOverridesDatesAndTimes(tempAvailabilityDatesAndTimes)
      : setDateOverridesDatesAndTimesNew(tempAvailabilityDatesAndTimes);
  };

  const handleTimeChange = (pId, atid, name, time) => {
    let tempAvailabilityDatesAndTimes = [...availabilityDatesAndTimes];
    tempAvailabilityDatesAndTimes = tempAvailabilityDatesAndTimes.map(
      (item) => {
        if (item.dayOfWeek === pId) {
          return {
            ...item,
            segments: item.segments.map((item) => {
              if (item.atid === atid) {
                return {
                  ...item,
                  [name]: time,
                };
              }
              return item;
            }),
          };
        }
        return item;
      }
    );
    setAvailabilityDatesAndTimes(tempAvailabilityDatesAndTimes);
  };

  const handleTimeChangeOverrides = (pId, atid, name, time, type) => {
    let tempAvailabilityDatesAndTimes = [...dateOverridesDatesAndTimesNew];

    if (type === AVAILABILITY_DAY) {
      tempAvailabilityDatesAndTimes = [...dateOverridesDatesAndTimes];
      tempAvailabilityDatesAndTimes = tempAvailabilityDatesAndTimes.map(
        (item) => {
          if (item.date === pId) {
            return {
              ...item,
              segments: item.segments.map((item) => {
                if (item.atid === atid) {
                  return {
                    ...item,
                    [name]: time,
                  };
                }
                return item;
              }),
            };
          }
          return item;
        }
      );
    }
    tempAvailabilityDatesAndTimes = tempAvailabilityDatesAndTimes.map(
      (item) => {
        if (item.dayOfWeek === pId) {
          return {
            ...item,
            segments: item.segments.map((item) => {
              if (item.atid === atid) {
                return {
                  ...item,
                  [name]: time,
                };
              }
              return item;
            }),
          };
        }
        return item;
      }
    );
    type === AVAILABILITY_DAY
      ? setDateOverridesDatesAndTimes(tempAvailabilityDatesAndTimes)
      : setDateOverridesDatesAndTimesNew(tempAvailabilityDatesAndTimes);
  };

  const validateInputs = () => {
    let isFoundError = false;
    const tempAvailabilityDatesAndTimes = availabilityDatesAndTimes.map(
      (dateTime) => {
        dateTime.segments.reverse();
        dateTime.segments = dateTime.segments.map(
          (timeRange, index, segments) => {
            const overlappingSegments = segments.filter(
              (otherTimeRange, otherIndex) => {
                if (index !== otherIndex) {
                  const firstStartTime = timeRange.startTime;
                  const firstEndTime = timeRange.endTime;
                  const secondStartTime = otherTimeRange.startTime;
                  const secondEndTime = otherTimeRange.endTime;
                  return (
                    firstStartTime < secondEndTime &&
                    secondStartTime < firstEndTime
                  );
                }
                return false;
              }
            );
            if (overlappingSegments.length > 0) {
              overlappingSegments.forEach((segment) => {
                if (!segment.error) {
                  segment.error = true;
                  isFoundError = true;
                }
              });
              if (!timeRange.error) {
                timeRange.error = true;
                isFoundError = true;
              }
            } else {
              timeRange.error = false;
            }
            return timeRange;
          }
        );
        dateTime.segments.reverse();
        return dateTime;
      }
    );
    setIsError(isFoundError);
    setAvailabilityDatesAndTimes(tempAvailabilityDatesAndTimes);
    return isFoundError;
  };

  const handleDayAvailability = (pId, available) => {
    setAvailabilityDatesAndTimes((prevValue) => {
      return prevValue.map((item) => {
        if (item.dayOfWeek === pId) {
          return {
            ...item,
            available: available,
          };
        } else {
          return item;
        }
      });
    });
  };

  const handleDayAvailabilityOverrides = (pId, available) => {
    setDateOverridesDatesAndTimesNew((prevValue) => {
      return prevValue.map((item) => {
        if (item.dayOfWeek === pId) {
          return {
            ...item,
            available: available,
          };
        } else {
          return item;
        }
      });
    });
  };

  useEffect(() => {
    handlePopState();
  }, [availabilityDatesAndTimes]);

  const handleModalOpen = () => {
    setOpenDateOverrides(true);
    setIsOverride(true);
  };

  const handlePopState = () => {
    if (
      availabilityDatesAndTimes.length > 0 &&
      availabilityDetails.length > 0
    ) {
      const cleanAvailabilityDatesAndTimes = availabilityDatesAndTimes.map(
        (obj) => {
          const segments = obj.segments.map(({ atid, ...rest }) => ({
            ...rest,
          }));
          return { ...obj, segments };
        }
      );
      const cleanAvailabilityDetails = availabilityDetails.map((obj) => {
        const segments = obj.segments.map(({ atid, ...rest }) => ({ ...rest }));
        return { ...obj, segments };
      });
      const hasError = availabilityDatesAndTimes.some((day) =>
        day.segments.some((segment) => segment.error === true)
      );
      if (!hasError) {
        const isEqual = _.isEqual(
          cleanAvailabilityDatesAndTimes,
          cleanAvailabilityDetails
        );
        setIsEdit(!isEqual);
      }
    }
  };

  useEffect(() => {
    if (isEdit && !isButtonCalled.current) {
      autoSaveButtonHandle();
    } else {
      isButtonCalled.current = false;
    }
  }, [isEdit]);

  useEffect(() => {
    if (userProfile) {
      selectedTimeZoneNew.current = userProfile?.timeZone;
      const foundTimeZone = timeZones?.find((timeZone) =>
        timeZone?.utc?.includes(selectedTimeZoneNew.current)
      );
      if (foundTimeZone) {
        const newSelectedTimeZoneValue = `${foundTimeZone?.text} ${foundTimeZone?.utc[0]} `;
        setNewSelectedTimeZone(newSelectedTimeZoneValue);
      }
      setUserSchedularId(userProfile?.schedulerId);
    }
  }, [userProfile]);
  
  useEffect(() => {
    getCurrentUser()
      .then((response) => {
        setUserProfile(response);
      })
      .catch((err) => {
        notificationManage(
          "danger",
          "Something Went Wrong. Please try again by logging"
        );
      });
    setCallDurationList(CommonUtils.getDurationsList());
  }, []);

  useEffect(() => {
    getBookingConfiguration();
  }, [userSchedularId]);

  const handleGet = (res) => {
    setAvailabilityDatesAndTimes(
      updateArrayAvailabilityDateTimes(
        ...[
          res
            ?.filter((e) => e.type === "WEEK")
            .toSorted(
              (d1, d2) =>
                DAY_ORDER[d1.dayOfWeek] - DAY_ORDER[d2.dayOfWeek]
            ),
        ],
        res
      )
    );
    setAvailabilityDetails(
      updateArrayAvailabilityDateTimes(
        ...[
          res
            ?.filter((e) => e.type === "WEEK")
            .toSorted(
              (d1, d2) =>
                DAY_ORDER[d1.dayOfWeek] - DAY_ORDER[d2.dayOfWeek]
            ),
        ],
        res
      )
    );
    setDateOverridesDatesAndTimes(
      updateArray(
        ...[res?.filter((e) => e.type === AVAILABILITY_DAY)],
        res
      )
    );
  }

  const getBookingConfiguration = () => {
    setIsShowSpinner(true);
    if (
      userSchedularId !== "" &&
      userSchedularId !== null &&
      selectedTimeZoneNew?.current !== ""
    ) {
      getAvailability(userSchedularId)
        .then((res) => {
          handleGet(res);
        })
        .catch((err) => {
          notificationManage(
            "danger",
            "Could not fetch the availability details. Please try again later."
          );
        })
        .finally(() => {
          setIsShowSpinner(false);
        });
    }
    handleSaveDuration();
  };

  const updateArray = (originalArray, responseArray) => {
    const originalMap = originalArray.reduce((map, item) => {
      map.set(item.date, item);
      return map;
    }, new Map());
    responseArray.forEach((responseItem) => {
      const originalItem = originalMap.get(responseItem.date);
      if (originalItem) {
        originalItem.available = responseItem.available;
        originalItem.segments = responseItem.segments.map((time, i) => ({
          atid: i >= 1 ? uuidv4() : i + 1,
          startTime: time.startTime,
          endTime: time.endTime,
          error: false,
        }));
      }
    });
    return originalArray;
  };

  const updateArrayAvailabilityDateTimes = (originalArray, responseArray) => {
    const originalMap = originalArray.reduce((map, item) => {
      map.set(item.dayOfWeek, item);
      return map;
    }, new Map());
    responseArray?.forEach((responseItem) => {
      const originalItem = originalMap.get(responseItem.dayOfWeek);
      if (originalItem) {
        originalItem.available = responseItem.available;
        originalItem.segments = responseItem.segments.map((time, i) => ({
          atid: i >= 1 ? uuidv4() : i + 1,
          startTime: time.startTime,
          endTime: time.endTime,
          error: false,
        }));
      }
    });
    return originalArray;
  };

  const handleSaveDuration = () => {
    if (userSchedularId) {
      getSchedularUser(userSchedularId, SCHEDULER_NAVIGATOR.RESOURCES)
        .then((res) => {
          setDurationValuesState(res.appointmentDuration);
          setDurationValueStateStable(res.appointmentDuration);
        })
        .catch((err) => {
          notificationManage(
            "danger",
            "Cannot fetch the duration details. Please try again later."
          );
        });
    }
  };

  const handleAppAlert = () => {
    if (notificationQueue.length > 0) {
      const errNt = notificationQueue.findLast((e) => e.status === "danger");
      const nt = errNt
        ? errNt
        : notificationQueue[notificationQueue.length - 1];
      if (nt) {
        notificationManage(nt.status, nt.message);
      }
      notificationQueue = []
    }
  };

  const addNotificationToQueue = (status, message) => {
    clearTimeout(waitingQueue);
    notificationQueue.push({
      status: status,
      message: message,
    })
    waitingQueue = setTimeout(() => {
      handleAppAlert();
    }, 5000)
  };

  const autoSaveButtonHandle = () => {
    isButtonCalled.current = true;
    let hasError = false;
    hasError = availabilityDatesAndTimes.some((day) =>
      day.segments.some((segment) => segment.error === true)
    );
    if (!hasError) {
      if (selectedTimeZoneNew.current || (!validateInputs() && isEdit)) {
        const updateAvailabilityDatesAndTimes = availabilityDatesAndTimes?.map(
          (wday) => ({
            dayOfWeek: wday.dayOfWeek,
            segments: wday.segments?.map((time, i) => ({
              startTime: time.startTime,
              endTime: time.endTime,
            })),
            type: AVAILABILITY_WEEK,
            date: "",
            available: wday.available,
          })
        );

        if (selectedTimeZoneNew.current && durationValuesState) {
          updateSchedularUser(
            {
              name: userProfile?.name,
              email: userProfile?.email,
              phone: userProfile?.phone,
              timeZone: selectedTimeZoneNew.current,
              serviceIds: userProfile?.serviceIds,
              appointmentDuration: durationValuesState,
            },
            userProfile?.schedulerId,
            SCHEDULER_NAVIGATOR.RESOURCES,
            selectedTimeZoneNew.current
          )
            .then((e) => {
              setIsEdit(false);
              handleSaveDuration();
            })
            .catch((err) => {
              console.error(err);
            });
        }

        updateAvailability(
          updateAvailabilityDatesAndTimes,
          userSchedularId,
          selectedTimeZoneNew.current
        )
          .then((e) => {
            addNotificationToQueue(
              "success",
              "Availabilities successfully updated"
            );
            setAvailabilityDetails(availabilityDatesAndTimes);
            setIsEdit(false);
            setIsValuePassed(false);
            handleGet(e);
          })
          .catch((err) => {
            addNotificationToQueue(
              "danger",
              "Something Went Wrong While Saving Changes. Please try again"
            );
          });
      }
    } else {
      notificationManage("danger", "Please Enter Valid Time");
    }
  };

  const handleTimeZoneChange = (selected) => {
    if (selected && selected?.length > 0) {
      const selectedTimeZone = selected[0]?.utc;
      selectedTimeZoneNew.current = selectedTimeZone;
      const foundTimeZone = timeZones?.find((timeZone) =>
        timeZone?.utc?.includes(selectedTimeZone)
      );
      if (foundTimeZone) {
        const newSelectedTimeZoneValue = `${foundTimeZone?.text} ${foundTimeZone?.utc[0]}`;
        setNewSelectedTimeZone(newSelectedTimeZoneValue);
      }
      autoSaveButtonHandle();
    }
  };
  
  const timeZoneValue = (text, utc) => {
    return text + " " + utc;
  };

  const deleteOverrides = (date) => {
    deleteOverridesAvailability(userProfile?.schedulerId, date)
      .then((e) => {
        notificationManage("success", "Account successfully updated");
        getBookingConfiguration();
      })
      .catch((err) => {
        notificationManage(
          "danger",
          "Something Went Wrong While Saving Changes. Please try again"
        );
      });
  };

  const deleteOverridesTime = (date, startTime, endTime, segments) => {
    updateOverridesAvailability(
      {
        wday: "",
        segments: segments
          .filter((item) => {
            return item.startTime !== startTime && item.endTime !== endTime;
          })
          .map((item) => {
            const { atid, ...newItem } = item;
            const { error, ...newItem2 } = newItem;
            return newItem2;
          }),
        type: AVAILABILITY_DAY,
        date: date,
        available: true,
      },
      userProfile?.schedulerId,
      date,
      selectedTimeZoneNew.current
    )
      .then((e) => {
        notificationManage("success", "Account successfully updated");
        getBookingConfiguration();
      })
      .catch((err) => {
        notificationManage(
          "danger",
          "Something Went Wrong While Saving Changes. Please try again"
        );
      });
  };

  return (
    <>
      {isShowSpinner ? (
        <div className="edit-profile-settings-spinner d-flex justify-content-center align-items-center">
          <Spinner animation="border" variant="primary" />
        </div>
      ) : (
        <div className="container main">
          <div className="edit-profile-settings" ref={captureAction}>
            <div className="edit-profile-settings-title">
              <p>Schedule your available times</p>
            </div>
            <div className="edit-profile-settings-container">
              <div className="edit-profile-settings-container-main">
                <div className="edit-profile-setting-container-space">
                  <div className="edit-profile-settings-select-duration">
                    <p className="sub-title">Set estimated call duration *</p>
                  </div>
                  <CheckboxDropdown
                    durationValues={durationValuesState}
                    setIsValuePassed={setIsValuePassed}
                    items={callDurationList}
                    setDurationValues={setDurationValues}
                    className="edit-profile-settings-select-dropdown"
                  />
                </div>
                <div className="edit-profile-setting-container-space edit-profile-setting-container-space-bottom">
                  <div className="edit-profile-settings-select-time-zone">
                    <p className="sub-title">Select your time zone *</p>
                  </div>
                  <div className="form-group-setting form-control-setter">
                    <Typeahead
                      className="time-zonal-profile"
                      id="formControlSelect1"
                      defaultInputValue={newSelectedTimeZone}
                      onChange={(selected) => handleTimeZoneChange(selected)}
                      labelKey="text"
                      renderMenuItemChildren={(option, props) => (
                        <div className="option-menu">
                          {truncateName(
                            timeZoneValue(option?.text, option?.utc),
                            45
                          )}
                        </div>
                      )}
                      placeholder="Search a timezone"
                      options={timeZones?.flatMap((time) =>
                        time?.utc?.map((timeUtc) => ({
                          text: time?.text + " " + timeUtc,
                          utc: timeUtc,
                        }))
                      )}
                      selectHintOnEnter={true}
                    />
                  </div>
                </div>
              </div>
              <div
                className="divider-availability"
                style={{
                  borderTopColor: "#bed9fd",
                  width: " 100% !important",
                }}
              ></div>
              <div className="availability-selector-title mb-3">
                Set your weekly hours
              </div>
              <div className="availability-selector-wrap mb-3">
                <div className="row">
                  <div className="select-time col-12 col-sm-12 col-md-4">
                    <p className="sub-title">Select available day(s) *</p>
                  </div>
                  <div className="select-time col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 col-xxl-4">
                    <p className="sub-title">Select available times *</p>
                  </div>
                </div>
                <div>
                  {availabilityDatesAndTimes.map((wday, i) => (
                    <AvailabilitySelector
                      key={i}
                      wday={wday}
                      addNewAvailabilityTime={addNewAvailabilityTime}
                      deleteAvailabilityTime={deleteAvailabilityTime}
                      handleTimeChange={handleTimeChange}
                      handleDayAvailability={handleDayAvailability}
                      isLimitReached={wday?.segments?.length === 3}
                      validateInputs={validateInputs}
                      availabilityDatesAndTimes={availabilityDatesAndTimes}
                      isError={isError}
                    />
                  ))}
                </div>
                <div
                  className="dropdown-divider w-100 mt-4 pt-2"
                  style={{
                    borderTopColor: "#BED9FD",
                    width: " 100% !important",
                  }}
                ></div>
              </div>
              <div className="overrides">
                <div className="add-date-overrides">
                  <div className="add-date-overrides-title">
                    Add date overrides
                  </div>
                  <div className="add-date-overrides-content">
                    <div className="add-date-overrides-content-new">
                      <div className="add-date-overrides-content-text">
                        Add dates when your availability changes from your
                        weekly hours
                      </div>
                      <div className="add-date-overrides-content-button ml-auto">
                        <button
                          type="button"
                          className="btn btn-primary btn-block btn-add-date-overrides d-flex align-items-center align-content-center justify-content-center"
                          onClick={() => {
                            handleModalOpen();
                          }}
                        >
                          <PlusLg className="bi bi-calendar btn-plus-icon"></PlusLg>
                          <span className="btn-add-date-overrides-text">
                            Add a date override
                          </span>
                        </button>
                      </div>
                    </div>
                    {dateOverridesDatesAndTimes && (
                      <>
                        <div
                          className="dropdown-divider w-100 mt-4 pt-2"
                          style={{
                            borderTopColor: "#BED9FD",
                            width: " 100% !important",
                          }}
                        ></div>
                        {dateOverridesDatesAndTimes.map((dateOverride, i) => (
                          <div
                            className="add-date-overrides-content-value"
                            key={i}
                          >
                            <div className="add-date-overrides-content-value-grid align-items-center">
                              <div className="add-date-overrides-content-all">
                                <div className="add-date-overrides-date">
                                  {dateOverride.date}
                                </div>
                                <div className="d-flex w-100 add-date-overrides-information">
                                  <div className="d-flex justify-content-start overrides-calendar-content-time-slots-times align-items-center ml-2">
                                    {dateOverride.segments?.map(
                                      (segment, i) => (
                                        <button
                                          className="btn time-slot-table-cell align-items-center text-align-center mr-1"
                                          key={i}
                                        >
                                          {dateOverride.available ? (
                                            <div>
                                              {segment.startTime}-
                                              {segment.endTime}
                                            </div>
                                          ) : (
                                            <div>Unavailable</div>
                                          )}
                                          <CloseButton
                                            className="bi bi-x-circle-fill"
                                            onClick={() => {
                                              if (
                                                dateOverride?.segments
                                                  ?.length === 1
                                              ) {
                                                deleteOverrides(
                                                  dateOverride.date
                                                );
                                              } else {
                                                deleteOverridesTime(
                                                  dateOverride.date,
                                                  segment.startTime,
                                                  segment.endTime,
                                                  dateOverride.segments
                                                );
                                              }
                                            }}
                                          ></CloseButton>
                                        </button>
                                      )
                                    )}
                                  </div>
                                  <div className="overrides-calendar-content-time-slots-times-delete d-flex justify-content-end align-items-center">
                                    <button
                                      type="button"
                                      className="btn btn-primary delete-button"
                                      onClick={() => {
                                        deleteOverrides(dateOverride.date);
                                      }}
                                    >
                                      <Trash className="bi bi-trash-fill trash-icon"></Trash>
                                    </button>
                                  </div>
                                </div>
                              </div>
                              <div
                                className="dropdown-divider w-100 mt-3 pt-2"
                                style={{
                                  borderTopColor: "#BED9FD",
                                  width: " 100% !important",
                                }}
                              ></div>
                            </div>
                          </div>
                        ))}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <DateOverridesModal
              openDateOverrides={openDateOverrides}
              setOpenDateOverrides={setOpenDateOverrides}
              availabilityDatesAndTimes={dateOverridesDatesAndTimesNew}
              addNewAvailabilityTime={addNewAvailabilityTimeOverrides}
              deleteAvailabilityTime={deleteAvailabilityTimeOverrides}
              handleTimeChange={handleTimeChangeOverrides}
              handleDayAvailability={handleDayAvailabilityOverrides}
              handleApplyDateOverrides={handleApplyDateOverrides}
              dateOverridesDatesAndTimes={dateOverridesDatesAndTimes}
              setOverrideDates={setOverrideDates}
              overrideSelectedDates={overrideDates}
              userSchedularId={userSchedularId}
              setIsOverrideDayAvailable={setIsOverrideDayAvailable}
              validateInputsOverrides={validateInputsOverrides}
              setScheduledCallsArr={setScheduledCallsArr}
              handleLeaveModal={handleLeaveModal}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default ProfileSettings;
