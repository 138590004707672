import Cookies from "js-cookie";
import moment from "moment-timezone";
import { ACCESS_TOKEN, SEARCH_HISTORY } from "../constants";
import { editProfile, getCurrentUser, getProfile } from "./APIUtils";
import { notificationManage } from "./NotificationUtils";
import { Popover } from "react-bootstrap";

export const errVal = ["", null, undefined];

export const handleEmailValidation = (email) => {
  const emailFormat = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
  return email.match(emailFormat) ? true : false;
};

export const visitorDetails = "visitorDetails";

export const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const toUpperCamelCase = (value) => {
  const tempStr = [];
  value.split(" ").map((item, index) => {
    return tempStr.push(index === 0 ? item.toUpperCase() : item.toLowerCase());
  });
  return tempStr.join(" ");
};

export const USER_ROLE = {
  USER: "ROLE_USER",
  ADMIN: "ROLE_ADMIN",
  SUPER_ADMIN: "ROLE_SUPER_ADMIN",
};

export const capitalizeFirstLetter = (s) => {
  return s && s[0].toUpperCase() + s.slice(1);
};

export const hideEmailCharacters = (email) => {
  let hiddenEmail = "";
  for (let i = 0; i < email.length; i++) {
    if (
      (i > 0 && i < email.indexOf("@")) ||
      (i > email.indexOf("@") + 1 && i < email.indexOf("."))
    ) {
      hiddenEmail += "*";
    } else {
      hiddenEmail += email[i];
    }
  }
  return hiddenEmail;
};

const getPath = () => {
  (localStorage.getItem("rightPanelState") === "settings" ||
    localStorage.getItem("rightPanelState") === null ||
    localStorage.getItem("rightPanelState") === "scheduled call") &&
    localStorage.setItem("rightPanelState", "profile");
  return "/profile/";
};

const handleResetPassword = () => {
  let storedUrl = Cookies.get("storeLocation");
  if (storedUrl.includes("recover/reset-password")) {
    return "/";
  }
  return storedUrl;
};

export const handleRedirectUrlAfterLogin = (props) => {
  const askAQuestion = sessionStorage.getItem("ask-a-question");
  const post = sessionStorage.getItem("post-id")
    ? JSON.parse(sessionStorage.getItem("post-id"))
    : null;
  let isEmpty = false;

  localStorage.getItem(ACCESS_TOKEN) &&
    getCurrentUser()
      .then((currentUser) => {
        props.verifyUser(currentUser);
        currentUser &&
          getProfile(currentUser?.id)
            .then((currentUserProfile) => {
              if (
                currentUserProfile?.userName === "" ||
                currentUserProfile?.email === null ||
                currentUserProfile?.mobileNumber === null ||
                currentUserProfile?.cityId === null ||
                (currentUserProfile?.isFriend
                  ? currentUserProfile?.description === null
                  : currentUserProfile?.categories === null)
              ) {
                Cookies.set("isEmpty", JSON.stringify(true));
                isEmpty = true;
              }
              Cookies.set("timeZone", currentUserProfile.timeZone);
              const visitorCategories =
                JSON.parse(localStorage.getItem(visitorDetails))
                  ?.preferredCategories || [];
              const profileCategories = currentUserProfile.categories || [];
              const finalCategories = [
                ...new Set([...visitorCategories, ...profileCategories]),
              ];
              const formData = new FormData();
              if (currentUserProfile.loginCount === 1) {
                formData.append("categories", finalCategories);
                const selectedCity =
                  JSON.parse(localStorage.getItem(visitorDetails))?.city || "";
                formData.append("cityId", selectedCity);
                editProfile(currentUserProfile.id, formData)
                  .then((updatedUserProfile) => {
                    props.history.push(
                      updatedUserProfile &&
                        updatedUserProfile.role !== USER_ROLE.USER
                        ? "/review"
                        : getPath()
                    );
                  })
                  .catch(() => {
                    notificationManage(
                      "danger",
                      "Oops! Something went wrong. Please try again!"
                    );
                  });
              } else {
                let storedUrl = Cookies.get("storeLocation");
                let scheduleLocation = Cookies.get("scheduleLocation");
                let scheduleCall = sessionStorage.getItem("popupShown");
                const isCurrentUrl =
                  currentUserProfile &&
                  currentUserProfile.role !== USER_ROLE.USER
                    ? "/review"
                    : isEmpty
                    ? getPath()
                    : (askAQuestion || post) && currentUserProfile?.cityId
                    ? `/city/${currentUserProfile?.cityId}`
                    : props?.friendLocation && !currentUserProfile?.isFriend
                    ? `${props?.friendLocation}`
                    :  handleResetPassword(storedUrl);
                if (scheduleLocation) {
                  props.history.push(scheduleLocation);
                }
                else if (isCurrentUrl === storedUrl) {
                  window.location.reload();
                } else {
                  props.history.push(isCurrentUrl);
                }
              }
            })
            .catch(() => {
              notificationManage("danger", "Fails to retrieve user data");
            });
      })
      .catch(() => {
        notificationManage("danger", "Fails to retrieve current user");
      });
};

export const getSearchHistory = () => {
  const store = JSON.parse(localStorage.getItem(SEARCH_HISTORY));
  const list = [];
  if (store !== null) {
    store.forEach((item) => {
      const obj = JSON.parse(item);
      if (!(errVal.includes(obj?.id) && errVal.includes(obj?.name))) {
        list.push({
          name: obj.name,
          id: obj.id,
        });
      }
    });
  }
  return list;
};

export const scrollTop = () => {
  window.scrollTo(0, 0);
};

export const getTruncateStr = (keyword, limit) => {
  if (keyword?.length > limit) {
    return keyword.slice(0, limit - 3) + "...";
  }
  return keyword;
};

export const convertTimesByTimeZone = (startTime, endTime, fromTz, toTz) => {
  startTime = startTime.split(".")[0];
  const start = moment
    .tz(startTime.split(".")[0], "HH:mm:ss", fromTz)
    .clone()
    .tz(toTz);
  const end = moment
    .tz(endTime.split(".")[0], "HH:mm:ss", fromTz)
    .clone()
    .tz(toTz);
  return {
    startTime: start.format("HH:mm:ss"),
    endTime: end.format("HH:mm:ss"),
  };
};

export const truncateName = (name, limit) => {
  if (name?.length <= limit) {
    return name;
  }
  return name?.slice(0, limit) + "...";
};

export const convertDate = (dateString) => {
  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
};

export const passwordState = Object.freeze({
  INVALID_PASSWORD:
    "Please enter a password with at least 8 characters, one uppercase letter, one lower case letter, one numeric, and one special character.",
  VALID_PASSWORD: "Valid password",
});

export const validatePassword = (password) => {
  return password.length < 8 ||
    !/[A-Z]/.test(password) ||
    !/[a-z]/.test(password) ||
    !/\d/.test(password) ||
    !/[!@#$%^&*]/.test(password)
    ? passwordState.INVALID_PASSWORD
    : passwordState.VALID_PASSWORD;
};

export const passwordPolicy = (
  <Popover className="account-type-info">
    <Popover.Content className="account-type-info-content">
      <div>
        <div className="account-type-info-content-title">
          The password should have,
        </div>
        <div className="account-type-info-content-description">
          At least 8 characters
        </div>
        <div className="account-type-info-content-description">
          At least one 1 uppercase character
        </div>
        <div className="account-type-info-content-description">
          At least one 1 lowercase character
        </div>
        <div className="account-type-info-content-description">
          At least one 1 number
        </div>
        <div className="account-type-info-content-description">
          At least one 1 special character
        </div>
      </div>
    </Popover.Content>
  </Popover>
);