import "./Home-City-Category.scss";
import IconEmployment from "../home-city-categories/icons/emplyement.svg";
import IconEducation from "../home-city-categories/icons/education.svg";
import IconCommmunity from "../home-city-categories/icons/community.svg";
import IconFood from "../home-city-categories/icons/food.svg";
import IconHealthCare from "../home-city-categories/icons/healthcare.svg";
import IconRecreational from "../home-city-categories/icons/recreational.svg";
import IconTransport from "../home-city-categories/icons/transport.svg";
import IconHousing from "../home-city-categories/icons/housing.svg";

const HomeCityCategory = () => {
  const featureData = [
    {
      id: 1,
      title: "Employment",
      icon: IconEmployment,
    },
    {
      id: 2,
      title: "Education",
      icon: IconEducation,
    },
    {
      id: 3,
      title: "Healthcare",
      icon: IconHealthCare,
    },
    {
      id: 4,
      title: "Recreational",
      icon: IconRecreational,
    },
    {
      id: 5,
      title: "Housing",
      icon: IconHousing,
    },
    {
      id: 6,
      title: "Transport",
      icon: IconTransport,
    },
    {
      id: 7,
      title: "Food",
      icon: IconFood,
    },
    {
      id: 8,
      title: "Community",
      icon: IconCommmunity,
    },
  ];

  return (
    <div className="home-city-category">
      <div className="home-city-category-title">
        <p>Wish you had a friend in the new city?</p>
        <svg className="home-city-category-underline">
          <rect width="59px" height="3px" fill="#EE4D67" />
        </svg>
      </div>
      <div className="home-city-category-contents">
        <p id="title">Find them on Move2NewCity</p>
        <p id="content">
          With Move2NewCity, you can find friends who can share lived
          experiences of the new city you are moving to. It is the platform that
          helps you with the settlement process when moving to the new city.
          Newcomers can find friends to gain knowledge and hear real stories to
          make their settlement experience seamless.
        </p>
      </div>
      <div className="home-city-category-icons">
        {featureData.map((feature) => {
          return (
            <FeatureItem
              key={feature.id}
              title={feature.title}
              icon={feature.icon}
            />
          );
        })}
      </div>
      <div className="home-city-category-below"></div>
    </div>
  );
};

const FeatureItem = ({ title, icon }) => {
  return (
    <div className="feature-item">
      <div className="feature-item-icon-container">
        <img
          className="feature-item-icon-image"
          src={icon}
          alt={`icon-${title}`}
        />
      </div>
      <div className="feature-item-icon-title">
        <p>{title}</p>
      </div>
    </div>
  );
};

export default HomeCityCategory;
