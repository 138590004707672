import { Button, Modal } from "react-bootstrap";
import "../../layouts/review/ReviewPosts.scss";

const EditAndApproveModal = (props) => {
  const {
    isEditAndApproveModalOpen,
    setIsEditAndApproveModalOpen,
    handleQuestionEditChange,
    rejection,
    handleQuestionEditSubmit,
    reviewMode,
  } = props.editAndApproveProps;
  return (
    <Modal
      size="sm"
      centered
      show={isEditAndApproveModalOpen}
      onHide={() => setIsEditAndApproveModalOpen(false)}
      backdropClassName="approve-questions-edit-modal-backdrop"
      dialogClassName="approve-questions-edit-modal-dialog"
      contentClassName="approve-questions-edit-modal-content">
      <Modal.Header
        className="approve-questions-edit-modal-header"
        closeButton></Modal.Header>
      <Modal.Body className="approve-questions-edit-modal-body">
        <textarea
          id="title"
          disabled={reviewMode === "Answers"}
          onChange={(e) => handleQuestionEditChange(e)}
          className="approve-questions-edit-modal-body-title">
          {rejection.title}
        </textarea>
        <textarea
          id="text"
          onChange={(e) => handleQuestionEditChange(e)}
          className="approve-questions-edit-modal-body-text">
          {rejection.text}
        </textarea>
      </Modal.Body>
      <Modal.Footer className="approve-questions-edit-modal-footer">
        <Button
          onClick={() => handleQuestionEditSubmit()}
          className="approve-questions-edit-modal-footer-button">
          Approve Edited Text
        </Button>
        <Button
          className="approve-questions-edit-modal-footer-button"
          onClick={() => setIsEditAndApproveModalOpen(false)}>
          Go Back
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default EditAndApproveModal;
