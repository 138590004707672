import React, { useEffect, useState } from "react";
import { Form, Spinner } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import "./Manage-City-Modal.scss";
import UploadFiles from "../drag-and-drop-upload/Upload-File";
import { PencilFill, TrashFill } from "react-bootstrap-icons";
import { API_BASE_URL } from "../../constants";
import { notificationManage } from "../../util/NotificationUtils";
import { addCity, editCity, editCityMedia } from "../../util/APIUtils";

const ManageCityModal = (props) => {
  const {
    open,
    onClose,
    setOpenManageCity,
    manageCityData,
    loadCityList,
    cities,
  } = props;

  const initialFormState = {
    name: "",
    country: "",
    image: "",
    errors: { name: null, country: null, image: null },
  };
  const [imageFile, setImageFile] = useState(null);
  const [city, setCity] = useState(initialFormState);
  const [editedCity, setEditedCity] = useState(null);
  const [errors, setErrors] = useState(initialFormState.errors);
  const [image, setImage] = useState({ file: null, objectUrl: null });
  const [showLoader, setShowLoader] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);

  useEffect(() => {
    if (imageFile) {
      setCity((prev) => ({
        ...prev,
        image: 0,
      }));
    }
    setErrors((prev) => ({
      ...prev,
      image: null,
    }));
  }, [imageFile]);

  useEffect(() => {
    if (!isEdit || editedCity !== null || imageFile !== null) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [editedCity, imageFile, isEdit]);

  useEffect(() => {
    if (manageCityData.city) {
      setImage((prevState) => ({
        ...prevState,
        objectUrl: `${API_BASE_URL}/v1/cities/${manageCityData.city.id}/image`,
      }));
    }
    setIsEdit(manageCityData.type === "Edit City");
  }, [manageCityData]);

  const handleTextInputChange = (event) => {
    const { name, value } = event.target;
    handleInputValidation(name, value);
    isEdit
      ? setEditedCity({ ...editedCity, [name]: value })
      : setCity({ ...city, [name]: value });
  };

  const handleInputValidation = (name, value) => {
    switch (name) {
      case "country":
        setErrors((previous) => ({
          ...previous,
          country: value.length === 0 && "Country name is required",
        }));
        break;
      case "name":
        setErrors((previous) => ({
          ...previous,
          name: value.length === 0 && "City name is required",
        }));
        break;
      case "image":
        setErrors((previous) => ({
          ...previous,
          image: value.length === 0 && "City image is required",
        }));
        break;
      default:
        break;
    }
  };

  const handleFormValidation = (data) => {
    let valid = true;
    Object.keys(initialFormState).forEach((key, index) => {
      if (data[key] === "") {
        handleInputValidation(key, "");
        valid *= false;
      } else {
        valid *= true;
      }
    });
    return valid;
  };

  const handleAddCity = (event) => {
    event.preventDefault();
    const formData = new FormData();
    if (handleFormValidation(city)) {
      if (
        cities.some(
          (item) =>
            item.name.toLowerCase() === city.name.toLowerCase() &&
            item.country.toLowerCase() === city.country.toLowerCase()
        )
      ) {
        notificationManage("warning", "City already exists");
        handleClose();
        return;
      }
      setShowLoader(true);
      formData.append("name", city.name);
      formData.append("country", city.country);
      formData.append("image", imageFile[0]);
      addCity(formData)
        .then(() => {
          notificationManage(
            "success",
            `${city.name} added to the list successfully`
          );
          setOpenManageCity(false);
          clearStates();
        }).catch(() => {
          setShowLoader(false);
          setOpenManageCity(false);
          notificationManage("danger", "City updating failed");
        })
        .finally(() => {
          handleClose();
          loadCityList();
        });
    }
  };

  const handleEditCity = (event) => {
    event.preventDefault();
    const data = {};
    if (
      editedCity &&
      handleFormValidation(editedCity) &&
      (imageFile || image !== null)
    ) {
      setShowLoader(true);
      for (let key of Object.keys(editedCity)) {
        data[key] = editedCity[key];
      }
      editCity(manageCityData.city.id, data)
        .then(() => {
          notificationManage(
            "success",
            `${manageCityData.city.name} updated successfully`
          );
          setOpenManageCity(false);
          handleClose();
        })
        .catch(() => {
          setShowLoader(false);
          notificationManage("danger", "City updating failed");
        })
        .finally(() => {
          loadCityList();
        });
    }
    if (imageFile) {
      handleEditMedia("image");
      setShowLoader(true);
    } else {
      if (!image?.objectUrl) {
        notificationManage("danger", "City image is required");
        setShowLoader(false);
        return;
      }
    }
  };

  const handleEditMedia = (name) => {
    const formData = new FormData();
    formData.append("file", imageFile[0]);
    editCityMedia(manageCityData.city.id, formData, name)
      .then(() => {
        !editedCity &&
          notificationManage("success", `City ${name} updated successfully`);
        setOpenManageCity(false);
        handleClose();
      })
      .catch(() => {
        setShowLoader(false);
        notificationManage("danger", `City ${name} update failed`);
      })
      .finally(() => {
        loadCityList();
      });
  };

  const handleDeleteClick = () => {
    setImageFile(null);
    setImage(null);
    setCity((prevState) => ({
      ...prevState,
      image: "",
    }));
  };

  const clearStates = () => {
    Array.from(document.querySelectorAll("textarea")).forEach(
      (textarea) => (textarea.value = "")
    );
    setCity(initialFormState);
    setErrors(initialFormState.errors);
    setImageFile(null);
    setShowLoader(false);
    setEditedCity(null);
    setImage(null);
  };

  const handleClose = () => {
    onClose();
    setImageFile(null);
    clearStates();
  };

  return (
    <Modal
      centered
      show={open}
      onHide={handleClose}
      backdrop="static"
      className="manage-city-modal">
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <Modal.Title>{manageCityData.type}</Modal.Title>
        <div className="manage-city-content"></div>
        <div className="manage-city-form">
          <Form>
            <div>
              {imageFile?.length || image?.objectUrl ? (
                <div className="manage-city-form-preview">
                  <div className="manage-city-form-preview-image">
                    <img
                      src={
                        image?.objectUrl
                          ? image?.objectUrl
                          : imageFile[0].preview
                      }
                      alt="uploaded img"></img>
                    <div className="manage-city-form-preview-image-overlay pt-2">
                      <PencilFill
                        fill="#FFFFFF"
                        size={20}
                        className="manage-city-form-preview-image-edit"
                        onClick={() => handleDeleteClick()}></PencilFill>
                      <TrashFill
                        fill="#FFFFFF"
                        size={20}
                        className="manage-city-form-preview-image-delete"
                        onClick={() => handleDeleteClick()}></TrashFill>
                    </div>
                  </div>
                </div>
              ) : (
                <UploadFiles
                  type="city image"
                  setImageFile={setImageFile}
                  setCity={setCity}
                />
              )}
            </div>
            <div className="ml-2 city-form-input-error">
              {errors.image !== null ? errors.image : undefined}
            </div>
            <Form.Group className="mb-3 pt-4 mt-3" controlId="formBasicName">
              <Form.Label className="manage-city-form-title">
                City name *
              </Form.Label>
              <Form.Control
                className="manage-city-form-input"
                type="text"
                name="name"
                placeholder="Enter the City name"
                defaultValue={manageCityData.city && manageCityData.city.name}
                onChange={handleTextInputChange}
                required
              />
              <div className="ml-2 city-form-input-error">
                {errors.name !== null ? errors.name : undefined}
              </div>
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicAddress">
              <Form.Label className="manage-city-form-title pt-4">
                Country *
              </Form.Label>
              <Form.Control
                className="manage-city-form-input"
                type="text"
                placeholder="Enter the Country name"
                name="country"
                defaultValue={
                  manageCityData.city && manageCityData.city.country
                }
                onChange={handleTextInputChange}
                required
              />
              <div className="ml-2 city-form-input-error">
                {errors.country !== null ? errors.country : undefined}
              </div>
            </Form.Group>
          </Form>
        </div>
      </Modal.Body>
      <Modal.Footer className="pt-4 px-3">
        <Button
          className="save-button button"
          variant="primary"
          disabled={isDisabled}
          onClick={isEdit ? handleEditCity : handleAddCity}>
          {showLoader && (
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
              className="mr-2"
            />
          )}
          {isEdit ? "Update" : "Save"}
        </Button>
        <Button
          className="cancel-button button"
          variant="outline-primary"
          onClick={handleClose}>
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ManageCityModal;
