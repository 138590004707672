import Cookies from "js-cookie";
import moment from "moment";
import queryString from "query-string";
import { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import "react-loading-skeleton/dist/skeleton.css";
import ReactPaginate from "react-paginate";
import EmptyCard from "../../components/empty-card/Empty-Card";
import ScheduleDetails from "../../components/schedule-details/Schedule-details";
import { SCHEDULER_NAVIGATOR, TENANT_ID } from "../../constants";
import {
  schedulePageSize,
  schedulerCallFilterList,
  schedulerStatus,
} from "../../constants/scheduleConstants";
import { ReactComponent as NextArrowIcon } from "../../img/nextArrow.svg";
import { ReactComponent as PreviousArrowIcon } from "../../img/previousArrow.svg";
import {
  fetchAllAppointments,
  fetchAppointmentById,
  getCurrentUser,
  getProfileById,
  getSchedularUser,
} from "../../util/APIUtils";
import { notificationManage } from "../../util/NotificationUtils";
import ScheduleCallHeader from "./Schedule-call-header";
import "./Schedule-call.scss";
import ScheduleCallSkeleton from "./ScheduleCallSkeleton";

const ScheduleCall = () => {
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [paginationLoading, setPaginationLoading] = useState(false);
  const [isFriend, setIsFriend] = useState();
  const [schedules, setSchedules] = useState([]);
  const [pagination, setPagination] = useState({
    currentPage: 0,
    totalPages: 0,
  });
  const [friendTimeZone, setFriendTimeZone] = useState("");
  const scheduleCallDetails = queryString.parse(window.location.search);
  const [friendSchedularId, setFriendSchedularId] = useState("");
  const [filterSelection, setFilterSelection] = useState(
    schedulerCallFilterList[0]
  );
  const [isUpdated, setIsUpdated] = useState(false);

  useEffect(() => {
    if (isFriend && friendSchedularId) {
      getSchedularUser(friendSchedularId, SCHEDULER_NAVIGATOR.RESOURCES)
        .then((res) => {
          setFriendTimeZone(res?.timeZone);
        })
        .catch((err) => {
          notificationManage("danger", "Cannot fetch friend timezone details");
        });
    }
  }, [friendSchedularId]);

  useEffect(() => {
    const fetchCurrentUser = async () => {
      try {
        const response = await getCurrentUser();
        if (response) {
          let customerData = { ...response };
          setCurrentUser(customerData);
          setIsFriend(customerData.isFriend);
          setFriendSchedularId(customerData.schedulerId);
        }
      } catch (error) {
        notificationManage("danger", "Can't find user to get details");
      }
    };
    fetchCurrentUser();
  }, []);

  useEffect(() => {
    currentUser && getAllAppointments(currentUser);
  }, [currentUser, isUpdated]);

  const checkStatesList = () => {
    return (
      scheduleCallDetails?.status &&
      Object.values(schedulerStatus).includes(scheduleCallDetails?.status)
    );
  };

  const getAllAppointments = async (currentUser, page, filterStatus) => {
    let responseSchedules = {};
    let notification = {
      type: "",
      msg: "",
      status: false,
    };
    if (currentUser) {
      try {
        if (page === undefined) setLoading(true);
        const { isFriend, schedulerId, timeZone } = currentUser;
        if (schedulerId) {
          if (isFriend) {
            if (
              checkStatesList() &&
              scheduleCallDetails?.appointment &&
              scheduleCallDetails?.status !== schedulerStatus.RESCHEDULED &&
              scheduleCallDetails?.status !== schedulerStatus.CANCELLED &&
              scheduleCallDetails?.status !== schedulerStatus.SCHEDULED
            ) {
              responseSchedules = await fetchAppointmentById(
                scheduleCallDetails?.appointment,timeZone
              );
            } else {
              responseSchedules = await fetchAllAppointments(
                schedulerId,
                "",
                new Date()?.toISOString()?.slice(0, 19),
                isFriend,
                Cookies.get("paginationCount")
                  ? Cookies.get("paginationCount")
                  : page || 0,
                schedulePageSize,
                filterStatus && filterStatus !== "ALL"
                  ? "startDateTime"
                  : "-created",
                timeZone,
                filterStatus && filterStatus !== "ALL" ? filterStatus : ""
              );
            }
          } else if (!isFriend) {
            if (
              checkStatesList() &&
              scheduleCallDetails?.appointment &&
              scheduleCallDetails?.status !== schedulerStatus.SUGGESTED &&
              scheduleCallDetails?.status !== schedulerStatus.DECLINED &&
              scheduleCallDetails?.status !== schedulerStatus.CONFIRMED
            ) {
              responseSchedules = await fetchAppointmentById(
                scheduleCallDetails?.appointment,timeZone
              );
            } else {
              responseSchedules = await fetchAllAppointments(
                schedulerId,
                "",
                new Date()?.toISOString()?.slice(0, 19),
                isFriend,
                Cookies.get("paginationCount")
                  ? Cookies.get("paginationCount")
                  : page || 0,
                schedulePageSize,
                filterStatus && filterStatus !== "ALL"
                  ? "startDateTime"
                  : "-created",
                timeZone,
                filterStatus && filterStatus !== "ALL" ? filterStatus : ""
              ).then((response) => {
                return response;
              });
            }
          }
          if (responseSchedules !== undefined && timeZone !== "") {
            let schedulesData = [];
            if (checkStatesList()) {
              schedulesData.push(responseSchedules);
            } else {
              schedulesData = [...responseSchedules.content];
            }
            let { totalPages, number } = responseSchedules;
            if (schedulesData) {
              await Promise.all(
                schedulesData.map(async (schedule) => {
                  let scheduleCustomerData = {};
                  let scheduleProfileData = {};
                  let scheduleNavigator = "";
                  const { consumerId, resourceId } = schedule;
                  const getSchedularId = () => {
                    if (isFriend && consumerId) {
                      scheduleNavigator = SCHEDULER_NAVIGATOR.CONSUMERS;
                      return consumerId;
                    }
                    if (!isFriend && resourceId) {
                      scheduleNavigator = SCHEDULER_NAVIGATOR.RESOURCES;
                      return resourceId;
                    }
                  };
                  scheduleCustomerData = await getSchedularUser(
                    getSchedularId(),
                    scheduleNavigator
                  );
                  if (scheduleCustomerData) {
                    const { email } = scheduleCustomerData;
                    scheduleProfileData = await getProfileById(email);
                  }
                  schedule.customer = scheduleCustomerData;
                  schedule.profile = scheduleProfileData;
                  return schedule;
                })
              );
            }
            setSchedules(schedulesData);
            if (totalPages !== pagination.totalPages || page === undefined) {
              let paginationData = {
                currentPage: number,
                totalPages: totalPages,
                pageReload: false,
              };
              setPagination(paginationData);
            }
          } else {
            notification = {
              type: "danger",
              msg: "Couldn't Get Data Please Try Again",
              status: true,
            };
          }
          setLoading(false);
          setPaginationLoading(false);
        } else {
          setLoading(false);
          notification = {
            type: "danger",
            msg: "User Scheduler ID Undefined Please Try Again",
            status: true,
          };
        }
      } catch (error) {
        setLoading(false);
        notification = {
          type: "danger",
          msg: "Error Occur Please Try Again",
          status: true,
        };
      }
    } else {
      notification = {
        type: "danger",
        msg: "Current User Undefined Data Please Try Again",
        status: true,
      };
    }
    notification.status &&
      notificationManage(notification.type, notification.msg);
    setIsUpdated(false);
  };

  const calculateDuration = (startTime, endTime) => (new Date(endTime)- new Date(startTime)) / (1000 * 60);
  
  const setScheduleWithUpdatedData = (data, appointmentID) => {
    setIsUpdated(true);
    if (data && appointmentID) {
      let index = schedules.findIndex(
        (schedule) => schedule.id === appointmentID
      );
      let updatedSchedules = [...schedules];
      if (index !== -1) {
        updatedSchedules[index] = {
          ...updatedSchedules[index],
          status: data.status,
          startDateTime: data.startDateTime,
          endDateTime: data.endDateTime,
          suggestedStartDateTime: data.suggestedStartDateTime,
          suggestedEndDateTime: data.suggestedEndDateTime,
          rescheduledStartDateTime: data.rescheduledStartDateTime,
          rescheduledEndDateTime: data.rescheduledEndDateTime,
        };
        setSchedules([...updatedSchedules]);
      }
    }
  };

  const getCurrentTimeZone = () => {
    // NOTE: current timezone process will change after user have timezone
    // NOTE: get value and filter from timeZone array in the util file
    const updateTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const offset = new Date().getTimezoneOffset();
    const offsetHours = Math.abs(Math.round(offset / 60));
    const offsetMinutes = Math.abs(offset % 60);
    const sign = offset >= 0 ? "-" : "+";
    const formattedTimezone = `(GMT${sign}${offsetHours
      .toString()
      .padStart(2, "0")}:${offsetMinutes
      .toString()
      .padStart(2, "0")}) ${updateTimeZone}`;
    return {
      timeZone: updateTimeZone,
      value: formattedTimezone,
    };
  };

  const handlePagination = ({ selected: selectedPage }) => {
    Cookies.set("paginationCount", JSON.stringify(selectedPage));
    setPagination({
      ...pagination,
      currentPage: selectedPage,
    });
    setPaginationLoading(true);
    getAllAppointments(currentUser, selectedPage, filterSelection.value);
  };

  const handleFilter = (eventKey) => {
    const filterdItem = schedulerCallFilterList.filter(
      (filterItem) => filterItem.value === eventKey
    )[0];
    if (filterdItem) {
      setFilterSelection(filterdItem);
      getAllAppointments(currentUser, 0, filterdItem.value);
      setLoading(true);
    }
  };

  const getStartTime = (status, schedule) => {
    switch (status) {
      case schedulerStatus.SUGGESTED:
        return schedule?.rescheduledStartDateTime;
      case schedulerStatus.RESCHEDULED:
        return schedule?.suggestedStartDateTime
          ? schedule?.suggestedStartDateTime
          : schedule?.startDateTime;
      default:
        return schedule?.startDateTime;
    }
  };

  const getEndTime = (status, schedule) => {
    switch (status) {
      case schedulerStatus.SUGGESTED:
        return schedule?.rescheduledEndDateTime;
      case schedulerStatus.RESCHEDULED:
        return schedule.suggestedEndDateTime
          ? schedule?.suggestedEndDateTime
          : schedule?.endDateTime;
      default:
        return schedule?.endDateTime;
    }
  };

  const scheduleDetails = (schedule, id) => {
    const { customer, profile } = schedule;
    return (
      <>
        {customer && profile && (
          <ScheduleDetails
            key={`schedule_${id}`}
            isLast={id === schedules.length - 1}
            name={customer.name}
            customerUsername={profile.userName}
            currentUserName={currentUser?.name}
            isFriend={isFriend}
            customerId={customer.id}
            scheduleTitle={`Scheduled a Call with ${customer.name}`}
            appointmentId={schedule.id}
            scheduledDate={getStartTime(schedule.status, schedule)}
            duration={
              schedule.startDateTime &&
              schedule.endDateTime &&
              calculateDuration(
                getStartTime(schedule.status, schedule),
                getEndTime(schedule.status, schedule)
              )
            }
            description={
              schedule.description || "Note: Not Given Special Message"
            }
            profileEmail={customer.email}
            startTime={getStartTime(schedule.status, schedule)}
            endTime={getEndTime(schedule.status, schedule)}
            createdDate={moment(schedule.created).format("YYYY-MM-DD")}
            status={schedule.status}
            friendProfile={profile}
            suggestedStartDateTime={schedule.suggestedStartDateTime}
            suggestedEndDateTime={schedule.suggestedEndDateTime}
            rescheduledStartDateTime={schedule.rescheduledStartDateTime}
            rescheduledEndDateTime={schedule.rescheduledEndDateTime}
            timeZone={currentUser.timeZone}
            schedularId={currentUser.schedulerId}
            updatedAt={moment(schedule.updated).format("YYYY-MM-DD")}
            tenantId={TENANT_ID}
            resourceID={schedule.resourceId}
            resourceName={schedule.resourceName}
            updator={schedule.updator}
            consumerID={schedule.consumerId}
            consumerName={schedule.consumerName}
            setScheduleWithUpdatedData={setScheduleWithUpdatedData}
            meetingLink={schedule?.meetingLink}
          />
        )}
      </>
    );
  };

  return (
    <>
      <div className="schedule-main-container container">
        {!loading && (
          <ScheduleCallHeader
            dataList={schedulerCallFilterList}
            selectedValue={filterSelection}
            handleAction={handleFilter}
          />
        )}
        <div className="scheduled-calls-container">
          {paginationLoading && (
            <div className="schedule-main-container-spinner">
              <Spinner animation="border" variant="primary" />
            </div>
          )}
          {loading ? (
            <div className="mt-4">
              <ScheduleCallSkeleton />
            </div>
          ) : (
            <>
              {schedules &&
                schedules?.map((schedule, id) => {
                  return scheduleDetails(schedule, id);
                })}
            </>
          )}
        </div>
        {!loading && pagination.totalPages > 1 && (
          <div className="scheduled-calls-pagination">
            <ReactPaginate
              pageCount={pagination.totalPages}
              marginPagesDisplayed={1}
              pageRangeDisplayed={3}
              nextLabel={<NextArrowIcon />}
              previousLabel={<PreviousArrowIcon />}
              onPageChange={handlePagination}
              containerClassName={"pagination"}
              activeClassName={"active"}
              pageClassName={"page-item"}
              pageLinkClassName={"page-link"}
              previousClassName={"page-item"}
              nextClassName={"page-item"}
              previousLinkClassName={"page-link"}
              nextLinkClassName={"page-link"}
              breakClassName={"page-item"}
              breakLinkClassName={"page-link"}
              forcePage={pagination.currentPage}
            />
          </div>
        )}
        {!loading && !pagination?.totalPages && !checkStatesList() && (
          <EmptyCard selectedPage={filterSelection.title} />
        )}
      </div>
    </>
  );
};

export default ScheduleCall;
