import { React, useCallback, useEffect, useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { declineCallOptions } from "../../constants/scheduleConstants";
import "./Decline-Call-Modal.scss";

const DeclineCallPopUp = ({ isFriend, show, handleClose, handleSubmit }) => {
  const [reasons, setReasons] = useState(null);
  const [showTextArea, setShowTextArea] = useState(false);
  const [isChecked, setIsChecked] = useState(true);

  const handleCheckboxChange = useCallback((id, checked) => {
    if (checked) {
      setIsChecked(false);
      const checkboxes = document.querySelectorAll('input[type="checkbox"]');
      checkboxes.forEach((checkbox) => {
        if (checkbox.id !== id && checkbox.checked) {
          checkbox.checked = false;
        }
      });
      if (id === "OTHER") {
        setShowTextArea(true);
        setReasons("");
      } else {
        setShowTextArea(false);
      }
    } else {
      setIsChecked(true);
      setShowTextArea(false);
    }
  }, []);

  const handleSubmiting = () => {
    const checkboxes = document.querySelectorAll(
      '.form-content-decline-call input[type="checkbox"]'
    );
    const checkedValues = Array.from(checkboxes)
      .filter((checkbox) => checkbox.checked)
      .map((checkbox) => checkbox.id);

    handleSubmit(checkedValues, reasons);
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      centered
      backdrop="static"
      className="decline-call"
    >
      <Modal.Header closeButton>
        <p className="decline-call-content-header">
          Are you sure you want to {isFriend ? "decline" : "cancel"} this call?
        </p>
      </Modal.Header>
      <Modal.Body>
        <div>
          <p className="decline-call-content-title">
            Reasons for {isFriend ? "declining" : "canceling"} :
          </p>
          <Form className="form-content-decline-call">
            {declineCallOptions.map(({ value, label }) => (
              <div key={value}>
                <Form.Check
                  type={"checkbox"}
                  id={value}
                  label={label}
                  onChange={(e) => {
                    handleCheckboxChange(value, e.target.checked);
                  }}
                  className="decline-call-checkbox"
                />
              </div>
            ))}
            <div className="d-flex-justify-content-center">
              {showTextArea && (
                <textarea
                  id="title"
                  placeholder="Give reasons"
                  className="decline-call-other-text-area"
                  value={reasons}
                  onChange={(e) => setReasons(e.target.value)}
                />
              )}
            </div>
          </Form>
        </div>
      </Modal.Body>
      <Modal.Footer className="justify-content-center">
        <Button
          className="primary default"
          onClick={handleSubmiting}
          disabled={isChecked}
        >
          Yes, {isFriend ? "Decline" : "Cancel the call"}
        </Button>
        <Button
          variant="primary"
          className="go-back-button"
          onClick={handleClose}
        >
          No, Go back
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DeclineCallPopUp;
