// Read from .env configuration file
export const ENV = process.env.REACT_APP_ENV;
export const BASE_URL = process.env.REACT_APP_OAUTH_REDIRECT_URL;
export const SCHEDULER_BASE_URL = process.env.REACT_APP_SCHEDULER_URL;
export const HOTJAR_CODE = process.env.REACT_APP_HOTJAR_CODE;
export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
export const TENANT_ID = process.env.REACT_APP_TENANT_ID;

export const ACCESS_TOKEN = "accessToken";
export const REFRESH_TOKEN = "refreshToken";
export const SEARCH_HISTORY = "searchHistory";
export const AUTH_SIGNIN = "signin";
export const AUTH_SIGNUP = "signup";
export const OAUTH2_REDIRECT_URI = `${BASE_URL}/oauth2/redirect`;
export const GOOGLE_AUTH_SIGNUP_URL = `${API_BASE_URL}/oauth2/authorize/google?redirect_uri=${OAUTH2_REDIRECT_URI}&state=${AUTH_SIGNUP}`;
export const FACEBOOK_AUTH_SIGNUP_URL = `${API_BASE_URL}/oauth2/authorize/facebook?redirect_uri=${OAUTH2_REDIRECT_URI}&state=${AUTH_SIGNUP}`;
export const GOOGLE_AUTH_SIGNIN_URL = `${API_BASE_URL}/oauth2/authorize/google?redirect_uri=${OAUTH2_REDIRECT_URI}&state=${AUTH_SIGNIN}`;
export const FACEBOOK_AUTH_SIGNIN_URL = `${API_BASE_URL}/oauth2/authorize/facebook?redirect_uri=${OAUTH2_REDIRECT_URI}&state=${AUTH_SIGNIN}`;
export const CHARACTER_LIMIT = 565;
export const CREATE_FRIEND_PROFILE = "/create-friend-profile";
export const CREATE_PROFILE = "/create-profile";
export const S3_BASE_URL =
  "https://s3.us-west-2.amazonaws.com/static.move2newcity";
export const AVAILABILITY_DAY = "DAY";
export const AVAILABILITY_WEEK = "WEEK";
export const CONVERSATION_ID = "conversationId";
export const HOTJAR_VERSION = "6";
export const USER_TYPES = {
  NEWCOMER: "newcomer",
  FRIEND: "friend",
};
export const ENVIROMENTS = {
  LOCAL: "local",
  DEVELOPMENT: "dev",
  PRODUCTION: "prod",
};

export const SCHEDULER_NAVIGATOR = {
  RESCHEDULE: "reschedule",
  SUGGEST: "suggest",
  CANCEL: "cancel",
  DECLINE: "decline",
  RESOURCES: "resources",
  CONSUMERS: "consumers",
  SCHEDULE: "schedule",
  REMINDER: "reminder",
  ACCEPT:"accept",
  CONFIRM:"confirm"
};

export const SCHEDULER_NAVIGATOR_ACTION = {
  ABORT: "abort",
  RESCHEDULE: "reschedule",
  CONFIRM: "confirmations",
  REMINDER: "reminder",
};

export const ANALYTICS = {
  MIGRATION_PREFERENCE: "MIGRATION_PREFERENCE",
  USER_PREFERENCE: "USER_PREFERENCE",
  UTM_SOURCE: "UTM_SOURCE",
};
export const ErrorMessages = {
  ALREADY_SIGNED_UP_NEWCOMER: "Looks like you're already signed up as newcomer",
  ALREADY_SIGNED_UP_FRIEND: "Looks like you're already signed up as friend",
  SIGNED_UP_WITH_LOCAL_FRIEND:
    "Looks like you're signed up with : local as friend account. Please use your: local account to login.",
  SIGNED_UP_WITH_LOCAL_NEWCOMER:
    "Looks like you're signed up with : local as newcomer account. Please use your: local account to login.",
  SIGNED_UP_WITH_GOOGLE_FRIEND:
    "Looks like you're signed up with : google as friend account. Please use your: google account to login.",
  SIGNED_UP_WITH_GOOGLE_NEWCOMER:
    "Looks like you're signed up with : google as newcomer account. Please use your: google account to login.",
  SIGNED_UP_WITH_FACEBOOK_FRIEND:
    "Looks like you're signed up with : facebook as friend account. Please use your: facebook account to login.",
  SIGNED_UP_WITH_FACEBOOK_NEWCOMER:
    "Looks like you're signed up with : facebook as newcomer account. Please use your: facebook account to login.",
  SIGN_UP_BEFORE_LOGIN: "Unable to redirect user to social signup",
};
export const USER_IDENTIFIER = "user-identifier";
