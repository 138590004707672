import React, { useEffect, useState } from "react";
import { Button, Pagination, Row, Spinner } from "react-bootstrap";
import { ACCESS_TOKEN, API_BASE_URL, CHARACTER_LIMIT } from "../../constants";
import {
  createCommentForParent,
  getCurrentUser,
  getPostById,
  getProfile,
  getQuestionsByCityId,
} from "../../util/APIUtils";
import { notificationManage } from "../../util/NotificationUtils";
import { capitalizeFirstLetter, months, truncateName } from "../../util/Utils";
import { utilSortDate } from "../../util/UtilSort";
import { linkRegEx } from "../../util/Regex";
import { InteractionAccordion } from "../reply-accordion/Reply-Accordion";
import QuestionConfirmationModel from "../../components/question-confirmation-modal/Question-Confirmation-Modal";
import "./Thread-Item.scss";
import { DisplayNoContent } from "../no-content/No-Content";
import { Link } from "react-router-dom";
import PopupLogin from "../popup-login/Popup-Login";
import { BASE_URL } from "../../constants";

export const ThreadItem = (props) => {
  const activeCategories = props.activeCategories;
  const setActiveCategories = props.setActiveCategories;
  const searchQuery = props.searchQuery;
  const cityId = props.cityId;
  const dropdownValue = props.dropdownValue;
  const setDropdownValue = props.setDropdownValue;
  const filterOption = props.filterOption;
  const newQuestionPosted = props.newQuestionPosted;
  const setNewQuestionPosted = props.setNewQuestionPosted;
  const handleSpinnerStatus = props.handleSpinnerStatus;
  const setGoToTopVisible = props.setGoToTopVisible;
  const currentProfile = props.currentProfile;
  const loggedUser = props.loggedUser;
  const limitComments = 3;
  const limitThread = window.matchMedia("(max-width: 688px)").matches ? 3 : 10;
  const paginationLimit = window.matchMedia("(max-width: 688px)").matches
    ? 20
    : 50;
  const [notificationPostId, setNotificationPostId] = useState(
    props.notificationPostId
  );
  const [notificationPostType, setNotificationPostType] = useState(
    props.notificationPostType
  );
  const [emailLinkPostId, setEmailLinkPostId] = useState(
    props.emailLinkPostId.split("=", 13)[1]
  );
  const [newEmailLinkPostId, setNewEmailLinkPostId] = useState(
    props.emailLinkPostId.split("=", 13)[0]
  );
  const newNotificationSelected = props.newNotificationSelected;
  const [threadList, setThreadList] = useState([]);
  const [commentString, setCommentString] = useState(null);
  const [currentUserId, setCurrentUserId] = useState("");
  const [filterData, setFilterData] = useState([]);
  const [active, setActive] = useState(1);
  const [showIndex, setShowIndex] = useState(false);
  const [truncated, setTruncated] = useState({});
  const [totalThreads, setTotalThreads] = useState(0);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [createdCommentId, setCreatedCommentId] = useState(null);
  const [currentUser, setCurrentUser] = useState(null);
  const [isSearchTrue, setIsSearchTrue] = useState(true);
  const [newNotification, setNewNotification] = useState("");
  const [isDataLoad, setIsDataLoad] = useState(true);
  const [requireLogin, setRequireLogin] = useState(false);
  const [emailLinkReceived, setEmailLinkReceived] = useState("");
  const [replyStatus, setReplyStatus] = useState(false);

  const dataReady = (data) => {
    data.forEach((value, index) => {
      let sortedComments = value.comments?.sort((a, b) => {
        return (
          b.interactions.filter((value) => value.type === "up_vote").length -
          a.interactions.filter((value) => value.type === "up_vote").length
        );
      });
      data[index].comments = sortedComments;
    });
    data.length === 0 && handleSpinnerStatus();
    data.length !== 0 && getCurrentUser()
      .then((user) => {
        setThreadList(filterUnapprovedPosts(data, user?.id));
      })
      .catch(() => {
        setThreadList(filterUnapprovedPosts(data, null));
      });
    setNewQuestionPosted(false);
  };

  useEffect(() => {
    const tempNewNotificationSelected = newNotificationSelected
      .substring(4, newNotificationSelected.length)
      .toString();
    setNewNotification(
      threadList.some((value) => value.id === tempNewNotificationSelected)
    );
  }, [newNotificationSelected, threadList]);

  useEffect(() => {
    localStorage.getItem(ACCESS_TOKEN) &&
      getCurrentUser().then((user) => {
        getProfile(user.id).then((currentUserProfile) => {
          setCurrentUser(currentUserProfile);
        });
      }).catch(console.warn);
  }, []);

  const urlLinkValidation = (word) => {
    const regex = new RegExp(/(https?:\/\/[^ ]*)/);
    return regex.test(word);
  };

  useEffect(() => {
    getQuestionsByCityId(cityId, activeCategories, searchQuery).then((data) => {
      if (notificationPostId || newNotificationSelected) {
        if (notificationPostType === "question") {
          data = data.filter((value) => value.id === notificationPostId);
          dataReady(data);
        } else if (
          notificationPostType === "interaction" ||
          notificationPostType === "comment"
        ) {
          getPostById(notificationPostId).then((response) => {
            let parentId = response.parentId;
            if (!parentId) {
              parentId = response.id;
            }
            data = data.filter((value) => value.id === parentId);
            dataReady(data);
          });
        }
      } else {
        dataReady(
          loggedUser?.id
            ? data?.filter(
                (item) =>
                  item?.createdBy?.id === loggedUser?.id ||
                  item?.status === "approved"
              )
            : data.filter((item) => item.status === "approved")
        );
        setIsDataLoad(false);
      }
    });
  }, [
    cityId,
    notificationPostId,
    newQuestionPosted,
    newNotificationSelected,
    activeCategories,
    setActiveCategories,
    loggedUser?.id,
  ]);

  useEffect(() => {
    getQuestionsByCityId(cityId, activeCategories, searchQuery).then((data) => {
      if (emailLinkPostId) {
        data = data.filter((value) => value.id === emailLinkPostId);
        dataReady(data);

        getPostById(emailLinkPostId).then((response) => {
          let parentId = response?.parentId;
          if (!parentId) {
            parentId = response?.id;
          }
          data = data.filter((value) => value.id === parentId);
          dataReady(data);
        });
      } else {
        dataReady(
          loggedUser?.id
            ? data.filter(
                (item) =>
                  item.createdBy?.id === loggedUser?.id ||
                  item.status === "approved"
              )
            : data.filter((item) => item.status === "approved")
        );
        setIsDataLoad(false);
      }
    });
  }, [emailLinkReceived]);

  useEffect(() => {
    setNotificationPostId(props.notificationPostId);
    setNotificationPostType(props.notificationPostType);
    setThreadList([]);
  }, [newNotificationSelected]);

  useEffect(() => {
    setEmailLinkReceived(true);
  }, [newEmailLinkPostId]);

  useEffect(() => {
    if (threadList.length) {
      handleSpinnerStatus();
      setFilterData(utilSortDate(threadList, "createdAt", -1));
    }
  }, [threadList]);

  useEffect(() => {
    if (notificationPostId) {
      if (threadList.length !== 0 && newNotification === true) {
        setIsDataLoad(false);
      }
    } else {
      if (threadList.length) {
        setIsDataLoad(false);
      }
    }
  }, [filterData, newNotificationSelected, threadList]);

  useEffect(() => {
    if (newNotificationSelected) {
      setIsDataLoad(true);
    }
  }, [newNotificationSelected]);

  useEffect(() => {
    if (threadList) {
      let threadListCopy = [...threadList];
      switch (dropdownValue) {
        case filterOption.LATEST_THREADS:
          setFilterData(
            threadListCopy?.slice(
              (active - 1) * limitThread,
              active * limitThread
            )
          );
          setTotalThreads(threadList.length);
          break;
        case filterOption.OLDEST_THREADS:
          setFilterData(
            utilSortDate(threadListCopy, "createdAt", 1)?.slice(
              (active - 1) * limitThread,
              active * limitThread
            )
          );
          setTotalThreads(threadList.length);
          break;
        case filterOption.TOP_RATED_THREADS:
          threadListCopy.sort((a, b) => {
            return (
              b.interactions.filter((value) => {
                return value.type === "up_vote";
              }).length -
              a.interactions.filter((value) => {
                return value.type === "up_vote";
              }).length
            );
          });
          setFilterData(
            threadListCopy?.slice(
              (active - 1) * limitThread,
              active * limitThread
            )
          );
          setTotalThreads(threadList.length);
          break;
        case filterOption.MY_QUESTIONS:
          threadListCopy = threadListCopy?.filter((value) => {
            return value?.createdBy?.id === currentUserId;
          });
          setFilterData(
            threadListCopy?.slice(
              (active - 1) * limitThread,
              active * limitThread
            )
          );
          setTotalThreads(threadListCopy.length);
          break;
        default:
          break;
      }
    }
  }, [active, dropdownValue, threadList, filterOption, loggedUser?.id]);

  useEffect(() => {
    localStorage.getItem(ACCESS_TOKEN) &&
      getCurrentUser().then((data) => {
        setCurrentUserId(data.id);
      }).catch(console.warn);
  }, []);

  useEffect(() => {
    filterData.map((data) => {
      if (searchQuery === null) {
        setIsSearchTrue(true);
      } else if (!data.title.includes(searchQuery)) {
        setIsSearchTrue(false);
      } else {
        setIsSearchTrue(true);
      }
    });
  }, [searchQuery, filterData]);

  useEffect(() => {
    setActive(1);
    scrollTop();
  }, [dropdownValue]);

  const DateDisplay = (props) => {
    let dateCreated = new Date(props.createdAt);
    dateCreated =
      dateCreated.getDate() + " " + months[dateCreated.getMonth()].slice(0, 3);

    return dateCreated;
  };

  const handleCommentSend = (id) => {
    if (commentString) {
      setCreatedCommentId(id);
      setShowConfirmationModal(true);
    } else {
      notificationManage("warning", "Please fill in all the fields");
    }
  };

  const handleCommentSubmit = () => {
    if (commentString) {
      const formData = new FormData();
      formData.append("parentId", createdCommentId);
      formData.append("title", commentString);
      setReplyStatus(true);
      createCommentForParent(formData)
        .then(() => {
          notificationManage("success", "Your comment was posted successfully");
          getQuestionsByCityId(cityId, activeCategories, searchQuery).then(
            (data) => {
              data.forEach((value, index) => {
                let sortedComments = value.comments?.sort(
                  (prevComment, nextComment) => {
                    return (
                      nextComment.interactions.filter(
                        (value) => value.type === "up_vote"
                      ).length -
                      prevComment.interactions.filter(
                        (value) => value.type === "up_vote"
                      ).length
                    );
                  }
                );
                data[index].comments = sortedComments;
              });
              setThreadList(filterUnapprovedPosts(data, currentProfile?.id));
            }
          );
          setCommentString("");
          Array.from(document.querySelectorAll("textarea")).forEach(
            (textarea) => (textarea.value = "")
          );
        })
        .catch(() => {
          notificationManage("danger", "Something went wrong");
        });
    } else {
      notificationManage("warning", "Please fill in all the fields");
    }
  };

  const handleCommentChange = (e) => {
    e.preventDefault();
    setCommentString(e.target.value.trim());
  };

  const handleSeeMoreClick = (id) => {
    setTruncated((prev) => ({
      ...prev,
      [id]: true,
    }));
  };

  const scrollTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  // Apparently update FE filterData values instantly before API call returns; for UX.
  const updateInteraction = (threadId, voteType, interactionType) => {
    let interactionList = [];
    let createdByList = {
      id: currentUserId,
    };

    let duplicateIndex = -1;
    let duplicateType = "";

    let updatedData = [];

    switch (interactionType) {
      case "question":
        let threadIndex = -1;
        filterData.forEach((value, index) => {
          if (value.id === threadId) {
            threadIndex = index;
          }
        });
        interactionList = filterData[threadIndex].interactions;

        interactionList.forEach((interaction, index) => {
          if (interaction.createdBy.id === currentUserId) {
            duplicateIndex = index;
            duplicateType = interaction.type;
          }
        });
        if (duplicateIndex !== -1) {
          // Remove interaction if already present
          interactionList.splice(duplicateIndex, 1);
        }
        if (duplicateType !== voteType) {
          // Add only if voting for different type of interaction
          interactionList.push({
            type: voteType,
            createdBy: createdByList,
          });
        }

        updatedData = [...filterData];
        updatedData[threadIndex].interactions = interactionList;
        setFilterData(updatedData);
        break;

      case "comment":
        let parentIndex = -1;
        let commentIndex = -1;
        filterData.forEach((parentValue, parentIdx) => {
          parentValue?.comments?.forEach((commentValue, commentIdx) => {
            if (commentValue.id === threadId) {
              parentIndex = parentIdx;
              commentIndex = commentIdx;
            }
          });
        });
        interactionList =
          filterData[parentIndex].comments[commentIndex].interactions;

        interactionList.forEach((interaction, index) => {
          if (interaction.createdBy.id === currentUserId) {
            duplicateIndex = index;
            duplicateType = interaction.type;
          }
        });
        if (duplicateIndex !== -1) {
          // Remove interaction if already present
          interactionList.splice(duplicateIndex, 1);
        }
        if (duplicateType !== voteType) {
          // Add only if voting for different type of interaction
          interactionList.push({
            type: voteType,
            createdBy: createdByList,
          });
        }

        updatedData = [...filterData];
        updatedData[parentIndex].comments[commentIndex].interactions =
          interactionList;
        setFilterData(updatedData);
        !localStorage.getItem(ACCESS_TOKEN) && setRequireLogin(true);

        break;

      default:
        break;
    }
  };

  const handleHidePopupLogin = () => {
    setRequireLogin(false);
  };

  setGoToTopVisible(filterData.length !== 0);

  const threadsCount = totalThreads;
  let paginationItems = [];

  const handlePaginationClick = (setActiveTo) => {
    setActive(setActiveTo);
    setTruncated({});
    scrollTop();
  };

  if (threadsCount > paginationLimit) {
    if (active - 4 > 0) {
      paginationItems.push(
        <Pagination.Item
          className="mr-2"
          onClick={() => handlePaginationClick(1)}
        >
          1
        </Pagination.Item>
      );
      paginationItems.push(
        <Pagination.Ellipsis className="mr-2"></Pagination.Ellipsis>
      );
      if (Math.ceil(threadsCount / limitThread) - active < 4) {
        for (
          let number =
            active - (4 - (Math.ceil(threadsCount / limitThread) - active));
          number < Math.ceil(threadsCount / limitThread);
          number++
        ) {
          paginationItems.push(
            <Pagination.Item
              className="mr-2"
              key={number}
              active={number === active}
              onClick={() => handlePaginationClick(number)}
            >
              {number}
            </Pagination.Item>
          );
        }
      } else {
        for (let number = active - 2; number < active + 3; number++) {
          paginationItems.push(
            <Pagination.Item
              className="mr-2"
              key={number}
              active={number === active}
              onClick={() => handlePaginationClick(number)}
            >
              {number}
            </Pagination.Item>
          );
        }
        paginationItems.push(
          <Pagination.Ellipsis className="mr-2"></Pagination.Ellipsis>
        );
      }
    } else {
      for (let number = 1; number <= 5; number++) {
        paginationItems.push(
          <Pagination.Item
            className="mr-2"
            key={number}
            active={number === active}
            onClick={() => handlePaginationClick(number)}
          >
            {number}
          </Pagination.Item>
        );
      }
      paginationItems.push(
        <Pagination.Ellipsis className="mr-2"></Pagination.Ellipsis>
      );
    }
    paginationItems.push(
      <Pagination.Item
        className="mr-2"
        active={Math.ceil(threadsCount / limitThread) === active}
        onClick={() =>
          handlePaginationClick(Math.ceil(threadsCount / limitThread))
        }
      >
        {Math.ceil(threadsCount / limitThread)}
      </Pagination.Item>
    );
  } else {
    for (
      let number = 1;
      number <= Math.ceil(threadsCount / limitThread);
      number++
    ) {
      paginationItems.push(
        <Pagination.Item
          className="mr-2"
          key={number}
          active={number === active}
          onClick={() => handlePaginationClick(number)}
        >
          {number}
        </Pagination.Item>
      );
    }
  }

  paginationItems.unshift(
    <Pagination.Prev
      className="mr-2"
      onClick={() => handlePaginationClick(active - 1)}
      disabled={active === 1}
    ></Pagination.Prev>
  );
  paginationItems.push(
    <Pagination.Next
      className="mr-2"
      onClick={() => handlePaginationClick(active + 1)}
      disabled={active === Math.ceil(threadsCount / limitThread)}
    ></Pagination.Next>
  );

  const filterUnapprovedPosts = (posts, id) =>
    posts
      ? posts?.filter((item) =>
          id
            ? item?.createdBy?.id === id || item?.status === "approved"
            : item?.status === "approved"
        )
      : 0;

  const getCommenterName = (ctx) => {
    return ctx ? (ctx?.userName ? ctx?.userName : ctx?.name && ctx?.name) : "NotFound";
  };

  return (
    <div className="thread">
      <QuestionConfirmationModel
        showConfirmationModal={showConfirmationModal}
        setShowConfirmationModal={setShowConfirmationModal}
        questionTitle={commentString}
        handleConfirmationSubmit={handleCommentSubmit}
      />
      <PopupLogin
        show={requireLogin}
        onHide={() => {
          handleHidePopupLogin();
        }}
        name="Sign In"
        onSuccessfulSignIn={props.reloadCurrentUser}
        reloadCurrentUser={props.reloadCurrentUser}
      />
      {filterData.length !== 0 && isDataLoad === false ? (
        <div className="thread-count mt-4 mb-3">
          Showing {(active - 1) * limitThread + 1} -{" "}
          {Math.floor(threadsCount / limitThread) < active
            ? threadsCount
            : active * limitThread}{" "}
          of {threadsCount} threads
        </div>
      ) : isDataLoad ? (
        <div className="thread-spinner">
          <Spinner animation="border" variant="primary" />
        </div>
      ) : (
        <div className="thread-count mt-4 mb-3">Showing 0 threads</div>
      )}

      {filterData.length === 0 && isDataLoad === false && (
        <DisplayNoContent
          text={
            isSearchTrue === false
              ? "Sorry we couldn’t find anything for this search."
              : dropdownValue === filterOption.MY_QUESTIONS
              ? "You have not posted any questions yet."
              : currentProfile?.isFriend
              ? "There are no threads for this city yet"
              : "There are no threads for this city yet. Ask a question to start a thread."
          }
          type="thread"
        />
      )}
      {filterData.map((value, index) => {
        const noOfUpVotes = value.interactions.filter((value) => {
          return value.type === "up_vote";
        }).length;
        const noOfDownVotes = value.interactions.filter((value) => {
          return value.type === "down_vote";
        }).length;
        const noOfComments = value.comments
          ? filterUnapprovedPosts(value.comments, currentProfile?.id).length
          : 0;
        const hasComments = value.comments?.some((value) => {
          return value?.comments;
        });

        const RenderReplies = ({ comment }) => {
          const [activeKey, setActiveKey] = useState("");
          const [commentRenderReply, setCommentRenderReply] = useState("");
          const commentWords = comment.title.split(/(\s+)/);

          const handleClickToggle = (eventKey) => {
            if (eventKey === activeKey) {
              setActiveKey("");
            } else {
              setActiveKey(eventKey);
            }
          };

          const nestedComments = (comment.comments || []).map((comment) => {
            return (
              <RenderReplies key={comment.id} comment={comment} type="child" />
            );
          });

          const noOfUpVotes = comment.interactions.filter((value) => {
            return value.type === "up_vote";
          }).length;
          const noOfDownVotes = comment.interactions.filter((value) => {
            return value.type === "down_vote";
          }).length;
          const noOfComments = comment.comments ? comment.comments.length : 0;

          const handleCommentRenderReplyChange = (e) => {
            e.preventDefault();
            setCommentRenderReply(e.target.value.trim());
          };

          const handleCommentRenderReplySubmit = (e, id) => {
            e.preventDefault();
            if (commentRenderReply) {
              handleClickToggle("");

              const formData = new FormData();
              formData.append("parentId", id);
              formData.append("title", commentRenderReply);

              createCommentForParent(formData)
                .then(() => {
                  notificationManage(
                    "success",
                    "Your Comment was posted successfully"
                  );
                  getQuestionsByCityId(
                    cityId,
                    activeCategories,
                    searchQuery
                  ).then((data) => {
                    data.forEach((value, index) => {
                      let sortedComments = value.comments?.sort((a, b) => {
                        return (
                          b.interactions.filter((value) => {
                            return value.type === "up_vote";
                          }).length -
                          a.interactions.filter((value) => {
                            return value.type === "up_vote";
                          }).length
                        );
                      });
                      data[index].comments = sortedComments;
                    });
                    setThreadList(
                      filterUnapprovedPosts(data, currentProfile?.id)
                    );
                  });
                  setCommentRenderReply("");
                  Array.from(document.querySelectorAll("textarea")).forEach(
                    (textarea) => (textarea.value = "")
                  );
                })
                .catch(() => {
                  notificationManage("danger", "Something went wrong");
                });
            } else {
              notificationManage("warning", "Please fill in all the fields");
            }
          };

          const noOfInteractions = {
            noOfUpVotes: noOfUpVotes,
            noOfDownVotes: noOfDownVotes,
            noOfComments: noOfComments,
          };
          const replyEventHandlers = {
            replyChange: handleCommentRenderReplyChange,
            replySubmit: handleCommentRenderReplySubmit,
          };
          const idValues = {
            cityId: cityId,
            currentUserId: currentUserId,
          };

          return (
            <div key={value.id} className="col-12 render-reply ">
              <div className="row ml-1 mb-0 pb-0 render-reply-item">
                <div className="col-1 mr-0 ml-5 render-reply-image">
                  <Link to={`/friends/${comment?.createdBy?.id}`}>
                    {comment?.createdBy?.image ||
                    comment?.createdBy?.imageUrl ? (
                      <img
                        className="profile-picture thread-profile-picture"
                        src={
                          comment?.createdBy?.image
                            ? `${API_BASE_URL}/v1/users/${comment?.createdBy?.id}/image`
                            : comment?.createdBy?.imageUrl
                        }
                        alt="profile pic"
                      />
                    ) : (
                      <div className="text-avatar">
                        <span>
                          {comment?.createdBy?.userName
                            ? comment?.createdBy?.userName[0]
                            : comment?.createdBy?.name &&
                              comment?.createdBy?.name[0]}
                        </span>
                      </div>
                    )}
                  </Link>
                </div>
                <div className="col-10 render-reply-background-color">
                  <div className="col-11 render-reply-background-color-bottom">
                    <div className="row render-reply-head">
                      <div
                        className={`render-reply-name-and-rating ${
                          comment?.status === "approved" && "w-100"
                        }`}
                      >
                        <Link
                          to={`/friends/${comment?.createdBy?.id}`}
                          className="reply-name"
                        >
                          <p className="font-weight-bold ml-4 mb-0 render-reply-comment-name">
                            {window.innerWidth < 688
                              ? truncateName(getCommenterName(comment?.createdBy), 8)
                              : getCommenterName(comment?.createdBy)}
                            {window.innerWidth < 688 &&
                              comment?.status !== "approved" && (
                                <p className="font-weight-light thread-date ml-auto">
                                  <DateDisplay createdAt={comment?.createdAt} />
                                </p>
                              )}
                          </p>
                        </Link>
                        {(window.innerWidth > 688 ||
                          (window.innerWidth < 688 &&
                            comment?.status === "approved")) && (
                          <p className="font-weight-light thread-date ml-1 mb-0">
                            <DateDisplay createdAt={comment?.createdAt} />
                          </p>
                        )}
                      </div>
                      {comment?.status !== "approved" && (
                        <div
                          className={`${
                            comment?.status !== "approved"
                              ? "thread-status-answer"
                              : "thread-status-hide"
                          } `}
                        >
                          <span
                            className={`${
                              comment?.status === "decline"
                                ? "thread-status-answer-decline"
                                : comment?.status === "pending" &&
                                  "thread-status-answer-pending"
                            } `}
                          >
                            {comment?.status === "decline"
                              ? "Rejected"
                              : comment?.status === "pending" && "Pending"}
                          </span>
                        </div>
                      )}
                    </div>
                    <div className="row pl-2 pb-1 render-reply-background-color-bottom-answer">
                      <div className="col-12 question-reply ml-3 px-0">
                        <p className="question-reply-text mb-0 p-2">
                          {commentWords.map((word) => {
                            return urlLinkValidation(word) ? (
                              <a
                                target="_blank"
                                className="question-reply-text-link"
                                href={
                                  word.includes("http") ? word : "//" + word
                                }
                              >
                                {word}
                              </a>
                            ) : (
                              <>{word} </>
                            );
                          })}
                        </p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12 render-reply-interaction-buttons">
                        <InteractionAccordion
                          key={comment.id.toString()}
                          value={comment}
                          noOfInteractions={noOfInteractions}
                          replyEventHandlers={replyEventHandlers}
                          idValues={idValues}
                          activeCategory={activeCategories}
                          searchQuery={searchQuery}
                          updateInteraction={updateInteraction}
                          setThreadList={setThreadList}
                          filterUnapprovedPosts={filterUnapprovedPosts}
                          currentProfile={currentUser}
                          reply={true}
                          reloadCurrentUser={props.reloadCurrentUser}
                          replyStatus={replyStatus}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <hr className="mx-0 px-0 pb-0 mb-0 mt-1" />
              </div>
              {showIndex === index && nestedComments}
            </div>
          );
        };

        const noOfInteractions = {
          noOfUpVotes: noOfUpVotes,
          noOfDownVotes: noOfDownVotes,
          noOfComments: noOfComments,
        };
        const replyEventHandlers = {
          replyChange: handleCommentChange,
          replySubmit: handleCommentSend,
        };
        const idValues = {
          cityId: cityId,
          currentUserId: currentUserId,
        };

        return (
          <div className="bg-white mb-4 thread-card">
            <Row className="thread-card-row">
              <div
                className={`px-4 pt-4 d-flex flex-column ${
                  value?.status !== "approved" ? "col-10" : "col-11"
                } thread-card-body`}
              >
                <div className="ml-3 post-detail">
                  <div className="thread-owner-and-date">
                    <span className="thread-owner">
                      {value?.createdBy?.userName
                        ? value?.createdBy?.userName
                        : value?.createdBy?.name && value?.createdBy?.name}
                    </span>
                    <span className="thread-date">
                      <DateDisplay createdAt={value?.createdAt} />
                    </span>
                  </div>
                  <div
                    className={`${
                      value?.status !== "approved"
                        ? "thread-status-question"
                        : "thread-status-hide"
                    } `}
                  >
                    <span
                      className={`${
                        value?.status === "decline"
                          ? "thread-status-question-decline"
                          : value.status === "pending" &&
                            "thread-status-question-pending"
                      } `}
                    >
                      {value?.status === "decline"
                        ? "Rejected"
                        : value?.status === "pending" && "Pending"}
                    </span>
                  </div>
                </div>
                <div className="mt-3 thread-questions ml-3">
                  <p className="thread-heading">{value?.title}</p>

                  {value?.text && (
                    <p className="thread-paragraph mb-3">
                      {value?.text
                        .substring(
                          0,
                          truncated[value?.id]
                            ? value?.text?.length
                            : CHARACTER_LIMIT
                        )
                        .trim()
                        .concat(
                          !truncated[value?.id] &&
                            value.text.length > CHARACTER_LIMIT
                            ? "..."
                            : ""
                        )}
                      {!truncated[value?.id] &&
                        value?.text.length > CHARACTER_LIMIT && (
                          <button
                            className="thread-see-more"
                            onClick={() => handleSeeMoreClick(value.id)}
                          >
                            See More
                          </button>
                        )}
                    </p>
                  )}
                  <div className="thread-tags">
                    {value.tags.map((tag) => (
                      <div className="thread-tags-label">
                        {capitalizeFirstLetter(
                          tag === "general" ? "Other" : tag
                        )}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div className="thread-card-content col-12">
                <hr className="mt-0 pt-0" />
                <div className="justify-content-start align-items-start ml-4 pl-1 mb-2">
                  <InteractionAccordion
                    key={value.id.toString()}
                    value={value}
                    noOfInteractions={noOfInteractions}
                    replyEventHandlers={replyEventHandlers}
                    idValues={idValues}
                    setThreadList={setThreadList}
                    filterUnapprovedPosts={filterUnapprovedPosts}
                    updateInteraction={updateInteraction}
                    currentProfile={currentUser}
                    reply={false}
                    reloadCurrentUser={props.reloadCurrentUser}
                    replyStatus={replyStatus}
                  />
                </div>
                <hr className="mt-0 mb-0 pt-0" />
              </div>
              <div className="thread-card-content col-12">
                {filterUnapprovedPosts(value.comments, currentProfile?.id)
                  .length > 0 && (
                  <div className="row thread-card-content-top">
                    <div className="col-2 thread-card-content-top-space"></div>
                    <div className="col-9 thread-card-content-top-space-area">
                      <p className="top-answer mb-0 ml-3">Top Answer</p>
                    </div>
                    {showIndex === index
                      ? filterUnapprovedPosts(
                          value.comments,
                          currentProfile?.id
                        ).map((comment) => {
                          return (
                            <RenderReplies key={comment.id} comment={comment} />
                          );
                        })
                      : filterUnapprovedPosts(
                          value.comments.slice(0, limitComments),
                          currentProfile?.id
                        ).map((comment) => {
                          return (
                            <RenderReplies key={comment.id} comment={comment} />
                          );
                        })}
                  </div>
                )}
                {(noOfComments > 3 || hasComments) && (
                  <div>
                    <p className="d-flex flex-column align-items-center my-2">
                      <Button
                        className="goto-top-button"
                        variant="link"
                        onClick={() => {
                          setShowIndex((showIndex) =>
                            showIndex === index ? null : index
                          );
                        }}
                      >
                        {showIndex === index ? (
                          <span className="see-all-answers">
                            Show Less
                            <i
                              className="fa fa-angle-double-up fa-lg ml-1"
                              aria-hidden="true"
                            ></i>
                          </span>
                        ) : (
                          <span className="see-all-answers">
                            See All Answers
                            <i className="fas fa-angle-double-down fa-lg ml-1"></i>
                          </span>
                        )}
                      </Button>
                    </p>
                  </div>
                )}
              </div>
            </Row>
          </div>
        );
      })}
      {dropdownValue === filterOption.MY_QUESTIONS && (
        <div className="d-flex justify-content-center mt-4">
          <button
            className="thread-view-all"
            onClick={() => {
              setDropdownValue(filterOption.LATEST_THREADS);
            }}
          >
            Go to all threads
          </button>
        </div>
      )}
      {notificationPostId || emailLinkPostId ? (
        <div className="d-flex justify-content-center">
          <button
            className="thread-view-all"
            onClick={() => {
              setNotificationPostId(null);
              setEmailLinkPostId(null);
              window.location.href = `${BASE_URL}/city/${cityId}`;
            }}
          >
            Go to all threads
          </button>
        </div>
      ) : (
        filterData.length !== 0 && (
          <div className="thread-pagination">
            <Pagination>{paginationItems}</Pagination>
          </div>
        )
      )}
    </div>
  );
};
