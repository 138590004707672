import React, { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { ChevronLeft, ChevronRight } from "react-bootstrap-icons";
import { useHistory } from "react-router-dom";
import Slider from "react-slick";
import { API_BASE_URL } from "../../constants";
import { getFriendsByCityId } from "../../util/APIUtils";
import { notificationManage } from "../../util/NotificationUtils";
import "./Friends-Carousel.scss";

const FriendsCarousel = ({ cityId, friendId, currentUserDetails }) => {
  const [friendInCity, setFriendInCity] = useState([]);
  const [isDataLoad, setIsDataLoad] = useState(true);
  const history = useHistory();

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 9,
    slidesToScroll: 1,
    variableWidth: true,
    arrows: true,
    prevArrow: <ChevronLeft />,
    nextArrow: <ChevronRight />,
    responsive: [
      {
        breakpoint: 1090,
        settings: {
          slidesToShow: 7,
          slidesToScroll: 1,
          infinite: false,
          dots: false,
          arrows: true,
          nextArrow: <ChevronRight />,
          prevArrow: <ChevronLeft />,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 1,
          infinite: false,
          dots: false,
          arrows: true,
          nextArrow: <ChevronRight />,
          prevArrow: <ChevronLeft />,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: false,
          dots: false,
          arrows: true,
          nextArrow: <ChevronRight />,
          prevArrow: <ChevronLeft />,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          dots: false,
          infinite: false,
          arrows: true,
          nextArrow: <ChevronRight />,
          prevArrow: <ChevronLeft />,
        },
      },
    ],
  };

  const truncateName = (name, limit) => {
    if (name.length <= limit) {
      return name;
    }
    return name?.slice(0, limit) + "...";
  };

  useEffect(() => {
    cityId &&
      getFriendsByCityId(cityId)
        .then((res) => {
          const data = res?.sort(function (x, y) {
            return x.id === friendId ? -1 : y.id === friendId ? 1 : 0;
          });

          setFriendInCity(data.filter((value) => value.role === "ROLE_USER"));
        })
        .catch((err) => {
          err?.then((resp) => {
            notificationManage("danger", "City friends data retreival failed");
          });
        });
  }, [cityId, friendId, currentUserDetails]);

  const navigateToFriendProfile = (id) => {
    history.push(`/friends/${id}`);
  };

  useEffect(() => {
    setIsDataLoad(true);
    setTimeout(() => {
      setIsDataLoad(false);
    }, 4000);
  }, [cityId, setFriendInCity]);

  return (
    <section>
      {isDataLoad ? (
        <div className="loading-spinner">
          <Spinner animation="border" variant="primary" />
        </div>
      ) : (
        <div>
          <Slider {...settings}>
            {!isDataLoad &&
              friendInCity?.map((friend) => {
                const names = friend.userName
                  ? friend.userName.split(" ")
                  : friend.name.split(" ");
                let firstName = names[0];
                return (
                  <div
                    onClick={(e) => {
                      navigateToFriendProfile(friend.id);
                    }}
                    key={friend.id}
                    className="friends-carousel"
                  >
                    <div className="friends-carousel-item">
                      <div
                        className={
                          friendId === friend.id
                            ? `friends-carousel-item-photo-active`
                            : `friends-carousel-item-photo`
                        }
                      >
                        {friend.imageUrl || friend.image ? (
                          <img
                            className={
                              friendId === friend.id
                                ? `friends-carousel-item-photo-avatar-active`
                                : `friends-carousel-item-photo-avatar`
                            }
                            alt={`${friend.name} img`}
                            src={
                              friend.image !== null
                                ? `${API_BASE_URL}/v1/users/${friend.id}/image`
                                : friend.imageUrl
                            }
                          />
                        ) : (
                          <div
                            className={
                              friendId === friend.id
                                ? "text-avatar text-avatar-active"
                                : "text-avatar"
                            }
                          >
                            <span>
                              {friend.userName
                                ? friend.userName[0].toUpperCase()
                                : friend.name && friend.name[0].toUpperCase()}
                            </span>
                          </div>
                        )}
                      </div>
                      <div
                        className={
                          friendId === friend.id
                            ? "friends-carousel-item-name-active"
                            : "friends-carousel-item-name"
                        }
                      >
                        <p>{truncateName(firstName, 10)}</p>
                      </div>
                    </div>
                  </div>
                );
              })}
          </Slider>
        </div>
      )}
    </section>
  );
};

export default FriendsCarousel;
