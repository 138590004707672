import { useState, useEffect } from "react";
import AlreadySignedUp from "../../components/already-signed-up/Already-Signed-Up-Modal";
import PopupLogin from "../../components/popup-login/Popup-Login";
import { ACCESS_TOKEN, ENV, ENVIROMENTS } from "../../constants";
import { getCurrentUser, getProfile } from "../../util/APIUtils";
import { notificationManage } from "../../util/NotificationUtils";
import { scrollTop } from "../../util/Utils";
import "./Become-Friend.scss";
import { isJWTExpired } from "../../util/JWTUtils";
import { BASE_URL } from "../../constants";
import { MetaTags } from "react-meta-tags";

const headerImg = "/assets/img/become-friend/img1.png";
const primaryImg = "/assets/img/become-friend/img2.png";
const footerImg = "/assets/img/become-friend/img3.png";
const primaryMobileImg = "/assets/img/become-friend/img4.png";
const accessToken = localStorage.getItem(ACCESS_TOKEN);

const BecomeFriend = (props) => {
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [showAlreadySignedUpModal, setShowAlreadySignedUpModal] =
    useState(false);
  const handlePopupLogin = () => {
    setShowLoginModal(!showLoginModal);
  };

  const handleBecomeFriend = () => {
    if (checkIsSessionTimeOut()) {
      window.location.href = `${BASE_URL}/login`;
    } else {
      localStorage.getItem(ACCESS_TOKEN)
        ? handleAccountType()
        : handlePopupLogin();
    }
  };

  const handleAccountType = () => {
    getCurrentUser()
      .then((user) => {
        getProfile(user?.id).then((res) => {
          !res.isFriend
            ? setShowAlreadySignedUpModal(true)
            : notificationManage("success", "You are already a friend", "");
        });
      })
      .catch((err) => {
        notificationManage("danger", "Something went wrong !");
      });
  };

  const checkIsSessionTimeOut = () => {
    if (accessToken && isJWTExpired(accessToken)) {
      localStorage.removeItem(ACCESS_TOKEN);
      return true;
    }
    return false;
  };

  useEffect(() => {
    scrollTop();
  }, []);

  return (
    <>
      <AlreadySignedUp
        show={showAlreadySignedUpModal}
        onHide={() => setShowAlreadySignedUpModal(false)}
        logout={props.logout}
      />
      <div className="become-friend">
        <div className="become-friend-header">
          <div className="become-friend-header-left">
            <p id="text-upper">Become a</p>
            <p id="text-lower">Friend</p>
            <div className="become-friend-navigate-arrow bounce">
              <a href="#friend">
                <i className="fas fa-chevron-down "></i>
              </a>
            </div>
          </div>
          <div className="become-friend-header-right">
            <img src={headerImg} alt="headerImg" className="header-img" />
          </div>
        </div>

        <div id="friend" className="become-friend-primary-content">
          <div className="become-friend-primary-content-left">
            <div className="become-friend-primary-content-left-content">
              <div>
                <p id="left-content-title">Who Should be a Friend?</p>
                <svg className="become-friend-primary-content-left-content-underline">
                  <rect width="59px" height="3px" fill="#EE4D67" />
                </svg>
              </div>
              <p id="left-content-body">
                You can be a FRIEND if you would like to share your lived
                experiences of a city. Through sharing your experience and
                knowledge with others, you are helping newcomers have a seamless
                settlement. One piece of advice or a helping hand can go a long
                way, especially to someone settling into a new city. What better
                way is there to help people than through a platform where you,
                along with other people, can come together and help newcomers?
                Not only that, being a FRIEND through Move2NewCity can be your
                new side hustle.
              </p>
              <img
                src={primaryMobileImg}
                alt="banner-img"
                className="primary-mobile-img"
              />
            </div>
          </div>
          <div className="become-friend-primary-content-right">
            <img src={primaryImg} alt="headerImg" className="primary-img" />
          </div>
        </div>
        <div className="become-friend-secondary-content">
          <div className="become-friend-secondary-content-body">
            <p>Share what you know about your city and start earning money</p>
            <svg className="become-friend-primary-content-left-content-underline">
              <rect width="59px" height="3px" fill="#EE4D67" />
            </svg>
          </div>
        </div>
        <div className="become-friend-footer">
          <div className="become-friend-footer-left">
            <img src={footerImg} alt="headerImg" className="footer-img" />
          </div>
          <div className="become-friend-footer-right">
            <div className="become-friend-footer-right-content">
              <div className="become-friend-footer-right-content-top">
                <div>
                  <p id="right-content-title">How it works?</p>
                  <svg className="become-friend-primary-content-left-content-underline">
                    <rect width="59px" height="3px" fill="#EE4D67" />
                  </svg>
                </div>
                <p id="right-content-body">
                  A person moving to a new city can find lived experiences here.
                  Friends who share their lived experiences will help newcomers
                  to gain valuable insights about the city that they are moving
                  to. Move2NewCity will help the <b>Newcomer</b> connect with
                  Friends through the platform and virtually. Move2NewCity is
                  completely free to register and answer questions. It is just
                  as easy to <b>make a video call</b> and talk face-to-face if
                  you wish, based on rates determined by you. So what are you
                  waiting for? Become a friend by completing this quick process.
                </p>
                <div className="become-friend-footer-right-content-bottom">
                  <div className="footer-friend-button">
                    <button
                      onClick={() => {
                        handleBecomeFriend();
                      }}
                      className="become-friend-action">
                      Become a friend
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="become-friend-button">
        <button
          onClick={() => {
            handleBecomeFriend();
          }}>
          Become a Friend
        </button>
      </div>
      <PopupLogin
        show={showLoginModal}
        onHide={() => {
          handlePopupLogin();
        }}
        isFriendDefaultChecked={true}
        name="Become a Friend"
      />
      {ENV === ENVIROMENTS.PRODUCTION && (
        <MetaTags>
          <title>Friends in Australia | Move2NewCity | Become a Friend</title>
          <meta
            name="description"
            content="Share your own experiences living in a city by joining the largest online community of friends in Australia at Move2NewCity"
          />
          <meta
            name="keywords"
            content="friends in australia, friends in victoria, friends in new south wales, friends in south australia, friends in queensland, make friends online in australia, australia"
          />
        </MetaTags>
      )}
    </>
  );
};

export default BecomeFriend;
