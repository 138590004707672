import React from "react";
import "./City-Event-Card.scss";
import { months } from "../../util/Utils";
import { API_BASE_URL } from "../../constants";

const CityEventCard = (props) => {
  const formatDate = (date) => {
    const d = new Date(date);
    return `${d.getDate()} ${months[d.getMonth()]}`;
  };

  return (
    <div className="mr-5 city-even-single-card">
      <div className="city-event-card">
        <div className="main">
          <img
            className="city-event-card-image"
            src={`${API_BASE_URL}/v1/events/${props.event.id}/image`}
            alt="eventImg"
          />
        </div>
        <div className="city-event-card-title">{props.event.name}</div>
        <div className="city-event-card-date">
          {formatDate(props.event.startDate)}
        </div>
      </div>
    </div>
  );
};

export default CityEventCard;
