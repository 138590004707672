import Cookies from "js-cookie";

export const getAvailableTimeList = () => {
  return [
    { time: "12:00 AM", value: "00:00" },
    { time: "12:15 AM", value: "00:15" },
    { time: "12:30 AM", value: "00:30" },
    { time: "12:45 AM", value: "00:45" },
    { time: "01:00 AM", value: "01:00" },
    { time: "01:15 AM", value: "01:15" },
    { time: "01:30 AM", value: "01:30" },
    { time: "01:45 AM", value: "01:45" },
    { time: "02:00 AM", value: "02:00" },
    { time: "02:15 AM", value: "02:15" },
    { time: "02:30 AM", value: "02:30" },
    { time: "02:45 AM", value: "02:45" },
    { time: "03:00 AM", value: "03:00" },
    { time: "03:15 AM", value: "03:15" },
    { time: "03:30 AM", value: "03:30" },
    { time: "03:45 AM", value: "03:45" },
    { time: "04:00 AM", value: "04:00" },
    { time: "04:15 AM", value: "04:15" },
    { time: "04:30 AM", value: "04:30" },
    { time: "04:45 AM", value: "04:45" },
    { time: "05:00 AM", value: "05:00" },
    { time: "05:15 AM", value: "05:15" },
    { time: "05:30 AM", value: "05:30" },
    { time: "05:45 AM", value: "05:45" },
    { time: "06:00 AM", value: "06:00" },
    { time: "06:15 AM", value: "06:15" },
    { time: "06:30 AM", value: "06:30" },
    { time: "06:45 AM", value: "06:45" },
    { time: "07:00 AM", value: "07:00" },
    { time: "07:15 AM", value: "07:15" },
    { time: "07:30 AM", value: "07:30" },
    { time: "07:45 AM", value: "07:45" },
    { time: "08:00 AM", value: "08:00" },
    { time: "08:15 AM", value: "08:15" },
    { time: "08:30 AM", value: "08:30" },
    { time: "08:45 AM", value: "08:45" },
    { time: "09:00 AM", value: "09:00" },
    { time: "09:15 AM", value: "09:15" },
    { time: "09:30 AM", value: "09:30" },
    { time: "09:45 AM", value: "09:45" },
    { time: "10:00 AM", value: "10:00" },
    { time: "10:15 AM", value: "10:15" },
    { time: "10:30 AM", value: "10:30" },
    { time: "10:45 AM", value: "10:45" },
    { time: "11:00 AM", value: "11:00" },
    { time: "11:15 AM", value: "11:15" },
    { time: "11:30 AM", value: "11:30" },
    { time: "11:45 AM", value: "11:45" },
    { time: "12:00 PM", value: "12:00" },
    { time: "12:15 PM", value: "12:15" },
    { time: "12:30 PM", value: "12:30" },
    { time: "12:45 PM", value: "12:45" },
    { time: "01:00 PM", value: "13:00" },
    { time: "01:15 PM", value: "13:15" },
    { time: "01:45 PM", value: "13:45" },
    { time: "02:00 PM", value: "14:00" },
    { time: "01:30 PM", value: "13:30" },
    { time: "02:15 PM", value: "14:15" },
    { time: "02:30 PM", value: "14:30" },
    { time: "02:45 PM", value: "14:45" },
    { time: "03:00 PM", value: "15:00" },
    { time: "03:15 PM", value: "15:15" },
    { time: "03:30 PM", value: "15:30" },
    { time: "03:45 PM", value: "15:45" },
    { time: "04:00 PM", value: "16:00" },
    { time: "04:15 PM", value: "16:15" },
    { time: "04:30 PM", value: "16:30" },
    { time: "04:45 PM", value: "16:45" },
    { time: "05:00 PM", value: "17:00" },
    { time: "05:15 PM", value: "17:15" },
    { time: "05:30 PM", value: "17:30" },
    { time: "05:45 PM", value: "17:45" },
    { time: "06:00 PM", value: "18:00" },
    { time: "06:15 PM", value: "18:15" },
    { time: "06:30 PM", value: "18:30" },
    { time: "06:45 PM", value: "18:45" },
    { time: "07:00 PM", value: "19:00" },
    { time: "07:15 PM", value: "19:15" },
    { time: "07:30 PM", value: "19:30" },
    { time: "07:45 PM", value: "19:45" },
    { time: "08:00 PM", value: "20:00" },
    { time: "08:15 PM", value: "20:15" },
    { time: "08:30 PM", value: "20:30" },
    { time: "08:45 PM", value: "20:45" },
    { time: "09:00 PM", value: "21:00" },
    { time: "09:15 PM", value: "21:15" },
    { time: "09:30 PM", value: "21:30" },
    { time: "09:45 PM", value: "21:45" },
    { time: "10:00 PM", value: "22:00" },
    { time: "10:15 PM", value: "22:15" },
    { time: "10:30 PM", value: "22:30" },
    { time: "10:45 PM", value: "22:45" },
    { time: "11:00 PM", value: "23:00" },
    { time: "11:15 PM", value: "23:15" },
    { time: "11:30 PM", value: "23:30" },
    { time: "11:45 PM", value: "23:45" },
  ];
};

export const days = [
  "MONDAY",
  "TUESDAY",
  "WEDNESDAY",
  "THURSDAY",
  "FRIDAY",
  "SATURDAY",
  "SUNDAY",
];

export const CommonUtils = {
  getCategoryList() {
    return [
      {
        id: 1,
        title: "Employment",
        tag: "employment",
        description:
          "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry",
        imagePath: "/assets/img/categories/employment-icon.png",
        selected: false,
      },
      {
        id: 2,
        title: "Health Care",
        tag: "healthcare",
        description:
          "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry",
        imagePath: "/assets/img/categories/health-care-icon.png",
        selected: false,
      },
      {
        id: 3,
        title: "Recreational",
        tag: "recreational",
        description:
          "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry",
        imagePath: "/assets/img/categories/recreational-icon.png",
        selected: false,
      },
      {
        id: 4,
        title: "Education",
        tag: "education",
        description:
          "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry",
        imagePath: "/assets/img/categories/education-icon.png",
        selected: false,
      },
      {
        id: 5,
        title: "Accomadation",
        tag: "accomadation",
        description:
          "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry",
        imagePath: "/assets/img/categories/accomodation-icon.png",
        selected: false,
      },
      {
        id: 6,
        title: "Shopping",
        tag: "shopping",
        description:
          "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry",
        imagePath: "/assets/img/categories/shopping-icon.png",
        selected: false,
      },
      {
        id: 7,
        title: "Financial",
        tag: "financial",
        description:
          "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry",
        imagePath: "/assets/img/categories/financial-icon.png",
        selected: false,
      },
      {
        id: 8,
        title: "Administration",
        tag: "administration",
        description:
          "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry",
        imagePath: "/assets/img/categories/administration-icon.png",
        selected: false,
      },
      {
        id: 9,
        title: "Other",
        tag: "other",
        description:
          "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry",
        imagePath: "/assets/img/categories/shopping-icon.png",
        selected: false,
      },
    ];
  },

  getPersonTypes() {
    return [
      {
        id: 1,
        title: "Professional",
        tag: "professional",
        selected: false,
      },
      {
        id: 2,
        title: "Student",
        tag: "student",
        selected: false,
      },
    ];
  },

  getDurationsList() {
    return [
      { id: "cp1", label: "15 minutes", checked: false },
      { id: "cp2", label: "30 minutes", checked: false },
      { id: "cp3", label: "45 minutes", checked: false },
    ];
  },
};

export const DAY_ORDER = {
  MONDAY: 1,
  TUESDAY: 2,
  WEDNESDAY: 3,
  THURSDAY: 4,
  FRIDAY: 5,
  SATURDAY: 6,
  SUNDAY: 7,
};

export const getUserTimezone=()=> {
  return Intl.DateTimeFormat().resolvedOptions().timeZone;
}

export const CURRENT_LOCATION = "currentLocationInfo";

export const LOCATION_PROP = {
  enableHighAccuracy: true,
  maximumAge: 10000,
  timeout: 5000,
};

export const saveLocationInfo = (lat, lng, name) => {
  const encodeInfo = JSON.stringify({
    latitude: lat,
    longitude: lng,
    name: name,
  });
  Cookies.set(CURRENT_LOCATION, encodeInfo);
};