import React, { useEffect, useState } from "react";
import { getAllArticles } from "../../util/APIUtils";
import { notificationManage } from "../../util/NotificationUtils";
import { USER_ROLE } from "../../util/Utils";
import "./Articles.scss";

const Articles = (props) => {
  const { history, currentUser } = props;
  const [articles, setArticles] = useState([]);

  const handleNavigation = () => {
    history.push("/editor");
  };

  const handleEdit = (article) => {
    history.push({ pathname: `/edit-article/${article.id}`, state: article });
  };

  useEffect(() => {
    getAllArticles()
      .then((res) => {
        setArticles(res);
      })
      .catch((err) => {
        err?.then((resp) => {
          notificationManage("danger", "Article list retrieval failed");
        });
      });
  }, []);

  return (
    currentUser.role === USER_ROLE.SUPER_ADMIN && (
      <div className="p-5 articles">
        <div className="text-right">
          <button
            className="btn btn-primary px-4 mb-4"
            type="button"
            onClick={handleNavigation}>
            Create Article
          </button>
        </div>
        {articles.map((article) => (
          <div className="articles-wrapper" key={article.id}>
            <div className="d-flex mb-2 articles-details">
              <div className="py-2 px-3 mr-4 articles-details-pill">
                {article.cityName}
              </div>
              <div className="py-2 px-3 mr-4 articles-details-pill">
                {article.category}
              </div>
              <div className="articles-button">
                <button
                  className="btn btn-outline-primary ml-5"
                  onClick={() => handleEdit(article)}>
                  Edit article
                </button>
              </div>
            </div>

            <iframe
              srcDoc={`${article.html}<style>${article.css}</style>`}
              title="output"
              sandbox="allow-scripts"
              frameBorder="0"
              width="100%"
              height="100%"
            />
          </div>
        ))}
      </div>
    )
  );
};

export default Articles;
