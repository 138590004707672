import React, { useState } from "react";
import { Button } from "react-bootstrap";
import "./Unsubscribe-Email-Option.scss";
import { unSubscribeEmail } from "../../util/APIUtils";
import { notificationManage } from "../../util/NotificationUtils";

const UnsubscribeEmailOption = (props) => {
  const [isClicked, setIsClicked] = useState(false);

  const unsubscribe = () => {
    unSubscribeEmail(props.match.params.id)
      .then(() => {
        setIsClicked(true);
      })
      .catch(() => {
        notificationManage(
          "danger",
          "Something went wrong while UnSubscribing"
        );
      });
  };
  return (
    <div className="unsubscribe-page">
      <div className="unsubscribe-image-holder">
        {!isClicked ? (
          <img
            src={"../assets/unsubscribe-option/unsubscribe.png"}
            alt="unsubscribe"
            className="unsubscribe-image"
          />
        ) : (
          <>
            <img
              src={"../assets/unsubscribe-option/check-mark.png"}
              alt="check"
              className="unsubscribe-image-check"
            />
            <img
              src={"../assets/unsubscribe-option/check-mark-circle.png"}
              alt="check"
              className="unsubscribe-image-check-circle"
            />
          </>
        )}
      </div>
      <div className="unsubscribe-content-main">
        {!isClicked ? "We are sorry to see you go!" : "Success!"}
      </div>
      <div className="unsubscribe-content-secondary">
        {!isClicked
          ? " Please click the button below to confirm"
          : "You have been successfully unsubscribed from our mailing list."}
      </div>
      {!isClicked && (
        <Button className="unsubscribe-button" onClick={() => unsubscribe()}>
          Unsubscribe
        </Button>
      )}
      <div className={`unsubscribe-footer-text${isClicked ? "-extend" : ""}`}>
        M2NC © 2021
      </div>
    </div>
  );
};

export default UnsubscribeEmailOption;
