export const filterOption = {
  ALL: "all",
  ONLINE_FRIENDS: "online",
  TOP_RATED_FRIENDS: "top",
};

export const filterOptionList = [
  { name: "All", property: filterOption.ALL },
  { name: "Online Friends", property: filterOption.ONLINE_FRIENDS },
  { name: "Top Rated Friends", property: filterOption.TOP_RATED_FRIENDS },
];

export const filterByCategories = (arrayToFilter, categories) =>
  arrayToFilter.filter((friend) =>
    categories.some((category) =>
      Object.keys(friend.ratings).includes(category)
    )
  );

export const sortByHighestRating = (arrayToSort) =>
  arrayToSort.sort(
    (friendA, friendB) =>
      Object.values(friendB.ratings).reduce((prev, next) => prev + next, 0) -
      Object.values(friendA.ratings).reduce((prev, next) => prev + next, 0)
  );

export const sortByHighestRatingsForCategories = (arrayToSort, categories) => {
  return arrayToSort.sort((friendA, friendB) => {
    let totalA = 0;
    let totalB = 0;
    for (let i = 0; i < categories.length; i++) {
      totalA += friendA.ratings?.[categories[i]];
      totalB += friendB.ratings?.[categories[i]];
    }
    return totalB - totalA;
  });
};

export const method = (filter, friendsList, categories) => {
  let cityFriendsListCopy = [...friendsList];
  let activeCategoriesCopy = [...categories];

  //filter friends who has posted replies
  cityFriendsListCopy = cityFriendsListCopy.filter(function (friend) {
    return (
      Object.values(friend.commentCounts).reduce((a, b) => a + b, 0) > 0 &&
      friend.role === "ROLE_USER"
    );
  });

  const otherTagIndex = activeCategoriesCopy.indexOf("general");
  if (otherTagIndex !== -1) {
    activeCategoriesCopy.splice(otherTagIndex, 1, "other");
  }

  switch (filter) {
    case filterOption.ALL:
      let onlineFriends = cityFriendsListCopy.filter(
        (friend) => friend.isOnline
      );

      if (activeCategoriesCopy.length) {
        onlineFriends = filterByCategories(onlineFriends, activeCategoriesCopy);
      }
      onlineFriends = onlineFriends.slice(0, 3);

      let topRatedFriends = [];

      if (activeCategoriesCopy.length) {
        // All overview, with categories selected
        topRatedFriends = filterByCategories(
          cityFriendsListCopy,
          activeCategoriesCopy
        );
        topRatedFriends = sortByHighestRatingsForCategories(
          topRatedFriends,
          activeCategoriesCopy
        );
      } else {
        // All overview, no categories selected
        topRatedFriends = sortByHighestRating(cityFriendsListCopy);
      }

      if (onlineFriends.length) {
        topRatedFriends = topRatedFriends.filter(
          (topFriend) =>
            !onlineFriends.some(
              (onlineFriend) => topFriend.id === onlineFriend.id
            )
        );
      }

      if (activeCategoriesCopy.length) {
        onlineFriends = filterByCategories(onlineFriends, activeCategoriesCopy);
      }

      return onlineFriends.concat(topRatedFriends);

    case filterOption.ONLINE_FRIENDS:
      let onlineFriendsList = cityFriendsListCopy.filter(
        (friend) => friend.isOnline
      );
      if (activeCategoriesCopy.length) {
        onlineFriendsList = filterByCategories(
          onlineFriendsList,
          activeCategoriesCopy
        );
      }

      return onlineFriendsList;

    case filterOption.TOP_RATED_FRIENDS:
      let topRatedFriendsList = [];
      if (activeCategoriesCopy.length) {
        // Top rated, and categories selected
        topRatedFriendsList = cityFriendsListCopy.filter((friend) => {
          return activeCategoriesCopy.every((category) =>
            Object.keys(friend.ratings).includes(category)
          );
        });
        topRatedFriendsList = sortByHighestRatingsForCategories(
          topRatedFriendsList,
          activeCategoriesCopy
        );
      } else {
        // Top rated, no categories selected
        topRatedFriendsList = sortByHighestRating(cityFriendsListCopy);
      }

      return topRatedFriendsList;

    default:
      throw "Invalid filter";
  }
};
