import "./Admin-Inquiry.scss";
import { Dropdown, Container } from "react-bootstrap";
import React, { useState, useEffect } from "react";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import InquiryList from "../../components/inquiry-list/Inquiry-List";
import { getInquiries } from "../../util/APIUtils";
import { notificationManage } from "../../util/NotificationUtils";
import { utilSortDate,utilSort } from "../../util/UtilSort";
import { Spinner } from "react-bootstrap";

const dropDownFilter = [
  {
    id: 0,
    name: "Latest",
    value: "latest",
  },
  {
    id: 1,
    name: "Oldest",
    value: "oldest",
  },
  {
    id: 2,
    name: "City",
    value: "city",
  },
];

function AdminInquiry(props) {
  const { currentUser } = props;
  const [inquiryStage, setInquiryStage] = useState("Unread");
  const [isDataLoad, setIsDataLoad] = useState(true);
  const inquiryStages = ["Unread", "Replied"];
  const [dropdownchoice, setDropdownchoice] = useState("latest");
  const [dropDownFilterData, setDropDownFilterData] = useState([]);
  const [allInquiries, setAllInquiries] = useState("");
  const [isUnRead, setIsUnRead] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [data, setData] = useState([]);
  let copiedfiler = [];
  const dropDownOptions = (selected) => {
    setDropdownchoice(selected);
  };

  const filteredUnReadInquiries = (usersCopy) => {
    copiedfiler = usersCopy.filter((usersCopy) => usersCopy.reply === null);
    return copiedfiler;
  };

  const filteredReadInquiries = (usersCopy) => {
    copiedfiler = usersCopy.filter((usersCopy) => usersCopy.reply !== null);
    return copiedfiler;
  };
  const handleTabSelect = (activeKey) => {
    setIsUnRead(activeKey)
    let allInquiriesCopy = [...allInquiries];
    switch (activeKey) {
      case "Unread":
        setFilteredData(filteredUnReadInquiries(allInquiriesCopy));
        break;
      case "Replied":
        setFilteredData(filteredReadInquiries(allInquiriesCopy));
        break;
      default:
        break;
    }
  };

  const loadInquiries = () => {
    (currentUser.role === "ROLE_SUPER_ADMIN" ||
      currentUser.role === "ROLE_ADMIN") &&
      getInquiries()
        .then((res) => {
          setAllInquiries(res);
          handleTabSelect("Unread");
        })
        .catch((err) => {
          err?.then((resp) => {
            notificationManage("danger", `Inquiries list retrieval failed`);
          });
        });
  };

  const upperCase = (str) => {
    var splitStr = str.toLowerCase().split(" ");
    for (var i = 0; i < splitStr.length; i++) {
      splitStr[i] =
        splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }
    return splitStr.join(" ");
  };

  const  cityAlphabeticallyOrdered =(ArrayOfData)=>{
    ArrayOfData.sort(function(a, b) {
      return a < b ? -1 : 1;
   });
  }

  useEffect(() => {
    if (filteredData) {
      let filteredDataCopy = [...filteredData];
      switch (dropdownchoice) {
        case "latest":
          setDropDownFilterData(utilSortDate(filteredDataCopy, "createdAt", -1));
          break;
        case "oldest":
          setDropDownFilterData(utilSortDate(filteredDataCopy, "createdAt", 1));
          break;
        case "city":
          setDropDownFilterData(utilSort(filteredDataCopy, "city", 1));
          break;
        default:
          break;
      }
    }
  }, [dropdownchoice,filteredData]);

  useEffect(() => {
    setIsDataLoad(true);
    setTimeout(() => {
      setIsDataLoad(false);
    }, 4000);
  }, [inquiryStage]);

  useEffect(() => {
    loadInquiries();
  }, [isDataLoad]);

  useEffect(() => {
    setData(dropDownFilterData);
  }, [dropDownFilterData]);
  return (
    <div>
      <Container className="inquiry">
          <Tabs
            className="mt-5 inquiry-tabs"
            defaultActiveKey="Unread"
            transition={false}
            onSelect={handleTabSelect}>
            {inquiryStages.map((item) => (
              <Tab
                tabClassName="py-3 px-5 inquiry-tab"
                key={item}
                eventKey={item}
                title={item}>
                <div className="row utility-row">
                  <div className="sortByTxt">Sort by:</div>
                  <div className=" col-3 UserSortBy">
                    <Dropdown className=" user-dropdown">
                      <Dropdown.Toggle className="user-dropdown-toggle">
                        {upperCase(dropdownchoice)}
                        <i className="fas fa-caret-down"></i>
                      </Dropdown.Toggle>
                      <Dropdown.Menu className={"user-dropdown-menu"}>
                        {dropDownFilter.map((menuItem, index) => {
                          return (
                            <Dropdown.Item
                              key={index}
                              className={"user-dropdown-options"}
                              onClick={(e) => dropDownOptions(menuItem.value)}>
                              {menuItem.name}
                            </Dropdown.Item>
                          );
                        })}
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </div>
                <hr class="separator mx-0 px-0 pb-0  mt-1" />
                {isDataLoad ? (
                  <div className="loading-spinner">
                    <Spinner animation="border" variant="primary" />
                  </div>
                ) : (
                  <InquiryList 
                  inquiries={data}
                  currentUserId={currentUser.id}
                  loadInquiries={loadInquiries}
                  isUnRead={isUnRead}
                  />
                )}
              </Tab>
            ))}
          </Tabs>
      </Container>
    </div>
  );
}

export default AdminInquiry;
