import Cookies from "js-cookie";
import { createRef, useEffect, useRef, useState } from "react";
import {
  Button,
  Container,
  Dropdown,
  Nav,
  NavItem,
  NavLink,
  Navbar,
  OverlayTrigger,
  Popover,
  Toast,
} from "react-bootstrap";
import { XLg } from "react-bootstrap-icons";
import { Typeahead } from "react-bootstrap-typeahead";
import ReactGA from "react-ga4";
import { useInView } from "react-intersection-observer";
import { useHistory } from "react-router";
import { Link, useLocation } from "react-router-dom";
import SockJsClient from "react-stomp";
import {
  API_BASE_URL,
  ENV,
  ENVIROMENTS,
  SEARCH_HISTORY,
  USER_IDENTIFIER,
} from "../../constants";
import {
  localStorageScheduleCall,
  panelState,
} from "../../constants/scheduleConstants";
import { useVerified } from "../../context/UserContext";
import {
  getCities,
  getNotifications,
  getProfileById,
  getUnreadNotificationCount,
  notificationDisplayValueReSetter,
} from "../../util/APIUtils";
import { notificationManage } from "../../util/NotificationUtils";
import { utilSortDate } from "../../util/UtilSort";
import { USER_ROLE, getSearchHistory, truncateName } from "../../util/Utils";
import AdminHeader from "../admin-header/Admin-Header";
import FooterNotificationEmpty from "../footer-notification/FooterNotificationEmpty";
import NotificationContent from "../footer-notification/NotificationContent";
import PopupLogin from "../popup-login/Popup-Login";
import PopupNotification from "../popup-notification/Popup-Notification";
import { v4 as uuid4 } from "uuid";
import "./Header.scss";

const logo = "/assets/img/logo.png";
const hamburgerMenu = "/assets/img/hamburger.png";
const homeIcon = "/assets/img/header-sidemenu/home.png";
const becomeFriendIcon = "/assets/img/header-sidemenu/becomefriend.png";
const loginIcon = "/assets/img/header-sidemenu/login.png";
const notifyIcon = "/assets/img/header-sidemenu/notify.png";

const Header = (props) => {
  const [userDetails, setUserDetails] = useState([]);
  const [cities, setCities] = useState([]);
  const history = useHistory();
  const location = useLocation();
  const [notifications, setNotifications] = useState([]);
  const [handshakeEstablished, setHandshakeEstablished] = useState(false);
  const [viewSideBar, setViewSideBar] = useState(false);
  const [viewNotification, setViewNotification] = useState(false);
  const [showPopupLogin, setShowPopupLogin] = useState(false);
  const [showPopupBecomeFriend, setShowPopupBecomeFriend] = useState(false);
  const [showPopupNotification, setShowPopupNotification] = useState(false);
  const [currentPathname, setCurrentPathname] = useState("");
  const [notificationTab, setNotificationTab] = useState(false);
  const [isReadAll, setIsReadAll] = useState(false);
  const [notificationCount, setNotificationCount] = useState(0);
  const [tabClickNotificationCount, setTabClickNotificationCount] = useState(0);
  const [activeLink, setActiveLink] = useState({
    home: true,
    becomeAFriend: false,
    login: false,
  });
  const { setUserVerification, setCurrentUser, currentUser } = useVerified();
  const [searchHistory, setSearchHistory] = useState([]);
  const [showSearchHistory, setShowSearchHistory] = useState(false);
  const [isFriend, setIsFriend] = useState(false);
  const [sockClientConnectable, setSockClientConnectable] = useState(true);
  const [pushNotification, setPushNotification] = useState();
  const [showPushNotification, setShowPushNotification] = useState(false);
  const toggleShowPushNotification = () =>
    setShowPushNotification(!showPushNotification);
  const [unreadNotificationCount, setUnreadNotificationCount] = useState("");
  const [notSeenNotifications, setNotSeenNotifications] = useState([]);
  const [notificationCountPrevious, setNotificationCountPrevious] = useState(0);
  const { setHistory, setLocation } = useVerified();
  const [isCreateProfilePage, setIsCreateProfilePage] = useState(false);
  const [isNotificationRecieved, setIsNotificationRecieved] = useState(false);
  const [isReadAllPopupNotification, setIsReadAllPopupNotification] =
    useState(false);

  useEffect(() => {
    setHistory(history);
    setLocation(location);
  }, [location, history]);

  let sockRef = createRef(null);
  let sideBarRef = useRef();
  let notificationOverlayRef = useRef();
  let searchHistoryRef = useRef(null);
  const notificationPageCount = useRef(0);
  const notificationPageCountPrevious = useRef(-1);

  const onMessageReceived = (latestNotification) => {
    setPushNotification(latestNotification);
    setNotificationTab(false);
    setShowPushNotification(true);
    setIsReadAll(false);
    setIsReadAllPopupNotification(false);
    setTimeout(() => {
      setShowPushNotification(false);
    }, 5000);
    setNotifications((prev) => {
      if (prev) {
        const notificationArr = [...prev, latestNotification];
        if (notificationArr.length > 0) {
          const sortedArr = utilSortDate(notificationArr, "updatedAt", -1);
          return sortedArr;
        }
      }
    });
  };

  useEffect(() => {
    if (
      window.location.pathname === "/account-role-exist" ||
      window.location.pathname === "/account-login-exist"
    ) {
      logout();
    }
  }, [window.location.pathname]);

  const logout = () => {
    props.logout();
    setUserVerification(true);
    setCurrentUser(undefined);
    setNotifications([]);
    clearIsFriendStatus();
    setUnreadNotificationCount(0);
    localStorage.setItem("isEmpty", JSON.stringify(false));
    Cookies.remove("paginationCount");
    Cookies.set("isEmpty", JSON.stringify(false));
    Cookies.remove("schedule-call");
    Cookies.remove("scheduleLocation");
    const cookies = document.cookie.split("; ");
    const pastDate = new Date(Date.now() - 86400000).toUTCString();
    cookies.forEach((cookie) => {
      const [name] = cookie.split("=");
      document.cookie = `${name}=; expires=${pastDate}; path=/`;
    });
    Cookies.set(USER_IDENTIFIER, uuid4());

    try {
      if (localStorage.getItem("visitorDetails")) {
        const prevDetails = JSON.parse(localStorage.getItem("visitorDetails"));
        localStorage.setItem(
          "visitorDetails",
          JSON.stringify({ ...prevDetails, preferredCategories: [] })
        );
      }
    } catch (error) {}
    notificationPageCount.current = 0;
  };

  const clearIsFriendStatus = () => {
    if (localStorage.getItem("isFriendSocialLogin")) {
      localStorage.removeItem("isFriendSocialLogin");
    }
  };

  const navigateToUsers = () => {
    history.push("/users");
  };

  const handleSearchChange = (selected) => {
    if (selected[0]) {
      history.push(`/city/${selected[0].id}`);
    }
  };

  const handleSideBar = () => {
    setViewSideBar(!viewSideBar);
  };

  const handleShowPopup = (value) => {
    switch (value) {
      case "login":
        setShowPopupLogin(!showPopupLogin);
        break;
      case "notification":
        setShowPopupNotification(!showPopupNotification);
        break;
      case "become a friend":
        loadBecomeFriendClickFunc();
        break;
      default:
        notificationManage("danger", "Something went wrong!");
    }
  };
  const notificationClicked = (isTrue, noOfNotifications) => {
    setNotificationTab(isTrue);
    setTabClickNotificationCount(unreadNotificationCount);
  };

  const readAll = () => {
    setNotificationTab(true);
    setIsReadAll(false);
  };

  const readAllPopUpNotifications = () => {
    setNotificationTab(true);
    setIsReadAllPopupNotification(true);
    notificationDisplayValueReSetter(props.currentUser.id);
  };

  useEffect(() => {
    setNotSeenNotifications(
      notifications.filter((value) => {
        return !value.seenBy.includes(props?.currentUser?.id);
      })
    );
  }, [notifications]);

  const handleCurrentPage = (value) => {
    switch (value) {
      case "Home":
        return currentPathname === buttonList[0].route;
      case "Become a Friend":
        return currentPathname === "/become-a-friend";
      case "Notification":
        return showPopupNotification;
      case "Login":
        return showPopupLogin;
      default:
        return false;
    }
  };

  const removeCityHistory = () => {
    setSearchHistory([]);
    localStorage.removeItem(SEARCH_HISTORY);
  };

  useEffect(() => {
    let tempCountHolder = unreadNotificationCount - tabClickNotificationCount;
    if (tempCountHolder <= 0) {
      setNotificationCount(0);
    }
    setNotificationCount(tempCountHolder);
  }, [unreadNotificationCount, tabClickNotificationCount]);

  useEffect(() => {
    if (props.currentUser) {
      setSockClientConnectable(true);
      getProfileById(props?.currentUser?.id)
        .then((res) => {
          setUserDetails(res);
          if (
            notificationPageCountPrevious.current === -1 ||
            notificationPageCount.current !==
              notificationPageCountPrevious.current
          ) {
            notificationPageCountPrevious.current++;
            getNotifications(
              res?.id,
              parseInt(notificationPageCount?.current)
            ).then((notifications) => {
              setNotifications((prev) => {
                const notificationArr = [...prev, ...notifications];
                return utilSortDate(notificationArr);
              });
            });
          }
          getUnreadNotificationCount(res?.id).then((count) => {
            notifications.length > 0 &&
              setUnreadNotificationCount(parseInt(count));
          });
        })
        .catch((err) => {
          err?.then((resp) => {
            notificationManage("danger", "User retrievals failed");
          });
        });
    } else {
      setSockClientConnectable(false);
    }
  }, [props?.currentUser]);

  useEffect(() => {
    getCities().then((res) => {
      setCities(res);
    });
  }, []);

  // Send message and initiate handshake upon receiving current user
  useEffect(() => {
    if (
      props.currentUser &&
      props.currentUser.id &&
      sockRef &&
      sockRef.state &&
      sockRef.state.connected &&
      !handshakeEstablished
    ) {
      try {
        sockRef.sendMessage(
          "/app/search",
          JSON.stringify(props.currentUser.id)
        );
        setHandshakeEstablished(true);
      } catch (err) {
        notificationManage("danger", "Something went wrong!");
      }
    }
  }, [props.currentUser, sockRef, handshakeEstablished]);

  useEffect(() => {
    setCurrentPathname(location.pathname);
    if (location.pathname === "/") {
      assignActiveLink("home");
    } else if (location.pathname === "/become-a-friend") {
      assignActiveLink("becomeAFriend");
    } else {
      assignActiveLink(null);
    }
    if (location.pathname === "/create-friend-profile") {
      setIsFriend(true);
    }
  }, [location.pathname]);

  const buttonList = [
    {
      id: 1,
      name: "Home",
      icon: homeIcon,
      route: "/",
    },
    {
      id: 2,
      name: "Become a Friend",
      icon: becomeFriendIcon,
      route: (
        <PopupLogin
          show={true}
          onHide={() => {
            handleShowPopup("become a friend");
          }}
          name="Become a Friend"
        />
      ),
    },
    props.currentUser
      ? {
          id: 3,
          name: "Notification",
          icon: notifyIcon,
        }
      : {
          id: 4,
          name: "Login",
          icon: loginIcon,
          route: (
            <PopupLogin
              show={showPopupLogin}
              onHide={() => {
                handleShowPopup("login");
              }}
              name="Sign In"
              fromHeader={true}
            />
          ),
        },
  ];

  useEffect(() => {
    let handler = (event) => {
      if (!sideBarRef.current.contains(event.target)) setViewSideBar(false);
    };
    document.addEventListener("mousedown", handler);
    document.addEventListener("touchmove", handler);
    return () => {
      document.removeEventListener("mousedown", handler);
      document.removeEventListener("touchmove", handler);
    };
  }, []);

  useEffect(() => {
    let handler = (event) => {
      setViewNotification(
        notificationOverlayRef?.current?.contains(event.target)
      );
    };
    document.addEventListener("click", handler);
    return () => {
      document.removeEventListener("click", handler);
    };
  }, []);
  useEffect(() => {
    viewNotification === true && setIsNotificationRecieved(true);
  }, [setIsNotificationRecieved, viewNotification]);

  useEffect(() => {
    showPopupNotification === true && setIsNotificationRecieved(true);
  }, [setIsNotificationRecieved, showPopupNotification]);

  useEffect(() => {
    const screenWidth = window.matchMedia("(max-width: 992px)");
    if (screenWidth.matches & viewSideBar) {
      document.body.style.overflowY = "hidden";
    } else {
      document.body.style.overflowY = "auto";
    }
  }, [viewSideBar]);

  useEffect(() => {
    setSearchHistory(getSearchHistory());
  }, [window.location.pathname]);

  useEffect(() => {
    props.currentUser &&
      getUnreadNotificationCount(props?.currentUser?.id).then((count) => {
        notifications.length > 0 && setUnreadNotificationCount(parseInt(count));
      });
  }, [notifications, props?.currentUser]);

  useEffect(() => {
    document.addEventListener(
      "click",
      (event) => {
        if (!searchHistoryRef?.current?.contains(event.target)) {
          setShowSearchHistory(false);
        }
      },
      true
    );
    return () => {
      document.removeEventListener(
        "click",
        (event) => {
          if (!searchHistoryRef?.current?.contains(event.target)) {
            setShowSearchHistory(false);
          }
        },
        true
      );
    };
  }, [searchHistoryRef]);

  const assignActiveLink = (newActiveLink) => {
    setActiveLink({
      home: false,
      becomeAFriend: false,
      login: false,
      [newActiveLink]: true,
    });
  };

  const { ref, inView, entry } = useInView({
    threshold: 0,
  });

  useEffect(() => {
    if (inView) {
      notificationPageCountPrevious.current === -1
        ? (notificationPageCountPrevious.current =
            notificationPageCountPrevious.current + 2)
        : notificationPageCountPrevious.current++;
      getNotifications(
        props?.currentUser?.id,
        parseInt(++notificationPageCount.current)
      ).then((notifications) => {
        setNotifications((prev) => {
          const notificationArr = [...prev, ...notifications];
          return utilSortDate(notificationArr);
        });
      });
    }
  }, [inView]);

  const loadHomeClickFunc = () => {
    localStorage.setItem("isClickedHome", JSON.stringify(true));
    if (
      localStorage.getItem("isSave") !== "true" ||
      localStorage.getItem("emptyField") === "true"
    ) {
      props.setAllowRedirect(false);
    } else {
      props.setAllowRedirect(true);
      history.push("/");
    }
  };

  const loadBecomeFriendClickFunc = () => {
    localStorage.setItem("isClickedBecomeFriend", JSON.stringify(true));
    if (
      localStorage.getItem("isSave") !== "true" ||
      localStorage.getItem("emptyField") === "true"
    ) {
      props.setAllowRedirect(false);
    } else {
      props.setAllowRedirect(true);
      history.push("/become-a-friend");
    }
  };

  const gotoDemoPage = () => {
    handleSideBar();
    history.push("/request-demo");
  };

  const profileNavigation = (directory, state) => {
    switch (state) {
      case "edit_account":
        localStorage.setItem(
          localStorageScheduleCall.rightPanelState,
          panelState.PROFILE
        );
        break;
      case "schedule_call":
        localStorage.setItem(
          localStorageScheduleCall.rightPanelState,
          panelState.SCHEDULE_CALL
        );
        break;
      case "availability":
        localStorage.setItem(
          localStorageScheduleCall.rightPanelState,
          panelState.SETTINGS
        );
        break;
      default:
        break;
    }
    window.location.href = directory;
  };

  useEffect(() => {
    localStorage.setItem("isSave", JSON.stringify(true));
    localStorage.setItem("emptyField", JSON.stringify(false));
  }, []);

  useEffect(() => {
    if (history.location.pathname === "/create-profile") {
      setIsCreateProfilePage(true);
    }
  }, [history.location.pathname]);

  useEffect(() => {
    if (
      history.location.pathname === "/profile" &&
      isCreateProfilePage === true
    ) {
      localStorage.setItem("isClickedCreateProfile", JSON.stringify(true));
    } else {
      localStorage.setItem("isClickedCreateProfile", JSON.stringify(false));
    }
  }, [props, isCreateProfilePage, history]);

  useEffect(() => {
    if (
      history.location.pathname === "/profile" &&
      localStorage.getItem("emptyField") === "false"
    ) {
      setIsCreateProfilePage(false);
    }
  }, [history, props, isCreateProfilePage]);

  useEffect(() => {
    if (
      viewNotification === false &&
      notificationTab === true &&
      isNotificationRecieved === true
    ) {
      readAll();
    }
  }, [viewNotification, notificationTab, isNotificationRecieved]);

  useEffect(() => {
    if (
      showPopupNotification === false &&
      notificationTab === true &&
      isNotificationRecieved === true
    ) {
      readAllPopUpNotifications();
    }
  }, [showPopupNotification, notificationTab, isNotificationRecieved]);

  const isSearchShow = () => {
    return ["/", "/signup", "/login", "/social-signup"].includes(
      location.pathname
    );
  };

  return (
    <div className="header-main-container">
      <div
        ref={sideBarRef}
        className={viewSideBar ? "navbar-sidebar-view" : "navbar-sidebar-hide"}
      >
        <div className="header-sidebar">
          <div>
            <XLg onClick={handleSideBar} color={"#fff"} size={"18.5px"} />
          </div>
          <div className="header-sidebar-content">
            {buttonList.map((item, key) => {
              return (
                <div className="header-sidebar-content-item" key={item.id}>
                  <div
                    className={`content-item-icon ${
                      item.name === "Notification" &&
                      parseInt(notificationCount) > 0 &&
                      !notificationTab &&
                      "show-notify-side-bar-count"
                    }`}
                    notify-count={parseInt(notificationCount)}
                  >
                    <img
                      className="content-item-image"
                      src={item.icon}
                      width={item.id === 2 ? "22px" : "17px"}
                      alt="user-avatar"
                    />
                  </div>
                  <div
                    className={
                      handleCurrentPage(item.name)
                        ? "content-item-text"
                        : "content-item-text hide-underline"
                    }
                  >
                    {item.name !== "Home" ? (
                      <p
                        onClick={() => {
                          handleShowPopup(item.name.toLowerCase());
                          handleSideBar();
                          notificationClicked(true, notifications);
                        }}
                      >
                        {item.name}
                      </p>
                    ) : (
                      <Link onClick={loadHomeClickFunc}>
                        <p onClick={handleSideBar}>{item.name}</p>
                      </Link>
                    )}
                    <svg width="45" height="3">
                      <rect width="45" height="3" />
                    </svg>
                  </div>
                  {item.name === "Login" && item.route}
                  {item.name === "Become a Friend" &&
                    showPopupBecomeFriend &&
                    item.route}
                  {item.name === "Notification" && showPopupNotification && (
                    <PopupNotification
                      isShow={true}
                      handlePopupNotification={() => {
                        handleShowPopup("notification");
                      }}
                      notifications={notifications}
                      userId={props.currentUser.id}
                      setNotifications={setNotifications}
                      notificationPageCount={notificationPageCount.current}
                      notificationPageCountPrevious={
                        notificationCountPrevious.current
                      }
                      setNotificationTab={setNotificationTab}
                      isReadAll={isReadAllPopupNotification}
                    />
                  )}
                </div>
              );
            })}
          </div>
          <Button className="go-to-demo" onClick={(e) => gotoDemoPage()}>
            Demo
          </Button>
        </div>
      </div>
      <div className="header-push-notification">
        <Toast
          show={showPushNotification}
          onClose={toggleShowPushNotification}
          className="header-push-notification-toast"
        >
          <Toast.Header className="header-push-notification-toast-header"></Toast.Header>
          <Toast.Body>
            <NotificationContent
              notificationsType={pushNotification?.type}
              notificationPostStatus={pushNotification?.postStatus}
              notificationsId={pushNotification?.id}
              notificationsSeenBy={pushNotification?.seenBy}
              userId={props?.currentUser?.id}
              userRole={props?.currentUser?.role}
              ownerId={pushNotification?.ownerId}
              createdById={pushNotification?.createdBy?.id}
              declineMessage={pushNotification?.declineMessage}
              notificationsCreatedBy={pushNotification?.createdBy?.name}
              notificationsTitle={truncateName(pushNotification?.title, 200)}
              notificationsTags={pushNotification?.tags}
              notificationsCreatedAt={pushNotification?.createdAt}
              notificationsUpdatedAt={pushNotification?.updatedAt}
              notificationsInteractionDetail={
                pushNotification?.interactionDetail
              }
              notificationsPostId={pushNotification?.postId}
              notificationsCityId={pushNotification?.cityId}
              appointmentDescription={pushNotification?.appointmentDescription}
              receiverId={pushNotification?.receiverId}
              appointmentId={pushNotification?.appointmentId}
              id={pushNotification?.id}
              setNotifications={setNotifications}
              setNotificationTab={setNotificationTab}
            />
          </Toast.Body>
        </Toast>
      </div>
      <Navbar
        className="header"
        collapseOnSelect
        expand="lg"
        bg="dark"
        variant="dark"
      >
        <Container className="header-container ">
          <div className="container-fluid">
            <div
              id="nav-brand"
              className="col-sm-3 order-sm-2 order-2 order-md-1 col-md-3 col-4"
            >
              <Navbar.Brand as={Link} to="/" className="ml-0">
                <img src={logo} className="header-logo" alt="logo" />
              </Navbar.Brand>
            </div>
            <div
              id="nav-dropdown"
              className="nav-dropdown col-sm-4 order-sm-3 order-3 order-md-2 col-md-4 col-4"
            >
              {!isSearchShow() && (
                <div className="search mt-2">
                  <Typeahead
                    id="search-city"
                    labelKey="name"
                    options={cities}
                    onChange={(selected) => handleSearchChange(selected)}
                    placeholder="Search a city"
                    minLength={1}
                    onFocus={() => {
                      if (
                        localStorage.getItem("isSave") !== "true" ||
                        localStorage.getItem("emptyField") === "true"
                      ) {
                        localStorage.setItem(
                          "isSearchBarClicked",
                          JSON.stringify(true)
                        );
                        props.setAllowRedirect(false);
                      } else {
                        props.setAllowRedirect(true);
                      }
                      setShowSearchHistory(true);
                    }}
                  />
                  <i className="fa fa-search" onClick={"search-city".open}></i>
                  {showSearchHistory && searchHistory.length !== 0 && (
                    <div ref={searchHistoryRef} className="search-history">
                      <div className="search-history-header">
                        <p>Search History</p>
                        <button
                          onClick={() => {
                            removeCityHistory();
                          }}
                        >
                          <i className="fas fa-trash-alt" />
                        </button>
                      </div>
                      <div className="search-history-content">
                        {searchHistory?.map((item, index) => {
                          return (
                            <button
                              key={index}
                              onClick={() => {
                                history.push(`/city-history/${item.id}`);
                                setShowSearchHistory(false);
                              }}
                            >
                              {item?.name !== "" && item?.name}
                            </button>
                          );
                        })}
                      </div>
                    </div>
                  )}
                </div>
              )}
              <div className="header-avatar-sm">
                {props.currentUser && (
                  <div className="header-avatar-dropdown">
                    <Dropdown as={NavItem}>
                      <Dropdown.Toggle
                        as={NavLink}
                        variant="success"
                        id="dropdown-basic"
                        className="header-text"
                      >
                        {props.currentUser.image ||
                        props.currentUser.imageUrl ? (
                          <img
                            className="drop-down-header-avatar"
                            alt="user-avatar"
                            src={
                              props.currentUser.image !== null
                                ? `${API_BASE_URL}/v1/users/${props?.currentUser?.id}/image?${userDetails?.image?.fileSha1}`
                                : props.currentUser.imageUrl
                            }
                          />
                        ) : (
                          <div className="text-avatar">
                            <span>
                              {props.currentUser.userName
                                ? props.currentUser.userName[0].toUpperCase()
                                : props.currentUser.name &&
                                  props.currentUser.name[0].toUpperCase()}
                            </span>
                          </div>
                        )}
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        {userDetails.isFriend === true || isFriend ? (
                          <Dropdown.Item
                            onClick={() =>
                              history.push(`/friends/${props?.currentUser?.id}`)
                            }
                          >
                            My Profile
                          </Dropdown.Item>
                        ) : (
                          ""
                        )}
                        <Dropdown.Item
                          onClick={() =>
                            profileNavigation("/profile", "edit_account")
                          }
                        >
                          <i className="fa fa-user mr-3"></i>Edit Account
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={() =>
                            profileNavigation("/profile", "schedule_call")
                          }
                        >
                          <i className="fa fa-calendar mr-3"></i>Scheduled Calls
                        </Dropdown.Item>
                        {userDetails.isFriend && (
                          <Dropdown.Item
                            onClick={() =>
                              profileNavigation("/profile", "availability")
                            }
                          >
                            <i className="fa fa-cog mr-3"></i>Availability
                          </Dropdown.Item>
                        )}
                        <Dropdown.Item onClick={logout}>
                          <i className="fa fa-sign-out mr-3"></i>Logout
                        </Dropdown.Item>
                        {props.currentUser.role === "ROLE_ADMIN" && (
                          <span>
                            <Dropdown.Item onClick={navigateToUsers}>
                              <i className="fa fa-users mr-3"></i>Manage User
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={() => history.push("/cities")}
                            >
                              <i className="fa fa-building mr-3"></i>Manage City
                            </Dropdown.Item>
                          </span>
                        )}
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                )}
              </div>
            </div>
            <div
              id="nav-icon"
              className="col-sm-5 order-sm-1 order-1 order-md-3 col-md-5 col-4 flex-row-reverse flex-sm-row-reverse flex-md-row d-flex justify-content-end"
            >
              {/* SideBar Icon */}
              <div
                className={
                  props.currentUser &&
                  parseInt(notificationCount) > 0 &&
                  !notificationTab &&
                  isReadAll
                    ? "custom-menu-icon show-notify-count"
                    : "custom-menu-icon"
                }
                notify-count={parseInt(notificationCount)}
              >
                <img
                  src={hamburgerMenu}
                  alt="menu-icon"
                  onClick={handleSideBar}
                />
              </div>
              <Navbar.Toggle aria-controls="responsive-navbar-nav" />
              <Navbar.Collapse id="responsive-navbar-nav">
                <Nav className="navbar-container">
                  <Nav.Link
                    className={`${
                      activeLink.home ? "active-underline" : ""
                    } header-text mr-4`}
                    as={Link}
                    onClick={loadHomeClickFunc}
                  >
                    Home
                  </Nav.Link>
                  <Nav.Link
                    className={`${
                      activeLink.becomeAFriend ? "active-underline" : ""
                    } header-text mr-4`}
                    as={Link}
                    onClick={(e) => {
                      loadBecomeFriendClickFunc();
                      if (ENV === ENVIROMENTS.PRODUCTION) {
                        ReactGA.event({
                          category: "Static page",
                          action: "Become a friend",
                        });
                      }
                    }}
                  >
                    Become a Friend
                  </Nav.Link>
                  {props.currentUser && (
                    <div ref={notificationOverlayRef} inView={inView}>
                      {/* Notification component Starts here......... */}
                      {sockClientConnectable && (
                        <SockJsClient
                          url={`${API_BASE_URL}/notification`}
                          topics={["/user/queue/search"]}
                          onMessage={onMessageReceived}
                          ref={(client) => {
                            sockRef = client;
                          }}
                          onDisconnect={() => setSockClientConnectable(true)}
                          debug={false}
                        />
                      )}
                      <OverlayTrigger
                        className="mr-3"
                        placement="bottom"
                        trigger="click"
                        show={viewNotification}
                        overlay={
                          <Popover
                            placement="bottom"
                            className="popover-notification-header"
                          >
                            <Popover.Title
                              as="h3"
                              className="popover-notification-header-title"
                            >
                              <div className="popover-notification-header-title-wrapper">
                                <div className="popover-notification-title">
                                  Notifications
                                </div>
                              </div>
                            </Popover.Title>
                            <Popover.Content className="popover-notification-header-body">
                              {notifications?.length ? (
                                notifications.map((ntf, key) => {
                                  return (
                                    <div
                                      key={key}
                                      ref={
                                        notifications.length / 2 === key
                                          ? ref
                                          : undefined
                                      }
                                    >
                                      <NotificationContent
                                        notificationsType={ntf?.type}
                                        notificationPostStatus={ntf?.postStatus}
                                        notificationsId={ntf?.id}
                                        notificationsSeenBy={ntf?.seenBy}
                                        userId={props?.currentUser?.id}
                                        userRole={props?.currentUser?.role}
                                        ownerId={ntf?.ownerId}
                                        createdById={ntf?.createdBy?.id}
                                        declineMessage={ntf?.declineMessage}
                                        notificationsCreatedBy={
                                          ntf?.createdBy?.name
                                        }
                                        notificationsTitle={ntf?.title}
                                        notificationsTags={ntf?.tags}
                                        notificationsCreatedAt={ntf?.createdAt}
                                        notificationsUpdatedAt={ntf?.updatedAt}
                                        notificationsInteractionDetail={
                                          ntf?.interactionDetail
                                        }
                                        notificationsPostId={ntf?.postId}
                                        notificationsCityId={ntf?.cityId}
                                        appointmentDescription={
                                          ntf?.appointmentDescription
                                        }
                                        receiverId={ntf?.receiverId}
                                        setNotifications={setNotifications}
                                        setNotificationTab={setNotificationTab}
                                        isReadAll={isReadAll}
                                        appointmentId={ntf?.appointmentId}
                                        id={ntf?.id}
                                      />
                                    </div>
                                  );
                                })
                              ) : (
                                <FooterNotificationEmpty />
                              )}
                            </Popover.Content>
                          </Popover>
                        }
                      >
                        <div
                          className={
                            parseInt(notificationCount) > 0 && !notificationTab
                              ? "notification notify show-count"
                              : ""
                          }
                          data-count={parseInt(notificationCount)}
                        >
                          <Nav.Link
                            className="header-text notification-text mr-4"
                            variant="success"
                            onClick={() =>
                              notificationClicked(true, notifications)
                            }
                          >
                            Notifications
                          </Nav.Link>
                        </div>
                      </OverlayTrigger>
                      {/* Notification component ends here......... */}
                    </div>
                  )}
                  {props.currentUser && (
                    <div className="header-avatar-dropdown">
                      <Dropdown as={NavItem}>
                        <Dropdown.Toggle
                          as={NavLink}
                          variant="success"
                          id="dropdown-basic"
                          className="header-text header-drop-down pb-0"
                        >
                          {props.currentUser.image ||
                          props.currentUser.imageUrl ? (
                            <img
                              className="drop-down-header-avatar"
                              alt="user-avatar"
                              src={
                                props.currentUser.image !== null
                                  ? `${API_BASE_URL}/v1/users/${props?.currentUser?.id}/image?${userDetails?.image?.fileSha1}`
                                  : props.currentUser.imageUrl
                              }
                            />
                          ) : (
                            <div className="text-avatar">
                              <span>
                                {props.currentUser.userName
                                  ? props.currentUser.userName[0].toUpperCase()
                                  : props.currentUser.name &&
                                    props.currentUser.name[0].toUpperCase()}
                              </span>
                            </div>
                          )}
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          {userDetails.isFriend && (
                            <Dropdown.Item
                              className="drop-down"
                              onClick={() => {
                                if (
                                  localStorage.getItem("isSave") !== "true" ||
                                  localStorage.getItem("emptyField") === "true"
                                ) {
                                  localStorage.setItem(
                                    "isClickedMyProfile",
                                    JSON.stringify(true)
                                  );
                                  props.setAllowRedirect(false);
                                } else {
                                  props.setAllowRedirect(true);
                                  history.push(
                                    `/friends/${props?.currentUser?.id}`
                                  );
                                }
                              }}
                            >
                              My Profile
                            </Dropdown.Item>
                          )}

                          <Dropdown.Item
                            className="drop-down"
                            onClick={() =>
                              profileNavigation("/profile", "edit_account")
                            }
                          >
                            Edit account
                          </Dropdown.Item>
                          <Dropdown.Item
                            className="drop-down"
                            onClick={() => {
                              if (
                                localStorage.getItem("isSave") !== "true" ||
                                localStorage.getItem("emptyField") === "true"
                              ) {
                                localStorage.setItem(
                                  "isClickedScheduledCalls",
                                  JSON.stringify(true)
                                );
                                props.setAllowRedirect(false);
                              } else {
                                props.setAllowRedirect(true);
                                profileNavigation("/profile", "schedule_call");
                              }
                            }}
                          >
                            Scheduled calls
                          </Dropdown.Item>
                          {userDetails.isFriend && (
                            <Dropdown.Item
                              className="drop-down"
                              onClick={() => {
                                if (
                                  localStorage.getItem("isSave") !== "true" ||
                                  localStorage.getItem("emptyField") === "true"
                                ) {
                                  localStorage.setItem(
                                    "isClickedAvailability",
                                    JSON.stringify(true)
                                  );
                                  props.setAllowRedirect(false);
                                } else {
                                  props.setAllowRedirect(true);
                                  profileNavigation("/profile", "availability");
                                }
                              }}
                            >
                              Availability
                            </Dropdown.Item>
                          )}

                          <Dropdown.Item
                            className="drop-down"
                            onClick={() => {
                              if (
                                localStorage.getItem("isSave") !== "true" ||
                                localStorage.getItem("emptyField") === "true"
                              ) {
                                localStorage.setItem(
                                  "isClickedLogout",
                                  JSON.stringify(true)
                                );
                                props.setAllowRedirect(false);
                              } else {
                                props.setAllowRedirect(true);
                                logout();
                              }
                            }}
                          >
                            Logout
                          </Dropdown.Item>
                          {props.currentUser.role === "ROLE_SUPER_ADMIN" && (
                            <span>
                              <Dropdown.Item
                                className="drop-down"
                                onClick={navigateToUsers}
                              >
                                Manage User
                              </Dropdown.Item>
                              <Dropdown.Item
                                className="drop-down"
                                onClick={() => profileNavigation("/cities")}
                              >
                                Manage City
                              </Dropdown.Item>
                            </span>
                          )}
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  )}
                  {!props.currentUser && (
                    <>
                      <Nav.Link
                        className={`${
                          activeLink.login ? "active-underline" : ""
                        } header-text`}
                        as={Link}
                        onClick={() => setShowPopupLogin(true)}
                      >
                        Login
                      </Nav.Link>
                      <Nav.Link
                        className="go-to-demo btn btn-primary demo-btn"
                        as={Link}
                        onClick={() => {
                          if (ENV === ENVIROMENTS.PRODUCTION) {
                            ReactGA.event({
                              category: "Static page",
                              action: "Demo Page",
                            });
                          }
                          history.push("/request-demo");
                        }}
                      >
                        Demo
                      </Nav.Link>
                    </>
                  )}
                </Nav>
              </Navbar.Collapse>
            </div>
          </div>
        </Container>
      </Navbar>
      {(props.currentUser?.role === USER_ROLE.ADMIN ||
        props.currentUser?.role === USER_ROLE.SUPER_ADMIN) && (
        <AdminHeader location={location} currentUser={props.currentUser} />
      )}
    </div>
  );
};

export default Header;
