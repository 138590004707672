import Cookies from "js-cookie";
import _ from "lodash";
import { useEffect, useState } from "react";
import { OverlayTrigger, Spinner, Tooltip } from "react-bootstrap";
import { Typeahead } from "react-bootstrap-typeahead";
import { useHistory } from "react-router-dom";
import {
  ACCESS_TOKEN,
  API_BASE_URL,
  CREATE_FRIEND_PROFILE,
  CREATE_PROFILE,
} from "../../constants";
import {
  editProfile,
  getCategoryList,
  getCities,
  getProfile,
  isUsernameExists,
} from "../../util/APIUtils";
import { notificationManage } from "../../util/NotificationUtils";
import timeZones from "../../util/TimeZones";
import { handleEmailValidation, truncateName } from "../../util/Utils";
import AlertModal from "../alert-popup-modal/Alert-Modal";
import WarningMessageModal from "../warning-message-modal/Warning-Message-Modal";
import "./Edit-Profile.scss";

const initialValue = {
  fullName: false,
  username: false,
  mobile: false,
  email: false,
  currentCity: false,
  selectedCategories: false,
  about: false,
};

const EditProfile = (props) => {
  const { id, path, allowRedirect, setAllowRedirect } = props;
  const history = useHistory();
  const [formValue, setFormValue] = useState({
    fullName: "",
    username: "",
    mobile: "",
    email: "",
    currentCity: "",
    yearInCity: 1,
    selectedCategories: [],
    about: "",
  });
  const [showAlertModal, setShowAlertModal] = useState(false);
  const [userDetails, setUserDetails] = useState([]);
  const [allCities, setAllCities] = useState([]);
  const [profilePhoto, setProfilePhoto] = useState("");
  const [errorMessage, setErrorMessage] = useState(initialValue);
  const [isUpload, setIsUpload] = useState(false);
  const [categoryList, setCategoryList] = useState([]);
  const [isShowSpinner, setIsShowSpinner] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [showCreate, setShowCreate] = useState(false);
  const [isSaved, setIsSaved] = useState(true);
  const [isEmptyField, setIsEmptyField] = useState(false);
  const [isEdit, setIsEdit] = useState("");
  const [isCategoryListEdited, setIsCategoryListEdited] = useState(false);
  const [isCityEdited, setIsCityEdited] = useState(false);
  const [saveButtonSpinner, setSaveButtonSpinner] = useState(false);
  const [isChangedImage, setIsChangedImage] = useState(false);
  const [usernameExists, setUsernameExists] = useState(false);
  const [selectedTimeZoneNew, setSelectedTimeZoneNew] = useState("Etc/GMT+12");
  const [selectedTimeZoneChange,setSelectedTimeZoneChange] =useState("Etc/GMT+12");
  const [isGotError, setGotError] = useState(false);
  const MAX_ABOUT_CHARACTER_LIMIT = 500;
  const [timeZoneInput, setTimeZoneInput] = useState();
  const [isTimeZoneEdited, setIsTimeZoneEdited] = useState(false);

  const handleTimeZoneChange = (selected) => {
    if (selected && selected?.length > 0) {
      setIsTimeZoneEdited(true);
      const selectedTimeZone = selected[0]?.utc;
      const foundTimeZone = timeZones?.find((timeZone) =>
        timeZone?.utc?.includes(selectedTimeZone)
      );
      if (foundTimeZone) {
        const newSelectedTimeZoneValue = `${foundTimeZone?.text} ${foundTimeZone?.utc[0]} `;
        setSelectedTimeZoneNew(selectedTimeZone);
        setSelectedTimeZoneChange(newSelectedTimeZoneValue);
      }
      setIsCityEdited(true);
    }
  };

  useEffect(() => {
    if (localStorage.getItem("rightPanelState") !== "schedule-call") {
      if (window.location.search) {
        window.location.href = window.location.href?.split("?")[0];
      }
    }
  }, []);

  useEffect(() => {
    const isEquals = _.isEqual(initialValue, errorMessage);
    isEquals ? setGotError(false) : setGotError(true);
  }, [errorMessage]);

  const timeZoneValue = (text, utc) => {
    return text + " " + utc;
  };

  const handleOnChangeInput = (fieldName, value) => {
    let isError = false;
    
    switch (fieldName) {
      case "mobile":
        isError =
          value !== "" &&
          !/^\d{10,}$/.test(value) &&
          !/^\+\d{10,}$/.test(value);
        break;
      case "yearInCity":
        const yearValue = parseInt(value);
        isError = yearValue !== "" && (!/^\d{1,}$/.test(yearValue) || yearValue < 1);
        isError = value === "" ? true : isError;
        break;
      default:
        isError = value === "";
        break;
    }

    setErrorMessage((prevErrorMessage) => ({
      ...prevErrorMessage,
      [fieldName]: isError,
    }));

    setIsCityEdited(userDetails.cityId !== value);
    setIsCityEdited(true);
    setIsCategoryListEdited(true);
    setFormValue((prevFormValue) => ({
      ...prevFormValue,
      [fieldName]: fieldName === "yearInCity" ? parseInt(value) : value
    }));

    setIsSaved(false);
    localStorage.setItem("isSave", JSON.stringify(false));

    if (fieldName === "about" && value.length <= MAX_ABOUT_CHARACTER_LIMIT) {
      setFormValue((prevFormValue) => ({
        ...prevFormValue,
        [fieldName]: value,
      }));
    }
  };
  

  const checkCategoryUpdated = (tempList) => {
    if (tempList.length === userDetails.categories.length) {
      for (let category of userDetails.categories) {
        if (tempList.indexOf(category) < 0) {
          setIsCategoryListEdited(true);
          return;
        }
      }
      setIsCategoryListEdited(false);
      return;
    }
    setIsCategoryListEdited(true);
  };

  const handleButtonDisable = () => {
    if (errorMessage) {
      return userDetails.name === formValue.fullName &&
        userDetails.userName === formValue.username &&
        userDetails.email === formValue.email &&
        userDetails.mobileNumber === formValue.mobile &&
        userDetails?.isFriend
        ? userDetails.description === formValue.about &&
            userDetails.years === formValue.yearInCity &&
            !isCategoryListEdited &&
            !isCityEdited &&
            !isChangedImage
        : !isCategoryListEdited &&
            !isCityEdited &&
            !isChangedImage &&
            userDetails?.timeZone === selectedTimeZoneNew;
    }
    return true;
  };

  useEffect(() => {
    const accessToken = localStorage.getItem(ACCESS_TOKEN);
    if (
      (accessToken && userDetails?.name === null) ||
      userDetails?.userName === "" ||
      userDetails?.email === null ||
      userDetails?.mobileNumber === null ||
      userDetails?.cityId === null ||
      (userDetails?.isFriend
        ? userDetails?.description === null
        : userDetails?.categories === null)
    )
      localStorage.setItem("isEmpty", JSON.stringify(true));
    else {
      localStorage.setItem("isEmpty", JSON.stringify(false));
    }
  }, []);

  const handleGoBack = () => {
    if (!handleButtonDisable()) {
      setShowAlertModal(true);
    } else {
      history.goBack();
    }
  };
  
  const handleConfirmModal = () => {
    setShowAlertModal(false);
    history.goBack();
  };

  const handleCancelModal = () => {
    setShowAlertModal(false);
  };

  const ifEmptyField = () => {
    let emptyFieldstemp = [
      ...["fullName", "username", "email"].filter(
        (item) => !formValue[item] || formValue[item].trim().length === 0
      ),
    ];

    (!formValue["currentCity"] || formValue["currentCity"].length === 0) &
      !userDetails?.cityId && emptyFieldstemp.push("currentCity");

    userDetails.isFriend &&
      (!formValue["about"] || formValue["about"].trim().length === 0) &&
      emptyFieldstemp.push("about");

    formValue["selectedCategories"].length === 0 &&
      emptyFieldstemp.push("selectedCategories");

    if (emptyFieldstemp.length !== 0) {
      if (history.location.pathname === "/profile") {
        if (localStorage.getItem("isClickedCreateProfile") === "true") {
          localStorage.setItem("emptyField", JSON.stringify(true));
          setIsEmptyField(true);
        } else {
          localStorage.setItem("emptyField", JSON.stringify(false));
          setIsEmptyField(false);
        }
      } else {
        localStorage.setItem("emptyField", JSON.stringify(true));
        setIsEmptyField(true);
      }
    } else {
      localStorage.setItem("emptyField", JSON.stringify(false));
      setIsEmptyField(false);
    }
  };

  const handleCategoryButton = (name) => {
    const tempList = [...formValue.selectedCategories];
    tempList.includes(name)
      ? tempList.splice(tempList.indexOf(name), 1)
      : tempList.push(name);
    checkCategoryUpdated(tempList);
    setFormValue((prevState) => ({
      ...prevState,
      selectedCategories: tempList,
    }));
    setIsCityEdited(true);
    setIsCategoryListEdited(true);
    setIsSaved(false);
    setErrorMessage((prevErrorMessage) => ({
      ...prevErrorMessage,
      selectedCategories: false, 
    }));
    localStorage.setItem("isSave", JSON.stringify(false));
  };
  
  const handleYearInCity = (key) => {
    let newYearInCity = Number(formValue.yearInCity);

    if (key === "inc") {
      newYearInCity += 1;
    } else {
      newYearInCity = Math.max(1, newYearInCity - 1);
    }

    setFormValue((prevState) => ({
      ...prevState,
      yearInCity: newYearInCity,
    }));

    setIsCityEdited(true);
    setIsCategoryListEdited(true);
    setIsSaved(false);
    localStorage.setItem("isSave", JSON.stringify(false));
  };
  
  const handleUpdateCity = (cityId) => {
    return cityId === null
      ? { id: null, name: null }
      : allCities.find(
          (city) => city.id === cityId && { id: city.id, name: city.name }
        );
  };

  const handleImageUpload = (event) => {
    if (event.target.files[0]) {
      setIsChangedImage(true);
      const imgFile = event.target.files[0];
      setProfilePhoto(imgFile);
    } else {
      setIsChangedImage(false);
    }
  };

  const updateErrorMessageStatus = (item) => {
    setErrorMessage((prevState) => ({
      ...prevState,
      [item]: true,
    }));
  };
  const handleProfileSave = () => {
    setShowEdit(false);
    Cookies.set("isEmpty", JSON.stringify(false));
    setSaveButtonSpinner(true);
    localStorage.setItem("isSave", JSON.stringify(false));
    localStorage.setItem("emptyField", JSON.stringify(false));
    props.setAllowRedirect(true);
    if (
      formValue.mobile !== userDetails.mobileNumber &&
      !/^\d{10,}$/.test(formValue.mobile) &&
      !/^\+\d{10,}$/.test(formValue.mobile) &&
      formValue.mobile !== ""
    ) {
      updateErrorMessageStatus("mobile");
      setSaveButtonSpinner(false);
      return;
    }
    if (timeZoneInput === "" && !isTimeZoneEdited) {
      updateErrorMessageStatus("timeZone");
      setSaveButtonSpinner(false);
      return;
    }
    const formData = new FormData();
    formValue.fullName !== userDetails.name &&
      formData.append("name", formValue.fullName);
    formValue.username !== userDetails.userName &&
      formData.append("userName", formValue.username);
    isChangedImage && formData.append("image", profilePhoto);
    formValue.mobile !== userDetails.mobileNumber &&
      formData.append("mobileNumber", formValue.mobile);
    formData.append("timeZone", selectedTimeZoneNew);
    // Only valid, if user has friends profile
    userDetails?.isFriend &&
      (() => {
        formValue.yearInCity !== userDetails.years &&
          formData.append("years", formValue.yearInCity);
        formValue.about !== userDetails.description &&
          formData.append("description", formValue.about);
      })();

    formValue.currentCity !== "" &&
      (() => {
        formData.append("cityId", handleUpdateCity(formValue.currentCity).id);
        formData.append(
          "cityName",
          handleUpdateCity(formValue.currentCity).name
        );
      })();
    formData.append("categories", formValue.selectedCategories);

    // Set Spinner Visible
    profilePhoto !== "" &&
      (() => {
        setIsUpload(true);
      })();

    const editEmail =
      formValue.email !== "" ? formValue.email : userDetails.email;
    editEmail !== userDetails.email
      ? handleEmailValidation(editEmail)
        ? (() => {
            formData.append("email", editEmail);
            handleUpdateRequest(formData);
          })()
        : updateErrorMessageStatus("email")
      : handleUpdateRequest(formData);

    Cookies.set("timeZone", selectedTimeZoneNew);
  };

  const inputValidation = () => {
    // Reset Error Messages
    setErrorMessage(initialValue);
    let emptyFields = [
      ...["fullName", "username", "email"].filter(
        (item) => !formValue[item] || formValue[item].trim().length === 0
      ),
    ];

    (!formValue["currentCity"] || formValue["currentCity"].length === 0) &
      !userDetails?.cityId && emptyFields.push("currentCity");

    userDetails.isFriend &&
      (!formValue["about"] || formValue["about"].trim().length === 0) &&
      emptyFields.push("about");

    formValue["selectedCategories"].length === 0 &&
      emptyFields.push("selectedCategories");

    if (emptyFields.length !== 0) {
      emptyFields.forEach((item) => updateErrorMessageStatus(item));
      setSaveButtonSpinner(false);
      return false;
    } else {
      return true;
    }
  };

  const handleUpdateRequest = (formData) => {
    inputValidation() &&
      editProfile(id, formData)
        .then((res) => {
          localStorage.setItem("isSave", JSON.stringify(true));
          localStorage.setItem("emptyField", JSON.stringify(false));
          localStorage.setItem(
            "isClickedScheduledCalls",
            JSON.stringify(false)
          );
          localStorage.setItem("isSearchBarClicked", JSON.stringify(false));
          setAllowRedirect(true);
          notificationManage(
            "success",
            "Your changes were successfully updated"
          );
          setIsUpload(false);
          props.reloadCurrentUser();
          setUserDetails(res);
          history.push(
            res?.isFriend ? `/friends/${res?.id}` : `/city/${res?.cityId}`
          );
        })
        .catch((err) => {
          setIsUpload(false);
          err?.then((resp) => {
            resp.message === "Email address already in use."
              ? notificationManage("danger", "Email already exists")
              : resp.message === "User Name already in use."
              ? notificationManage("danger", "User Name already exists")
              : notificationManage("danger", "Edit user failed");
          });
        })
        .finally(() => setSaveButtonSpinner(false));
  };

  useEffect(() => {
    if (
      formValue.username !== userDetails.userName &&
      formValue.username.length
    ) {
      (async () => {
        try {
          const existingUsername = await isUsernameExists(formValue.username);
          setUsernameExists(existingUsername);
        } catch (error) {
          notificationManage(
            "danger",
            "An error occurred while checking username existence"
          );
        }
      })();
    } else {
      setUsernameExists(false);
    }
  }, [formValue.username, userDetails.userName]);

  useEffect(() => {
    getCities()
      .then((res) => {
        setAllCities(res);
      })
      .catch((err) => {
        err?.then((resp) => {
          notificationManage("danger", "All Cities retrieval failed");
        });
      });
  }, []);

  useEffect(() => {
    setIsShowSpinner(true);
    getProfile(id)
      .then((res) => {
        setUserDetails(res);
        const selectedTimeZoneNew = res?.timeZone;
        setSelectedTimeZoneNew(selectedTimeZoneNew);
        const foundTimeZone = timeZones?.find((timeZone) =>
          timeZone?.utc?.includes(selectedTimeZoneNew)
        );
        if (foundTimeZone) {
          const newSelectedTimeZoneValue = `${foundTimeZone?.text} ${foundTimeZone?.utc[0]} `;
          setSelectedTimeZoneChange(newSelectedTimeZoneValue);
        }
      })
      .catch((err) => {
        err?.then((resp) => {
          notificationManage("danger", "User retrieval failed");
        });
      })
      .finally(() => {
        setIsShowSpinner(false);
      });
  }, [id, path]);

  useEffect(() => {
    if (userDetails.length !== 0) {
      setFormValue((prevState) => ({
        ...prevState,
        fullName: userDetails.name ?? "",
        username: userDetails.userName ?? "",
        mobile: userDetails.mobileNumber ?? "",
        email: userDetails.email,
        about: userDetails.description ?? "",
        yearInCity: userDetails.years,
        selectedCategories: userDetails.categories
          ? [...userDetails.categories]
          : [],
      }));
    }
  }, [userDetails]);

  useEffect(() => {
    getCategoryList()
      .then((res) => {
        const sortedCategories = res?.sort((a, b) =>
          a.title.localeCompare(b.title)
        );
        const otherIndex = sortedCategories?.findIndex(
          (item) => item.title === "Other"
        );
        if (otherIndex !== -1) {
          const otherCategory = sortedCategories?.splice(otherIndex, 1)[0];
          sortedCategories?.push(otherCategory);
        }
        setCategoryList(sortedCategories);
      })
      .catch(() => {
        notificationManage("danger", "Category list retrieval failed");
      });
  }, []);

  useEffect(() => {
    ifEmptyField();
  }, [props.allowRedirect, props]);

  useEffect(() => {
    localStorage.setItem("emptyField", JSON.stringify(false));
  }, []);

  const handlePop = () => {
    handleGoBack();
  };
  useEffect(() => {
    window.addEventListener("popstate", handlePop);
    return () => {
      window.removeEventListener("popstate", handlePop);
    };
  }, [isEdit]);

  useEffect(() => {
    if (isSaved === false || isEmptyField === true) {
      props.setAllowRedirect(false);
    } else {
      props.setAllowRedirect(true);
    }
  }, [props.setAllowRedirect]);

  useEffect(() => {
    if (isEmptyField === true && allowRedirect === false) {
      setShowCreate(true);
      setIsEdit("create");
    } else {
      setShowCreate(false);
    }
  }, [showCreate, isEmptyField, setAllowRedirect, allowRedirect]);

  useEffect(() => {
    if (isSaved === false && allowRedirect === false) {
      setShowEdit(true);
      setIsEdit("edit");
    } else {
      setShowEdit(false);
    }
  }, [setAllowRedirect, showEdit, isSaved, allowRedirect]);

  const getPageTitle = () => {
    switch (path) {
      case CREATE_FRIEND_PROFILE:
        return "Create Friend account";
      case CREATE_PROFILE:
        return "Create account";
      default:
        return userDetails?.isFriend
          ? `Edit friend account`
          : `Edit user account`;
    }
  };

  const remainingCharacters =
    MAX_ABOUT_CHARACTER_LIMIT - formValue.about.length;
  const characterCountClass =
    remainingCharacters < 0 ? "character-count-exceeded" : "";

  return (
    <>
      {isShowSpinner ? (
        <div className="edit-friend-profile-spinner">
          <Spinner animation="border" variant="primary" />
        </div>
      ) : (
        <>
          <WarningMessageModal
            show={isEdit === "edit" ? showEdit : showCreate}
            setShow={isEdit === "edit" ? setShowEdit : setShowCreate}
            setAllowRedirect={setAllowRedirect}
            isEdit={isEdit}
            currentUser={id}
            saveButtonSpinner={saveButtonSpinner}
          />
          <AlertModal
            showModal={showAlertModal}
            hideModal={handleCancelModal}
            confirmModal={handleConfirmModal}
            id={""}
            type="Confirmation"
            message="You have unsaved changes"
            process="Discard Changes"
          />
          <div className="container edit-profile-container">
            <div className="edit-friend-profile">
              <div className="edit-friend-profile-title">
                <p>{getPageTitle()}</p>
              </div>
              <div className="edit-friend-profile-container">
                <div className="edit-friend-profile-avatar">
                  <div className="avatar-container">
                    {isUpload ? (
                      <div className="text-avatar-spinner">
                        <span>
                          <Spinner animation="border" variant="primary" />
                        </span>
                      </div>
                    ) : userDetails.image ||
                      userDetails.imageUrl ||
                      profilePhoto !== "" ? (
                      <img
                        width={99}
                        height={99}
                        className="display-picture"
                        src={
                          profilePhoto !== ""
                            ? URL.createObjectURL(profilePhoto)
                            : userDetails.image !== null
                            ? `${API_BASE_URL}/v1/users/${id}/image`
                            : userDetails.imageUrl
                        }
                        alt="user-google"
                      />
                    ) : (
                      <div className="text-avatar">
                        <span>
                          {userDetails.userName
                            ? userDetails.userName[0].toUpperCase()
                            : userDetails.name &&
                              userDetails.name[0].toUpperCase()}
                        </span>
                      </div>
                    )}
                    <button className="avatar-container-edit">
                      <i
                        className="fas fa-pen avatar-container-edit-pencil"
                        style={{ color: "white" }}
                      ></i>
                      <input
                        className="file-upload-button"
                        id="uploadImage"
                        type="file"
                        accept=".jpg, .jpeg, .png"
                        onChange={(img) => {
                          handleImageUpload(img);
                        }}
                      />
                    </button>
                  </div>
                </div>

                <div className="edit-friend-profile-content">
                  <div className="form-row">
                    <div className="form-col">
                      <label
                        htmlFor="fullName"
                        className="edit-friend-profile-label"
                      >
                        Full name *
                      </label>
                      <input
                        type="text"
                        className="form-control form-control-edit"
                        value={formValue.fullName}
                        onChange={(name) => {
                          handleOnChangeInput("fullName", name.target.value);
                        }}
                        placeholder="e.g Maria Hill"
                      />
                      <p
                        className={
                          errorMessage.fullName
                            ? "error-message"
                            : "error-message-hide"
                        }
                      >
                        * Full name is required
                      </p>
                    </div>
                    <div className="form-col">
                      <label
                        htmlFor="userName"
                        className="edit-friend-profile-label"
                      >
                        Username *
                      </label>
                      <OverlayTrigger
                        placement={"right"}
                        delay={{ show: 250, hide: 400 }}
                        overlay={
                          <Tooltip className="tooltip-extra-info">
                            The username you provide here will be used when you
                            publish question and answers
                          </Tooltip>
                        }
                      >
                        <i class="far fa-question-circle edit-question-mark"></i>
                      </OverlayTrigger>
                      <input
                        type="text"
                        className="form-control form-control-edit"
                        value={formValue.username}
                        onChange={(userName) => {
                          handleOnChangeInput(
                            "username",
                            userName.target.value
                          );
                        }}
                        placeholder="e.g Maria"
                      />
                      <p
                        className={
                          errorMessage.username
                            ? "error-message"
                            : "error-message-hide"
                        }
                      >
                        * Username is required
                      </p>
                      <p
                        className={
                          usernameExists
                            ? "error-message"
                            : "error-message-hide"
                        }
                      >
                        Username already exist. Please try another one
                      </p>
                    </div>
                  </div>

                  <div className="form-row">
                    <div className="form-col">
                      <label
                        htmlFor="mobileNumber"
                        className="edit-friend-profile-label"
                      >
                        Mobile number
                      </label>
                      <input
                        type="text"
                        className="form-control form-control-edit"
                        value={formValue.mobile}
                        onChange={(number) => {
                          handleOnChangeInput("mobile", number.target.value);
                        }}
                        placeholder="e.g +61 345 54432"
                      />
                      <p
                        className={
                          errorMessage.mobile
                            ? "error-message"
                            : "error-message-hide"
                        }
                      >
                        * Invalid mobile number
                      </p>
                    </div>
                    <div className="form-col">
                      <label
                        htmlFor="email"
                        className="edit-friend-profile-label"
                      >
                        Email *
                      </label>
                      <input
                        id="email-input"
                        type="email"
                        className="form-control form-control-edit"
                        value={formValue.email}
                        onChange={(emailAddress) => {
                          handleOnChangeInput(
                            "email",
                            emailAddress.target.value
                          );
                        }}
                        placeholder="mariah@gmail.com"
                      />
                      <p
                        className={
                          errorMessage.email
                            ? "error-message"
                            : "error-message-hide"
                        }
                      >
                        * Invalid Email address
                      </p>
                    </div>
                  </div>
                  <div className="form-row edit-friend-profile-content-timezone">
                    <div className="form-col edit-friend-profile-content-timezone-city">
                      <label className="edit-friend-profile-label">
                        {userDetails?.isFriend
                          ? `Current city *`
                          : `City moving to *`}
                      </label>
                      <div className="dropdown-custom">
                        <div className="edit-friends-profile-custom-icon">
                          <i className="fa fa-lg fa-angle-down dropdown-icon-down" />
                        </div>
                        <select
                          type="city"
                          className="form-control form-control-edit"
                          onChange={(currentCity) => {
                            handleOnChangeInput(
                              "currentCity",
                              currentCity.target.value
                            );
                          }}
                        >
                          <option value={null} selected={`selected`} disabled>
                            {userDetails?.isFriend
                              ? `--select current city--`
                              : `--select city--`}
                          </option>
                          {allCities.map((city) => {
                            return (
                              <option
                                key={city.id}
                                value={city.id}
                                selected={
                                  userDetails.cityId === city.id && `selected`
                                }
                              >
                                {city.name}
                              </option>
                            );
                          })}
                        </select>
                        <p
                          className={
                            errorMessage.currentCity
                              ? "error-message"
                              : "error-message-hide"
                          }
                        >
                          * City is Required
                        </p>
                      </div>
                    </div>
                    <div className="form-col edit-friend-profile-content-timezone-second">
                      {userDetails?.isFriend && (
                        <div>
                          <label
                            htmlFor="numberOfYear"
                            className="edit-friend-profile-label edit-friend-profile-year"
                          >
                            No. of years in the city *
                          </label>
                          <div className="dropdown-custom">
                            <div className="edit-friends-profile-custom-icon">
                              <i
                                onClick={() => {
                                  handleYearInCity("inc");
                                }}
                                className="fa fa-lg fa-up fa-angle-up input-number-up"
                              />
                            </div>
                            <div className="edit-friends-profile-custom-icon">
                              <i
                                onClick={() => {
                                  handleYearInCity("dec");
                                }}
                                className={
                                  formValue.yearInCity > 1
                                    ? "fa fa-lg fa-down fa-angle-down input-number-down"
                                    : "fa fa-lg fa-down fa-angle-down input-number-down disabled"
                                }
                              />
                            </div>
                            <input
                              onChange={(years) => {
                                handleOnChangeInput(
                                  "yearInCity",
                                  years.target.value
                                );
                              }}
                              value={formValue.yearInCity}
                              type="number"
                              min={1}
                              className="form-control form-control-edit"
                              id="numberOfYear"
                            />
                          </div>
                          <p
                            className={
                              errorMessage.yearInCity
                                ? "error-message"
                                : "error-message-hide"
                            }
                          >
                            * Years in city should be greater than 0
                          </p>
                        </div>
                      )}
                      {!userDetails?.isFriend && (
                        <div className="edit-profile-setting-container-space edit-profile-setting-container-space-bottom mt-0">
                          <div className="edit-profile-settings-select-time-zone">
                            <label
                              htmlFor="numberOfYear"
                              className="edit-friend-profile-label-zone"
                            >
                              Select your time zone *
                            </label>
                          </div>
                          <div className="form-group-setting-zone form-control-setter">
                            <Typeahead
                              className="time-zonal"
                              id="formControlSelect1"
                              defaultInputValue={selectedTimeZoneChange}
                              onChange={(selected) =>
                                handleTimeZoneChange(selected)
                              }
                              onInputChange={(text) => {
                                isTimeZoneEdited &&
                                  text === "" &&
                                  setIsTimeZoneEdited(false);
                                setTimeZoneInput(text);
                              }}
                              labelKey="text"
                              renderMenuItemChildren={(option, props) => (
                                <div className="option-menu">
                                  {truncateName(
                                    timeZoneValue(option?.text, option?.utc),
                                    45
                                  )}
                                </div>
                              )}
                              placeholder="Search a timezone"
                              options={timeZones?.flatMap((time) =>
                                time?.utc?.map((timeUtc) => ({
                                  text: time?.text + " " + timeUtc,
                                  utc: timeUtc,
                                }))
                              )}
                              selectHintOnEnter={true}
                            />
                          </div>
                          <p
                            className={
                              errorMessage.timeZone &&
                              timeZoneInput.length === 0
                                ? "error-message"
                                : "error-message-hide"
                            }
                          >
                            * Time zone is required
                          </p>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="category-button-group-container">
                  <div>
                    <label
                      htmlFor="expertCategories"
                      className="edit-friend-profile-label"
                    >
                      {userDetails?.isFriend
                        ? `Expert categories`
                        : `Categories interested`}{" "}
                      *
                    </label>
                  </div>
                  <div className="category-button-group">
                    {categoryList.map((item) => {
                      const { id, title, tag } = item;
                      return (
                        <button
                          key={id}
                          className={
                            formValue.selectedCategories.includes(
                              title?.toLocaleLowerCase()
                            )
                              ? `category-button-group-button`
                              : `category-button-group-active`
                          }
                          onClick={() => {
                            handleCategoryButton(title?.toLocaleLowerCase());
                          }}
                        >
                          {title}
                        </button>
                      );
                    })}
                    <p
                      className={
                        errorMessage.selectedCategories
                          ? "error-message"
                          : "error-message-hide"
                      }
                    >
                      * Categories are required
                    </p>
                  </div>
                </div>

                {userDetails?.isFriend && (
                  <div className="form-col-about">
                    <label
                      htmlFor="label"
                      className="edit-friend-profile-label edit-friend-profile-about"
                    >
                      About you * (
                      {remainingCharacters >= 0 ? remainingCharacters : 0}{" "}
                      characters left)
                    </label>
                    <textarea
                      type="text"
                      className="form-control form-control-about"
                      value={formValue.about}
                      id="aboutYou"
                      onChange={(aboutYou) => {
                        const trimmedValue = aboutYou.target.value.slice(
                          0,
                          MAX_ABOUT_CHARACTER_LIMIT
                        );
                        handleOnChangeInput("about", trimmedValue);
                      }}
                    />
                    <p
                      className={
                        errorMessage.about
                          ? "error-message"
                          : "error-message-hide"
                      }
                    >
                      * About is required
                    </p>
                  </div>
                )}

                <div className="edit-friend-profile-buttons">
                  <button
                    className={`${
                      (handleButtonDisable() || usernameExists) &&
                      "save-button-disable"
                    } save-button`}
                    onClick={() => {
                      handleProfileSave();
                    }}
                    disabled={handleButtonDisable() || usernameExists}
                  >
                    <span className="d-flex">
                      {saveButtonSpinner && !isGotError && (
                        <span
                          className={
                            saveButtonSpinner ? "save-button-spinner" : "d-none"
                          }
                        >
                          <Spinner animation="border" variant="primary" />
                        </span>
                      )}
                      Save
                    </span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default EditProfile;
