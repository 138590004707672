import { Button, Spinner } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { useHistory } from "react-router";
import { ACCESS_TOKEN } from "../../constants";
import {
  localStorageScheduleCall,
  panelState,
} from "../../constants/scheduleConstants";
import "./Warning-Message-Modal.scss";

const WarningMessageModal = (props) => {
  const {
    show,
    setShow,
    setAllowRedirect={setAllowRedirect},
    isEdit,
    currentUser,
    saveButtonSpinner,
  } = props;

  const handleLogout = () => {
    localStorage.removeItem(ACCESS_TOKEN);
  };

  const history = useHistory();

  const profileNavigation = (directory, state) => {
    switch (state) {
      case panelState.PROFILE:
        localStorage.setItem(
          localStorageScheduleCall.rightPanelState,
          panelState.PROFILE
        );
        localStorage.setItem("emptyField", JSON.stringify(false));
        break;
      case panelState.SCHEDULE_CALL:
        localStorage.setItem(
          localStorageScheduleCall.rightPanelState,
          panelState.SCHEDULE_CALL
        );

        break;
      case panelState.SETTINGS:
        localStorage.setItem(
          localStorageScheduleCall.rightPanelState,
          panelState.SETTINGS
        );
        break;
      default:
        break;
    }
    window.location.href = directory;
  };

  const goBackAndSave = () => {
    props.setAllowRedirect(true);
    const isClickedHome = JSON.parse(localStorage.getItem("isClickedHome"));
    const isClickedBecomeFriend = JSON.parse(localStorage.getItem("isClickedBecomeFriend"));
    const isClickedScheduledCalls = JSON.parse(localStorage.getItem("isClickedScheduledCalls"));
    const isClickedAvailability = JSON.parse(localStorage.getItem("isClickedAvailability"));
    const isClickedMyProfile = JSON.parse(localStorage.getItem("isClickedMyProfile"));
    const isClickedLogout = JSON.parse(localStorage.getItem("isClickedLogout"));
    const isSearchBarClicked = JSON.parse(localStorage.getItem("isSearchBarClicked"));

switch (true) {
      case isClickedHome:
        localStorage.removeItem("isClickedHome");
        history.push("/profile");
        break;
      case isClickedScheduledCalls:
        localStorage.removeItem("isClickedScheduledCalls");
        history.push("/profile");
        break;
      case isClickedAvailability:
        localStorage.removeItem("isClickedAvailability");
        history.push("/profile");
        break;
      case isClickedBecomeFriend:
        localStorage.removeItem("isClickedBecomeFriend");
        history.push("/profile");
        break;
      case isClickedMyProfile:
        localStorage.removeItem("isClickedMyProfile");
        history.push("/profile");
        break;
      case isSearchBarClicked:
        localStorage.removeItem("isSearchBarClicked");
        history.push("/profile");
        break;
      case isClickedLogout:
        localStorage.removeItem("isClickedLogout");
        history.push("/profile");
        break;
      default:
        history.goBack();
        break;
    }
  };

  const handleClose = () => {
    setAllowRedirect(true);
    setShow(false);    
  };
  const leaveWithoutSave = () => {
    props.setAllowRedirect(true);
    localStorage.setItem("isSave", JSON.stringify(true));
    const isClickedHome = JSON.parse(localStorage.getItem("isClickedHome"));
    const isClickedBecomeFriend = JSON.parse(localStorage.getItem("isClickedBecomeFriend"));
    const isClickedScheduledCalls = JSON.parse(localStorage.getItem("isClickedScheduledCalls"));
    const isClickedAvailability = JSON.parse(localStorage.getItem("isClickedAvailability"));
    const isClickedMyProfile = JSON.parse(localStorage.getItem("isClickedMyProfile"));
    const isClickedLogout = JSON.parse(localStorage.getItem("isClickedLogout"));
    const isSearchBarClicked = JSON.parse(localStorage.getItem("isSearchBarClicked"));

    switch (true) {
      case isClickedHome:
        localStorage.removeItem("isClickedHome");
        history.push("/");
        break;
      case isClickedScheduledCalls:
        localStorage.removeItem("isClickedScheduledCalls");
        profileNavigation("/profile", panelState.SCHEDULE_CALL);
        break;
      case isClickedAvailability:
        localStorage.removeItem("isClickedAvailability");
        profileNavigation("/profile", panelState.SETTINGS);
        break;
      case isClickedBecomeFriend:
        localStorage.removeItem("isClickedBecomeFriend");
        history.push("/become-a-friend");
        break;
      case isClickedMyProfile:
        localStorage.removeItem("isClickedMyProfile");
        history.push(`/friends/${currentUser}`);
        break;
      case isSearchBarClicked:
        localStorage.removeItem("isSearchBarClicked");
        history.push("/profile", panelState.PROFILE);
        break;
      case isClickedLogout:
        localStorage.removeItem("isClickedLogout");
        handleLogout();
        history.push("/");
        window.location.reload();
        break;
      default:
        history.goBack();
        break;
    }
  };

  return (
    <div>
      <Modal
        show={show}
        onHide={handleClose} 
        backdrop="static"
        className="error-alert-modal"
        onCancel={handleClose}
      >
        <Modal.Header
          className="error-message-popup-modal-header"
          onClose={handleClose}
          closeButton
        ></Modal.Header>
        <Modal.Body className="error-message-popup-modal-body">
          <Modal.Title> Are you sure you want to leave this page?</Modal.Title>
          <form className="error-message-popup">
            <div className="error-message-popup-content">
              {isEdit === "edit"
                ? " You have unsaved content, and it will be lost unless you save it"
                : " You have incomplete fields ,and it will be lost unless you save it."}
            </div>
            <div className="error-message-popup-buttons">
              <p className="leaveWithoutSavePointer" onClick={leaveWithoutSave}>
                {isEdit === "edit"
                  ? "Leave Without Saving"
                  : "Leave without Complete"}
              </p>
              <Button onClick={goBackAndSave}>
                <span className="d-flex">
                  {saveButtonSpinner && (
                    <span
                      className={
                        saveButtonSpinner ? "save-button-spinner" : "d-none"
                      }
                    >
                      <Spinner animation="border" variant="primary" />
                    </span>
                  )}
                  {isEdit === "edit" ? "Go back & Save" : "Go back & Complete "}
                </span>
              </Button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default WarningMessageModal;