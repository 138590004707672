import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Container,
  Dropdown,
  Form,
  FormControl,
  Row,
  Spinner,
} from "react-bootstrap";
import { Filter } from "react-bootstrap-icons";
import { useHistory } from "react-router";
import AlertBox from "../../components/alert-box/Alert-Box";
import { AskAQuestionModal } from "../../components/ask-question-modal/Ask-Question-Modal";
import CityEventCard from "../../components/city-event-card/City-Event-Card";
import CityFriendCard from "../../components/city-friend-card/City-Friend-Card";
import { NoFriendsFound } from "../../components/no-friends-found/No-Friends-Found";
import { ThreadItem } from "../../components/thread-item/Thread-Item";
import {
  ACCESS_TOKEN,
  ANALYTICS,
  API_BASE_URL,
  ENV,
  ENVIROMENTS,
  SEARCH_HISTORY,
  USER_IDENTIFIER,
} from "../../constants";
import {
  getAllEventsByCityIdAndDisplay,
  getCategoryList,
  getCity,
  getCurrentUser,
  getFriendsByCityId,
  getLocInfo,
  getProfile,
  postAnalytics,
} from "../../util/APIUtils";
import {
  filterOption as filterTopFriendsOption,
  filterOptionList,
  method,
} from "../../util/CityUtils";
import { notificationManage } from "../../util/NotificationUtils";
import { visitorDetails } from "../../util/Utils";
import "./City.scss";
import { MetaTags } from "react-meta-tags";
import { CITY_META_DATA } from "../../util/CityMetaData";
import Cookies from "js-cookie";
import {
  CURRENT_LOCATION,
  LOCATION_PROP,
  saveLocationInfo,
} from "../../util/CommonUtils";

const City = (props) => {
  const notificationPostId = props.location.notificationPostId;
  const notificationPostType = props.location.notificationPostType;
  const newNotificationSelected = props.location.search;
  const emailLinkPostId = props.location.search;
  const cityId = props.match.params.id;
  const loggedUser = props.currentUser;
  const filterOption = {
    TOP_RATED_THREADS: "Top rated threads",
    OLDEST_THREADS: "Oldest threads",
    LATEST_THREADS: "Latest threads",
    MY_QUESTIONS: "My questions",
  };
  const [dropdownMenuItems, setDropDownMenuItems] = useState([
    ["Top rated threads", filterOption.TOP_RATED_THREADS],
    ["Oldest threads", filterOption.OLDEST_THREADS],
    ["Latest threads", filterOption.LATEST_THREADS],
  ]);
  const [searchQuery, setSearchQuery] = useState(null);
  const [searchQueryString, setSearchQueryString] = useState(null);
  const [dropdownValue, setDropdownValue] = useState(
    filterOption.LATEST_THREADS
  );
  const [cityDetails, setCityDetails] = useState([]);
  const [eventList, setEventList] = useState([]);
  const [categories, setCategories] = useState([]);
  const [friendsInCity, setFriendsInCity] = useState([]);
  const [activeCategories, setActiveCategories] = useState([]);
  const [newQuestionPosted, setNewQuestionPosted] = useState(false);
  const [isDataLoad, setIsDataLoad] = useState(true);
  const [goToTopVisible, setGoToTopVisible] = useState(true);
  const [noFriendsFoundVisible, setNoFriendsFoundVisible] = useState(true);
  const [currentProfile, setCurrentProfile] = useState();
  const [filter, setFilter] = useState(filterTopFriendsOption.ALL);
  const [sortedFriendsList, setSortedFriendsList] = useState([]);
  const [showAlertBox, setShowAlertBox] = useState(false);
  const history = useHistory();
  const [savedActiveCategories, setSavedActiveCategories] = useState([]);
  const [myCategories, setMyCategories] = useState(() => {
    return (
      JSON.parse(localStorage.getItem(visitorDetails))?.preferredCategories ||
      []
    );
  });
  const [mQuery, setMQuery] = useState(window.matchMedia("(max-width: 992px)"));

  useEffect(() => {
    getCurrentUser()
      .then((res) => {
        getFriendsByCityId(cityId)
          .then((data) => {
            data = data.filter((value) => value.id !== res.id);
            setFriendsInCity(data);
          })
          .catch(() => {
            notificationManage("danger", "Something went wrong");
          });
      })
      .catch(() => {
        getFriendsByCityId(cityId)
          .then((data) => {
            setFriendsInCity(data);
          })
          .catch(() => {
            notificationManage("danger", "Something went wrong");
          });
      })
      .finally(() => {
        // Post Analytics
        getCity(cityId).then((res) => {
          if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
              (ctx) => geoLocSuccessHandler(ctx, res?.name),
              null, // Skip errors
              LOCATION_PROP
            );
          }
        });
      });
  }, [cityId]);

  useEffect(() => {
    localStorage.getItem(ACCESS_TOKEN) &&
      !loggedUser?.isFriend &&
      !dropdownMenuItems.some((item) =>
        item.includes(filterOption.MY_QUESTIONS)
      ) &&
      setDropDownMenuItems([
        ...dropdownMenuItems,
        ["My questions", filterOption.MY_QUESTIONS],
      ]);
  }, [loggedUser]);

  useEffect(() => {
    getCategoryList()
      .then((res) => {
        const sortedCategories = res?.sort((a, b) =>
          a.title.localeCompare(b.title)
        );
        const otherIndex = sortedCategories?.findIndex(
          (item) => item.title === "Other"
        );
        if (otherIndex !== -1) {
          const otherCategory = sortedCategories?.splice(otherIndex, 1)[0];
          sortedCategories?.push(otherCategory);
        }
        setCategories(sortedCategories);
      })
      .catch(() => {
        notificationManage("danger", "Category list retrieval failed");
      });
  }, []);

  useEffect(() => {
    if (notificationPostId) {
      setActiveCategories([]);
    }
  }, [notificationPostId, newNotificationSelected]);

  useEffect(() => {
    const currentDate = new Date();
    getAllEventsByCityIdAndDisplay(cityId).then((data) => {
      setEventList(
        data.filter((value) => {
          const eventEndDate = new Date(value.endDate);
          return currentDate < eventEndDate;
        })
      );
    });
  }, [cityId]);

  useEffect(() => {
    getCity(cityId).then((data) => {
      setCityDetails(data);
    });
  }, [cityId]);

  useEffect(() => {
    const store = JSON.parse(localStorage.getItem(SEARCH_HISTORY));
    if (store && window.location.pathname.split("/")[1] === "city-history") {
      const currentCityHistory = store.find(
        (item) => JSON.parse(item)?.id === cityId
      );
      setActiveCategories([...JSON.parse(currentCityHistory)?.categories]);
    }
  }, [cityId]);

  useEffect(() => {
    const store = JSON.parse(localStorage.getItem(SEARCH_HISTORY));
    if (store) {
      if (store.some((item) => JSON.parse(item)?.id === cityId)) {
        const currentCityIndex = store.findIndex(
          (item) => JSON.parse(item)?.id === cityId
        );
        store[currentCityIndex] = JSON.stringify({
          id: cityId,
          name: cityDetails?.name,
          categories: activeCategories,
        });
        localStorage.setItem(SEARCH_HISTORY, JSON.stringify(store));
      } else {
        store[store.length] = JSON.stringify({
          id: cityId,
          name: cityDetails?.name,
          categories: activeCategories,
        });
        localStorage.setItem(SEARCH_HISTORY, JSON.stringify(store));
      }
    } else {
      const list = [];
      list.push(
        JSON.stringify({
          id: cityId,
          name: cityDetails?.name,
          categories: activeCategories,
        })
      );
      localStorage.setItem(SEARCH_HISTORY, JSON.stringify(list));
    }
  }, [activeCategories, cityDetails?.name, cityId]);

  useEffect(() => {
    if (window.location.pathname.split("/")[1] !== "city-history") {
      setActiveCategories(() => {
        return [...myCategories];
      });
      if (!currentProfile) {
        if (localStorage.getItem(ACCESS_TOKEN)) {
          getCurrentUser().then((currentUser) => {
            getProfile(currentUser.id).then((currentUserProfile) => {
              setCurrentProfile(currentUserProfile);
              setMyCategories(currentUserProfile.categories);
              setActiveCategories(() => {
                return [...currentUserProfile.categories, ...myCategories];
              });
            });
          });
        }
      }
    }
  }, [currentProfile]);

  useEffect(() => {
    try {
      setSortedFriendsList(method(filter, friendsInCity, activeCategories));
    } catch (error) {
      notificationManage("danger", error);
    }
  }, [friendsInCity, filter, activeCategories]);

  const handleSearchChange = (e) => {
    e.preventDefault();
    if (e.target.value !== "") {
      setSearchQueryString(e.target.value);
    } else {
      setSearchQueryString(null);
      setActiveCategories(savedActiveCategories);
    }
  };

  const handleSearchClick = (e) => {
    e.preventDefault();
    if (searchQueryString !== null) {
      setActiveCategories([]);
      setSearchQuery(searchQueryString);
    }
  };

  const handleSearchThreads = (e) => {
    e.preventDefault();
    if (activeCategories.length > 0) {
      setSavedActiveCategories(activeCategories);
    }
    if (searchQueryString !== null) {
      setActiveCategories([]);
      setSearchQuery(searchQueryString);
    }
  };

  const scrollTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const handleSpinnerStatus = () => {
    setIsDataLoad(false);
  };

  const handleCategoryChange = (selectedCategory) => {
    setSearchQuery(null);
    setActiveCategories((prev) => {
      const activeCategoryFound = activeCategories.find(
        (active) => active === selectedCategory
      );

      if (activeCategoryFound) {
        return prev.filter((item) => item !== activeCategoryFound);
      } else {
        return [...prev, selectedCategory];
      }
    });
  };

  const navigateToFriendProfile = (id) => {
    history.push(`/friends/${id}`);
  };

  const handleCityMeta = () => {
    const currMeta = CITY_META_DATA.find(
      (e) => e?.name?.toLowerCase() === cityDetails?.name?.toLowerCase()
    );
    return (
      currMeta && (
        <MetaTags>
          <title>{currMeta?.title}</title>
          <meta name="description" content={currMeta?.description} />
          <meta name="keywords" content={currMeta?.keywords} />
        </MetaTags>
      )
    );
  };

  const geoLocSuccessHandler = (ctx, cityName) => {
    const cords = ctx?.coords;
    if (cords && ENV === ENVIROMENTS.PRODUCTION) {
      const prevLocStr = Cookies.get(CURRENT_LOCATION);
      if (prevLocStr) {
        const prevLocInfo = JSON.parse(prevLocStr);
        if (
          prevLocInfo?.latitude === cords?.latitude &&
          prevLocInfo?.longitude === cords?.longitude
        ) {
          if (prevLocInfo?.name) {
            postAnalytics({
              userIdentifier: Cookies.get(USER_IDENTIFIER) ?? "anonymous",
              analyticType: ANALYTICS.MIGRATION_PREFERENCE,
              fromLocation: prevLocInfo?.name,
              toLocation: cityName,
            }).catch(console.error);
          }
        } else {
          fetchLocationInfo(cords?.latitude, cords?.longitude, cityName);
        }
      } else {
        fetchLocationInfo(cords?.latitude, cords?.longitude, cityName);
      }
    }
  };

  const fetchLocationInfo = (lat, lng, cityName) => {
    if (ENV === ENVIROMENTS.PRODUCTION) {
      getLocInfo(lat, lng).then((res) => {
        const currCity = res[0]?.display_name;
        if (currCity) {
          saveLocationInfo(lat, lng, currCity);
          postAnalytics({
            userIdentifier: Cookies.get(USER_IDENTIFIER) ?? "anonymous",
            analyticType: ANALYTICS.MIGRATION_PREFERENCE,
            fromLocation: currCity,
            toLocation: cityName,
          }).catch(console.error);
        }
      });
    }
  };

  return (
    <section className="content city">
      {isDataLoad ? (
        <div className="city-spinner">
          <Spinner animation="border" variant="primary" />{" "}
        </div>
      ) : (
        <AlertBox
          title="You have Signed In as a Friend"
          body={
            <p>
              Sorry ! You cannot ask a question because you have signed in as{" "}
              <span className="highlighted-text">Friend</span>. Please Sign in
              as <span className="highlighted-text">Newcomer</span> to ask
              questions.
            </p>
          }
          showAlertBox={showAlertBox}
          setShowAlertBox={setShowAlertBox}
          type="ask-question-friend-alert"
        />
      )}
      <div className="city-responsive-header">
        {!isDataLoad && (
          <div className="city-responsive-header-menu">
            <p>Friends from {cityDetails.name}</p>
            <Dropdown drop="left" className="city-friend-header-dropdown">
              <Dropdown.Toggle className="city-friend-header-dropdown-toggle">
                <Filter />
              </Dropdown.Toggle>
              <Dropdown.Menu className="city-friend-header-dropdown-menu">
                {filterOptionList.map((item, index) => {
                  return (
                    <Dropdown.Item
                      className={filter === item.property && `item-active`}
                      key={index}
                      value={item.property}
                      onClick={() => {
                        setFilter(item.property);
                      }}
                    >
                      {item.name}
                    </Dropdown.Item>
                  );
                })}
              </Dropdown.Menu>
            </Dropdown>
          </div>
        )}
        {noFriendsFoundVisible && !isDataLoad && (
          <div
            className={
              sortedFriendsList.length === 0 ||
              filterTopFriendsOption.ONLINE_FRIENDS === filter ||
              filterTopFriendsOption.TOP_RATED_FRIENDS === filter
                ? "city-friends-empty"
                : "city-friends-empty display-none"
            }
          >
            <div className="city-friends-empty-content">
              <NoFriendsFound
                setNoFriendsFoundVisible={setNoFriendsFoundVisible}
                message={
                  filter === filterTopFriendsOption.ONLINE_FRIENDS
                    ? "Sorry, there are no online friends available at the moment"
                    : "We are yet to find friends in this city"
                }
              />
            </div>
          </div>
        )}
        <div
          className={
            friendsInCity.length !== 0
              ? "city-friends-slider"
              : "city-friends-slider display-none"
          }
        >
          {sortedFriendsList?.map((friend) => {
            const fullName = friend.userName
              ? friend.userName.split(" ")
              : friend.name.split(" ");
            return (
              <div
                key={friend.id}
                className="city-friends-slider-item"
                onClick={(e) => {
                  navigateToFriendProfile(friend.id);
                }}
              >
                {friend.image || friend.imageUrl ? (
                  <img
                    className="city-friend-card-image"
                    src={
                      friend.image
                        ? `${API_BASE_URL}/v1/users/${friend.id}/image`
                        : friend.imageUrl
                    }
                    alt={`${friend.name} img`}
                  />
                ) : (
                  <div className="text-avatar">
                    <span>
                      {friend.userName
                        ? friend.userName[0].toUpperCase()
                        : friend.name && friend.name[0].toUpperCase()}
                    </span>
                  </div>
                )}
                <div className="d-flex">
                  {friend.isOnline && (
                    <i className="fas fa-circle online-indicator"></i>
                  )}
                  <p>{fullName[0]}</p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <div className="city-ask-question-button-fixed">
        {(currentProfile?.isFriend === false || !currentProfile) &&
          mQuery.matches && (
            <AskAQuestionModal
              buttonName="Ask"
              cityId={props.match.params.id}
              setNewQuestionPosted={setNewQuestionPosted}
              setShowAlertBox={setShowAlertBox}
              reloadCurrentUser={props.reloadCurrentUser}
            />
          )}
      </div>
      <Container className={!isDataLoad ? `main` : `main main-display-none`}>
        <Row className="city-main-container">
          <Col lg={9} className="city-content">
            <div className="city-search-bar-container">
              <div className="city-search-bar-container-title">
                {cityDetails.name}
              </div>
              <Form className="city-search-bar d-flex">
                <div className="city-disable-close-icon">
                  <FormControl
                    type="search"
                    placeholder="Search posts"
                    className="px-3 border-0 placeholder search-text-content"
                    aria-label="Search"
                    onChange={handleSearchChange}
                    onKeyUp={handleSearchThreads}
                  />
                </div>
                <button
                  className="bg-white border-0 px-3 city-search-icon"
                  onClick={handleSearchClick}
                >
                  <i className="fa fa-search fa-lg" />
                </button>
              </Form>
            </div>
            <div className="mt-4 city-category-title">Categories</div>
            <Row className="city-categories pl-2">
              {categories.map((item) => {
                return (
                  <div key={item.id} className="category">
                    <span
                      className={`
                         category-pill ${
                           activeCategories.includes(item.tag)
                             ? "category-pill-active"
                             : ""
                         }`}
                      onClick={(e) => {
                        handleCategoryChange(item.tag);
                      }}
                    >
                      {item.title}
                    </span>
                  </div>
                );
              })}
            </Row>
            <div className="d-flex justify-content-between align-items-center">
              <div
                className="d-inline-flex align-items-center mt-3 city-content-title"
                id="sort-by"
              >
                Sort by:
                <Dropdown className="ml-3">
                  <Dropdown.Toggle className="latest-posts-toggle">
                    {dropdownValue}
                  </Dropdown.Toggle>
                  <Dropdown.Menu className={"latest-posts-menu"}>
                    {dropdownMenuItems.map((menuItem, index) => {
                      return (
                        <Dropdown.Item
                          key={index}
                          className={"latest-posts-options"}
                          value={menuItem[1]}
                          onClick={() => setDropdownValue(menuItem[1])}
                        >
                          {menuItem[0]}
                        </Dropdown.Item>
                      );
                    })}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
              <div className="city-ask-question-button-relative">
                {(currentProfile?.isFriend === false || !currentProfile) &&
                  !mQuery.matches && (
                    <AskAQuestionModal
                      buttonName="Ask a question"
                      cityId={props.match.params.id}
                      setNewQuestionPosted={setNewQuestionPosted}
                      setShowAlertBox={setShowAlertBox}
                      reloadCurrentUser={props.reloadCurrentUser}
                    />
                  )}
              </div>
            </div>
            <div className="mt-4 thread-main-title">
              <ThreadItem
                cityId={cityId}
                activeCategories={activeCategories}
                setActiveCategories={setActiveCategories}
                searchQuery={searchQueryString}
                dropdownValue={dropdownValue}
                setDropdownValue={setDropdownValue}
                filterOption={filterOption}
                newQuestionPosted={newQuestionPosted}
                setNewQuestionPosted={setNewQuestionPosted}
                handleSpinnerStatus={() => {
                  handleSpinnerStatus();
                }}
                notificationPostId={notificationPostId}
                notificationPostType={notificationPostType}
                newNotificationSelected={newNotificationSelected}
                setGoToTopVisible={setGoToTopVisible}
                currentProfile={currentProfile}
                reloadCurrentUser={props.reloadCurrentUser}
                loggedUser={loggedUser}
                emailLinkPostId={emailLinkPostId}
              />
            </div>
            {goToTopVisible && (
              <div className="d-flex justify-content-center mt-5 mb-2 goto-top">
                <span>
                  <Button
                    className="goto-top-button"
                    variant="link"
                    onClick={scrollTop}
                  >
                    Go to top{" "}
                    <i className="fa fa-arrow-up ml-1" aria-hidden="true"></i>
                  </Button>
                </span>
              </div>
            )}
            <div className="event-card mt-4">
              {eventList.length > 0 && (
                <div className="event-header mb-3">Events in this city</div>
              )}
              <div>
                <Row xs="auto">
                  {eventList
                    .sort(
                      (a, b) => new Date(a.startDate) - new Date(b.startDate)
                    )
                    .map((event) => {
                      return (
                        <div key={event.id} className="event-items">
                          <Col className="px-0 mx-0">
                            <CityEventCard event={event} />
                          </Col>
                        </div>
                      );
                    })}
                </Row>
              </div>
            </div>
          </Col>
          <Col lg={3} className="friend-card border-left mt-3 mt-4 pt-1">
            <CityFriendCard id={cityId} activeCategories={activeCategories} />
          </Col>
        </Row>
      </Container>
      {ENV === ENVIROMENTS.PRODUCTION && handleCityMeta()}
    </section>
  );
};

export default City;
