import { months } from "../../util/Utils";
import PostComment from "../post-comment/Post-Comment";
import "./Friends-Post.scss";
import { useState } from "react";
import { Link } from "react-router-dom";

const FriendsPost = (props) => {
  const {
    id,
    author,
    datePost,
    title,
    text,
    comments,
    handleDataUpdate,
    currentUserDetails,
    cityId,
  } = props;
  const [showAllComments, setShowAllComments] = useState(false);
  const formatDate = (date) => {
    let day = date[8] + date[9];
    let monthName = months[Number(date[5] + date[6]) - 1];
    return monthName[0] + monthName[1] + monthName[2] + " " + day;
  };

  return (
    <div className="friends-container">
      <div className="friends-container-question">
        <Link
          className="notification-link"
          to={{
            pathname: `/city/${cityId}`,
            search: `?id=${id}`,
            notificationPostId: id,
            notificationPostType: "question",
          }}>
        <OriginalPostComponent
          id={id}
          author={author}
          datePost={formatDate(datePost)}
          title={title}
          text={text}
          comments={comments}
        />
        </Link>
      </div>
      <div className="friends-container-comment-section">
        {showAllComments
          ? comments?.map((comment) => {
              return (
                <div key={comment.id}>
                  <PostComment
                    id={comment.id}
                    author={comment.createdBy.userName}
                    dateComment={formatDate(comment.createdAt)}
                    title={comment.title}
                    comments={comment.comments}
                    interactions={comment.interactions}
                    handleDataUpdate={handleDataUpdate}
                    currentUserDetails={currentUserDetails}
                  />
                </div>
              );
            })
          : comments?.slice(0, 2).map((comment) => {
              return (
                <div key={comment.id}>
                  <PostComment
                    id={comment.id}
                    author={comment.createdBy.userName}
                    dateComment={formatDate(comment.createdAt)}
                    title={comment.title}
                    comments={comment.comments}
                    interactions={comment.interactions}
                    handleDataUpdate={handleDataUpdate}
                    currentUserDetails={currentUserDetails}
                    noOfComments={comments.length}
                  />   
               </div>
              );
            })}
      </div>
      <div  className="ans-button"  variant="link">
      {comments.length >2 ? (
        showAllComments? (
          <span className="arrow" onClick={() =>setShowAllComments(false)}>
            <hr className="line mx-0 px-0 pb-0  mt-1"/>
            Show Less Answers
            <i
              className=" arrow-up fas fa-angle-double-up fa-lg ml-1"
              aria-hidden="true"></i>
          </span>
        ) : (
          <span className="arrow" onClick={()=>setShowAllComments(true)}>
            <hr className="line mx-0 px-0 pb-0  mt-1"/>
            See More Answers
            <i className="Arrow-down fa fa-angle-double-down fa-lg ml-1"></i>
          </span>
        )
      ):("")}
       </div>
    </div>
  );
};

const OriginalPostComponent = (props) => {
  const { id, author, datePost, title, text } = props;

  return (
    <div key={id} className="question-container">
      <div className="question-details">
        <p>{author}</p>
        <p>{datePost}</p>
      </div>
      <div className="question-title">
        <p>{title === null ? `No Title` : title}</p>
      </div>
      <div className="question-content">
        <p>{text}</p>
      </div>
    </div>
  );
};

export default FriendsPost;
