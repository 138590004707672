import React from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import "./Question-Confirmation-Modal.scss";

const QuestionConfirmationModel = (props) => {
  const {
    showConfirmationModal,
    setShowConfirmationModal,
    questionTitle,
    handleConfirmationSubmit,
  } = props;
  const rocketImage = "/assets/img/rocketImage.png";

  const handleClose = () => setShowConfirmationModal(false);

  const handleSubmit = () => {
    handleConfirmationSubmit();
    setShowConfirmationModal(false);
  };

  return (
    <>
      <Modal
        centered
        show={showConfirmationModal}
        onHide={handleClose}
        backdrop="static"
        className="question-confirm-modal"
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div>
            <div className="rocket-image-main">
              <img
                src={rocketImage}
                alt="rocket-img"
                className="rocket-image-sub"
              />
            </div>
            <div className="modal-body-publish">
              <p>Are you sure you want to publish this?</p>
            </div>
            <div className="modal-body-text">
              <div className="modal-body-text-content">
                <p>{questionTitle}</p>
              </div>
            </div>
          </div>
          <div>
            <hr />
          </div>
        </Modal.Body>
        <Modal.Footer className="pb-5 pt-0">
          <Button
            className="publish-button"
            variant="primary"
            onClick={handleSubmit}
          >
            Yes, I want to publish
          </Button>
          <Button
            className="goback-button"
            variant="outline-primary"
            onClick={handleClose}
          >
            No, Go back to editing
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default QuestionConfirmationModel;
