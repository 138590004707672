import React, { useEffect, useState } from "react";
import { Dropdown, Form } from "react-bootstrap";
import "./Custom-Call-Duration-Dropdown.scss";

const CheckboxMenu = React.forwardRef(
  ({ children, style, className, "aria-labelledby": labeledBy }, ref) => {
    return (
      <div
        ref={ref}
        style={style}
        className={`${className} CheckboxMenu`}
        aria-labelledby={labeledBy}
      >
        <div className="d-flex flex-column mh-100 mh-vh-100 overflow-hidden">
          <ul className="list-unstyled flex-shrink mb-0 overflow-hidden">
            {children}
          </ul>
        </div>
      </div>
    );
  }
);

const CheckDropdownItem = React.forwardRef(
  ({ children, id, checked, onChange, menuDisabled }, ref) => {
    return (
      <Form.Group ref={ref} className="dropdown-item mb-0" controlId={id}>
        <Form.Check
          type="checkbox"
          label={children}
          checked={checked}
          onChange={onChange && onChange.bind(onChange, id)}
          disabled={menuDisabled}
        />
        <div className="dropdown-divider w-100"></div>
      </Form.Group>
    );
  }
);

export const CheckboxDropdown = ({
  items,
  setDurationValues,
  durationValues,
  setIsValuePassed,
}) => {
  const [durationList, setDurationList] = useState(items);
  const [isAllChecked, setIsAllChecked] = useState(false);
  const [titleText, setTitleText] = useState([]);
  const [extraSelection, setExtraSelection] = useState(0);

  const handleChecked = (key, event) => {
    const selectedCount = durationList.filter((i) => i?.checked)?.length;

    if (selectedCount === 1 && event.target.checked === false) {
      return;
    }
    if (selectedCount === 3 && event.target.checked === true) {
      setIsAllChecked(true);
    }
    durationList.find((i) => i.id === key).checked = event.target.checked;
    handleTitleText();
    setIsAllChecked(false);
    setIsValuePassed(true);
  };

  const handleSelectAll = () => {
    durationList.forEach((i) => (i.checked = true));
    handleTitleText();
    setIsValuePassed(true);
  };

  const handleSelectNone = () => {
    durationList.forEach((i) => (i.checked = false));
    setTitleText([]);
    setDurationValues([]);
    setIsValuePassed(true);
  };

  const checkDurationList = (durationList, durationValues) => {
    return durationList.map((duration) => {
      if (durationValues.includes(parseInt(duration.label))) {
        return { ...duration, checked: true };
      } else {
        return duration;
      }
    });
  };

  useEffect(() => {
    if (durationList && durationValues) {
      setDurationList(checkDurationList(durationList, durationValues));
      handleTitleText();
      const selectedCount = durationList.filter((i) => i?.checked)?.length;
      selectedCount === 3 && setIsAllChecked(true);
    }
  }, [durationValues?.length]);

  const handleTitleText = () => {
    const selectedDurationValue = [];
    const selectedItems = durationList
      ?.filter((i) => i.checked && i.label)
      ?.map((i) => i.label);
    let count = 0;
    durationList?.forEach((i, index) => {
      if (i.checked) {
        if (selectedDurationValue.length === 2) {
          count = count + 1;
        }
        if (selectedDurationValue.length < 2) {
          const label =
            (selectedDurationValue.length >= 1 ? ", " : "") + i.label;
          selectedDurationValue.push(label);
        }
      }
    });
    setDurationValues(selectedItems);
    setTitleText(selectedDurationValue);
    setExtraSelection(count);
  };

  return (
    <Dropdown className="custom-call-duration-dropdown">
      <Dropdown.Toggle variant="primary" id="dropdown-basic">
        {titleText.length === 0 ? (
          <div>Select </div>
        ) : (
          <div>
            {titleText}
            {extraSelection !== 0 ? " ... +1" : ""}
          </div>
        )}
      </Dropdown.Toggle>
      <Dropdown.Menu
        as={CheckboxMenu}
        onSelectAll={handleSelectAll}
        onSelectNone={handleSelectNone}
      >
        <Form.Group className="dropdown-item mb-0">
          <Form.Check
            type="checkbox"
            label="All"
            checked={isAllChecked}
            disabled={durationList.filter((i) => i.checked).length === 3}
            onChange={(event) => {
              setIsAllChecked(event.target.checked);
              event.target.checked ? handleSelectAll() : handleSelectNone();
            }}
          />
          <div
            className="dropdown-divider w-100"
            style={{ borderTopColor: "#BED9FD", width: " 100% !important" }}
          ></div>
        </Form.Group>
        {durationList.map((i) => (
          <Dropdown.Item
            key={i.id}
            as={CheckDropdownItem}
            id={i.id}
            checked={i.checked}
            onChange={handleChecked}
            menuDisabled={
              i.checked && durationList.filter((i) => i.checked).length === 1
            }
          >
            {i.label}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};
