import { React } from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const ScheduleCallSkeleton = () => {
  return (
    <SkeletonTheme color="#DEDEDE" highlightColor="#D2DDEE">
      <div className="details-container no-margin-bottom">
        <>
          <div className="friend-container">
            <div className="friend-profile-skeleton">
              <div className="friend-profile-skeleton-image-wrap">
                <Skeleton circle={true} height={100} width={100} />
              </div>
              <div className="friend-profile-skeleton-details">
                <p className="friend-profile-skeleton-title">
                  <Skeleton className="friend-skeleton-title" height={20} />
                </p>
                <p className="friend-profile-skeleton-subtitle-city subtitle">
                  <Skeleton className="friend-skeleton-city" height={20} />
                </p>
                <p className="friend-profile-skeleton-subtitle-spentTime subtitle">
                  <Skeleton className="friend-skeleton-time" height={20} />
                </p>
              </div>
            </div>
          </div>
        </>
        <div className="details-skeleton-container">
          <p className="skeleton-title">
            <Skeleton width={100} height={8} />
          </p>
          <p className="skeleton-description-container">
            <Skeleton className="skeleton-description" />
          </p>
          <div className="skeleton-schedule-details">
            <p className="skeleton-title-for">
              <Skeleton className="skeleton-duration" />
            </p>
            <p className="skeleton-title-for">
              <Skeleton className="skeleton-date" />
            </p>
            <p className="skeleton-title-for">
              <Skeleton className="skeleton-date" />
            </p>

            <div className="skeleton-button-container">
              <p className="skeleton-call-join-button">
                <Skeleton className="skeleton-join-button" />
              </p>
              <p className="skeleton-call-join-button">
                <Skeleton className="skeleton-join-button" />
              </p>
            </div>
          </div>
        </div>
      </div>
    </SkeletonTheme>
  );
};

export default ScheduleCallSkeleton;
