import queryString from "query-string";
import { useEffect, useState } from "react";
import { Collapse, Container } from "react-bootstrap";
import {
  BellFill,
  CreditCardFill,
  GearFill,
  PersonFill,
  TelephoneFill,
} from "react-bootstrap-icons";
import EditProfile from "../../../components/edit-profile/Edit-Profile";
import Notifications from "../../../components/notifications/Notifications";
import Payment from "../../../components/payment/Payment";
import ProfileSettings from "../../../components/profile-settings/Profile-Settings";
import {
  localStorageScheduleCall,
  panelState,
  schedulerStatus,
} from "../../../constants/scheduleConstants";
import { getCurrentUser } from "../../../util/APIUtils";
import ScheduleCall from "../../schedule-call/Schedule-call";
import "./Profile.scss";

const Profile = (props) => {
  const {
    path,
    allowRedirect,
    currentUser,
    reloadCurrentUser,
    setAllowRedirect,
  } = props;
  const [rightPanel, setRightPanel] = useState({
    profile: false,
    notifications: false,
    payment: false,
    settings: false,
    scheduledCalls: false,
  });
  const [showLeftPanel, setShowLeftPanel] = useState(true);
  const [mQuery, setMQuery] = useState(window.matchMedia("(max-width: 992px)"));
  const [isFriend, setIsFriend] = useState(false);
  const scheduleDetails = queryString?.parse(props.location.search);
  useEffect(() => {
    if (window.location.search) {
      if (currentUser?.id && !isFriend) {
        checkStatesList()
          ? handleRightPanelButton(panelState.SCHEDULE_CALL)
          : (window.location.href = `/friends/${currentUser?.id}`);
      } else {
        checkStatesList() && handleRightPanelButton(panelState.SCHEDULE_CALL);
      }
    }
  }, []);

  const checkStatesList = () => {
    return (
      scheduleDetails?.status &&
      Object.values(schedulerStatus).includes(scheduleDetails?.status)
    );
  };

  const hideLeftPanelMobile = () => {
    if (mQuery.matches) {
      setShowLeftPanel(false);
    }
  };

  useEffect(() => {
    getCurrentUser().then((response) => {
      setIsFriend(response.isFriend);
    }).catch(console.warn);
  }, []);

  const handleRightPanel = (rightPanelState) => {
    if (rightPanelState) {
      switch (rightPanelState) {
        case panelState.PROFILE:
          setRightPanel({
            ...rightPanel,
            profile: true,
          });
          break;
        case panelState.SCHEDULE_CALL:
          setRightPanel({
            ...rightPanel,
            scheduledCalls: true,
          });
          break;
        case panelState.SETTINGS:
          setRightPanel({
            ...rightPanel,
            settings: true,
          });
          break;
        default:
          break;
      }
    }
  };

  useEffect(() => {
    const rightPanelState = localStorage.getItem(
      localStorageScheduleCall.rightPanelState
    );
    handleRightPanel(rightPanelState);
  }, [path]);

  const handleRightPanelButton = (panelStateValue) => {
    let panelConfig = {
      profile: false,
      notifications: false,
      payment: false,
      settings: false,
      scheduledCalls: false,
    };
    if (panelStateValue) {
      switch (panelStateValue) {
        case panelState.PROFILE:
          panelConfig.profile = true;
          break;
        case panelState.SETTINGS:
          panelConfig.settings = true;
          break;
        case panelState.SCHEDULE_CALL:
          panelConfig.scheduledCalls = true;
          break;
        default:
          break;
      }
    }
    setRightPanel(panelConfig);
    localStorage.setItem(
      localStorageScheduleCall.rightPanelState,
      panelStateValue
    );
    hideLeftPanelMobile();
  };

  const handleNotificationButton = () => {
    setRightPanel({
      profile: false,
      notifications: true,
      payment: false,
      settings: false,
      scheduledCalls: false,
    });
    hideLeftPanelMobile();
  };

  const handlePaymentButton = () => {
    setRightPanel({
      profile: false,
      notifications: false,
      payment: true,
      settings: false,
      scheduledCalls: false,
    });
    hideLeftPanelMobile();
  };

  const getPanelName = (rightPanel) => {
    let panelName = "";
    panelName = Object.keys(rightPanel).filter((key) => rightPanel[key]);
    if (panelName[0] === "settings") {
      return "Availability";
    } else if (panelName[0] === "profile") {
      return "Edit account";
    } else if (panelName[0] === "scheduledCalls") {
      return "Scheduled calls";
    } else {
      return panelName[0];
    }
  };

  return (
    <div className="profile-main-container">
      <Container className="main">
        <div className="profile-main-container-wrapper">
          <div className="profile-left-panel ">
            <div
              className={`left-button-main-container${
                !showLeftPanel ? `-hide` : ``
              }`}
            >
              <Collapse in={showLeftPanel}>
                <div id="collapsible-top-panel">
                  <button
                    onClick={() => handleRightPanelButton(panelState.PROFILE)}
                    className="left-button-container"
                  >
                    <div className="left-button-container-icon">
                      {rightPanel.profile ? (
                        <PersonFill />
                      ) : (
                        <PersonFill color={"#7E888C"} />
                      )}
                    </div>
                    <div
                      className={
                        rightPanel.profile
                          ? `left-button-container-text-active`
                          : `left-button-container-text`
                      }
                    >
                      <p>Edit account</p>
                    </div>
                  </button>

                  <button
                    onClick={handleNotificationButton}
                    className="left-button-container d-none"
                  >
                    <div className="left-button-container-icon">
                      {rightPanel.notifications ? (
                        <BellFill />
                      ) : (
                        <BellFill color={"#7E888C"} />
                      )}
                    </div>
                    <div
                      className={
                        rightPanel.notifications
                          ? `left-button-container-text-active`
                          : `left-button-container-text`
                      }
                    >
                      <p>Notifications</p>
                    </div>
                  </button>

                  <button
                    onClick={handlePaymentButton}
                    className="left-button-container d-none"
                  >
                    <div className="left-button-container-icon">
                      {rightPanel.payment ? (
                        <CreditCardFill />
                      ) : (
                        <CreditCardFill color={"#7E888C"} />
                      )}
                    </div>
                    <div
                      className={
                        rightPanel.payment
                          ? `left-button-container-text-active`
                          : `left-button-container-text`
                      }
                    >
                      <p>Payment</p>
                    </div>
                  </button>

                  <button
                    onClick={() => {
                      if (
                        localStorage.getItem("isSave") !== "true" ||
                        localStorage.getItem("emptyField") === "true"
                      ) {
                        localStorage.setItem(
                          "isClickedScheduledCalls",
                          JSON.stringify(true)
                        );
                        props.setAllowRedirect(false);
                      } else {
                        props.setAllowRedirect(true);
                        handleRightPanelButton(panelState.SCHEDULE_CALL);
                      }
                    }}
                    className="left-button-container"
                  >
                    <div className="left-button-container-icon">
                      {rightPanel.scheduledCalls ? (
                        <TelephoneFill />
                      ) : (
                        <TelephoneFill color={"#7E888C"} />
                      )}
                    </div>
                    <div
                      className={
                        rightPanel.scheduledCalls
                          ? `left-button-container-text-active`
                          : `left-button-container-text`
                      }
                    >
                      <p>Scheduled calls</p>
                    </div>
                  </button>
                  {isFriend && (
                    <button
                      onClick={() => {
                        if (
                          localStorage.getItem("isSave") !== "true" ||
                          localStorage.getItem("emptyField") === "true"
                        ) {
                          localStorage.setItem(
                            "isClickedAvailability",
                            JSON.stringify(true)
                          );
                          props.setAllowRedirect(false);
                        } else {
                          props.setAllowRedirect(true);
                          handleRightPanelButton(panelState.SETTINGS);
                        }
                      }}
                      className="left-button-container"
                    >
                      <div className="left-button-container-icon">
                        {rightPanel.settings ? (
                          <GearFill />
                        ) : (
                          <GearFill color={"#7E888C"} />
                        )}
                      </div>
                      <div
                        className={
                          rightPanel.settings
                            ? `left-button-container-text-active`
                            : `left-button-container-text`
                        }
                      >
                        <p>Availability</p>
                      </div>
                    </button>
                  )}
                </div>
              </Collapse>
            </div>
            <div
              className="left-button-container left-button-container-text left-button-container-text-active"
              onClick={() => setShowLeftPanel(!showLeftPanel)}
            >
              <p className="left-button-container-text-content">
                {!showLeftPanel && getPanelName(rightPanel)}
              </p>
              {!showLeftPanel && (
                <div className="left-button-main-container-toggle">
                  <button
                    aria-controls="collapsible-top-panel"
                    aria-expanded={showLeftPanel}
                  >
                    <i
                      className="fa fa-angle-down fa-2x"
                      aria-hidden="true"
                    ></i>
                  </button>
                </div>
              )}
            </div>

            {mQuery.matches && (
              <div className="left-button-main-container-toggle-active">
                <button
                  onClick={() => setShowLeftPanel(!showLeftPanel)}
                  className={
                    showLeftPanel ? "open-animation" : "close-animation"
                  }
                  aria-controls="collapsible-top-panel"
                  aria-expanded={showLeftPanel}
                >
                  <i className="fa fa-angle-up fa-2x" aria-hidden="true"></i>
                </button>
              </div>
            )}
          </div>
          <div>
            {rightPanel.settings && (
              <p className="profile-main-container-setting-title">
                Schedule your available times
              </p>
            )}
          </div>
          <div className="profile-right-panel">
            {rightPanel.profile && (
              <EditProfile
                id={currentUser.id}
                path={path}
                reloadCurrentUser={reloadCurrentUser}
                allowRedirect={allowRedirect}
                setAllowRedirect={setAllowRedirect}
              />
            )}
            {rightPanel.notifications && <Notifications />}
            {rightPanel.payment && <Payment />}
            {rightPanel.settings && <ProfileSettings />}
            {rightPanel.scheduledCalls && <ScheduleCall />}
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Profile;
