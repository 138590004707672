export const ScheduleCallData = {
  name: "Steve Rogers",
  imageUrl: "",
  city: "Sydney",
  years: "5 Years",
  status: true,
  minCost: "$3.33",
  messageToFriend: "",
  freeDuration: "",
  selectedTimeZone: "",
};

export const selectDuration = ["15 mins", "30 mins", "45 mins"];
