import { Button, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./Alert-Modal.scss";

const AlertModal = ({
  showModal,
  hideModal,
  confirmModal,
  id,
  type,
  message,
  process,
  link
}) => {

  const handleRedirect = (e) => {
    e.preventDefault();
    window.location.href = link;
  }

  return (
    <Modal
      centered
      show={showModal}
      onHide={hideModal}
      backdrop="static"
      className="popup-leave-modal"
    >
      <Modal.Header
        className="modal-header-close-btn fw-bolder"
        closeButton
      ></Modal.Header>
      <Modal.Body className="popup-modal-body">
        <div>
          <div className="modal-body-content">
            Are you sure you want to leave this page?
          </div>
          <div className="modal-alert-message">
            You have {message} content,and it will be lost unless you save it.
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer className="popup-modal-footer pb-5 d-flex justify-content-center mb-3">
        <div className="popup-modal-footer-btn">
          <Link to="/" className="mr-4" onClick={handleRedirect}>
            {process}
          </Link>
        </div>
        <Button className="modal-go-back-btn mr-0" onClick={hideModal}>
          <div className="modal-go-back-btn-text pl-2 pr-2">{type}</div>
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AlertModal;
