import moment from "moment";
import { useEffect, useRef, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { schedulerStatus } from "../../constants/scheduleConstants";
import { fetchAllAppointments, getCurrentUser } from "../../util/APIUtils";
import { notificationManage } from "../../util/NotificationUtils";
import { convertDate } from "../../util/Utils";
import CalendarModal from "../calendar/CalendarModal";
import AvailabilitySelector from "../profile-settings/availability/Availability-Selector";
import "./Date-Overrides-Modal.scss";

const DateOverridesModal = (props) => {
  const {
    openDateOverrides,
    setOpenDateOverrides,
    availabilityDatesAndTimes,
    addNewAvailabilityTime,
    deleteAvailabilityTime,
    handleTimeChange,
    handleDayAvailability,
    handleApplyDateOverrides,
    userSchedularId,
    setIsOverrideDayAvailable,
    validateInputsOverrides,
    dateOverridesDatesAndTimes,
    setOverrideDates,
    overrideSelectedDates,
    setScheduledCallsArr,
    handleLeaveModal,
  } = props;
  const [isDateSelected, setIsDateSelected] = useState(false);
  const [friendSchedularId, setFriendSchedularId] = useState("");
  const [calendarDate, setCalendarDate] = useState();
  const [fullDayName, setFullDayName] = useState("");
  const [selectedDates, setSelectedDates] = useState([]);
  const scheduledCalls = useRef();
  const [isCallScheduled, setIsCallScheduled] = useState(false);
  const [friendTimeZone, setFriendTimeZone] = useState("");
  const filteredNewData = useRef();

  const onClose = () => {
    setIsDateSelected(false);
    setOpenDateOverrides(false);
    handleLeaveModal();
  };

  const getSchedules = async () => {
    if (overrideSelectedDates.length !== 0) {
      const scheduledCallsArr = [];
      const schedulerStatusList = [
        schedulerStatus.SCHEDULED,
        schedulerStatus.SUGGESTED,
        schedulerStatus.RESCHEDULED,
        schedulerStatus.APPROVED,
        schedulerStatus.CONFIRMED,
      ];

      try {
        for (const item of overrideSelectedDates) {
          const res = await fetchAllAppointments(
            userSchedularId,
            convertDate(item),
            moment().format("YYYY-MM-DDTHH:mm:ss"),
            true,
            0,
            10,
            "startDateTime",
            friendTimeZone,
            schedulerStatusList.join(",")
          );

          if (res?.content.length !== 0) {
            scheduledCallsArr.push(res);
          }
        }
        scheduledCalls.current = scheduledCallsArr;
        setScheduledCallsArr(scheduledCallsArr);
        setIsCallScheduled(true);
      } catch (error) {
        console.error(error);
        notificationManage(
          "danger",
          "Something Went Wrong. Could not fetch scheduled calls"
        );
      }
    }
  };

  useEffect(() => {
    setIsCallScheduled(false);
    isDateSelected && getSchedules();
  }, [overrideSelectedDates?.length, isDateSelected]);

  const closeForm = () => {
    setIsDateSelected(false);
    setOpenDateOverrides(false);
    handleLeaveModal();
  };

  const getDateOverrides = (dayStr) => {
    const dateOverrides =
      overrideSelectedDates?.length !== 0
        ? dateOverridesDatesAndTimes?.filter(
            (day) =>
              day?.date ===
              convertDate(
                overrideSelectedDates[overrideSelectedDates?.length - 1]
              )
          )
        : dateOverridesDatesAndTimes?.filter(
            (day) => day?.date === convertDate(calendarDate)
          );

    const overrides =
      availabilityDatesAndTimes &&
      availabilityDatesAndTimes?.filter((day) => day?.dayOfWeek === dayStr);
    return dateOverrides?.length === 0 ? overrides : dateOverrides;
  };

  useEffect(() => {
    if (calendarDate) {
      setIsDateSelected(true);
      setFullDayName(
        moment(overrideSelectedDates[overrideSelectedDates?.length - 1])
          .format("dddd")
          .toLocaleUpperCase()
      );
    } else {
      setIsDateSelected(false);
    }
  }, [calendarDate, overrideSelectedDates?.length]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getCurrentUser();
        setFriendSchedularId(response?.schedulerId);
        setFriendTimeZone(response?.timeZone);
      } catch (error) {
        notificationManage(
          "danger",
          "Something Went Wrong. Please try again by logging"
        );
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    filteredNewData.current = getDateOverrides(fullDayName);
  }, [fullDayName, availabilityDatesAndTimes, dateOverridesDatesAndTimes]);

  const handleApply = () => {
    if (fullDayName) {
      const overrides = getDateOverrides(fullDayName);
      handleApplyDateOverrides(
        filteredNewData?.current,
        calendarDate,
        overrides[overrides?.length - 1]?.type
      );
    }
  };

  return (
    <div>
      <Modal
        centered
        show={openDateOverrides}
        onHide={onClose}
        backdrop="static"
        className="date-overrides-modal"
      >
        <Modal.Header closeButton>
          <div className="modal-body-date-overrides">
            <p>Add date overrides</p>
          </div>
        </Modal.Header>
        <Modal.Body>
          <div>
            <div className="modal-body-date-overrides-middle-content pb-3 pt-3">
              <div className="modal-body-date-overrides-middle-content-left">
                <CalendarModal
                  scheduleDateNew={new Date()}
                  friendId={friendSchedularId}
                  setCalendarDate={setCalendarDate}
                  setOverrideDates={setOverrideDates}
                  isOverride={true}
                  setSelectedDates={setSelectedDates}
                />
              </div>
              <div className="modal-body-date-overrides-middle-content-right mt-2 p-1">
                {!isDateSelected || selectedDates?.length === 0 ? (
                  <div className="modal-body-date-overrides-middle-content-right-title w-10">
                    Select the date(s) you want to assign specific hours
                  </div>
                ) : (
                  <div className="modal-body-date-overrides-middle-content-right-selected">
                    <div className="modal-body-date-overrides-middle-content-right-selected-scheduled-calls">
                      <>
                        {isCallScheduled &&
                          scheduledCalls?.current?.length !== 0 && (
                            <>
                              <div className="modal-body-date-overrides-middle-content-right-selected-scheduled-calls-sched">
                                <div className="add-date-overrides-content-value">
                                  <div className="date-overrides-modal-text mb-3 mt-3 ml-4">
                                    You have appointments on these times
                                  </div>
                                  {scheduledCalls.current?.map(
                                    (item, index) => (
                                      <div
                                        className="add-date-overrides-content-value-grid row ml-2 align-items-center"
                                        key={index}
                                      >
                                        <div className="col-4 flex-1">
                                          {
                                            item?.content[0]?.startDateTime?.split(
                                              "T"
                                            )[0]
                                          }
                                        </div>
                                        <div className="overrides-modal-calendar-content-time-slots-times w-auto mr-7">
                                          {item?.content
                                            ?.filter(
                                              (item) =>
                                                item?.status !==
                                                  schedulerStatus.CANCELLED &&
                                                item?.status !==
                                                  schedulerStatus.DECLINED
                                            )
                                            ?.map((call) => (
                                              <button
                                                className="btn time-slot-table-cell justify-content-center align-items-center text-align-center m-1 mr-3"
                                                key={call?.id}
                                              >
                                                <div>
                                                  {call?.startDateTime
                                                    ?.split("T")[1]
                                                    ?.slice(0, 5)}{" "}
                                                  -{" "}
                                                  {call?.endDateTime
                                                    ?.split("T")[1]
                                                    ?.slice(0, 5)}
                                                </div>
                                              </button>
                                            ))}
                                        </div>
                                      </div>
                                    )
                                  )}
                                </div>
                              </div>
                              <div
                                className="dropdown-divider w-100 mt-4 pt-2"
                                style={{
                                  borderTopColor: "#BED9FD",
                                  width: " 100% !important",
                                }}
                              ></div>
                            </>
                          )}
                      </>
                    </div>
                    <div className="modal-body-date-overrides-middle-content-right-selected-title mt-2 mb-1 ml-4 pt-3">
                      What hours are you available?
                    </div>
                    <div className="modal-body-date-overrides-middle-content-right-selected-content">
                      {fullDayName &&
                        getDateOverrides(fullDayName)?.map((day, i) => (
                          <AvailabilitySelector
                            key={i}
                            wday={day}
                            availabilityDatesAndTimes={
                              availabilityDatesAndTimes
                            }
                            openDateOverrides={openDateOverrides}
                            addNewAvailabilityTime={addNewAvailabilityTime}
                            deleteAvailabilityTime={deleteAvailabilityTime}
                            handleTimeChange={handleTimeChange}
                            handleDayAvailability={handleDayAvailability}
                            isLimitReached={day?.segments?.length === 3}
                            isOverride={true}
                            validateInputsOverrides={validateInputsOverrides}
                            setIsOverrideDayAvailable={
                              setIsOverrideDayAvailable
                            }
                            isScheduledCallAvailable={
                              scheduledCalls.current?.[0]?.content?.length > 0
                            }
                          />
                        ))}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="pb-3 pt-3">
          <Button
            className="cancel-button"
            variant="outline-primary"
            onClick={closeForm}
          >
            Cancel
          </Button>
          <Button
            className="apply-button "
            variant="primary"
            disabled={!isDateSelected || selectedDates?.length === 0}
            onClick={handleApply}
          >
            Apply
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default DateOverridesModal;
