import React, { Component } from "react";
import { Link } from "react-router-dom";
import "./NotFound.scss";

class NotFound extends Component {
  render() {
    const { location } = this.props;
    let isServerError;
    location.pathname === "/server-error"
      ? (isServerError = true)
      : (isServerError = false);

    return (
      <div className="page-not-found d-flex justify-content-center align-items-center">
        <div className="page-not-found-container">
        <img
          src={
            isServerError
              ? "/assets/img/404-page/error.png"
              : "/assets/img/404-page/Frame.png"
          }
          alt="static-img"
          className={!isServerError ? "not-found-error" : "server-error"}
        />
        <h1 className="title">{isServerError ? 500 : 404}</h1>
        <div className="sub-title">
          {isServerError
            ? "Sorry, unexpected error"
            : "Oops! This Page Could Not Be Found"}
        </div>
        <div className="desc">
          {isServerError
            ? "We are working on fixing the problem. Be back soon"
            : "Sorry but the page you are looking for does not exist, has been removed, name changed or is temporarily unavailable"}
        </div>
        {!isServerError && (
          <Link to="/">
            <button className="go-back-btn btn btn-primary" type="button">
              Go Back
            </button>
          </Link>
        )}
      </div>
      </div>
    );
  }
}

export default NotFound;
