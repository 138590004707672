import React from "react";
import "./Privacy-Policy.scss";

function PrivacyPolicy() {
  const policyContent = [
    {
      id: "purpose",
      mainTitle: "Purpose of this Policy",
      description: [
        {
          topic:
            "The purpose of this Privacy Policy is to let you know what kind of information Move2NewCity and our Affiliates may gather about you when you visit this Site, inline with the Privacy Act 1998 (Cth) including the Australian Privacy Principles.",
        },
        {
          topic:
            "“Personal Information” is, broadly speaking, any information relating to a person, where that person is identified or could be identified.",
        },
        {
          topic:
            "Protecting your privacy is very important to Move2NewCity. We are committed to maintaining the security of Personal Information provided to us.",
        },
      ],
    },
    {
      id: "definitions",
      mainTitle: "Definitions",
      description: [
        {
          topic: "The following definitions apply in this Privacy Policy: ",
          content: [
            "“Registered” means your have signed up with Move2NewCity;",
            "“Members” refer to Users who establish are Registered with Move2NewCity, primarily “Newcomers” and “Friends;",
            "“Policy” means this Privacy Policy as amended from time to time;",
            "“Privacy Act” means the Privacy Act (Cth) 1988, as amended from time to time;",
            <span>
              “User Agreement” means the agreement between Move2NewCity and each
              User of the Site which sets out the terms and conditions in
              respect to the Site, and is located at {" "}
              <a
                className="custom-link"
                target="_blank"
                href="/terms-and-conditions">
                Move2NewCity.com.au/terms-of-use
              </a>
            </span>,
            "“Users” refer to Members and Visitors; ",
            "“Visitors” refer to individuals who visit and browse the Site but not Registered.",
          ],
        },
        {
          topic: (
            <span>
              Unless the context provides otherwise, capitalized terms not
              defined in paragraph 2.1 or elsewhere in this Policy have the
              meanings given in our User Agreement located at{" "}
              <a className="custom-link" href="/privacy-policy">
                Move2NewCity.com/privacy-policy
              </a>
            </span>
          ),
        },
      ],
    },
    {
      id: "personal-information-collection",
      mainTitle: "Who does Move2NewCity collect Personal Information from",
      description: [
        {
          topic: "We may collect Personal Information from:",
          content: [
            "Visitors when they visit our Site; or",
            "Visitors who are engaged through social media channels or Affiliate websites; or",
            "Members, which includes Newcomers and Friends, who are a part of the Move2NewCity solution.",
          ],
        },
      ],
    },
    {
      id: "user-consent",
      mainTitle: "User consent",
      data: "By submitting Personal Information through our Site or Affiliate Services (including when you register yourself), you agree to the terms of this Policy on your own behalf or on behalf of the person whose information you are submitting, and you expressly consent to the collection, use and disclosure of that Personal Information in accordance with this Policy.",
    },
    {
      id: "types-of-data-collect",
      mainTitle: "Types of data we collect",
      description: [
        {
          topic:
            "The information Move2NewCity collects about you depends on how and why you access our Site and use our Services.",
        },
        {
          topic:
            "We collect Personal Information and anonymous data, as described below.",
        },
      ],
      subTitles: [
        {
          id: "information-you-provide",
          title: "Information you provide to us",
          content: [
            "If you are a Newcomer, when you Register, we will collect Personal Information from you, such as your email address, first and last name, whether you are moving as a family or an individual, whether you are a student or a professional, areas of know-how you are after including not limited to accommodation, education, transportation etc; and credit card details through an Affiliate service for any paid services.",
            "If you are a Friend and you are willing to share your lived experiences and earn rewards, when you Register, we will collect Personal Information from you, such as your email address, first and last name, areas of know-how you are after including not limited to accommodation, education, transportation etc.",
            "If you are a Member other than those described in paragraphs 5.2.1.1 and 5.2.1.2 above, when you Register, we will collect Personal Information from you, similar to the information outlined in paragraphs 5.2.1.1 and 5.2.1.2 above.",
          ],
        },
        {
          id: "sensitive-information",
          title: "Sensitive Information",
          content: [
            "We may collect sensitive information (including lived experiences) from Members to provide better matching services between Newcomers and Friends. ",
          ],
        },
        {
          id: "other-ways-collect-information",
          title: "Other ways we may collect Personal Information",
          content: [
            "Upon Registration, we collect and retain information on your behalf.",
            "When you post messages or upload content to Move2NewCity, the information contained in your posting will be stored on our servers, and other Members to whom you provide access will be able to see such messages and content.",
            "We also collect other types of Personal Information that you provide to us voluntarily, such as your operating system and version, and other requested information.",
            "We may also collect Personal Information at such other points in our Site that state that Personal Information is being collected. Please note that you can choose not to provide us with certain information, but this may limit the features of Move2NewCity or Services you are able to use.",
          ],
        },
        {
          id: "information-via-technology",
          title: "Information collected via technology",
          content: [
            "To make Move2NewCity and Services more useful to you, our servers (which may be hosted by a third party service provider) collect information from you, including your browser type, operating system, Internet Protocol (IP) address, domain name, and/or a date/time stamp for your visit.",
            "Like many other website operators, we use cookies on our Site. Cookies are very small files which a website uses to identify you when you come back to the site, and which store details about your use of the site. Cookies are not malicious programs that access or damage your computer. We use cookies to improve the experience of Users and enable Members to access secure areas of our Site. You may choose to disable cookies by adjusting the privacy settings in your browser, but you may not be able to access some parts of our Site or Services if you do so.",
            <div>
              We may also use or enable:
              <ul>
                <li>
                  third party analytics services to track and analyse anonymous
                  data from Users of our Site and Services; and
                </li>
                <li>
                  location-enabled services for Members as a means to facilitate
                  better services based on the co-relationships of the requested
                  services.
                </li>
              </ul>
            </div>,
            "If, in future, you use the location-enabled services, Move2NewCity may receive information about your actual location (such as GPS signals sent by a mobile device or via your web browser when on the Site).",
          ],
        },
        {
          id: "invitation-emails",
          title: "Invitation emails",
          content: [
            "Members and Visitors of Move2NewCity can invite other people to become Move2NewCity Members by requesting us to send invitation emails to them.",
            "We store the email addresses that are provided to us and, if they are provided by a Move2NewCity Member, we will associate those email addresses with such Member’s account.",
            "We will only send one invitation and will not use the email address for any other communication unless the person becomes a member of Move2NewCity.",
            "We do not currently sell these email addresses or use them for any other purpose.",
            "Where you provide someone else’s email address to us for the purposes of sending them an invitation email, you must first obtain the person’s consent and ensure they agree to receive an invitation email from us.",
          ],
        },
        {
          id: "information-from-others",
          title: "Information collected from others about you",
          content: [
            "Other Members may provide us Personal Information about you through our Site or the Services. Examples include a Member providing your email address to send you an invitation to become a Member.",
          ],
        },
        {
          id: "information-from-third-parties",
          title: "Information collected from third parties",
          content: [
            "We may receive Personal Information and/or anonymous data about you from companies that assist Move2NewCity in providing the Services, such as service providers who assist with identifying you or verifying information provided by you (eg. Reference check to verify a lived experience), or companies that offer their products and/or services on our Site.",
          ],
        },
        {
          id: "unsolicited-information",
          title: "Unsolicited Information",
          content: [
            "Where we collect unsolicited information (being information provided to us without us having sought the information), we will only hold, use and disclose that information if we could otherwise do so had we collected it by the means described above. If that unsolicited information could not have been collected by the means described above, then we will destroy, permanently delete or de-identify the information as appropriate.",
          ],
        },
      ],
    },
    {
      id: "use-of-personal-information",
      mainTitle: "Use of Your Personal Information",
      subTitles: [
        {
          id: "general-use",
          title: "General use",
          content: [
            "to facilitate the creation of, and; to send you a welcome e-mail and to verify ownership of the e-mail address provided during Registration;",
            "to identify you as a Newcomer or a Friend in our system;",
            "to prompt you to set up your profile after you have Registered;",
            "to verify your credentials;",
            "to provide improved administration of our Site and Services;",
            "to provide the Services you request;",
            "to improve the quality of experience when you interact with our Site and Services;",
            "to tailor the features, performance and support of the Site or Services to you;",
            "to send you administrative e-mail notifications, such as security or support and maintenance advisories, or to inform you when our User Agreement or any of our policies (including this Policy) have been updated;",
            "to respond to your inquiries or requests and/or troubleshoot problems;",
            "to send newsletters, surveys, and other promotional materials related to our Services and for other Move2NewCity marketing purposes;",
            "to customize the advertising you may see on the Site or through the Services;",
            "to collect and/or distribute rewards; and",
            "to verify your compliance with your obligations in our User Agreement or other Move2NewCity policies.",
          ],
          text: "In general, we use the Personal Information we collect either to provide the Services to you, to respond to requests that you make, or to aid us in serving you better, including in the following ways:",
        },
        {
          id: "creation-of-Anonymous-Data",
          title: "Creation of Anonymous Data",
          text: "We may create anonymous data records from Personal Information by excluding information (such as your name and/or email) that makes the data personally identifiable to you. We use this anonymous data to analyse request and usage patterns so that we may enhance the content of our Services and improve Site navigation. Move2NewCity may use anonymous data for any purpose and may disclose anonymous data to third parties as it sees fit.",
        },
      ],
    },
    {
      id: "disclosure-of-personal-information",
      mainTitle: "Disclosure of Your Personal Information",
      description: [
        {
          topic:
            "We may disclose your Personal Information as described below and as described elsewhere in this Policy.",
        },
        {
          id: "disclosure-to-others",
          topic: "Disclosure to other Users",
          isSubtitle: true,
          content: [
            "We may share your Personal Information with our Affiliates to provide you the required services from Move2NewCity.",
            "We do not disclose the Personal Information of any Member to any other Member. We do, however, provide other Members with the opportunity to communicate with you through your Move2NewCity display name (identity/handle). This display name will be visible to all the users of Move2NewCity. It is entirely up to you, whether you want the actual first name, last name, or both to be a part of this display name.",
            "Members are to upload an avatar as a part of your profile. Again, it is entirely up to you whether you want to have your photograph or an alternative avatar. In either case, this will be visible to all the Users of Move2NewCity.",
          ],
        },
        {
          id: "external-service-providers",
          topic: "External service providers and other third parties",
          isSubtitle: true,
          content: [
            <div>
              We may share your Personal Information with our Affiliates to:
              <ul>
                <li>
                  provide you with the Services that we offer you through our
                  Site;
                </li>
                <li>conduct quality assurance testing;</li>
                <li>facilitate creation of accounts; and</li>
                <li>provide technical support.</li>
              </ul>
            </div>,
            "Examples of such third party service providers include companies that provide communication services and providers that and verify reference checks of your lived experiences, if you are a Friend, and companies that process payments from both Friends and Newcomers if you use paid services.",
            "We may also disclose your Personal Information to our external service providers and advisers (Affiliates) who have been engaged to provide us with legal, administrative, financial, accounting, auditing, insurance, research, marketing, business consulting, technology, support or other services.",
            <div>
              In addition, we may disclose your Personal Information to:
              <ul>
                <li>
                  other external service providers, consultants, or business
                  partners of ours (Affiliates) who may work with us to improve
                  or develop our Site and Services, operate or develop our
                  business, conduct surveys or seek feedback from you, conduct
                  market and other research, or facilitate or collaborate with
                  us in relation to promotions; and
                </li>
                <li>
                  without limiting paragraph 7.3.4.1 above, providers of
                  consumer review websites or platforms, to enable those
                  providers to send you an invitation to leave a review of our
                  Services.
                </li>
              </ul>
            </div>,
            "We take reasonable steps to ensure our Affiliates, these third party service providers, advisers, etc do not use your Personal Information other than for the purpose for which it was provided to them by Move2NewCity.",
          ],
        },
        {
          id: "direct-marketing",
          topic: "Direct Marketing",
          isSubtitle: true,
          content: [
            "As indicated above, Move2NewCity may use information collected from or about you for the purpose of sending you marketing and promotional information and materials.",
            "If you wish to opt out of receiving direct marketing communications from Move2NewCity, please use the “unsubscribe” option (in the case of e-mail communications) or contact us directly using the details below. If you wish to opt-out of receiving direct marketing communications from third party organizations to whom we have disclosed your Personal Information, you may make a separate request to that organization.",
          ],
        },
        {
          id: "marketing-on-third-party-sites",
          topic: "Marketing on third party sites",
          isSubtitle: true,
          content: [
            "We may disclose your Personal Information, such as your email address, to the operators of other websites, social media platforms and search engines, so that we may deliver tailored advertisements to you via those websites, platforms and search engines.",
          ],
        },
        {
          id: "affiliates-and-acquisitions",
          topic: "Affiliates and acquisitions",
          isSubtitle: true,
          content: [
            "We may share some or all of your Personal Information with our Affiliates, in which case we will require our Affiliates to honour this Policy unless they have their own privacy policy (in which case, they will act in accordance with their own policy).",
            "If another company acquires the shares in Move2NewCity, the business, or our assets, that company will possess the Personal Information collected by us and will assume the rights and obligations regarding your Personal Information as described in this Policy.",
          ],
        },
        {
          id: "personal-information-disclosures",
          topic: "Disclosure of Personal Information to overseas entities",
          isSubtitle: true,
          content: [
            "We may transfer or otherwise disclose your Personal Information to third parties located outside Australia, including to third parties who provide information technology and other services to us. Those third parties may be located in the United States or other countries. By providing Personal Information to us, you consent to the transfer of that Personal Information to entities located outside Australia.",
          ],
        },
        {
          id: "other-disclosures",
          topic: "Other disclosures",
          isSubtitle: true,
          content: [
            <div>
              Regardless of any choices you make regarding your Personal
              Information (as described in section 9 below), Move2NewCity may
              disclose Personal Information to third parties if it believes in
              good faith that such disclosure is necessary:
              <ul>
                <li>in connection with any legal investigation;</li>
                <li>
                  to comply with relevant laws, or to respond to subpoenas or
                  warrants served on us;
                </li>
                <li>
                  to lessen or prevent a serious threat to the life, health or
                  safety of an individual or to public safety;
                </li>
                <li>
                  to investigate or assist in preventing any violation or
                  potential violation of the law;
                </li>
                <li>
                  where another “permitted general situation” or “permitted
                  health situation” (as defined in the Privacy Act) applies;
                  and/or where disclosure is reasonably necessary for a law
                  enforcement related activity.
                </li>
              </ul>
            </div>,
          ],
        },
      ],
    },
    {
      id: "data-collection",
      mainTitle: "Data collection",
      subTitles: [
        {
          id: "data-collection-by-ad-networks",
          title: "Information collected by ad networks",
          text: "We may use third-party advertising companies to serve ads when you visit our Site. These companies may collect and use information (not including your name or email address) about your visits to the Site and other websites in order to provide advertisements about goods and services to you that are tailored to your interests. This information is collected using cookies. As indicated above, you can disable cookies by changing your browser settings, but if you do, some parts of our Site may not function properly.",
        },
        {
          id: "data-collection-by-third-parties",
          title: "Information collected by other third parties",
          text: "This Policy addresses only our use and disclosure of Personal Information we collect from and/or about you. If you disclose information to others, including to other Members, those persons or organizations will not be bound to act in accordance with this Policy. We do not control the privacy policies of third parties, and you are subject to the privacy policies of those third parties where applicable.",
        },
      ],
    },
    {
      id: "choices-regarding-personal-data",
      mainTitle: "Your choices regarding your personal data",
      description: [
        {
          topic:
            "Unless you become a Member, Move2NewCity does not collect information that identifies you personally, unless otherwise you have opted in to provide such information as a User, for instance during social media engagement activities.",
        },
      ],
      subTitles: [
        {
          id: "choices-about-marketing",
          title: "Choices about marketing",
          content: [
            "We offer you choices regarding the collection, use, and sharing of your Personal Information.",
            "We will periodically send you free newsletters and e-mails, or other marketing communications that directly promote the use of our Site or Services (unless you have previously requested not to receive such communications from us). As we indicated in section 7.4 above, when you receive newsletters or promotional communications from us, you may indicate a preference to stop receiving further communications from us and you will have the opportunity to “opt-out” by following the unsubscribe instructions provided in the e-mail you receive, or by contacting us directly (please see contact information below). Our electronic marketing activities will comply with the requirements of the Spam Act 2003 (Cth).",
            "Although you request not to receive direct marketing communications from us, we may send you Service related communications, including notices of any updates to our User Agreement or this Privacy Policy.",
          ],
        },
        {
          id: "deletion-personal-information",
          title: "Deletion of Personal Information",
          content: [
            "You may request deletion of your Personal Information by us, but please note that we may be required to keep this information and not delete it (or to keep this information for a certain time, in which case we will comply with your deletion request only after we have fulfilled such requirements).",
            "If we no longer need your Personal Information for any purpose for which it may be used or disclosed under the Privacy Act, and the information is not otherwise required to be kept under an Australian law or court order, we will take reasonable steps to destroy or permanently de-identify the information.",
            "When we delete any information, it will be deleted from the active database, but may remain in our archives. We may retain your information for fraud protection or similar purposes if this is permitted pursuant to an Australian law or court order.",
            "If we have already disclosed some of your Personal Information to third parties, we cannot force the deletion or modification of any such information by the parties to whom we have made those disclosures.",
          ],
        },
      ],
    },
    {
      id: "personal-information-security",
      mainTitle: "Security of your Personal Information",
      points: [
        "Move2NewCity is committed to protecting the security of your Personal Information. As a cloud native platform, we use a variety of industry-standard security technologies and procedures to help protect your Personal Information from unauthorized access, use, and disclosure.",
        "Any information which we hold about you is stored on secure servers that are protected in controlled facilities and accessing these details by you will always be authenticated.",
        "No method of transmission over the Internet, or method of electronic storage, is 100% secure, so while Move2NewCity uses reasonable efforts to protect your Personal Information, we cannot guarantee its absolute security.",
        "In addition, our employees and contractors who provide services related to our information systems are obliged to respect the confidentiality and privacy of any Personal Information held by Move2NewCity.",
      ],
    },
    {
      id: "personal-information-access",
      mainTitle: "Access to your Personal Information",
      points: [
        "You can request access to the Personal Information we hold about you by contacting us using the details below.",
        "We will generally allow you to access the Personal Information we hold about you if you request it, but we may refuse access if we are permitted or required by law to do so.",
        "We will process requests for access to Personal Information within a reasonable time, and we may charge you reasonable costs associated with providing access (for example, to cover the costs of verifying the request and retrieving the information).",
        "If we refuse to provide you with access to your Personal Information, we will generally provide reasons for the refusal.",
      ],
    },
    {
      id: "personal-information-correction",
      mainTitle: "Correction of your Personal Information",
      points: [
        "We take reasonable steps to ensure the Personal Information we hold about you is accurate, complete and up to date.",
        "Please let us know if you think any of the information we hold about you is incorrect, incomplete or out of date. You may also change or update your Personal Information by logging into your Move2NewCity and editing your Profile.",
      ],
    },
    {
      id: "complaints",
      mainTitle: "Complaints",
      points: [
        "If you have any complaints about our dealings with your Personal Information, including any breaches by us of any Australian Privacy Principles, you are able to submit that complaint by contacting us using the details below. You will need to provide sufficient details regarding your complaint as well as any supporting evidence and information.",
        "Complaints will be referred for investigation by our Privacy Officer and a response will be provided to you within a reasonable time (usually no longer than 30 days). We may seek further information from you in order to provide you with a full and complete response.",
        "If you are not satisfied with the outcome of our investigation, you can contact us to raise your concerns, or you may wish to contact the Office of the Australian Information Commissioner.",
      ],
    },
    {
      id: "privacy-policy-changes",
      mainTitle: "Changes to this Privacy Policy",
      points: [
        "We may update this Policy from time to time. We will post the updated Policy on our Site, so please check regularly for any updates. The updated Policy will take effect from the date of posting.",
        "Your continued use of our Site or Services, or provision of further Personal Information to us after this Policy has been updated, will constitute your acceptance of the amended Policy.",
      ],
    },
    {
      id: "contact-information",
      mainTitle: "Contact Information",
      points: [
        "Move2NewCity welcomes your comments or questions regarding the Site, our Services, or this Policy",
        <span>
          Please contact us using the following details.{" "}
          <a className="custom-link" href="mailto: privacy@move2newcity.com">
            Email: privacy@move2newcity.com
          </a>
        </span>,
        "Privacy Policy last updated on 26 January 2022",
      ],
    },
  ];

  return (
    <div className="privacy-policy">
      <div className="privacy-policy-main-header"> Privacy Policy</div>
      <div className="privacy-policy-last-updated">
        <p>Last Updated: </p> 26 January 2022
      </div>
      <div className="privacy-policy-content-header">INTRODUCTION</div>
      <svg className="privacy-policy-svg-line-ta">
        <rect className="rect" fill="#EE4D67" />
      </svg>
      <div className="privacy-policy-introduction-content">
        Welcome to Move2NewCity! The privacy of the users who visit and use
        services provided by Move2NewCity are taken very seriously, and
        Move2NewCity is committed to protecting the personal information
        provided by the website users. Please read the following privacy policy
        to learn more about how Move2NewCity uses and protects your personal
        data and information.
      </div>
      <div className="privacy-policy-content-header">TABLE OF CONTENTS</div>
      <svg className="privacy-policy-svg-line-ta">
        <rect className="rect" fill="#EE4D67" />
      </svg>
      <div className="privacy-policy-content-list">
        {policyContent.map((titleItem) => (
          <div className="privacy-policy-content-list-information">
            <div className="information-title">
              <div>
                <svg className="title-svg">
                  <rect className="rect" fill="#EE4D67" />
                </svg>
              </div>
              <div className="information-title-text">
                <a href={`#${titleItem.id}`}> {titleItem.mainTitle}</a>
              </div>
            </div>
            {titleItem.subTitles &&
              titleItem.subTitles.map((subTitle) => (
                <div className="information-subtitle">
                  <div>
                    <svg className="subtitle-svg">
                      <rect className="rect" fill="#EE4D67" />
                    </svg>
                  </div>
                  <div className="information-subtitle-text">
                    <a href={`#${subTitle.id}`}>{subTitle.title}</a>
                  </div>
                </div>
              ))}
            {titleItem.description &&
              titleItem.description
                .filter((desc) => desc.isSubtitle === true)
                .map((desc) => (
                  <div className="information-subtitle">
                    <div>
                      <svg className="subtitle-svg">
                        <rect className="rect" fill="#EE4D67" />
                      </svg>
                    </div>
                    <div className="information-subtitle-text">
                      <a href={`#${desc.id}`}>{desc.topic}</a>
                    </div>
                  </div>
                ))}
          </div>
        ))}
      </div>
      <div className="privacy-policy-content-body">
        <ol className="privacy-policy-content-body-list">
          {policyContent.map((item, key) => (
            <div className="privacy-policy-content-body-item">
              <div className="main-title">
                <div className="privacy-policy-content-header">
                  <li id={item.id}>{item.mainTitle}</li>
                </div>
                <svg className="privacy-policy-svg-line-ta">
                  <rect className="rect" fill="#EE4D67" />
                </svg>
              </div>
              {item.description && (
                <div className="privacy-policy-content-body-item-topic">
                  {item.description.map((desc, descKey) => (
                    <div className="privacy-policy-content-body-item-topic-element">
                      <div
                        id={desc.id}
                        className={`${
                          desc.isSubtitle ? "subtitle" : "ordered-list"
                        }`}>
                        <span className="ordered-list-number">
                          {key + 1} {"."} {descKey + 1}
                        </span>
                        <span className="ordered-list-text"> {desc.topic}</span>
                      </div>
                      {desc.content && (
                        <ul className="privacy-policy-content-body-item-topic-list">
                          {desc.content.map((content, contentKey) => (
                            <li
                              className="privacy-policy-content-body-item-topic-list-element pt-1"
                              key={contentKey}>
                              {content}
                            </li>
                          ))}
                        </ul>
                      )}
                    </div>
                  ))}
                </div>
              )}
              {item.data && (
                <div className="privacy-policy-content-body-item-data">
                  {item.data}
                </div>
              )}
              {item.subTitles && (
                <div className="privacy-policy-content-body-item-subtitles">
                  {item.subTitles.map((subTitle, subTitleKey) => (
                    <div
                      id={subTitle.id}
                      className="privacy-policy-content-body-item-subtitles-main">
                      <ul>
                        <div
                          key={subTitleKey}
                          className="privacy-policy-content-body-item-subtitles-main-title">
                          <li> {subTitle.title}</li>
                        </div>
                        {subTitle.text && (
                          <div className="privacy-policy-content-body-item-subtitles-main-text">
                            {subTitle.text}
                          </div>
                        )}
                        {subTitle.content && (
                          <div className="privacy-policy-content-body-item-subtitles-main-content">
                            <ul>
                              {subTitle.content.map((cont, contKey) => (
                                <li
                                  className="privacy-policy-content-body-item-subtitles-main-content-list-element"
                                  key={contKey}>
                                  {cont}
                                </li>
                              ))}
                            </ul>
                          </div>
                        )}
                      </ul>
                    </div>
                  ))}
                </div>
              )}
              {item.points && (
                <div className="privacy-policy-content-body-item-points">
                  <ul className="privacy-policy-content-body-item-points-list">
                    {item.points.map((point, key) => (
                      <li
                        key={key}
                        className="privacy-policy-content-body-item-points-list-item">
                        {point}
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
          ))}
        </ol>
      </div>
    </div>
  );
}

export default PrivacyPolicy;
