import React, { useEffect, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import NotificationContent from "../footer-notification/NotificationContent";
import FooterNotificationEmpty from "../footer-notification/FooterNotificationEmpty";
import "./Popup-Notification.scss";
import { utilSortDate } from "../../util/UtilSort";
import { getNotifications } from "../../util/APIUtils";
import { useInView } from "react-intersection-observer";
import { notificationDisplayValueReSetter } from "../../util/APIUtils";
import { Nav } from "react-bootstrap";

const PopupNotification = (props) => {
  const {
    isShow,
    notifications,
    handlePopupNotification,
    userId,
    setNotifications,
    notificationPageCount,
    notificationPageCountPrevious,
    setNotificationTab,
    isReadAll
  } = props;

  const notificationPageCountRef = useRef(notificationPageCount);
  const notificationPageCountPreviousRef = useRef(
    notificationPageCountPrevious
  );
  const [showModal, setShowModal] = useState(isShow);
  const [notSeenNotifications, setNotSeenNotifications] = useState([]);
  const { ref, inView, entry } = useInView({
    threshold: 0,
  });

  useEffect(() => {
    setNotSeenNotifications(
      notifications.filter((value) => {
        return !value.seenBy.includes(props?.currentUser?.id);
      })
    );
  }, [notifications]);

  useEffect(() => {
    if (inView) {
      notificationPageCountPreviousRef.current === -1
        ? (notificationPageCountPreviousRef.current =
            notificationPageCountPreviousRef.current + 2)
        : notificationPageCountPreviousRef.current++;
      getNotifications(
        userId,
        parseInt(++notificationPageCountRef.current)
      ).then((notifications) => {
        setNotifications((prev) => {
          const notificationArr = [...prev, ...notifications];
          return utilSortDate(notificationArr);
        });
      });
    }
  }, [inView]);

  return (
    <div className="popup-notification">
      <Modal show={showModal} className="popup-notification-modal">
        <Modal.Header>
          <i
            onClick={() => {
              handlePopupNotification();
            }}
            className="fa fa-chevron-left"
            aria-hidden="true"></i>
          <Modal.Title>
            <div className="popup-notification-text"> Notifications </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="popup-notification-modal-body">
          {notifications?.length ? (
            notifications?.map((ntf, key) => (
              <div ref={notifications.length / 2 === key ? ref : undefined}>
                <NotificationContent
                  notificationsType={ntf?.type}
                  notificationPostStatus={ntf?.postStatus}
                  notificationsId={ntf?.id}
                  notificationsSeenBy={ntf?.seenBy}
                  userId={userId}
                  ownerId={ntf?.ownerId}
                  createdById={ntf?.createdBy?.id}
                  notificationsCreatedBy={ntf?.createdBy?.name}
                  notificationsTitle={ntf?.title}
                  declineMessage={ntf?.declineMessage}
                  notificationsTags={ntf?.tags}
                  userRole={props?.currentUser?.role}
                  notificationsCreatedAt={ntf?.createdAt}
                  notificationsUpVote={ntf?.upVote}
                  notificationsUpdatedAt={ntf?.updatedAt}
                  notificationsPostId={ntf?.postId}
                  notificationsCityId={ntf?.cityId}
                  notificationsInteractionDetail={
                    ntf?.interactionDetail
                  }
                  setShowModal={setShowModal}
                  setNotifications={setNotifications}
                  setNotificationTab={setNotificationTab}
                  isReadAll={isReadAll}
                  appointmentId={ntf?.appointmentId}
                  id={ntf?.id}
                />
              </div>
            ))
          ) : (
            <FooterNotificationEmpty />
          )}
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default PopupNotification;
