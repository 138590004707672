import React from "react";
import "./Text-image-grid.scss";

const TextImageGrid = (props) => {
  return (
    <div>
      {props.content.map((row) => (
        <div className={`text-image-grid ${row.cssClass}`}>
          <div>
            <div className="text-image-grid-title">{row.title}</div>
            <div className="text-image-grid-line"></div>
            <div className="text-image-grid-description">{row.description}</div>
          </div>
          <div>
            <img
              src={row.image}
              alt="image"
              className="text-image-grid-image"
            />
          </div>
        </div>
      ))}
    </div>
  );
};

export default TextImageGrid;
