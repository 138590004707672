import React, { useState, useEffect } from "react";
import { addUser, editUser } from "../../util/APIUtils";
import { notificationManage } from "../../util/NotificationUtils";
import { handleEmailValidation } from "../../util/Utils";
import { emailRegex } from "../../util/Regex";
import Modal from "react-bootstrap/Modal";
import UploadFiles from "../drag-and-drop-upload/Upload-File";
import { PencilFill } from "react-bootstrap-icons";
import "./Users-Form.scss";
import { API_BASE_URL } from "../../constants";
import { Spinner } from "react-bootstrap";
const UsersForm = (props) => {
  const initialFormState = {
    user: {
      name: "",
      email: "",
      password: "",
      isActive: "",
      role: "",
      image: "",
    },
    errors: {
      name: null,
      email: null,
      password: null,
      isActive: null,
      role: null,
      image: null,
    },
  };
  const [profilePhoto, setProfilePhoto] = useState("");
  const [user, setUser] = useState(initialFormState.user);
  const [formValue, setFormValue] = useState({
    name: "",
    email: "",
    password: "",
    isActive: null,
    role: "",
    image: "",
  });
  const [errors, setErrors] = useState(initialFormState.errors);
  const [isUpload, setIsUpload] = useState(false);
  const [isDataLoad, setIsDataLoad] = useState(true);
  const { openAddUser, setOpenAddUser, isAdmin, editUserInfo, loadUserList } =
    props;

  const handleOnChangeInput = (key, value) => {
    setFormValue((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const activeDropDownFilter = [
    {
      id: 0,
      name: "Inactive",
      value: "false",
    },
    {
      id: 1,
      name: "Active",
      value: "true",
    },
  ];

  const adminTypeDropDownFilter = [
    {
      id: 0,
      name: "ROLE_ADMIN",
      value: "Admin",
    },
    {
      id: 1,
      name: "ROLE_SUPER_ADMIN",
      value: "Super admin",
    },
  ];

  const getUserStatus = (isActive) => {
    if (isActive === true) {
      return "Active";
    }
    return "In-active";
  };
  const getUserRole = (role) => {
    if (role === "ROLE_ADMIN") {
      return "Admin";
    }
    return "Super admin";
  };

  const handleAddFormValidation = () => {
    setErrors(initialFormState.user);
    let emptyFields = [];
    ["name", "email", "password", "isActive", "role"].forEach((item) => {
      if (!formValue[item] || formValue[item].length === 0) {
        emptyFields.push(item);
        switch (item) {
          case "name":
            setErrors((previous) => ({
              ...previous,
              name: formValue[item].length === 0 && "Name is required",
            }));
            break;
          case "email":
            setErrors((previous) => ({
              ...previous,
              email:
                formValue[item].length === 0
                  ? "Email is required"
                  : !emailRegex.test(formValue[item]) && "Invalid Email",
            }));
            break;
          case "password":
            setErrors((previous) => ({
              ...previous,
              password: formValue[item].length === 0 && "Password is required",
            }));
            break;
          case "isActive":
            setErrors((previous) => ({
              ...previous,
              isActive:
                formValue[item].length === 0 &&
                "Pick if admin is active or not",
            }));
            break;
          case "role":
            setErrors((previous) => ({
              ...previous,
              role:
                formValue[item].length === 0 &&
                "Pick what type of admin the user is",
            }));
            break;
          default:
            break;
        }
      }
    });

    if (emptyFields.length !== 0) {
      return false;
    } else {
      return true;
    }
  };

  const handleEditFormValidation = () => {
    setErrors(initialFormState.user);
    let emptyFields = [];
    ["name", "email", "role"].forEach((item) => {
      if (!formValue[item] || formValue[item].length === 0) {
        emptyFields.push(item);
        switch (item) {
          case "name":
            setErrors((previous) => ({
              ...previous,
              name: formValue[item].length === 0 && "Name is required",
            }));
            break;
          case "email":
            setErrors((previous) => ({
              ...previous,
              email:
                formValue[item].length === 0
                  ? "Email is required"
                  : !emailRegex.test(formValue[item]) && "Invalid Email",
            }));
            break;
          case "role":
            setErrors((previous) => ({
              ...previous,
              role:
                formValue[item].length === 0 &&
                "Pick what type of admin the user is",
            }));
            break;
          default:
            break;
        }
      }
    });

    if (emptyFields.length !== 0) {
      return false;
    } else {
      return true;
    }
  };

  const handleAdd = () => {
    const formData = new FormData();
    formValue.name !== user.name && formData.append("name", formValue.name);
    formValue.password !== user.password &&
      formData.append("password", formValue.password);
    formValue.isActive !== user.isActive &&
      formData.append("isActive", formValue.isActive);
    formValue.role !== user.role && formData.append("role", formValue.role);
    profilePhoto !== "" &&
      (() => {
        formData.append("image", profilePhoto[0]);
        setIsUpload(true);
      })();

    const editEmail = formValue.email !== "" ? formValue.email : user.email;
    editEmail !== user.email
      ? handleEmailValidation(editEmail)
        ? (() => {
            formData.append("email", editEmail);
            saveUser(formData);
          })()
        : notificationManage("danger", "Email Not Valid")
      : saveUser(formData);
  };

  const saveUser = (formData) => {
    if (handleAddFormValidation()) {
      addUser(formData)
        .then(
          notificationManage("success", "User added added"),
          setOpenAddUser(false),
          setIsUpload(false)
        )
        .catch((err) => {
          err?.then((resp) => {
            notificationManage(
              "danger",
              resp.message !== "No message available"
                ? resp.message
                : "Admin creation failed"
            );
          });
        })
        .finally(() => {
          loadUserList();
          setProfilePhoto("");
        });
    }
  };

  const handleEdit = () => {
    const formDataEdit = new FormData();
    formValue.name !== user.name && formDataEdit.append("name", formValue.name);
    formValue.isActive !== user.isActive &&
      formDataEdit.append("isActive", formValue.isActive);
    formValue.role !== user.role && formDataEdit.append("role", formValue.role);

    profilePhoto !== "" &&
      (() => {
        formDataEdit.append("image", profilePhoto[0]);
      })();

    const editEmail = formValue.email !== "" ? formValue.email : user.email;
    editEmail !== user.email
      ? handleEmailValidation(editEmail)
        ? (() => {
            formDataEdit.append("email", editEmail);
            saveEditedUser(formDataEdit);
          })()
        : notificationManage("danger", "Email Not Valid")
      : saveEditedUser(formDataEdit);
  };
  const saveEditedUser = (formDataEdit) => {
    if (handleEditFormValidation()) {
      editUser(user.id, formDataEdit)
        .then(
          notificationManage("success", "User updated"),
          setOpenAddUser(false),
          setIsUpload(false)
        )
        .catch((err) => {
          err?.then((resp) => {
            notificationManage(
              "danger",
              resp.message !== "No message available"
                ? resp.message
                : "Admin update failed"
            );
          });
        })
        .finally(() => {
          setErrors(initialFormState.user);
          setUser(initialFormState);
          loadUserList();
        });
    }
  };

  const handleDeleteClick = () => {
    setProfilePhoto(null);
    user.image = "";
    user.imageUrl = "";
  };

  const onClose = () => {
    setErrors(initialFormState.user);
    setOpenAddUser(false);
    setUser(initialFormState);
    setProfilePhoto("");
    loadUserList();
  };

  const clearData = () => {
    setErrors(initialFormState.user);
    setUser(initialFormState);
    setProfilePhoto("");
    loadUserList();
  };

  useEffect(() => {
    setIsDataLoad(true);
    setTimeout(() => {
      setIsDataLoad(false);
    }, 1000);
  }, [user.image, user.imageUrl]);

  useEffect(() => {
    if (!isAdmin) {
      setUser(editUserInfo);
    }
  }, [isAdmin, editUserInfo]);

  useEffect(() => {
    if (user.length !== 0) {
      setFormValue((prevState) => ({
        ...prevState,
        name: user.name ?? "",
        email: user.email ?? "",
        isActive: user.isActive ?? "",
        role: user.role ?? "",
      }));
    }
  }, [user]);

  return (
    <Modal
      show={openAddUser}
      onHide={onClose}
      backdrop="static"
      className="add-New-Users-modal"
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body className="Modal-body-form">
        <Modal.Title>{isAdmin ? "Add new admin" : "Edit user"}</Modal.Title>
        {isDataLoad ? (
          <div className="loading-spinner">
            <Spinner animation="border" variant="primary" />
          </div>
        ) : (
          <form
            className="addUser-modal-form"
            onSubmit={(e) => e.preventDefault()}
          >
            <div className="addUser-modal-form-modal">
              {profilePhoto?.length || user.imageUrl || user.image ? (
                <div className="addUser-modal-form-modal">
                  <div className="addUser-modal-form-modal-image">
                    <img
                      height="200"
                      src={
                        profilePhoto?.length
                          ? profilePhoto[0].preview
                          : user.image !== null
                          ? `${API_BASE_URL}/v1/users/${user.id}/image`
                          : user.imageUrl
                      }
                      alt="user img"
                    />
                    {isDataLoad ? (
                      <div className="loading-spinner">
                        <Spinner animation="border" variant="primary" />
                      </div>
                    ) : (
                      <div className="addUser-modal-form-modal-image-wrapper pt-24">
                        <PencilFill
                          fill="#FFFFFF"
                          size={20}
                          className="addUser-modal-form-modal-image-wrapper-edit-icon"
                          onClick={() => handleDeleteClick()}
                        ></PencilFill>
                      </div>
                    )}
                  </div>
                </div>
              ) : (
                <UploadFiles
                  className="users-drag-image"
                  type="users image"
                  setImageFile={setProfilePhoto}
                />
              )}
            </div>
            <div className="pl-4">
              <label className="col-12 users-label">Name *</label>
              <input
                className="col-12 p-2 users-input-fields"
                type="text"
                name="name"
                defaultValue={user.name}
                onChange={(name) => {
                  handleOnChangeInput("name", name.target.value);
                }}
              />
              <div className="ml-2 users-error-message">
                {errors.name !== null ? errors.name : undefined}
              </div>
            </div>
            <div className=" pl-4">
              <label className="col-12 users-label">Email address *</label>
              <input
                className="col-12 p-2 users-input-fields"
                type="email"
                name="email"
                defaultValue={user.email}
                onChange={(email) => {
                  handleOnChangeInput("email", email.target.value);
                }}
              />
              <div className="ml-2 users-error-message">
                {errors.email !== null ? errors.email : undefined}
              </div>
            </div>
            {isAdmin ? (
              <div className="pl-4">
                <label className="col-12 users-label">Password *</label>
                <input
                  className="col-12 p-2 users-input-fields"
                  type="password"
                  name="password"
                  onChange={(password) => {
                    handleOnChangeInput("password", password.target.value);
                  }}
                />
                <div className="ml-2 users-error-message">
                  {errors.password !== null ? errors.password : undefined}
                </div>
              </div>
            ) : (
              ""
            )}
            <div className=" pl-4">
              <label className="col-12 users-label">Status *</label>
              <div className="dropdown-custom-modal">
                <div className="edit-friends-profile-custom-icon"></div>
                <select
                  type="user"
                  defaultValue={getUserStatus(user.isActive)}
                  className="form-control form-control-edit"
                  onClick={(status) => {
                    status.target.value === "Active"
                      ? handleOnChangeInput("isActive", true)
                      : handleOnChangeInput("isActive", false);
                  }}
                >
                  {activeDropDownFilter.map((status) => {
                    return <option selected={`selected`}>{status.name}</option>;
                  })}
                </select>
              </div>
              <div className="ml-2 users-error-message">
                {errors.isActive !== null ? errors.isActive : undefined}
              </div>
            </div>
            <div className=" pl-4 mb-5">
              <label className="col-12 users-label"> Admin type * </label>
              <div className="dropdown-custom-modal">
                <div className="edit-friends-profile-custom-icon"></div>
                <select
                  type="user"
                  defaultValue={!isAdmin ? getUserRole(user.role) : null}
                  className="form-control form-control-edit"
                  onClick={(adminType) => {
                    adminType.target.value === "Admin"
                      ? handleOnChangeInput("role", "ROLE_ADMIN")
                      : handleOnChangeInput("role", "ROLE_SUPER_ADMIN");
                  }}
                >
                  <option value={null} selected={`selected`} disabled>
                    --Select option--
                  </option>
                  {adminTypeDropDownFilter.map((adminType) => {
                    return (
                      <option
                        selected={
                          !adminType.value === getUserRole(user.role) &&
                          `selected`
                        }
                      >
                        {adminType.value}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div className="ml-2 users-error-message">
                {errors.role !== null ? errors.role : undefined}
              </div>
            </div>
            <div className="mb-2 offset-1 users-footer">
              {isAdmin ? (
                <button
                  onClick={() => {
                    handleAdd();
                  }}
                  className="btn btn-primary users-form-button-add"
                >
                  Save
                </button>
              ) : (
                <button
                  onClick={() => {
                    handleEdit();
                  }}
                  className="ml-4 btn btn-primary users-form-button-add"
                >
                  Update
                </button>
              )}
              <button
                onClick={onClose}
                className=" btn btn-outline-primary users-form-button"
              >
                Cancel
              </button>
            </div>
          </form>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default UsersForm;
