import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { getInquiryById, replyToInquiry } from "../../util/APIUtils";
import { notificationManage } from "../../util/NotificationUtils";
import { Spinner } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import "./Reply-Inquiry-Modal.scss";


function ReplyInquiryModal(props) {
  const { openReplyInquiry, setOpenReplyInquiry, inquiryId, currentUserId,reRenderList } = props;
  const [inquiry, setInquiry] = useState("");
  const [isInquiriesLoad, setIsInquiriesLoad] = useState(true);
  const [replyWritten, setReplyWritten] = useState(null);
 
  const onClose = () => {
    setOpenReplyInquiry(false);
  };

  const handleInputChange = (value) => {
    setReplyWritten(value);
   };

  const loadInquiry = () => {
    getInquiryById(inquiryId)
      .then((res) => {
        setInquiry(res);
      })
      .catch((err) => {
        err?.then((resp) => {
          notificationManage("danger", `Inquiries list retrieval failed`);
        });
      });
  };

  const clearData = () => {
    setInquiry("");
  };

  const closeForm = () =>{
    setInquiry("");
    setOpenReplyInquiry(false);
  }

  const handleReply = () => {
    const replyDataSet = {
      reply: replyWritten,
      repliedBy: currentUserId,
    };
 
    replyToInquiry (inquiryId,replyDataSet)
      .then(() => {
        notificationManage("success", "Reply sent");
        setReplyWritten("");
       
      })
      .catch((err) => {
        notificationManage("danger", `reply wasn't sent`);
      })
      .finally(() => {
        reRenderList();
        setOpenReplyInquiry(false)
      });
  };

  useEffect(() => {
    loadInquiry();
  }, [openReplyInquiry]);

  useEffect(() => {
    setIsInquiriesLoad(true);
    setTimeout(() => {
      setIsInquiriesLoad(false);
    }, 4000);
  }, [inquiry]);

  return (
    <div>
      <Modal
        centered
        show={openReplyInquiry}
        onHide={onClose}
        backdrop="static"
        className="inquiry-reply-modal">
        <Modal.Header closeButton clearData={clearData}></Modal.Header>
        <Modal.Body>
          <div>
            <div className="modal-body-inquiry">
              <p>Inquiry</p>
            </div>
            {isInquiriesLoad ? (
              <div className="loading-spinner-inquiry">
                <Spinner animation="border" variant="primary" />
              </div>
            ) : (
              <>
                <div className="modal-body-message">
                  <div className="modal-body-message-content">
                    <p>{inquiry.message}</p>
                  </div>
                </div>
                <div className="modal-body-reply">
                  <input
                    className="modal-body-message-content-reply"
                    placeholder="Type a reply"
                    onChange={(reply) => {
                      handleInputChange(reply.target.value)
                    }}></input>
                </div>
              </>
            )}
          </div>
          <div>
            <hr />
          </div>
        </Modal.Body>
        <Modal.Footer className="pb-5 pt-0">
          <Button
            className="reply-button "
            variant="primary"
            onClick={handleReply}>
            Send reply
          </Button>
          <Button
            className="cancel-button"
            variant="outline-primary"
            onClick={closeForm}
          >
            Go back
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default ReplyInquiryModal;
