import { useState } from "react";
import { Modal, Spinner } from "react-bootstrap";
import "./Social-SignUp.scss";
import { getDeviceTimeZone } from "../../util/TimeZones";
import { socialSignup } from "../../util/APIUtils";
import { notificationManage } from "../../util/NotificationUtils";
import { ACCESS_TOKEN, REFRESH_TOKEN, USER_TYPES } from "../../constants";

const SocialSignUp = ({ show, onClose }) => {
  const [accountType, setAccountType] = useState(null);
  const [isLoading, setIsLoading] = useState(false)
  const searchParams = new URLSearchParams(window.location.search);

  const handleContinue = () => {
    if (accountType) {
        setIsLoading(true)
    const timeZone = getDeviceTimeZone() ?? "Asia/Kolkata";
    const token = searchParams.get('token');
    const isFriend = accountType === USER_TYPES.FRIEND;
    socialSignup(token, timeZone, isFriend)
      .then((res) => {
        localStorage.setItem(ACCESS_TOKEN, res?.accessToken)
        localStorage.setItem(REFRESH_TOKEN, res?.refreshToken)
        localStorage.setItem("rightPanelState", "profile")
        window.location.href = "/profile"
      })
      .catch((err) => {
        notificationManage("danger", "Unable create account");
        window.location.href = "/signup"
      })
      .finally(() => {
        setIsLoading(false);
      });
    } else {
        notificationManage("danger", "Please select account type")
    }
  }

  return (
    <Modal
      centered
      show={show}
      onHide={onClose}
      backdrop={true}
      className="social-signup"
      dialogClassName="social-signup-dialog"
      contentClassName="social-signup-content"
    >
      <Modal.Header className="social-signup-header" closeButton>
        <h4 className="m-0">Account Type</h4>
      </Modal.Header>
      <Modal.Body className="social-signup-body d-flex flex-column align-items-center">
        <h2>Account Type</h2>
        <p>Select an account type you want to create</p>
        <button
          className={`${
            accountType === USER_TYPES.NEWCOMER
              ? "social-signup-body-option-active"
              : "social-signup-body-option"
          } m-2 `}
          onClick={(e) => setAccountType(USER_TYPES.NEWCOMER)}
        >
          Newcomer
        </button>
        <button
          className={`${
            accountType === USER_TYPES.FRIEND
              ? "social-signup-body-option-active"
              : "social-signup-body-option"
          } m-2 `}
          onClick={(e) => setAccountType(USER_TYPES.FRIEND)}
        >
          Friend
        </button>
        <button
          className="social-signup-body-btn m-3 d-flex justify-content-center align-items-center"
          onClick={handleContinue}
        >
          Continue
          {isLoading && (
            <Spinner
              className="ml-2 social-signup-body-btn-spinner"
              animation="border"
            />
          )}
        </button>
      </Modal.Body>
    </Modal>
  );
};

export default SocialSignUp;
