import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import ReplyInquiryModal from "../../components/reply-inquiry-modal/Reply-Inquiry-Modal";
import "./Inquiry-List.scss";
import { CHARACTER_LIMIT } from "../../constants";

function InquiryList(props) {
  const { inquiries, currentUserId, loadInquiries,isUnRead } = props;
  const [openReplyInquiry, setOpenReplyInquiry] = useState(false);
  const [inquiryId, setInquiryId] = useState("");
  const [inqTruncated, setInqTruncated] = useState({});
  const [replyTruncated, setReplyTruncated] = useState({});
  const [isClicked, setIsSeeMoreClicked] = useState(false)

  const dateOfQue = (dateOfCreation) => {
    return (dateOfCreation = dateOfCreation.split("T")[0]);
  };

  const handleModalOpen = (id) => {
    setInquiryId(id);
    setOpenReplyInquiry(true);
  };

  const handleSeeMoreClick = (id, isTrue,isClicked) => {
    setReplyTruncated((prev) => ({
      ...prev,
      [id]: isTrue,
    }));
    setIsSeeMoreClicked(isClicked)
  };

  const handleSeeMoreInqClick = (id, isTrue,isClicked) => {
    setInqTruncated((prev) => ({
      ...prev,
      [id]: isTrue,
    }));
    setIsSeeMoreClicked(isClicked)
  };

  return (
    <div className="inquiry-wrapper">
      <ReplyInquiryModal
        openReplyInquiry={openReplyInquiry}
        setOpenReplyInquiry={setOpenReplyInquiry}
        inquiryId={inquiryId}
        currentUserId={currentUserId}
        reRenderList={loadInquiries}
      />
      <div className="row inquiry-table">
        <span className="col-2 ml-5 inquiry-table-headers-row">Name</span>
        {isUnRead !== "Replied" ?(
             <span className="col-3 inquiry-table-headers-row">Message</span>
          ):(
            <span className="col-5 inquiry-table-headers-row">Message</span>
        )}
        <span className="col-2 inquiry-table-headers-row">Email</span>
        <span className="col-1 ml-5 inquiry-table-headers-row">Date</span>
        <span className="col-1  ml-3  inquiry-table-headers-row">City</span>
        {isUnRead !== "Replied" ?(
           <span className="col-1  inquiry-table-headers-row">Action</span>
          ):(
          ""
        )}
      </div>
      {inquiries.map((question) => {
        return (
          <div className="card border-light p-4 my-4 item" style={{borderRadius:"10px"}} key={question.id}>
            <div className="row inquiry-info-list">
              <div className="col-2 ml-4 inquiry-table-result-row-name">
                {question.name}
              </div>
              {question.reply?(
              <div className="col-5  inquiry-table-result-row">
                <div className="row message-field">
                    {question.message && (
                     <div className="col-12 message-field-result-question">
                      {question.message
                        .substring(
                          0,
                          inqTruncated[question.id]
                            ? question.message.length
                            : CHARACTER_LIMIT
                        )
                        .trim()
                        .concat(
                          !inqTruncated[question.id] &&
                          question.message.length > CHARACTER_LIMIT
                            ? "..."
                            : ""
                        )}
                         {!inqTruncated[question.id] &&
                        question.message.length > CHARACTER_LIMIT ? (
                          <button
                            className="thread-see-more"
                            onClick={() => handleSeeMoreInqClick(question.id, true, true)}>
                            See More
                          </button>
                        ):(inqTruncated[question.id] &&
                          isClicked === true &&(
                          <button
                          className="thread-see-more"
                          onClick={() => handleSeeMoreInqClick(question.id, false,false)}>
                          See Less
                        </button>
                          )
                        )}
                   </div>
                  )}
                  <div className="col-12 message-field-reply-text"> Reply:</div>
                    <div className="reply-field">
                  {question.reply && (
                    <div className="col-12 reply-field-reply">
                      {question.reply
                        .substring(
                          0,
                          replyTruncated[question.id]
                            ? question.reply.length
                            : CHARACTER_LIMIT
                        )
                        .trim()
                        .concat(
                          !replyTruncated[question.id] &&
                          question.reply.length > CHARACTER_LIMIT
                            ? "..."
                            : ""
                        )}
                      {!replyTruncated[question.id] &&
                        question.reply.length > CHARACTER_LIMIT ? (
                          <button
                            className="thread-see-more"
                            onClick={() => handleSeeMoreClick(question.id, true, true)}>
                            See More
                          </button>
                        ):(replyTruncated[question.id] &&
                          isClicked === true &&(
                          <button
                          className="thread-see-more"
                          onClick={() => handleSeeMoreClick(question.id, false,false)}>
                          See Less
                        </button>
                          )
                        )}
                    </div>
                  )}
                  </div>
                </div>
              </div>
              ):
              (
                <div className="col-3  inquiry-table-result-row">
                    <div className="col-12">{question.message}</div>
                </div>
              )
            }
              <div className="col-2  inquiry-table-result-row">
                {question.email}
              </div>
              <div className="col-1  ml-5  inquiry-table-result-row">
                {dateOfQue(question.createdAt)}
              </div>
              <div className="col-1 ml-3 inquiry-table-result-row">
                {question.city}
              </div>
              {question.reply ? (
                ""
              ) : (
                <Button
                  className="col-1 inquiry-table-result-row-button"
                  onClick={() => handleModalOpen(question.id)}>
                  Reply
                </Button>
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
}

export default InquiryList;
