import React, { useEffect, useState } from "react";
import { Route, Redirect } from "react-router-dom";

const PrivateRoute = ({ component: Component, reloadCurrentUser, ...rest }) => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (rest.currentUser && (rest.currentUser.id || rest.currentUser.error)) {
      setIsLoading(false);
    } else {
      reloadCurrentUser();
    }
  }, [rest, reloadCurrentUser]);

  return (
    !isLoading && (
      <Route
        {...rest}
        render={(props) =>
          rest.currentUser && rest.currentUser.id ? (
            <Component
              {...rest}
              {...props}
              reloadCurrentUser={reloadCurrentUser}
            />
          ) : (
            <Redirect
              to={{
                pathname: "/",
                state: { from: props.location },
              }}
            />
          )
        }
      />
    )
  );
};

export default PrivateRoute;
