import React, { useState } from "react";
import { FormCheck, FormControl } from "react-bootstrap";

export const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  <div
    ref={ref}
    className="event-dropdown-toggle"
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}>
    <div>{children}</div>
    <div>
      <i className="fas fa-chevron-down" />
    </div>
  </div>
));

export const CustomMenu = React.forwardRef(
  (
    {
      children,
      style,
      className,
      "aria-labelledby": labeledBy,
      event,
      setEvent,
      updateErrorMessages,
      listOfCities,
    },
    ref
  ) => {
    const [value, setValue] = useState("");

    const handleCitySelection = (city) => {
      if (event.cityIdList.includes(city)) {
        setEvent((prev) => ({
          ...prev,
          cityIdList: event.cityIdList.filter((value) => value !== city),
        }));
      } else {
        setEvent((prev) => ({
          ...prev,
          cityIdList: event.cityIdList.concat(city),
        }));
      }
      updateErrorMessages("cities");
    };

    return (
      <div
        ref={ref}
        style={style}
        className={className}
        aria-labelledby={labeledBy}>
        <FormControl
          autoFocus
          className="mx-3 my-2 w-auto"
          placeholder="Type to filter..."
          onChange={(e) => setValue(e.target.value)}
          value={value}
        />
        <div>
          {listOfCities
            .filter((city) => city.name.toLowerCase().startsWith(value))
            .map((city) => {
              return (
                <FormCheck
                  type="checkbox"
                  label={city.name}
                  id={city.name}
                  className="dropdown-menu-item"
                  defaultChecked={event.cityIdList?.some(
                    (value) => value === city.id
                  )}
                  onClick={() => handleCitySelection(city.id)}
                />
              );
            })}
        </div>
      </div>
    );
  }
);
