import React, { useState, useEffect } from "react";
import "./No-Content.scss";

export const DisplayNoContent = (props) => {
  const { text, type } = props;
  const [mode, setMode] = useState();

  useEffect(() => {
    switch (type) {
      case "thread":
        setMode("no-content-threads");
        break;
      case "friend-post":
        setMode("no-content-friend-posts");
        break;
      case "event":
        setMode("no-content-events");
        break;
      default:
        setMode("no-content-default");
    }
  }, [type]);

  return (
    <div className={`${mode} no-content p-3`}>
      <div className="no-content-text text-align-center">{text}</div>
    </div>
  );
};
