import React, { useEffect, useState } from "react";
import { Spinner, Tooltip, OverlayTrigger } from "react-bootstrap";
import { Calendar3 } from "react-bootstrap-icons";
import { Link, useHistory, useParams } from "react-router-dom";
import { ACCESS_TOKEN, API_BASE_URL } from "../../constants";
import {
  followFriend,
  getCurrentUser,
  getProfileById,
  unfollowFriend,
} from "../../util/APIUtils";
import { truncateName } from "../../util/Utils";
import PopupLogin from "../popup-login/Popup-Login";
import RatingStar from "../rating-star/Rating-Star";
import EmailVerificationModal from "../email-verification-modal/Email-verification-modal";
import "./Friends-Page-Left-Panel.scss";
import { useVerified } from "../../context/UserContext";
import { hideEmailCharacters } from "../../util/Utils";
import AlertBox from "../../components/alert-box/Alert-Box";
import Cookies from "js-cookie";

function FriendPageLeftPanel(props) {
  const {
    userCity,
    userName,
    description,
    profilePic,
    profilePic2,
    userId,
    year,
    keys,
    values,
    followingList,
    currentProfileUser,
    commentCount,
  } = props;

  const [requireLogin, setRequireLogin] = useState(false);
  const [friendsFollowingList, setFriendsFollowingList] = useState([]);
  const [isDataLoad, setIsDataLoad] = useState(true);
  const { id } = useParams();
  const [isDisabled, setIsDisabled] = useState(true);
  const history = useHistory();
  const [isFriend, setIsFriend] = useState(false);
  const [friendLocation, setFriendLocation] = useState("");
  const [requireVerification, setRequireVerification] = useState(false);
  const [checkIsFriend, setCheckIsFriend] = useState("");
  const [showAlertBox, setShowAlertBox] = useState(false);
  const { currentUser } = useVerified();

  useEffect(() => {
    getProfileById(id)
      .then((response) => {
        if (
          response.isFriend &&
          response.schedulerId !== null &&
          JSON.parse(Cookies.get("isEmpty")) === false
        ) {
          setIsDisabled(false);
        } else {
          setIsDisabled(true);
        }
      })
      .catch((error) => {
        console.error(error);
        setIsDisabled(true);
      });
  }, [id]);

  useEffect(() => {
    getCurrentUser()
      .then((response) => {
        Cookies.set("timeZone", response?.timeZone);
        setIsFriend(response.isFriend);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  const storedValue = sessionStorage.getItem("popupShown");
  const sessionValue = storedValue ? JSON.parse(storedValue) : false;

  const storedValueRefresh = sessionStorage.getItem("refreshPage");
  const sessionValueRefresh = storedValueRefresh
    ? JSON.parse(storedValueRefresh)
    : false;

  useEffect(() => {
    getCurrentUser()
      .then((currentUser) => {
        if (currentUser?.isFriend && sessionValue) {
          setShowAlertBox(true);
          sessionStorage.setItem("popupShown", JSON.stringify(false));
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  useEffect(() => {
    getCurrentUser()
      .then((currentUser) => {
        if (currentUser.isFriend && sessionValue && sessionValueRefresh) {
          window.location.reload();
          sessionStorage.setItem("refreshPage", JSON.stringify(false));
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  const handleOnClick = () => {
    if (localStorage.getItem(ACCESS_TOKEN)) {
      getCurrentUser()
        .then((response) => {
          if (response.emailVerified) {
            history.push(`/friend/${id}/schedule`);
          } else {
            setRequireLogin(false);
            setFriendLocation(null);
            setRequireVerification(true);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
      sessionStorage.setItem("popupShown", JSON.stringify(true));
      setFriendLocation(`/friend/${id}/schedule`);
      setRequireLogin(true);
      setCheckIsFriend(`/friends/${id}`);
      Cookies.set("schedule-call", `/friend/${id}/schedule`);
    }
  };

  const handleHidePopupLogin = () => {
    setRequireLogin(!requireLogin);
  };

  const handleFollow = (follow) => {
    if (localStorage.getItem(ACCESS_TOKEN)) {
      if (follow) {
        followFriend(userId);
        let updatedFriendsList = friendsFollowingList?.slice(0);
        updatedFriendsList.push(userId);
        setFriendsFollowingList(updatedFriendsList);
      } else {
        unfollowFriend(userId);
        let updatedFriendsList = friendsFollowingList;
        updatedFriendsList = updatedFriendsList.filter(
          (element) => element !== userId
        );
        setFriendsFollowingList(updatedFriendsList);
      }
    } else {
      setRequireLogin(true);
    }
  };

  useEffect(() => {
    setIsDataLoad(true);
    setTimeout(() => {
      setIsDataLoad(false);
    }, 4000);
  }, [profilePic, profilePic2]);

  useEffect(() => {
    setFriendsFollowingList(followingList);
  }, [followingList]);

  return (
    <>
      {showAlertBox ? (
        <AlertBox
          title="You have Signed In as a Friend"
          body={
            <p>
              Sorry! You cannot schedule a call because you have signed in as{" "}
              <span className="highlighted-text">Friend</span>. Please Sign in
              as <span className="highlighted-text">Newcomer</span> to schedule
              a call.
            </p>
          }
          showAlertBox={showAlertBox}
          setShowAlertBox={setShowAlertBox}
          type="schedule-call-friend-alert"
        />
      ) : null}
      <section className="friends-page-left-panel">
        <div>
          <PopupLogin
            show={requireLogin}
            onHide={() => {
              handleHidePopupLogin();
            }}
            name="Sign In"
            friendLocation={friendLocation}
            checkIsFriend={checkIsFriend}
          />
          <p className="left-panel-header-text">About: </p>
          <div className="main-card">
            {isDataLoad ? (
              <div className="loading-spinner">
                <Spinner animation="border" variant="primary" />
              </div>
            ) : (
              <div className="card-body">
                {currentProfileUser === userId && (
                  <Link to="/profile">
                    <i className="fas fa-pen my-profile-edit-pen"></i>
                  </Link>
                )}
                <div className="left-panel-card">
                  <div className="left-panel-text">
                    {truncateName(userName, 20)}
                  </div>
                  {profilePic || profilePic2 ? (
                    <img
                      className={`${
                        profilePic || profilePic2 ? "gradient" : ""
                      } left-panel-card-image`}
                      alt={`${userName} img`}
                      src={
                        profilePic2 !== null
                          ? `${API_BASE_URL}/v1/users/${userId}/image`
                          : profilePic
                      }
                    />
                  ) : (
                    <div className="text-avatar default-user-image">
                      <span>{userName && userName[0]?.toUpperCase()}</span>
                    </div>
                  )}
                  <h5 className="left-panel-text-2">{userCity}</h5>
                  <h5 className="left-panel-text-2">
                    Time spent - {year} years
                  </h5>
                  {currentProfileUser !== userId &&
                    (friendsFollowingList?.includes(userId) ? (
                      <div
                        className="left-panel-following"
                        onClick={() => handleFollow(false)}
                      >
                        Following
                      </div>
                    ) : (
                      <div
                        className="left-panel-following"
                        onClick={() => handleFollow(true)}
                      >
                        Follow
                      </div>
                    ))}
                </div>
              </div>
            )}
          </div>
          {!isFriend && (
            <OverlayTrigger
              placement={"auto"}
              delay={{ show: 0, hide: 400 }}
              overlay={
                <Tooltip
                  className={`${isDisabled ? "tooltip-extra-info" : "d-none"}`}
                >
                  {isDisabled &&
                    "You can't perform this action because you have not completed your profile"}
                </Tooltip>
              }
            >
              <div className="left-panel-schedule-call-button">
                <button
                  type="button"
                  className="friend-left-panel-container btn btn-primary btn-block"
                  disabled={isDisabled}
                  onClick={() => {
                    handleOnClick();
                  }}
                >
                  <Calendar3 className="bi bi-calendar3"></Calendar3>Schedule a
                  call
                </button>
              </div>
            </OverlayTrigger>
          )}
          <div className="mobile-container">
            <div className="description-container">
              <div className="left-panel-header-text-2">Description: </div>
              <div className="main-card-2">
                <div className="card-body">
                  <div className="card-text-2">
                    {window.innerWidth > 688 && window.innerWidth < 992
                      ? truncateName(description, 280)
                      : description}
                  </div>
                </div>
              </div>
            </div>
            <div className="contribution-container">
              <div className="left-panel-header-text-2">Contributing in: </div>
              <div className="main-card-3">
                <div className="card-body mb-4">
                  {keys?.map((item, key) => {
                    return (
                      <div key={key} className="form-group row">
                        <label className="col-sm-7 col-form-label">
                          {" "}
                          {item}{" "}
                        </label>
                        <div className="col-sm-5 col-form-star px-0">
                          <RatingStar
                            count={values[key]}
                            commentCounts={commentCount[key]}
                            colorMode="blackMode"
                          />
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
        <EmailVerificationModal
          isShow={requireVerification}
          handleVerificationModal={() => setRequireVerification(false)}
          title="Verify your Email"
          description="Almost there! We have sent a verification email to"
          email={currentUser && hideEmailCharacters(currentUser.email)}
          footer="You need to verify your email address to use the services offered by Move2NewCity."
        />
      </section>
    </>
  );
}

export default FriendPageLeftPanel;
