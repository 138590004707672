import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Button, Modal, OverlayTrigger, Popover } from "react-bootstrap";
import { Link } from "react-router-dom";
import {
  ACCESS_TOKEN,
  AUTH_SIGNIN,
  AUTH_SIGNUP,
  ENV,
  ENVIROMENTS,
  FACEBOOK_AUTH_SIGNIN_URL,
  FACEBOOK_AUTH_SIGNUP_URL,
  GOOGLE_AUTH_SIGNIN_URL,
  GOOGLE_AUTH_SIGNUP_URL,
  REFRESH_TOKEN,
} from "../../constants";
import { useVerified } from "../../context/UserContext";
import { getCurrentUser, login, signup } from "../../util/APIUtils";
import { notificationManage } from "../../util/NotificationUtils";
import {
  handleEmailValidation,
  handleRedirectUrlAfterLogin,
  passwordPolicy,
  passwordState,
  validatePassword,
} from "../../util/Utils";
import "./Popup-Login.scss";
import ForgotPasswordModal from "../forgot-password/Forgot-Password-Modal";
import ReactGA from "react-ga4";
import { ExclamationCircle } from "react-bootstrap-icons";
import Cookies from "js-cookie";
import { fetchTimezone, getUserTimezone } from "../../util/CommonUtils";

const googleLogo = "/assets/img/social-media/google-logo-1.png";
const fbLogo = "/assets/img/social-media/facebook-logo.png";

const PopupLogin = (props) => {
  // Heavy prop drilling for 'reloadCurrentUser' prop (from App.js), switch to a global context when required
  const {
    show,
    onHide,
    name,
    type,
    isFriendDefaultChecked,
    onSuccessfulSignIn,
    reloadCurrentUser,
    fromHeader,
    friendLocation,
    currentPageUrl,
    checkIsFriend,
    isSignUp = false,
  } = props;

  const baseForm = {
    email: localStorage.getItem("rememberMe") ?? "",
    password: "",
  };
  const signInFormInitial = {
    ...baseForm,
    rememberMe: !!localStorage.getItem("rememberMe"),
  };
  const signUpFormInitial = {
    password: "",
    email: "",
    name: "",
    accountType: "",
    tosAgreed: false,
    isFriend: isFriendDefaultChecked,
  };
  const signInErrorInitial = {
    ...Object.fromEntries(
      Object.keys(signInFormInitial).map((key) => [key, false])
    ),
    ...{ emailNotExist: false, passwordIncorrect: false },
  };
  const signUpErrorInitial = Object.fromEntries(
    Object.keys(signUpFormInitial).map((key) => [key, false])
  );
  const history = useHistory();
  const [signInForm, setSignInForm] = useState(signInFormInitial);
  const [signUpForm, setSignUpForm] = useState(signUpFormInitial);
  const [signInError, setSignInError] = useState(signInErrorInitial);
  const [signUpError, setSignUpError] = useState(signUpErrorInitial);
  const [isSignIn, setIsSignIn] = useState(true);
  const [showForgotPasswordModal, setShowForgotPasswordModal] = useState(false);
  const [isFriend, setIsFriend] = useState(isFriendDefaultChecked);
  const [showLoginPopup, setShowLoginPopup] = useState(false);

  useEffect(() => {
    if (!showForgotPasswordModal) {
      setShowLoginPopup(show);
    }
    resetAllObjects();
    setIsSignIn(!isSignUp);
  }, [props]);

  useEffect(() => {
    if (signInForm.rememberMe) {
      localStorage.setItem("rememberMe", signInForm.email);
    }
  }, [signInForm.rememberMe, signInForm.email]);

  const loginProviderDetails = [
    {
      id: 1,
      text:
        name === "Sign Up" || !isSignIn
          ? "Sign up with Google"
          : "Continue with Google",
      logo: googleLogo,
      apiLink:
        name === "Sign Up" || !isSignIn
          ? `${GOOGLE_AUTH_SIGNUP_URL}&timeZone=${getUserTimezone()}`
          : GOOGLE_AUTH_SIGNIN_URL,
    },
    {
      id: 2,
      text:
        name === "Sign Up" || !isSignIn
          ? "Sign up with Facebook"
          : "Continue with Facebook",
      logo: fbLogo,
      apiLink:
        name === "Sign Up" || !isSignIn
          ? `${FACEBOOK_AUTH_SIGNUP_URL}&timeZone=${getUserTimezone()}`
          : FACEBOOK_AUTH_SIGNIN_URL,
    },
  ];

  const resetAllObjects = () => {
    setSignInForm(signInFormInitial);
    setSignUpForm(signUpFormInitial);
    setSignInError(signInErrorInitial);
    setSignUpError(signUpErrorInitial);
  };

  const onClose = () => {
    type && type === "forgotPassword" ? history.push("/") : onHide(false);
  };

  const onCloseForgotPassword = () => {
    setShowLoginPopup(true);
    setShowForgotPasswordModal(false);
  };

  const onSendForgotPassword = () => {
    setShowLoginPopup(false);
    setShowForgotPasswordModal(false);
  };

  useEffect(() => {
    if (!isSignIn) {
      localStorage.setItem(
        "isFriendSocialLogin",
        JSON.stringify(isFriendDefaultChecked)
      );
    }
  }, [isFriendDefaultChecked, isSignIn]);

  return (
    <>
      <ForgotPasswordModal
        open={showForgotPasswordModal}
        onClose={onCloseForgotPassword}
        onSend={onSendForgotPassword}
      />
      <div className="popup-login">
        <Modal
          className="popup-login-modal"
          show={showLoginPopup}
          onHide={() => {
            onClose();
          }}
          onExit={() => {
            resetAllObjects();
          }}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header className="popup-login-modal-header" closeButton>
            <p>{isSignIn ? "Sign In" : "Sign Up"}</p>
          </Modal.Header>
          <Modal.Body className="popup-login-modal-body">
            <div className="popup-login-container">
              <div className="popup-login-container-form">
                {isSignIn ? (
                  <SignIn
                    signInForm={signInForm}
                    setSignInForm={setSignInForm}
                    signInError={signInError}
                    setSignInError={setSignInError}
                    setIsSignIn={setIsSignIn}
                    onHide={onHide}
                    onSuccessfulSignIn={onSuccessfulSignIn}
                    reloadCurrentUser={reloadCurrentUser}
                    setShowForgotPasswordModal={setShowForgotPasswordModal}
                    setShowLoginPopup={setShowLoginPopup}
                    loginProviderDetails={loginProviderDetails}
                    friendLocation={friendLocation}
                    currentPageUrl={currentPageUrl}
                    checkIsFriend={checkIsFriend}
                  />
                ) : (
                  <SignUp
                    signUpForm={signUpForm}
                    setSignUpForm={setSignUpForm}
                    signUpError={signUpError}
                    setSignUpError={setSignUpError}
                    setIsSignIn={setIsSignIn}
                    setSignInForm={setSignInForm}
                    onHide={onHide}
                    isFriendCheck={isFriend}
                    fromHeader={fromHeader}
                    loginProviderDetails={loginProviderDetails}
                    friendLocation={friendLocation}
                    currentPageUrl={currentPageUrl}
                    checkIsFriend={checkIsFriend}
                  />
                )}
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
};

export default PopupLogin;

export const SocialLoginSignup = ({
  loginProviderDetails,
  fromHeader,
  accountTypeSelected,
  fromSignup,
}) => {
  const selectAccountPopover = (
    <Popover className="account-type-select">
      <Popover.Content className="account-type-select-content">
        <div>
          <div className="account-type-select-content-description">
            Please select account type first
          </div>
        </div>
      </Popover.Content>
    </Popover>
  );

  return (
    <div>
      <div className="popup-login-button-section">
        {loginProviderDetails.map((provider) => {
          const { id, text, logo, apiLink } = provider;
          return (
            <div
              key={id}
              className="popup-login-button-container"
              style={{ position: "relative" }}
            >
              <OverlayTrigger placement="top" overlay={selectAccountPopover}>
                <div
                  className={`button-container-overlay ${
                    accountTypeSelected || !fromSignup || !fromHeader
                      ? "none"
                      : ""
                  }`}
                ></div>
              </OverlayTrigger>
              <a href={apiLink}>
                <button
                  className="popup-login-button"
                  onClick={() => {
                    Cookies.set("storeLocation", window.location.pathname);
                  }}
                  disabled={
                    fromHeader && !accountTypeSelected && fromSignup
                      ? true
                      : false
                  }
                >
                  <div className="popup-login-button-content">
                    <p className="content"> {text} </p>
                    <img
                      className="popup-button-icon"
                      src={logo}
                      alt={`${text}-logo`}
                    />
                  </div>
                </button>
              </a>
            </div>
          );
        })}
      </div>
      <div className="popup-login-divider d-flex align-items-center justify-content-center">
        {!fromSignup && <hr className="w-100 m-0 mr-3" />}
        <span className="">or</span>
        {!fromSignup && <hr className="w-100 m-0 ml-3" />}
      </div>
    </div>
  );
};

export const SignIn = ({
  signInForm,
  setSignInForm,
  signInError,
  setSignInError,
  setIsSignIn,
  onHide,
  onSuccessfulSignIn,
  reloadCurrentUser,
  setShowForgotPasswordModal,
  setShowLoginPopup,
  loginProviderDetails,
  friendLocation,
  currentPageUrl,
  checkIsFriend,
}) => {
  const { verifyUser, history, location } = useVerified();
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [passwordIncorrect, setPasswordIncorrect] = useState(false);
  const updateSignInForm = (key, value) => {
    setSignInForm((prev) => ({
      ...prev,
      [key]: value,
    }));
    setSignInError((prev) => ({
      ...prev,
      [key]: false,
    }));
  };

  const updateSignInError = (key, value) => {
    if (key === "all") {
      setSignInError((prev) =>
        Object.fromEntries(
          Object.entries(prev).map((value) => {
            return [value[0], false];
          })
        )
      );
    } else {
      setSignInError((prev) => ({
        ...prev,
        [key]: value,
      }));
    }
  };

  const validateSignIn = () => {
    let signInErrorCopy = { ...signInError };

    signInErrorCopy.email = !handleEmailValidation(signInForm.email);
    signInErrorCopy.password = !signInForm.password;

    setSignInError(signInErrorCopy);
    return Object.values(signInErrorCopy).every((value) => !value);
  };

  const submitSignIn = async () => {
    Cookies.set("storeLocation", window.location.pathname);
    try {
      if (validateSignIn()) {
        const response = await login(signInForm);
        switch (response?.status) {
          case 200:
            response
              .json()
              ?.then((res) => {
                localStorage.setItem(ACCESS_TOKEN, res?.accessToken);
                localStorage.setItem(REFRESH_TOKEN, res?.refreshToken);
                notificationManage("success", "You're successfully logged in!");
              })
              .catch((err) => {
                console.error(err);
                notificationManage(
                  "danger",
                  "An error occurred during sign-in"
                );
              })
              .finally(() => {
                if (onSuccessfulSignIn) {
                  loadSuccessfulSignInModal();
                } else {
                  redirect();
                }
                onHide(false);
                getCurrentUser().then((res) => {
                  ReactGA.event({
                    category: "Local Authentication",
                    action: res?.isFriend
                      ? `${AUTH_SIGNIN?.toUpperCase()} Friend - ${res?.id}`
                      : `${AUTH_SIGNIN?.toUpperCase()} Newcomer - ${res?.id}`,
                  });
                }).catch(console.warn);
              });
            break;
          case 400:
            notificationManage("danger", "Your account is temporarily deleted");
            break;
          case 401:
            notificationManage("danger", "Your password is incorrect");
            updateSignInError("passwordIncorrect", true);
            break;
          case 404:
            window.location.href = "/signup";
            notificationManage(
              "danger",
              "This email is not associated with any account"
            );
            updateSignInError("emailNotExist", true);
            break;
          default:
            notificationManage("danger", "An error occurred during sign-in");
            setPasswordIncorrect(true);
        }
      }
    } catch (err) {
      console.error(err);
      notificationManage("danger", "An error occurred during sign-in");
    }
  };

  const loadSuccessfulSignInModal = () => {
    onSuccessfulSignIn();
    reloadCurrentUser();
  };

  const redirect = () => {
    const newProps = {
      verifyUser,
      history,
      location,
      friendLocation,
      currentPageUrl,
      checkIsFriend,
    };
    handleRedirectUrlAfterLogin(newProps);
  };

  const handleForgotPassword = () => {
    setShowForgotPasswordModal(true);
    setShowLoginPopup(false);
    onHide(false);
  };

  return (
    <>
      <SocialLoginSignup
        loginProviderDetails={loginProviderDetails}
        friendLocation={friendLocation}
        currentPageUrl={currentPageUrl}
        checkIsFriend={checkIsFriend}
      />
      <label for="email">Email *</label>
      <div className="username">
        <input
          type="text"
          id="email"
          className="popup-login-input"
          value={signInForm.email}
          placeholder="Enter your email address"
          onChange={(e) => {
            updateSignInForm("email", e.target.value);
            updateSignInError("all", false);
          }}
        />
        {(signInError.email || signInError.emailNotExist) && (
          <ExclamationCircle
            color="#FF0000"
            size={14}
            className="username-error-icon"
          />
        )}
      </div>
      {signInError.email && (
        <div className="input-error-message">Enter a valid email address</div>
      )}
      {signInError.emailNotExist && (
        <div className="input-error-message">
          The email address that you have entered doesn't match any account.
          Please check your email or Sign Up
        </div>
      )}

      <label for="password">Password *</label>
      <div className="password">
        <input
          type={passwordVisible ? "text" : "password"}
          id="password"
          value={signInForm.password}
          placeholder="Enter your password"
          onChange={(e) => {
            updateSignInForm("password", e.target.value);
            updateSignInError("all", false);
          }}
        />
        {signInError.password || signInError.passwordIncorrect ? (
          <ExclamationCircle
            color="#FF0000"
            size={14}
            className="password-error-icon"
          />
        ) : passwordVisible ? (
          <i
            className="fas fa-eye-slash password-visible-icon"
            onClick={() => setPasswordVisible(false)}
          ></i>
        ) : (
          <i
            className="fas fa-eye password-visible-icon"
            onClick={() => setPasswordVisible(true)}
          ></i>
        )}
      </div>
      {signInError.password && (
        <div className="input-error-message">Enter your password</div>
      )}
      {signInError.passwordIncorrect && (
        <div className="input-error-message">
          The password you entered is incorrect. Please try again. If you
          created an account using Google or Facebook, please log in using the
          respective buttons above.
        </div>
      )}
      {passwordIncorrect && (
        <div className="input-error-message">
          The password you entered is incorrect. Please try again. If you
          created an account using Google or Facebook, please log in using the
          respective buttons above.
        </div>
      )}
      <div className="remember-me-forgot-password">
        <div className="remember-me">
          <input
            type="checkbox"
            id="remember-me"
            checked={signInForm.rememberMe}
            onChange={(e) => {
              if (!e.target.checked) {
                localStorage.removeItem("rememberMe");
              }
              updateSignInForm("rememberMe", e.target.checked);
            }}
          />
          <label htmlFor="remember-me">Remember Me</label>
        </div>
        <button
          className="forgot-password"
          onClick={() => handleForgotPassword()}
        >
          Forgot Password?
        </button>
      </div>
      <Button className="login-button" onClick={() => submitSignIn()}>
        Login
      </Button>
      <div className="toggle-view" onClick={() => setIsSignIn(false)}>
        New User? <span>Sign Up Here</span>
      </div>
    </>
  );
};

export const SignUp = ({
  signUpForm,
  setSignUpForm,
  signUpError,
  setSignUpError,
  setIsSignIn,
  setSignInForm,
  isFriendCheck,
  fromHeader,
  loginProviderDetails,
  friendLocation,
  currentPageUrl,
  checkIsFriend,
}) => {
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [isFriend, setIsFriend] = useState(isFriendCheck);
  const [accountTypeSelected, setAccountTypeSelected] = useState(false);
  const [signupProviderDetails, setSignupProviderDetails] = useState([]);

  useEffect(() => {
    const providers = [...loginProviderDetails];
    const updated = providers.map((p) => {
      p.apiLink = `${p.apiLink}&account_type=${
        isFriend ? "friend" : "newcomer"
      }`;
      return p;
    });
    setSignupProviderDetails(updated);
  }, [loginProviderDetails, isFriend]);

  const clearSignUpForm = () => {
    setSignUpForm({
      name: "",
      email: "",
      password: "",
      isFriend: false,
    });
    setSignUpError({
      name: false,
      email: false,
      password: false,
      isFriend: false,
    });
    setAccountTypeSelected(false);
    updateSignUpForm("isFriend", undefined);
  };

  const updateSignUpForm = (key, value) => {
    if (key === "isFriend") {
      setIsFriend(value);
      localStorage.setItem("isFriendSocialLogin", JSON.stringify(value));
      setSignUpForm((prev) => ({
        ...prev,
        [key]: isFriend,
      }));
    }
    setSignUpForm((prev) => ({
      ...prev,
      [key]: value,
    }));
    const signupErrCopy = signUpError
    signupErrCopy[key] = false
    if (key === "password" && value === "") {
      signUpError["password"] = true
    }
    setSignUpError(signupErrCopy);
  };

  const validateSignUp = () => {
    let signUpErrorCopy = { ...signUpError };

    signUpErrorCopy.name = !signUpForm.name.trim();
    signUpErrorCopy.email = !handleEmailValidation(signUpForm.email);
    signUpErrorCopy.password = !signUpForm.password;
    signUpErrorCopy.isFriend = signUpForm.isFriend === undefined;

    setSignUpError(signUpErrorCopy);
    return Object.values(signUpErrorCopy).every((value) => !value);
  };

  const submitSignUp = async () => {
    signUpForm.timezone = getUserTimezone();
    try {
      if (validateSignUp()) {
        const response = await signup(signUpForm);
        switch (response?.status) {
          case 200:
            response
              .json()
              ?.then((res) => {
                ReactGA.event({
                  category: "Local Authentication",
                  action: signUpForm.isFriend
                    ? `${AUTH_SIGNUP?.toUpperCase()} Friend - ${signUpForm.name.trim()}`
                    : `${AUTH_SIGNUP?.toUpperCase()} Newcomer - ${signUpForm.name.trim()}`,
                });
                notificationManage(
                  "success",
                  "You're successfully registered. Please login to continue!"
                );
                clearSignUpForm();
                prepareSignIn();
              })
              .catch((err) => {
                console.error(err);
                notificationManage(
                  "danger",
                  "An error occurred during sign-up"
                );
              });
            break;
          case 400:
            response
              .json()
              .then((value) => {
                switch (value.message) {
                  case !signUpForm.isFriend &&
                    "Email address already in use as a friend":
                    window.location.href = "/account-role-exist?role=true";
                    break;
                  case signUpForm.isFriend &&
                    "Email address already in use as a friend":
                    window.location.href = `/social-login-exist?email=${signUpForm.email}`;
                    break;
                  case signUpForm.isFriend &&
                    "Email address already in use as a newcomer":
                    window.location.href = "/account-role-exist?role=false";
                    break;
                  case !signUpForm.isFriend &&
                    "Email address already in use as a newcomer":
                    window.location.href = `/social-login-exist?email=${signUpForm.email}`;
                    break;
                  default:
                    notificationManage(
                      "danger",
                      `Something went wrong, Error Code: ${response.status}`
                    );
                    break;
                }
              })
              .catch((err) => {
                console.error(err);
                notificationManage(
                  "danger",
                  "An error occurred during sign-up"
                );
              });
            break;
          default:
            break;
        }
      }
    } catch (err) {
      console.error(err);
      notificationManage("danger", "An error occurred during sign-up");
    }
  };

  const handleSignInClick = () => {
    setIsSignIn(true);
    clearSignUpForm();
  };

  const prepareSignIn = () => {
    setIsSignIn(true);
    setSignInForm((prev) => ({
      ...prev,
      email: signUpForm.email,
      password: signUpForm.password,
    }));
  };

  const accountTypeInfoPopover = (
    <Popover className="account-type-info">
      <Popover.Content className="account-type-info-content">
        <div>
          <div className="account-type-info-content-title">Newcomer</div>
          <div className="account-type-info-content-description">
            As a newcomer you can post questions regarding the city you are
            moving to
          </div>
        </div>
        <div>
          <div className="account-type-info-content-title">Become a Friend</div>
          <div className="account-type-info-content-description">
            As a friend you can provide information on newcomer questions
          </div>
        </div>
      </Popover.Content>
    </Popover>
  );

  const selectAccountPopover = (
    <Popover className="account-type-select">
      <Popover.Content className="account-type-select-content">
        <div>
          <div className="account-type-select-content-description">
            Please select account type first
          </div>
        </div>
      </Popover.Content>
    </Popover>
  );

  const passwordInvalidError = () => {
    const passStatus = validatePassword(signUpForm.password);
    return (
      !signUpError.password && signUpForm.password !== "" &&
      (
        <div className={passStatus === passwordState.INVALID_PASSWORD ? `input-error-message` : `input-success-message`}>
          {passStatus}
        </div>
      )
    );
  };

  return (
    <>
      {fromHeader && (
        <div className="account-type">
          <div>
            Account type *
            <OverlayTrigger placement="bottom" overlay={accountTypeInfoPopover}>
              <i className="fas fa-info-circle"></i>
            </OverlayTrigger>
          </div>
          <div>
            <input
              type="radio"
              id="newcomer"
              name="account-type"
              defaultChecked={
                signUpForm.isFriend !== undefined && !signUpForm.isFriend
              }
              onChange={() => {
                updateSignUpForm("isFriend", false);
                setAccountTypeSelected(true);
              }}
            />
            <label htmlFor="newcomer">Newcomer</label>
          </div>
          <div>
            <input
              type="radio"
              id="friend"
              name="account-type"
              defaultChecked={
                signUpForm.isFriend !== undefined && signUpForm.isFriend
              }
              onChange={() => {
                updateSignUpForm("isFriend", true);
                setAccountTypeSelected(true);
              }}
            />
            <label htmlFor="friend">Friend</label>
          </div>
          {signUpError.isFriend && (
            <div className="input-error-message">Select an account type</div>
          )}
        </div>
      )}
      <SocialLoginSignup
        loginProviderDetails={signupProviderDetails}
        fromHeader={fromHeader}
        accountTypeSelected={accountTypeSelected}
        fromSignup={true}
        friendLocation={friendLocation}
        currentPageUrl={currentPageUrl}
        checkIsFriend={checkIsFriend}
      />
      <label for="name">Name *</label>
      <OverlayTrigger
        placement="top"
        overlay={
          fromHeader && !accountTypeSelected ? (
            selectAccountPopover
          ) : (
            <span></span>
          )
        }
      >
        <input
          type="text"
          id="name"
          value={signUpForm.name}
          disabled={fromHeader && !accountTypeSelected ? true : false}
          placeholder="Enter your name"
          onChange={(e) => updateSignUpForm("name", e.target.value)}
        />
      </OverlayTrigger>
      {signUpError.name && (
        <div className="input-error-message">A name is required</div>
      )}
      <label className="popup-login-input-label" for="email">
        Email *
      </label>
      <OverlayTrigger
        placement="top"
        overlay={
          fromHeader && !accountTypeSelected ? (
            selectAccountPopover
          ) : (
            <span></span>
          )
        }
      >
        <input
          autoComplete="off"
          type="text"
          id="email"
          value={signUpForm.email}
          placeholder="Enter your email address"
          disabled={fromHeader && !accountTypeSelected ? true : false}
          onChange={(e) => updateSignUpForm("email", e.target.value)}
        />
      </OverlayTrigger>
      {signUpError.email && (
        <div className="input-error-message">
          A valid email address is required
        </div>
      )}
      <label className="popup-login-input-label" for="password">
        Password *
        <OverlayTrigger placement="right" overlay={passwordPolicy}>
              <i className="fas fa-info-circle pl-1"></i>
        </OverlayTrigger>
      </label>
      <div className="password">
        <OverlayTrigger
          placement="top"
          overlay={
            fromHeader && !accountTypeSelected ? (
              selectAccountPopover
            ) : (
              <span></span>
            )
          }
        >
          <input
            type={passwordVisible ? "text" : "password"}
            id="password"
            value={signUpForm.password}
            disabled={fromHeader && !accountTypeSelected ? true : false}
            placeholder="Enter your password"
            onChange={(e) => updateSignUpForm("password", e.target.value)}
          />
        </OverlayTrigger>
        {passwordVisible ? (
          <i
            className={`${
              !accountTypeSelected && "login-password-disable-items"
            } fas fa-eye-slash password-visible-icon`}
            disabled={true}
            onClick={() => setPasswordVisible(false)}
          ></i>
        ) : (
          <i
            className={`${
              !accountTypeSelected && "login-password-disable-items"
            } fas fa-eye password-visible-icon`}
            onClick={() => setPasswordVisible(true)}
          ></i>
        )}
      </div>
      {signUpError.password && (
        <div className="input-error-message">A password is required</div>
      )}
      {passwordInvalidError()}
      <div className="terms-of-service">
        <OverlayTrigger
          placement="top"
          overlay={
            fromHeader && !accountTypeSelected ? (
              selectAccountPopover
            ) : (
              <span></span>
            )
          }
        >
          <input
            type="checkbox"
            id="tos-agreed"
            className="tos-agreed-checkbox"
            checked={signUpForm.tosAgreed}
            disabled={fromHeader && !accountTypeSelected ? true : false}
            onChange={(e) => updateSignUpForm("tosAgreed", e.target.checked)}
          />
        </OverlayTrigger>
        <div>
          I agree to the{" "}
          <Link to="/terms-and-conditions" target="_blank">
            Terms of Service
          </Link>{" "}
          &{" "}
          <Link to="/privacy-policy" target="_blank">
            Privacy Policy
          </Link>
        </div>
      </div>
      <Button
        disabled={!signUpForm.tosAgreed}
        onClick={() => submitSignUp()}
        className={`${!signUpForm.tosAgreed && "disable-sign-up-button"} `}
      >
        Sign Up
      </Button>
      <div className="toggle-view" onClick={() => handleSignInClick()}>
        Already have an account? <span>Sign In Here</span>
      </div>
    </>
  );
};
