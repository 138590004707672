import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { OverlayTrigger, Spinner } from "react-bootstrap";
import { Calendar3 } from "react-bootstrap-icons";
import Button from "react-bootstrap/Button";
import { useHistory } from "react-router-dom";
import {
  schedulerActions,
  schedulerStatus,
} from "../../constants/scheduleConstants";
import { rescheduleSuggestAppointments } from "../../util/APIUtils";
import { notificationManage } from "../../util/NotificationUtils";
import AddToCalenderModal from "../add-to-calendar-modal/Add-To-Calender-Modal";
import DeclineCallPopUp from "../decline-call-modal/Decline-Call-Modal";
import FriendProfile from "../friend-schedule-call/friend-Profile/FriendProfile";
import ScheduleCalendar from "../schedule-calendar/ScheduleCalendar";
import "./Schedule-details.scss";
import {
  SCHEDULER_NAVIGATOR,
  SCHEDULER_NAVIGATOR_ACTION,
} from "../../constants";
import { Tooltip } from "antd";

const ScheduleDetails = ({
  name,
  customerUsername,
  currentUserName,
  isFriend,
  isLast,
  scheduleTitle,
  createdDate,
  scheduledDate,
  duration,
  description,
  endTime,
  tenantId,
  resourceID,
  resourceName,
  appointmentId,
  status,
  friendProfile,
  suggestedStartDateTime,
  suggestedEndDateTime,
  rescheduledStartDateTime,
  rescheduledEndDateTime,
  timeZone,
  schedularId,
  updatedAt,
  setScheduleWithUpdatedData,
  updator,
  consumerID,
  consumerName,
  meetingLink,
}) => {
  const history = useHistory();
  const {
    SCHEDULED,
    SUGGESTED,
    RESCHEDULED,
    APPROVED,
    CONFIRMED,
    DECLINED,
    CANCELLED,
  } = schedulerStatus;
  const { suggest, reschedule, confirm, accept, cancel, decline } =
    schedulerActions;
  const [displayModal, setDisplayModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [schedulerCalandarPopUp, setSchedulerCalandarPopUp] = useState({
    displayStatus: false,
    modalTitle: "",
    modalSubTitle: "",
    buttonTitle: "",
    actionType: "",
  });
  const [showPopUp, setShowPopUp] = useState(false);
  const [updatorName, setUpdatorName] = useState("");
  const handleCloseModal = () => {
    setShowPopUp(false);
  };

  const declineData = {
    abortReason: null,
    abortReasonDescription: null,
  };

  const handleSubmiting = (checkedValues, reasons) => {
    declineData.abortReason = checkedValues[0];
    declineData.abortReasonDescription = reasons ?? null;
    if (!isFriend) {
      onActionSchedule(appointmentId, cancel, declineData);
    } else {
      onActionSchedule(appointmentId, decline, declineData);
    }
    setShowPopUp(false);
  };

  const date = scheduledDate ? scheduledDate.split("T")[0] : "";
  const startTimeNew = scheduledDate
    ? scheduledDate?.split("T")[1]?.slice(0, 8)
    : "";
  const endTimeNew = endTime ? endTime?.split("T")[1]?.slice(0, 8) : "";

  const ScheduleDetails = useRef({
    eventTitle: null,
    eventDate: null,
    startTimeForModel: null,
    endTime: null,
  });

  const showModal = () => {
    if (!displayModal) {
      ScheduleDetails.current.startTimeForModel = startTimeNew;
      ScheduleDetails.current.eventTitle = scheduleTitle;
      ScheduleDetails.current.eventDate = date;
      ScheduleDetails.current.endTime = endTimeNew;
      return setDisplayModal(true);
    }
    return setDisplayModal(false);
  };

  const navigateToReschedule = () => {
    history.push(`/friend/${friendProfile.id}/${appointmentId}/re-schedule`);
  };

  const onActionSchedule = async (appointmentID, scheduleAction, data = {}) => {
    let notification = {
      type: "",
      msg: "",
      status: false,
    };
    let action = "";
    if (
      scheduleAction === SCHEDULER_NAVIGATOR.CANCEL ||
      scheduleAction === SCHEDULER_NAVIGATOR.DECLINE
    ) {
      action = SCHEDULER_NAVIGATOR_ACTION.ABORT;
    } else if (
      scheduleAction === SCHEDULER_NAVIGATOR.CONFIRM ||
      scheduleAction === SCHEDULER_NAVIGATOR.ACCEPT
    ) {
      action = SCHEDULER_NAVIGATOR_ACTION.CONFIRM;
    } else if (
      scheduleAction === SCHEDULER_NAVIGATOR.SUGGEST ||
      scheduleAction === SCHEDULER_NAVIGATOR.RESCHEDULE
    ) {
      action = SCHEDULER_NAVIGATOR_ACTION.RESCHEDULE;
    } else if (scheduleAction === SCHEDULER_NAVIGATOR.REMINDER) {
      action = SCHEDULER_NAVIGATOR_ACTION.REMINDER;
    }
    if (appointmentID) {
      setLoading(true);
      try {
        let responseData = await rescheduleSuggestAppointments(
          data,
          action,
          appointmentID,
          schedularId,
          scheduleAction,
          timeZone
        );
        if (responseData) {
          setScheduleWithUpdatedData(responseData, appointmentID);
          notification = {
            type: "success",
            msg: getSuccessMsg(scheduleAction),
            status: true,
          };
        } else {
          notification = {
            type: "danger",
            msg: "Error Occur Please Try Again",
            status: true,
          };
        }
        setLoading(false);
      } catch (error) {
        notification = {
          type: "danger",
          msg: "Error Occur Please Try Again",
          status: true,
        };
        setLoading(false);
      }
    }
    notification.status &&
      notificationManage(notification.type, notification.msg);
    return true;
  };

  const onActionSuggestAndReshedule = async (actionType) => {
    setSchedulerCalandarPopUp({
      displayStatus: true,
      modalTitle:
        actionType === suggest
          ? "Suggest a time"
          : actionType === reschedule
          ? "Reschedule the call"
          : "",
      modalSubTitle:
        actionType === suggest || actionType === reschedule
          ? `Choose date and time from ${resourceName}’s calendar`
          : "",
      buttonTitle:
        actionType === suggest
          ? "Submit"
          : actionType === reschedule
          ? "Reschedule"
          : "",
      actionType: actionType,
    });
  };

  const onSubmitSuggestAndReshedule = (dateTimeObject) => {
    let actionType = schedulerCalandarPopUp.actionType;
    if (actionType) {
      setSchedulerCalandarPopUp({
        displayStatus: false,
        modalTitle: "",
        modalSubTitle: "",
        buttonTitle: "",
        actionType: "",
      });
      onActionSchedule(appointmentId, actionType, dateTimeObject);
    }
  };

  const onCloseSchedulerCalendar = () => {
    setSchedulerCalandarPopUp({
      ...schedulerCalandarPopUp,
      displayStatus: false,
    });
  };

  const schdeuleRescheduleSuggestTitleName = (
    status,
    isFriend,
    currentUserName,
    userName
  ) => {
    let name = isFriend ? userName : currentUserName;
    let title = "";
  
    if (status === SUGGESTED) {
      title = "suggested a new date & time";
    } else if (status === RESCHEDULED) {
      title = isFriend ? "requested to reschedule the call" : "has rescheduled the call";
    }
    return `${name} ${title}.`;
  };

  const confirmationMessage = (status, isFriend, customerUsername) => {
    if (status === RESCHEDULED) {
      return isFriend
        ? "Reschedule confirmation pending"
        : `Reschedule confirmation pending by ${customerUsername}`;
    } else if (status === SUGGESTED) {
      return isFriend
        ? `Reschedule confirmation pending by ${customerUsername}`
        : "Reschedule confirmation pending";
    } else if (status === SCHEDULED) {
      return "Pending";
    }
  };

  const confirmation_pending_status = confirmationMessage(
    status,
    isFriend,
    customerUsername
  );

  const getSuccessMsg = (actionType) => {
    switch (actionType) {
      case confirm:
        return "Reschedule Confirmed!";
      case accept:
        return "Appointment Accepted!";
      case reschedule:
      case suggest:
        return "New Date & Time Sent!";
      case cancel:
        return "Appointment Cancelled!";
      case decline:
        return "Appointment Declined!";
      default:
        return "";
    }
  };

  useEffect(() => {
    if (updator === resourceID) {
      setUpdatorName(resourceName);
    } else if (updator === consumerID) {
      setUpdatorName(consumerName);
    }

    if (!updator) {
      setUpdatorName(
        [SCHEDULED, RESCHEDULED, CANCELLED, CONFIRMED].includes(status)
          ? consumerName
          : resourceName
      );
    }
  }, [updator, status]);

  const scheduleStatus = (status) => {
    switch (status) {
      case SCHEDULED:
      case SUGGESTED:
      case RESCHEDULED:
        return (
          <p className="common-details-status common-details-status-warning">
            {confirmation_pending_status}
          </p>
        );
      case APPROVED:
      case CONFIRMED:
        return (
          <p className="common-details-status common-details-status-success">
            {"Confirmed by " + updatorName}
          </p>
        );
      case DECLINED:
        return (
          <p className="common-details-status common-details-status-danger">
            {"Declined by " + updatorName}
          </p>
        );
      case CANCELLED:
        return (
          <p className="common-details-status common-details-status-danger">
            {"Cancelled by " + updatorName}
          </p>
        );
      default:
        return <></>;
    }
  };

  const scheduleDetailsButtons = (status) => {
    switch (status) {
      case SCHEDULED:
        return (
          <div className="common-button-container">
            <div class="common-button-sub-container">
              <Button
                className="btn common-button common-button-confirm-schedule"
                onClick={() => {
                  isFriend
                    ? onActionSchedule(appointmentId, accept)
                    : onActionSuggestAndReshedule(reschedule);
                }}
              >
                {isFriend ? "Confirm" : "Reschedule"}
              </Button>
            </div>
            <div class="common-button-sub-container">
              <Button
                className="btn common-button common-button-outline-suggestion btn-outline"
                onClick={() => setShowPopUp(true)}
              >
                {isFriend ? "Decline" : "Cancel the call"}
              </Button>
            </div>
          </div>
        );
      case APPROVED:
      case CONFIRMED:
        return (
          <div className="common-button-container">
            <div className="common-button-sub-container">
              <Button
                className="btn common-button common-button-join-suggest"
                onClick={() => {
                  if (meetingLink) {
                    window.open(meetingLink, "_blank");
                  } else {
                    notificationManage(
                      "danger",
                      "Something went wrong with meeting link"
                    );
                  }
                }}
              >
                Join
              </Button>
            </div>

            {!isFriend && (
              <div className="d-flex justify-content-end common-button-sub-container-reschedule">
                <Button
                  className="btn common-button common-button-confirm-schedule"
                  onClick={() => onActionSuggestAndReshedule(reschedule)}
                >
                  Reschedule
                </Button>
              </div>
            )}
          </div>
        );

      // case DECLINED: {requested to hide the button for now}
      case CANCELLED:
        return (
          <div className="common-button-container">
            {!isFriend && status === DECLINED && (
              <div class="common-button-sub-container">
                <Button
                  className="btn common-button common-button-confirm-schedule"
                  onClick={() => navigateToReschedule()}
                >
                  Scheduled calls
                </Button>
              </div>
            )}
          </div>
        );
      case SUGGESTED:
        return (
          <div className="common-button-container">
            <div class="common-button-sub-container">
              {!isFriend && (
                <Button
                  className="btn common-button common-button-confirm-schedule"
                  onClick={() =>
                    onActionSchedule(appointmentId, isFriend ? accept : confirm)
                  }
                >
                  Confirm
                </Button>
              )}
            </div>
            <div class="common-button-sub-container">
              <Button
                className="btn common-button common-button-join-suggest"
                onClick={() =>
                  onActionSuggestAndReshedule(isFriend ? suggest : reschedule)
                }
                disabled={isFriend}
              >
                {isFriend ? "Suggest a new time" : "Reschedule"}
              </Button>
            </div>
            <div class="common-button-sub-container">
              {!isFriend && (
                <Button
                  className="btn common-button common-button-outline-suggestion btn-outline mt-0 mb-2"
                  onClick={() => setShowPopUp(true)}
                >
                  Cancel
                </Button>
              )}
            </div>
          </div>
        );
      case RESCHEDULED:
        return (
          <div className="common-button-container">
            {isFriend ? (
              <>
                <div class="common-button-sub-container">
                  <Button
                    className="btn common-button common-button-confirm-schedule mt-0 mb-2"
                    onClick={() => onActionSchedule(appointmentId, accept)}
                  >
                    Confirm
                  </Button>
                </div>
                <div class="common-button-sub-container">
                  <Button
                    className="btn common-button common-button-join-suggest"
                    onClick={() => onActionSuggestAndReshedule(suggest)}
                  >
                    Suggest a new time
                  </Button>
                </div>
              </>
            ) : (
              <>
                <div class="common-button-sub-container">
                  <Button
                    className="btn common-button common-button-confirm-schedule"
                    disabled
                  >
                    Reschedule
                  </Button>
                </div>
                <div class="common-button-sub-container">
                  <Button
                    className="btn common-button common-button-outline-suggestion btn-outline mt-0 mb-2"
                    onClick={() => setShowPopUp(true)}
                  >
                    Cancel the call
                  </Button>
                </div>
              </>
            )}
          </div>
        );
      default:
        return <></>;
    }
  };

  return (
    <div className={`details-container ${isLast ? "no-margin-bottom" : ""}`}>
      {loading && (
        <div className="details-container-spinner">
          <Spinner animation="border" variant="primary" />
        </div>
      )}
      <div className="details-sub-container">
        <div className="friend-container">
          <FriendProfile
            friendProfile={friendProfile}
            isStatusPreview={false}
            isFriend={isFriend}
          />
        </div>
        <div className="friend-container-vertical-line"></div>
        <div className="details-content">
          <div className="description-div">
            <p className="date-display">{createdDate}</p>
          </div>
          <div className="description-div">
            <p className="header-details description">{description}</p>
          </div>
          <div className="schedule-details">
            <div className="duration-container">
              <p className="title-for">Duration:</p>
              <p className="description-for description-for-duration">
              <span className="duration">{duration}</span> Minutes
              </p>
              <p>
                <div className="add-to-calendar-button">
                  <OverlayTrigger
                    key="right"
                    placement="right"
                    overlay={
                      <div
                        style={{
                          position: "absolute",
                          backgroundColor: "#ffffff",
                          padding: "2px 10px",
                          marginLeft: "8px",
                          color: "black",
                          borderRadius: 5,
                          border: "1px solid #7ca1d4",
                          fontWeight: "300px",
                          fontSize: "11px",
                          lineHeight: "17px",
                        }}
                      >
                        Add to Calendar
                      </div>
                    }
                  >
                    <Tooltip placement="right" title="Add to calendar" trigger={"hover"}>
                      <button
                        className="btn-floating btn btn-primary btn-add-to-calendar my-xl-n1 ml-4"
                        onClick={() => showModal()}
                      >
                        <Calendar3 className="calendar p-0 bi bi-calendar3"></Calendar3>
                      </button>
                    </Tooltip>
                  </OverlayTrigger>
                </div>
              </p>
            </div>
            <div className="date-container">
              <p className="title-for">Date:</p>
              <p className="description-for description-for-date">
                {moment(scheduledDate, moment.ISO_8601).format("Do MMMM, YYYY")}
              </p>
            </div>
            <div className="time-container">
              <div className="time-with-timeZone">
                <p className="title-for title-for-time">Time:</p>
                <div className="title-for-time-display">
                  <p className="description-for description-for-time">
                    {`${moment(scheduledDate, moment.ISO_8601).format(
                      "HH.mm"
                    )} -  ${moment(endTime, moment.ISO_8601).format("HH.mm")}`}
                  </p>
                  <p className="details-timezone suggestion-details-timezone">
                    {timeZone}
                  </p>
                </div>
              </div>
            </div>
            {status !== SUGGESTED && status !== RESCHEDULED && (
              <div className="time-container">
                <div className="time-with-timeZone">
                  <p className="title-for title-for-time">Status:</p>
                  {scheduleStatus(status)}
                </div>
              </div>
            )}

            {/* Schedule Suggestion OR Reschedule */}
            {(status === SUGGESTED || status === RESCHEDULED) && (
              <>
                <hr className="line-break" />
                <div>
                  <div className="details-suggestion-reshedule-title">
                    <div class="warning-msg" role="alert">
                      {schdeuleRescheduleSuggestTitleName(
                        status,
                        isFriend,
                        currentUserName,
                        name
                      )}
                    </div>
                    <div className="description-div-suggest-reschedule">
                      <p className="date-display">{updatedAt}</p>
                    </div>
                  </div>

                  <div className="suggestion-details">
                    <div>
                      <div className="duration-container">
                        <p className="title-for bold-text">New date:</p>
                        <p className="suggestion-details-text">
                          {moment(
                            status === SUGGESTED
                              ? suggestedStartDateTime
                              : rescheduledStartDateTime,
                            moment.ISO_8601
                          ).format("Do MMMM, YYYY")}
                        </p>
                      </div>
                      <div className="duration-container">
                        <p className="title-for duration-container-new-time bold-text">
                          New time:
                        </p>
                        <div className="duration-container-with-timezone">
                          <p className="suggestion-details-text">
                            {`${moment(
                              status === SUGGESTED
                                ? suggestedStartDateTime
                                : rescheduledStartDateTime,
                              moment.ISO_8601
                            ).format("HH.mm")} -  ${moment(
                              status === SUGGESTED
                                ? suggestedEndDateTime
                                : rescheduledEndDateTime,
                              moment.ISO_8601
                            ).format("HH.mm")}`}
                          </p>
                          <p className="suggestion-details-timezone">
                            {timeZone}
                          </p>
                        </div>
                      </div>
                      <div className="time-container">
                        <div className="time-with-timeZone">
                          <p className="title-for title-for-time">Status:</p>
                          {scheduleStatus(status)}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
            {scheduleDetailsButtons(status)}
          </div>

          <AddToCalenderModal
            showModal={displayModal}
            hideModal={showModal}
            startTime={ScheduleDetails.current.startTimeForModel}
            endTime={ScheduleDetails.current.endTime}
            eventTitle={
              isFriend
                ? ScheduleDetails.current.eventTitle
                : "Scheduled a call with " + friendProfile.name
            }
            eventDate={ScheduleDetails.current.eventDate}
          />

          <ScheduleCalendar
            displayStatus={schedulerCalandarPopUp.displayStatus}
            resourceID={resourceID}
            modalTitle={schedulerCalandarPopUp.modalTitle}
            modalSubTitle={schedulerCalandarPopUp.modalSubTitle}
            buttonTitle={schedulerCalandarPopUp.buttonTitle}
            tenantId={tenantId}
            duration={duration || 15}
            onClose={onCloseSchedulerCalendar}
            onSubmit={onSubmitSuggestAndReshedule}
          />
        </div>
      </div>

      <DeclineCallPopUp
        isFriend={isFriend}
        show={showPopUp}
        handleClose={handleCloseModal}
        handleSubmit={handleSubmiting}
      />
    </div>
  );
};
export default ScheduleDetails;
