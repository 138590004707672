import Cookies from "js-cookie";
import { useEffect, useRef, useState } from "react";
import { ChevronLeft, ChevronRight } from "react-bootstrap-icons";
import { Typeahead } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";
import MetaTags from "react-meta-tags";
import { useLocation } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import CityCard from "../../components/city-card/City-Card";
import HomeCityCategory from "../../components/home-city-categories/Home-City-Category";
import WhosAFriendCategory from "../../components/home-whos-friend-category/Whos-Friend-Category";
import PopupLogin from "../../components/popup-login/Popup-Login";
import SearchModal from "../../components/search-modal/Search-Modal";
import CookieMessage from "../../components/cookie-message/CookieMessage";
import {
  ANALYTICS,
  ENV,
  ENVIROMENTS,
  SEARCH_HISTORY,
  USER_IDENTIFIER,
} from "../../constants";
import {
  activateAccount,
  getCategoryList,
  getCities,
  getCurrentUser,
  getLocInfo,
  getProfile,
  postAnalytics,
} from "../../util/APIUtils";
import {
  CURRENT_LOCATION,
  CommonUtils,
  LOCATION_PROP,
  saveLocationInfo,
} from "../../util/CommonUtils";
import { notificationManage } from "../../util/NotificationUtils";
import { getSearchHistory } from "../../util/Utils";
import "./Home.scss";
import SocialSignUp from "../../components/social-signup/Social-SignUp";

const searchBtn = "/assets/img/search.png";
const settings = {
  dots: false,
  infinite: false,
  speed: 500,
  slidesToShow: 4,
  slidesToScroll: 1,
  nextArrow: <ChevronRight className="slider-arrow" />,
  prevArrow: <ChevronLeft className="slider-arrow" />,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
        infinite: false,
        dots: false,
      },
    },
    {
      breakpoint: 668,
      settings: {
        slidesToShow: 3,
        arrows: false,
        slidesToScroll: 1,
        infinite: true,
        dots: false,
        swipeToSlide: true,
        touchMove: true,
        swipe: true,
      },
    },
    {
      breakpoint: 992,
      settings: {
        slidesToShow: 3,
        arrows: false,
        slidesToScroll: 1,
        infinite: true,
        dots: false,
        swipeToSlide: true,
        touchMove: true,
        swipe: true,
      },
    },
  ],
};
const campignParams = [
  "utm_content",
  "utm_term",
  "utm_id",
  "utm_source",
  "utm_medium",
  "utm_campaign",
];

const Home = (props) => {
  const { history } = props;
  const [cities, setCities] = useState([]);
  const [categories, setCategories] = useState([]);
  const [personTypes, setPersonTypes] = useState([]);
  const [showSearchError, setShowSearchError] = useState(false);
  const [currentUser, setCurrentUser] = useState();
  const [searchHistory, setSearchHistory] = useState([]);
  const [showSearchHistory, setShowSearchHistory] = useState(false);
  const [requireLogin, setRequireLogin] = useState(false);
  const searchRef = useRef(null);
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const error = urlParams.get("error");
  const [customSearch, setCustomSearch] = useState({
    showModal: false,
    searchedCity: null,
  });
  const [isSignUp, setIsSignUp] = useState(false);
  const [socialSignup, setSocialSignup] = useState(false)

  const selectCity = (city) => {
    if (city) {
      if (localStorage.getItem("visitorDetails") || currentUser?.isFriend) {
        history.push(`/city/${city.id}`);
      } else {
        setCustomSearch({ showModal: true, searchedCity: city });
      }
    } else {
      setShowSearchError(true);
    }
  };

  const handleSearchChange = (selected) => {
    if (selected.length !== 0) {
      setCustomSearch({ searchedCity: selected[0] });
      localStorage.getItem("visitorDetails") || currentUser?.isFriend
        ? history.push(`/city/${selected[0]?.id}`)
        : setCustomSearch({ showModal: true, searchedCity: selected[0] });
    }
  };

  const handleSearchTextChange = (text) => {
    setShowSearchError(text?.length === 0)
  };

  const handleHidePopupLogin = () => {
    setRequireLogin(!requireLogin);
    if (props.match.path === "/login" || props.match.path === "/signup") {
      history.push("/");
    }
  };

  const deleteSearchHistory = () => {
    setSearchHistory([]);
    localStorage.removeItem(SEARCH_HISTORY);
  };

  const getCampaignInfo = () => {
    const campaignIn = {};
    campignParams.forEach((e) => {
      if (urlParams.has(e)) {
        // recreate the object attribute
        campaignIn[e?.split("_")[1]] = urlParams.get(e);
      }
    });
    return campaignIn;
  };

  useEffect(() => {
    if (window.location.pathname === "/signup") {
      if (error) {
        notificationManage("danger", error, "No account!");
        props.history.push("/signup");
      }
      setIsSignUp(true);
      setRequireLogin(true);
    }

    if (window.location.pathname === "/social-signup") {
      setSocialSignup(true)
    }

    setSearchHistory(getSearchHistory());
  }, []);

  useEffect(() => {
    document.addEventListener(
      "click",
      (event) => {
        if (!searchRef?.current?.contains(event.target)) {
          setShowSearchHistory(false);
        }
      },
      true
    );
    return () => {
      document.removeEventListener(
        "click",
        (event) => {
          if (!searchRef?.current?.contains(event.target)) {
            setShowSearchHistory(false);
          }
        },
        true
      );
    };
  }, [searchRef]);

  useEffect(() => {
    getCities().then((res) => {
      setCities(res);
    });
  }, []);

  useEffect(() => {
    getCategoryList().then((res) => {
      setCategories(res);
    });
  }, []);

  useEffect(() => {
    setPersonTypes(CommonUtils.getPersonTypes());
  }, []);

  useEffect(() => {
    getCurrentUser().then((currentUser) => {
      getProfile(currentUser.id).then((currentUserProfile) => {
        setCurrentUser(currentUserProfile);
      }).catch(console.warn);
    }).catch(console.warn);
  }, []);

  useEffect(() => {
    if (props.match.path === "/activate-account/:token") {
      activateAccount(props?.match?.params?.token)
        .then(() => {
          notificationManage("success", "Account activated successfully");
        })
        .catch((err) => {
          err?.then((resp) => {
            resp.message
              ? notificationManage("danger", resp.message)
              : notificationManage("danger", "Something went wrong!");
          });
        });
    }
  }, [props.match?.params?.token, props.match.path]);

  useEffect(() => {
    if (props.match.path === "/login") {
      setRequireLogin(true);
    }
  }, []);

  useEffect(() => {
    if (
      campignParams.some((e) => urlParams.has(e)) &&
      ENV === ENVIROMENTS.PRODUCTION
    ) {
      navigator.geolocation
        ? navigator.geolocation.getCurrentPosition(
            (ctx) => geoLocHandler(ctx),
            (ctx) => geoLocHandler(ctx),
            LOCATION_PROP
          )
        : postUtmAnalyticsWithoutFrom();
    }
  }, []);

  const geoLocHandler = (ctx) => {
    const cords = ctx?.coords ?? null;
    if (cords) {
      const prevLocStr = Cookies.get(CURRENT_LOCATION);
      if (prevLocStr) {
        const prevLocInfo = JSON.parse(prevLocStr);
        if (
          prevLocInfo?.latitude === cords?.latitude &&
          prevLocInfo?.longitude === cords?.longitude
        ) {
          if (prevLocInfo?.name) {
            postAnalytics({
              userIdentifier: Cookies.get(USER_IDENTIFIER) ?? "anonymous",
              analyticType: ANALYTICS.UTM_SOURCE,
              campaign: getCampaignInfo(),
              fromLocation: prevLocInfo?.name,
            }).catch(console.error);
          }
        } else {
          fetchLocationInfo(cords?.latitude, cords?.longitude);
        }
      } else {
        fetchLocationInfo(cords?.latitude, cords?.longitude);
      }
    } else {
      postUtmAnalyticsWithoutFrom();
    }
  };

  const fetchLocationInfo = (lat, lng) => {
    getLocInfo(lat, lng).then((res) => {
      const currCity = res[0]?.display_name;
      if (currCity) {
        saveLocationInfo(lat, lng, currCity);
        postAnalytics({
          userIdentifier: Cookies.get(USER_IDENTIFIER) ?? "anonymous",
          analyticType: ANALYTICS.UTM_SOURCE,
          campaign: getCampaignInfo(),
          fromLocation: currCity,
        }).catch(console.error);
      } else {
        postUtmAnalyticsWithoutFrom();
      }
    });
  };

  const postUtmAnalyticsWithoutFrom = () => {
    postAnalytics({
      userIdentifier: Cookies.get(USER_IDENTIFIER) ?? "anonymous",
      analyticType: ANALYTICS.UTM_SOURCE,
      campaign: getCampaignInfo(),
    }).catch(console.error);
  };

  return (
    <section className="content-home">
      <CookieMessage />
      <div className="banner">
        <PopupLogin
          show={requireLogin}
          onHide={() => {
            handleHidePopupLogin();
          }}
          name="Sign In"
          isSignUp={isSignUp}
          fromHeader={isSignUp}
        />
        <div className="banner-image">
          <div className="banner-content">
            <h2>Are you settling down in a new city?</h2>
            <div className="banner-content-header px-4">
              Learn about your new city from your new friends who are living
              there
            </div>
            <div className="search mt-2">
              <Typeahead
                id="search-city"
                labelKey="name"
                minLength={1}
                options={cities}
                onChange={(selected) => {
                  handleSearchChange(selected);
                }}
                onInputChange={(text) => {
                  handleSearchTextChange(text);
                }}
                placeholder="Where to?"
                onFocus={() => {
                  setShowSearchHistory(true);
                }}
                onMenuToggle={() => {
                  setShowSearchHistory(false);
                }}
              />
              <button
                className="search-btn"
                onClick={() => selectCity(customSearch.searchedCity)}
              >
                <img src={searchBtn} alt="search" />
              </button>
            </div>
            <div ref={searchRef}>
              {showSearchHistory && searchHistory.length !== 0 && (
                <div ref={searchRef} className="banner-search-history">
                  <div className="banner-search-history-header">
                    <p>Search History</p>
                    <button
                      onClick={() => {
                        deleteSearchHistory();
                      }}
                    >
                      <i className="fas fa-trash-alt"></i>
                    </button>
                  </div>
                  <div className="banner-search-history-content">
                    {searchHistory.map(
                      (item, index) =>
                        item.name && (
                          <button
                            onClick={() => {
                              history.push(`/city-history/${item.id}`);
                            }}
                            key={index}
                          >
                            {item?.name !== "" && item?.name}
                          </button>
                        )
                    )}
                  </div>
                </div>
              )}
            </div>
            {showSearchError && (
              <div className="search-error-text">
                Please search and select the city
              </div>
            )}
          </div>
          <div className="banner-cities">
            <div className="mb-5 banner-cities-main">
              <h5 className="banner-cities-header">Top Cities</h5>
              <Slider {...settings}>
                {cities
                  .filter((cityCopy) => cityCopy.isActive === true)
                  .map((city, index) => (
                    <div
                      className="city-card-main"
                      key={index}
                      onClick={() => selectCity(city)}
                    >
                      <CityCard city={city} index={index}></CityCard>
                    </div>
                  ))}
              </Slider>
            </div>
          </div>
        </div>
      </div>
      <div className="home-feature">
        <div className="feature-container col-md-7">
          <HomeCityCategory />
        </div>
        <div className="become-friend-benifits col-md-5">
          <WhosAFriendCategory />
        </div>
      </div>
      <SearchModal
        personTypes={personTypes}
        categories={categories}
        showModal={customSearch.showModal}
        city={customSearch.searchedCity}
        history={history}
        setCustomSearch={setCustomSearch}
      />
      <SocialSignUp
        show={socialSignup}
        onClose={() => setSocialSignup(!socialSignup)}
      />
      {ENV === ENVIROMENTS.PRODUCTION && (
        <MetaTags>
          <title>Moving to Australia | Move2NewCity</title>
          <meta
            name="description"
            content="Explore the Move2NewCity website to get useful tips and informative insights through the online community on moving to Australia"
          />
          <meta
            name="keywords"
            content="moving to australia, relocating to australia, relocation services australia, settling in australia, migrating services australia, adelaide, brisbane, melbourne, sydney "
          />
        </MetaTags>
      )}
    </section>
  );
};

export default Home;
