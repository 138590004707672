import React, { useCallback } from "react";
import Cropper from "react-easy-crop";
import { getCroppedImg } from "../../util/CropUtils";
import AppConfirmationModal from "../app-confirmation-modal/App-Confirmation-Modal";

const CityBannerCrop = (props) => {
  const {
    open,
    crop,
    zoom,
    croppedAreaPixels,
    banners,
    setOpen,
    setCroppedAreaPixels,
    setCrop,
    setZoom,
  } = props;

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const showCroppedImage = useCallback(async () => {
    try {
      const croppedImage = await getCroppedImg(
        banners[banners.length - 1].objectUrl,
        croppedAreaPixels
      );
      banners[banners.length - 1].objectUrl = URL.createObjectURL(croppedImage);
      banners[banners.length - 1].file = croppedImage;
      setOpen(false);
    } catch (e) {
      console.error(e);
    }
  }, [croppedAreaPixels]);

  const handleClose = () => {
    setOpen(false);
  };

  const body = (
    <div className="city-form-input-banner-crop">
      <Cropper
        image={banners[banners.length - 1]?.objectUrl}
        crop={crop}
        zoom={zoom}
        aspect={5 / 2}
        onCropChange={setCrop}
        onCropComplete={onCropComplete}
        onZoomChange={setZoom}
      />
    </div>
  );

  return (
    <AppConfirmationModal
      open={open}
      title="Banner"
      body={body}
      action="Crop"
      onAction={showCroppedImage}
      onClose={handleClose}
    />
  );
};

export default CityBannerCrop;
