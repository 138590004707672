import { useState } from "react";
import { PencilFill, Trash } from "react-bootstrap-icons";
import { API_BASE_URL } from "../../constants";
import { deleteEvent } from "../../util/APIUtils";
import { notificationManage } from "../../util/NotificationUtils";
import ConfirmationModal from "../../components/confirmation-modal/Confirmation-Modal";
import { months } from "../../util/Utils";

const EventItem = ({ event, setEditEvent, toggleModal, refresh }) => {
  const [isTruncated, setIsTruncated] = useState(true);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [deleting, setDeleting] = useState(false);

  const formatDate = (date) => {
    const d = new Date(date);
    return `${months[d.getMonth()].slice(
      0,
      3
    )} ${d.getDate()} ${d.getFullYear()}`;
  };

  const handleDeleteClick = () => {
    setDeleting(true);
    deleteEvent(event.id)
      .then(() => {
        notificationManage("success", `Event for ${event.name} was deleted`);
      })
      .catch(() => {
        notificationManage("danger", "Something went wrong");
      })
      .finally(() => {
        setDeleting(false);
        refresh();
        setShowConfirmation(false);
      });
  };

  const getDeleteConfirmation = () => {
    setShowConfirmation(true);
  };

  return (
    <>
      <ConfirmationModal
        open={showConfirmation}
        isDeleting={deleting}
        body={`Are you sure you want delete ${event.name}?`}
        action="Yes"
        onAction={() => handleDeleteClick()}
        onClose={() => setShowConfirmation(false)}
      />
      <div className="events-item">
        <div className="events-item-truncate">
          <i
            className={`fas fa-chevron-${isTruncated ? "down" : "up"}`}
            onClick={() => setIsTruncated(!isTruncated)}
          />
        </div>
        <div className="events-item-image">
          <img
            src={`${API_BASE_URL}/v1/events/${event.id}/image?${Date.now()}`}
            alt="eventImg"
          />
        </div>
        <div className="events-item-name">{event.name}</div>
        <div className="events-item-cities">
          {event.cities.map((city) => (
            <div className="events-item-cities-item">{city.name}</div>
          ))}
        </div>
        <div
          className={`events-item-description ${
            isTruncated ? "events-item-description-truncated" : ""
          }`}
        >
          {event.description}
        </div>
        <div className="events-item-duration">
          {formatDate(event.startDate)} - {formatDate(event.endDate)}
        </div>
        <div className="events-item-action">
          <button
            onClick={() => {
              setEditEvent(event);
              toggleModal("addEventModal");
            }}
          >
            <PencilFill size={15} color="#fff" />
            <div>Edit</div>
          </button>
          <button onClick={() => getDeleteConfirmation()}>
            <Trash size={15} color="#fff" />
            <div>Delete</div>
          </button>
        </div>
      </div>
    </>
  );
};

export default EventItem;
