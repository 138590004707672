import React from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import "./App-Confirmation-Modal.scss";

const AppConfirmationModel = (props) => {
  const { open, title, body, action, onAction, onClose, modalClass } = props;

  return (
    <Modal
      centered
      show={open}
      onHide={onClose}
      backdrop="static"
      className={`modal-container ${modalClass}`}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="modal-body-content">{body}</div>
      </Modal.Body>
      <Modal.Footer className="p-4">
        <Button
          className="mr-3 button"
          variant="outline-primary"
          onClick={onClose}>
          Cancel
        </Button>
        <Button className="button" variant="primary" onClick={onAction}>
          {action}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AppConfirmationModel;
