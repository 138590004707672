import React from "react";
import { API_BASE_URL } from "../../constants";
import "./City-Card.scss";

function CityCard(props) {
  const imageUrl = `${API_BASE_URL}/v1/cities/${props.city.id}/image`;

  return (
    <div
      className="city-card d-flex align-items-end city-card-background"
      style={{ backgroundImage: `url(${imageUrl})` }}>
      <div className="city-card-overlay"></div>
      <div className="col-12 mb-4 city-card-content">
        <div className="row d-flex justify-content-center">
          <span className="city-card-title">{props?.city?.name}</span>
        </div>
        <div className="row d-flex justify-content-center">
          <span className="city-card-country-title">
            {props?.city?.country}
          </span>
        </div>
      </div>
    </div>
  );
}

export default CityCard;
