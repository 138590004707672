import { store } from "react-notifications-component";

export const notificationManage = (type, message, state) => {
  const title = (() => {
    if (state == "") {
      return null
    }
    switch (type) {
      case "success":
        return "Success!"
      case "default":
        return null
      default:
        return state ? state : "Oops!";
    }
  })()
  store.addNotification({
    title: title,
    message: message,
    type: type,
    insert: "top",
    container: "top-right",
    animationIn: ["animate__animated", "animate__fadeIn"],
    animationOut: ["animate__animated", "animate__fadeOut"],
    dismiss: {
      duration: 3000,
      onScreen: true,
    },
  });
};
