import React, { useEffect, useState } from "react";
import { Button, Card, Dropdown } from "react-bootstrap";
import { Filter } from "react-bootstrap-icons";
import { Link } from "react-router-dom";
import { ACCESS_TOKEN, API_BASE_URL } from "../../constants";
import { useVerified } from "../../context/UserContext";
import {
  followFriend,
  getCity,
  getCurrentUser,
  getFriendsByCityId,
  getProfile,
  unfollowFriend,
} from "../../util/APIUtils";
import { filterOption, filterOptionList, method } from "../../util/CityUtils";
import { notificationManage } from "../../util/NotificationUtils";
import { hideEmailCharacters, USER_ROLE } from "../../util/Utils";
import EmailVerificationModal from "../email-verification-modal/Email-verification-modal";
import { NoFriendsFound } from "../no-friends-found/No-Friends-Found";
import PopupLogin from "../popup-login/Popup-Login";
import RatingStar from "../rating-star/Rating-Star";
import "./City-Friend-Card.scss";

const CityFriendCard = (props) => {
  const [cityFriendsList, setCityFriendsList] = useState([]);
  const [friendsFollowingList, setFriendsFollowingList] = useState([]);
  const [requireLogin, setRequireLogin] = useState(false);
  const [cityName, setCityName] = useState("");
  const [isFollowButtonHover, setIsFollowButtonHover] = useState(false);
  const [currentUserProfile, setCurrentUserProfile] = useState({ id: null });
  const { verified } = useVerified();
  const [requireVerification, setRequireVerification] = useState(false);
  const [filter, setFilter] = useState(filterOption.ALL);
  const [sortedFriendsList, setSortedFriendsList] = useState([]);
  const { activeCategories } = props;
  const { currentUser } = useVerified();

  const handleRequireLogin = () => {
    setRequireLogin(!requireLogin);
  };

  const handleHideVerificationModal = () => {
    setRequireVerification(!requireVerification);
  };

  useEffect(() => {
    localStorage.getItem(ACCESS_TOKEN) &&
      getCurrentUser().then((currentUser) => {
        setCurrentUserProfile(currentUser);
        getProfile(currentUser.id).then((currentUserProfile) => {
          setFriendsFollowingList(currentUserProfile.following);
        });
      }).catch(console.warn);
  }, []);

  useEffect(() => {
    getFriendsByCityId(props.id)
      .then((data) => {
        setCityFriendsList(
          data.filter((friend) => friend.id !== currentUserProfile?.id)
        );
      })
      .catch(() => {
        notificationManage("danger", "Something went wrong");
      });
  }, [props.id, currentUserProfile]);

  useEffect(() => {
    getCity(props.id).then((data) => {
      setCityName(data.name);
    });
  }, [props.id]);

  useEffect(() => {
    try {
      setSortedFriendsList(method(filter, cityFriendsList, activeCategories));
    } catch (error) {
      notificationManage("danger", error);
    }
  }, [cityFriendsList, filter, activeCategories]);

  return (
    <div>
      <div className="city-friend-header mb-4">
        <p>Friends from {cityName}</p>
        <Dropdown drop="left" className="city-friend-header-dropdown">
          <Dropdown.Toggle className="city-friend-header-dropdown-toggle">
            <Filter />
          </Dropdown.Toggle>
          <Dropdown.Menu className="city-friend-header-dropdown-menu">
            {filterOptionList.map((item, index) => {
              return (
                <Dropdown.Item
                  className={filter === item.property && `item-active`}
                  key={index}
                  value={item.property}
                  onClick={() => {
                    setFilter(item.property);
                  }}>
                  {item.name}
                </Dropdown.Item>
              );
            })}
          </Dropdown.Menu>
        </Dropdown>
      </div>
      <PopupLogin
        show={requireLogin}
        onHide={() => {
          handleRequireLogin();
        }}
        name="Sign In"
      />
      <EmailVerificationModal
        isShow={requireVerification}
        handleVerificationModal={() => {
          handleHideVerificationModal();
        }}
        title="Verify your Email"
        description="Almost there! We have sent a verification email to"
        email={currentUser && hideEmailCharacters(currentUser.email)}
        footer="You need to verify your email address to use the services offered by Move2NewCity."
      />
      {sortedFriendsList.length === 0 && (
        <NoFriendsFound
          message={
            filter === filterOption.ONLINE_FRIENDS
              ? "Sorry, there are no online Friends available at the moment"
              : "We are yet to find Friends in this city"
          }
        />
      )}
      {sortedFriendsList.map((value, index) => {
        let keys = Object.keys(sortedFriendsList[index].ratings);
        let values = Object.values(sortedFriendsList[index].ratings);
        let commentCountKeys = Object.keys(
          sortedFriendsList[index].commentCounts
        );
        let commentCountValues = Object.values(
          sortedFriendsList[index].commentCounts
        );

        const handleFollow = (e, follow) => {
          e.preventDefault();
          localStorage.getItem(ACCESS_TOKEN)
            ? getCurrentUser().then(() => {
                if (!verified) {
                  setRequireVerification(true);
                } else {
                  if (follow) {
                    followFriend(value.id);
                    let updatedFriendsList = friendsFollowingList.slice(0);
                    updatedFriendsList.push(value.id);
                    setFriendsFollowingList(updatedFriendsList);
                  } else {
                    unfollowFriend(value.id);
                    let updatedFriendsList = friendsFollowingList;
                    updatedFriendsList = updatedFriendsList.filter(
                      (element) => {
                        return element !== value.id;
                      }
                    );
                    setFriendsFollowingList(updatedFriendsList);
                  }
                }
              }).catch(console.warn)
            : handleRequireLogin();
        };

        const FollowUnfollowButton = ({ showFollowing }) => {
          return (
            <Button
              className={`${
                showFollowing ? "friend-following" : "friend-follow"
              } button`}
              disabled={
                currentUserProfile.role === USER_ROLE.ADMIN ||
                currentUserProfile.role === USER_ROLE.SUPER_ADMIN
              }
              onClick={(e) => handleFollow(e, !showFollowing)}
              onMouseEnter={() => setIsFollowButtonHover(true)}
              onMouseLeave={() => setIsFollowButtonHover(false)}>
              {showFollowing ? "Following" : "Follow"}
            </Button>
          );
        };

        return (
          <div key={index}>
            <Link
              {...(!isFollowButtonHover && {
                to: `/friends/${value.id}`,
              })}
              className="city-friend-link">
              <div className="city-friend-card">
                <Card className="container text-center mb-4">
                  <Card.Body className="px-0">
                    <Card.Title className="user-name">
                      {value.userName ? value.userName : value.name}
                    </Card.Title>
                    {sortedFriendsList[index].image ||
                    sortedFriendsList[index].imageUrl ? (
                      <Card.Img
                        className="city-friend-card-image"
                        src={
                          sortedFriendsList[index].image
                            ? `${API_BASE_URL}/v1/users/${value.id}/image`
                            : sortedFriendsList[index].imageUrl
                        }
                        alt="user image"
                      />
                    ) : (
                      <div className="text-avatar">
                        <span>
                          {sortedFriendsList[index].userName
                            ? sortedFriendsList[index].userName[0].toUpperCase()
                            : sortedFriendsList[index].name &&
                              sortedFriendsList[index].name[0].toUpperCase()}
                        </span>
                      </div>
                    )}
                    {value?.isOnline && (
                      <div className="online">
                        Online<i className="fas fa-circle"></i>
                      </div>
                    )}
                    {value.id !== currentUserProfile.id && (
                      <div className="follow-button">
                        <FollowUnfollowButton
                          showFollowing={friendsFollowingList.includes(
                            value.id
                          )}
                        />
                      </div>
                    )}
                    <div className="content mt-4">
                      <div className="content-card">
                        {keys.map((item, key) => {
                          return (
                            <div key={key} className="container p-0 m-0">
                              <div className="row p-0 m-0 align-items-center mx-2 m-1">
                                <div className="col-6 friend-rating-title p-0 m-0">
                                  <label className="friend-rating-title-item p-0 m-0">
                                    {item}
                                  </label>
                                </div>
                                <div className="col-6 p-0 m-0 rating-star">
                                  <RatingStar
                                    count={values[key]}
                                    colorMode="blackMode"
                                    commentCounts={commentCountValues[key]}
                                  />
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              </div>
            </Link>
          </div>
        );
      })}
    </div>
  );
};

export default CityFriendCard;
