import React, { useEffect, useState, Fragment } from "react";
import {
  addCity,
  deleteCityBanner,
  editCity,
  editCityMedia,
} from "../../util/APIUtils";
import { PencilFill, TrashFill } from "react-bootstrap-icons";
import { API_BASE_URL } from "../../constants";
import CityTemplate from "../../components/city-template/City-Template";
import { notificationManage } from "../../util/NotificationUtils";
import CityBannerCrop from "../../components/city-banner-crop/City-Banner-Crop";
import "./City-Form.scss";

const NewCityForm = (props) => {
  const { history, location, currentUser } = props;
  const initialFormState = {
    name: "",
    country: "",
    description: "",
    image: null,
    banners: [],
    errors: { name: null, country: null, description: null },
  };
  const [editedCity, setEditedCity] = useState(null);
  const [errors, setErrors] = useState(initialFormState.errors);
  const [image, setImage] = useState({ file: null, objectUrl: null });
  const [banners, setBanners] = useState([]);
  const [city, setCity] = useState(initialFormState);
  const [uploadImageSpinner, setUploadImageSpinner] = useState(false);
  const [uploadBannerSpinner, setUploadBannerSpinner] = useState(false);
  const [showImageButton, setShowImageButton] = useState(false);
  const [showBannersButton, setShowBannersButton] = useState(false);
  // states required for banner cropping
  const [isPreview, setIsPreview] = useState(false);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const isEdit = location.pathname.split("/").indexOf("edit-city") > -1;

  const handleTextInputChange = (event) => {
    const { name, value } = event.target;
    handleInputValidation(name, value);
    isEdit
      ? setEditedCity({ ...editedCity, [name]: value })
      : setCity({ ...city, [name]: value });
  };

  const handleFileInputChange = (event) => {
    const { name, files } = event.target;
    if (files) {
      if (name === "image") {
        setImage({ file: files[0], objectUrl: URL.createObjectURL(files[0]) });
        setCity({ ...city, [name]: files[0] });
      } else {
        const banner = {
          file: files[0],
          objectUrl: URL.createObjectURL(files[0]),
          name: files[0].name,
        };
        setBanners((prevState) => [...prevState, banner]);
        setIsPreview(true);
      }
    }
    if (isEdit) {
      name === "image" ? setShowImageButton(true) : setShowBannersButton(true);
    }
  };

  const handleInputValidation = (name, value) => {
    switch (name) {
      case "country":
        setErrors((previous) => ({
          ...previous,
          country: value.length === 0 && "Country is required",
        }));
        break;
      case "name":
        setErrors((previous) => ({
          ...previous,
          name: value.length === 0 && "City is required",
        }));
        break;
      case "description":
        setErrors((previous) => ({
          ...previous,
          description: value.length === 0 && "Desription is required",
        }));
        break;
      default:
        break;
    }
  };

  const handleFormValidation = () => {
    let valid = true;
    Object.keys(initialFormState).forEach((key, index) => {
      if (city[key] === "") {
        handleInputValidation(key, "");
        valid *= false;
      } else {
        valid *= true;
      }
    });
    return valid;
  };

  const handleAdd = (event) => {
    event.preventDefault();
    const formData = new FormData();
    if (handleFormValidation()) {
      formData.append("name", city.name);
      formData.append("country", city.country);
      formData.append("description", city.description);
      formData.append("image", city.image);
      banners.map((banner) =>
        formData.append("banners", banner.file, banner.name)
      );
      addCity(formData)
        .then((res) => {
          history.push("/cities");
        })
        .catch((err) => {
          err?.then((resp) => {
            notificationManage("danger", "City creation failed");
          });
        });
    }
  };

  const handleEditCity = (event) => {
    event.preventDefault();
    const data = {};
    for (let key of Object.keys(editedCity)) {
      data[key] = editedCity[key];
    }
    editCity(city.id, data)
      .then((res) => {
        history.push("/cities");
      })
      .catch((err) => {
        err?.then((resp) => {
          notificationManage("danger", "City updation failed");
        });
      });
  };

  const handleEditMedia = (name) => {
    if (name === "image") {
      setUploadImageSpinner(true);
    } else {
      setUploadBannerSpinner(true);
    }
    const formData = new FormData();
    name === "image"
      ? formData.append("file", image.file)
      : formData.append(
          "file",
          banners[banners.length - 1].file,
          banners[banners.length - 1].name
        );
    editCityMedia(city.id, formData, name)
      .then(() => {
        handleStateStateAfterEditMedia(
          "success",
          name,
          `City ${name} updated successfully`
        );
      })
      .catch((err) => {
        err?.then((resp) => {
          handleStateStateAfterEditMedia(
            "danger",
            name,
            `City ${name} updation failed`
          );
        });
      });
  };

  const handleBannerDelete = (bannerIndex) => {
    setUploadBannerSpinner(true);
    deleteCityBanner(city.id, bannerIndex)
      .then(() => {
        handleStateStateAfterEditMedia(
          "success",
          "banner",
          "City banner deleted successfully"
        );
        setBanners(banners.filter((banner, index) => index !== bannerIndex));
      })
      .catch((err) => {
        err?.then((resp) => {
          handleStateStateAfterEditMedia(
            "danger",
            "banner",
            "City banner deletion failed"
          );
        });
      });
  };

  const handleStateStateAfterEditMedia = (type, name, msg) => {
    name === "image"
      ? setUploadImageSpinner(false)
      : setUploadBannerSpinner(false);
    name === "image" ? setShowImageButton(false) : setShowBannersButton(false);
    type === "success"
      ? notificationManage("success", msg)
      : notificationManage("danger", msg);
  };

  const DeleteBannerFromState = (bannerIndex) => {
    setBanners(banners.filter((banner, index) => index !== bannerIndex));
  };

  const handleCancel = () => {
    history.push("/cities");
  };

  const renderBannerImages = (source) => {
    return source.map((item, index) => {
      return (
        <div className="col-0 mr-3 mb-3 city-form-input-banner">
          <div className="city-form-input-banner-overlay">
            <img
              src={item.objectUrl}
              key={item}
              alt="banner"
              className={item !== "" && "city-form-input-banner-display"}
            />
          </div>
          <span className="city-form-input-banner-delete">
            <TrashFill
              className="city-form-input-delete-icon"
              color="#FFFFFF"
              onClick={
                isEdit
                  ? () => handleBannerDelete(index)
                  : () => DeleteBannerFromState(index)
              }
            />
          </span>
        </div>
      );
    });
  };

  const renderImageInputFields = () => {
    return (
      <Fragment>
        <div className="pl-4 mb-5 city-form-input">
          <div className="row">
            <label className="col-2 city-form-input-label">Image</label>
            <div>
              <input
                className="city-form-input-file"
                type="file"
                id="image"
                name="image"
                onChange={handleFileInputChange}
                required
              />
              {!isEdit ? (
                <div>
                  <label htmlFor="image" className="city-form-input-file-label">
                    Upload
                  </label>
                  {image.objectUrl !== null && (
                    <div className="my-3">
                      <img
                        src={image.objectUrl}
                        alt="city"
                        className="city-form-input-file-image"
                      />
                    </div>
                  )}
                </div>
              ) : (
                <Fragment>
                  <div className="row city-form-input-edit-image">
                    <div className="city-form-input-overlay">
                      <img
                        src={image.objectUrl}
                        alt="city"
                        className="city-form-input-display-image"
                      />
                    </div>
                    <div className="city-form-input-edit-icon">
                      <label htmlFor="image">
                        {isEdit && uploadImageSpinner ? (
                          <span
                            className="spinner-border spinner-border-sm edit-spinner city-form-input-edit-image-spinner"
                            role="status"
                            aria-hidden="true"></span>
                        ) : (
                          <PencilFill
                            className="mt-5 city-form-input-edit"
                            color="#FFFFFF"
                          />
                        )}
                      </label>
                    </div>
                  </div>
                  {showImageButton && (
                    <div className="row ml-0 mt-1 city-form-input-sub-button-container">
                      <p
                        className="mr-2 city-form-input-sub-button"
                        onClick={cancleImageUpload}>
                        Cancel
                      </p>
                      <p
                        className="city-form-input-sub-button"
                        onClick={() => handleEditMedia("image")}>
                        Upload
                      </p>
                    </div>
                  )}
                </Fragment>
              )}
            </div>
          </div>
          {!isEdit && (
            <div className="mt-3 city-form-input-file-description">
              Image will be displayed on the homepage city carousel
            </div>
          )}
        </div>
        <div className="pl-2 mb-5 city-form-input">
          <div className="d-flex">
            <label className="col-2 city-form-input-label">Banners</label>
            <div className="row input-field">
              <input
                className="city-form-input-file"
                type="file"
                id="banner"
                name="banner"
                onChange={handleFileInputChange}
                required
              />
              {!isEdit && (
                <label
                  htmlFor="banner"
                  className="ml-3 city-form-input-file-label">
                  Upload
                </label>
              )}
              <div className="row ml-1 mr-1">{renderBannerImages(banners)}</div>
              {isEdit && (
                <div>
                  <label
                    htmlFor="banner"
                    className="mb-2 city-form-edit-banner-label">
                    {isEdit && uploadBannerSpinner ? (
                      <span
                        className="spinner-border spinner-border-sm spinner city-form-input-edit-banner-spinner"
                        role="status"
                        aria-hidden="true"></span>
                    ) : (
                      <i className="fa fa-plus-circle"></i>
                    )}
                  </label>
                  {showBannersButton && (
                    <div className="row justify-content-center city-form-input-sub-button-container">
                      <p
                        className="mr-2 city-form-input-sub-button"
                        onClick={cancleBannerUpload}>
                        Cancel
                      </p>
                      <p
                        className="city-form-input-sub-button"
                        onClick={() => handleEditMedia("banner")}>
                        Upload
                      </p>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
          {!isEdit && (
            <div className="ml-3 city-form-input-file-description">
              Banners will be displayed in a carousel on the city page as shown
              in the template
              <br />
              Banner should be 1280x549 in size
            </div>
          )}
        </div>
      </Fragment>
    );
  };

  const cancleImageUpload = () => {
    setImage((prevState) => ({
      ...prevState,
      objectUrl: `${API_BASE_URL}/v1/cities/${location.state.id}/image`,
    }));
    setShowImageButton(false);
  };

  const cancleBannerUpload = () => {
    setBanners(banners.filter((banner, index) => index !== banners.length - 1));
    setShowBannersButton(false);
  };

  useEffect(() => {
    if (isEdit && currentUser.role === "ROLE_ADMIN") {
      setCity(location.state);
      isEdit &&
        setImage((prevState) => ({
          ...prevState,
          objectUrl: `${API_BASE_URL}/v1/cities/${location.state.id}/image`,
        }));
      city.banners !== undefined &&
        city.banners.map((banner, index) =>
          setBanners((prevState) => [
            ...prevState,
            {
              objectUrl: `${API_BASE_URL}/v1/cities/${city.id}/banner/${index}`,
            },
          ])
        );
    }
  }, [isEdit, location, currentUser, city.banners]);

  return (
    currentUser.role === "ROLE_ADMIN" && (
      <div className="row mr-0 city-form">
        <div className="col-6 mt-5">
          <form className="col">
            <div className="pl-4 pb-4 city-form-title">
              {!isEdit ? "Add New City" : "Edit City"}
            </div>
            <div className="row pl-4 mb-5 city-form-input">
              <label className="col-2 city-form-input-label">Country</label>
              <input
                className="col-7 p-2 city-form-input-field"
                type="text"
                name="country"
                defaultValue={city.country}
                onChange={handleTextInputChange}
                required
              />
              <div className="ml-2 city-form-input-error">
                {errors.country !== null ? errors.country : undefined}
              </div>
            </div>
            <div className="row pl-4 mb-5 city-form-input">
              <label className="col-2 city-form-input-label">City Name</label>
              <input
                className="col-7 p-2 city-form-input-field"
                type="text"
                name="name"
                defaultValue={city.name}
                onChange={handleTextInputChange}
                required
              />
              <div className="ml-2 city-form-input-error">
                {errors.name !== null ? errors.name : undefined}
              </div>
            </div>
            <div className="row pl-4 mb-5 city-form-input">
              <label className="col-2 city-form-input-label">Description</label>
              <textarea
                className="col-7 p-2 city-form-input-field"
                type="text"
                name="description"
                defaultValue={city.description}
                onChange={handleTextInputChange}
                required
              />
              <div className="ml-2 city-form-input-error">
                {errors.description !== null ? errors.description : undefined}
              </div>
            </div>

            {!isEdit && renderImageInputFields()}
            <div className="mt-2 pl-2 mb-5 offset-2">
              <button
                onClick={handleCancel}
                className="btn btn-outline-primary mr-4 city-form-button">
                Cancel
              </button>
              {!isEdit ? (
                <button
                  onClick={handleAdd}
                  className="btn btn-primary city-form-button">
                  Save
                </button>
              ) : (
                <button
                  onClick={handleEditCity}
                  disabled={editedCity === null}
                  className="btn btn-primary city-form-button">
                  Update
                </button>
              )}
            </div>
          </form>
        </div>
        <div className="col-6 mt-5">
          {isEdit ? (
            <div className="media-input-wrapper">
              {renderImageInputFields()}
            </div>
          ) : (
            <Fragment>
              <div className="template-title">City page template</div>
              <CityTemplate />
            </Fragment>
          )}
        </div>
        <CityBannerCrop
          open={isPreview}
          crop={crop}
          zoom={zoom}
          croppedAreaPixels={croppedAreaPixels}
          banners={banners}
          setOpen={setIsPreview}
          setCroppedAreaPixels={setCroppedAreaPixels}
          setCrop={setCrop}
          setZoom={setZoom}
        />
      </div>
    )
  );
};

export default NewCityForm;
