export const schedulerStatus = {
  SCHEDULED: "SCHEDULED",
  SUGGESTED: "SUGGESTED",
  RESCHEDULED: "RESCHEDULED",
  APPROVED: "APPROVED",
  CONFIRMED: "CONFIRMED",
  DECLINED: "DECLINED",
  CANCELLED: "CANCELLED",
  ALL: "ALL"
};
export const schedulePageSize = 5;

export const schedulerActions = {
  rescheduleAndSuggest: "Reschedule&Suggest",
  suggest: "suggest",
  reschedule: "reschedule",
  confirm: "confirm",
  accept: "accept",
  cancel: "cancel",
  decline: "decline",
};
export const declineCallOptions = [
  {
    id: 1,
    value: "CIRCUMSTANCES",
    label: "Unforeseen circumstances",
  },
  {
    id: 2,
    value: "SCHEDULE_CONFLICTS",
    label: "Schedule conflicts",
  },
  {
    id: 3,
    value: "TECHNICAL_ISSUES",
    label: "Technical issues",
  },

  { id: 4, value: "OTHER", label: "Other" },
];

export const panelState = {
  PROFILE: "profile",
  NOTIFICATIONS: "notifications",
  PAYMENT: "payment",
  SETTINGS: "settings",
  SCHEDULE_CALL: "scheduled call",
};

export const localStorageScheduleCall = {
  rightPanelState: "rightPanelState",
};

export const schedulerCallFilterList = [
  { id: 0, value: "ALL", title: "All" },
  { id: 2, value: "DECLINED", title: "Declined" },
  { id: 3, value: "CANCELLED", title: "Cancelled" },
  { id: 4, value: "SCHEDULED", title: "Pending" },
  { id: 6, value: "CONFIRMED,APPROVED", title: "Confirmed" },
  { id: 7, value: "RESCHEDULED,SUGGESTED", title: "Rescheduled" },
];
