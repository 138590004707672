import React, { useState, useEffect } from "react";
import Editor from "../../components/editor/Editor";
import useLocalStorage from "../../hooks/useLocalStorage";
import {
  editArticle,
  getCategoryList,
  getCities,
  saveArticle,
} from "../../util/APIUtils";
import { USER_ROLE } from "../../util/Utils";
import { notificationManage } from "../../util/NotificationUtils";
import "./Article-Creator.scss";

const ArticleCreator = (props) => {
  const { history, currentUser, location } = props;
  const [html, setHtml] = useLocalStorage("html", "");
  const [css, setCss] = useLocalStorage("css", "");
  const [srcDoc, setSrcDoc] = useState("");
  const [cities, setCities] = useState([]);
  const [selected, setSelected] = useState({ city: "", category: "" });
  const [loading, setLoading] = useState(false);
  const [initialState, setInitialState] = useState({ html: null, css: null }); // initial state when editing existing article
  const [categories, setCategories] = useState([]);
  const isEdit = location.pathname.split("/").indexOf("edit-article") > -1;

  const handleInput = (event) => {
    const { name, value } = event.target;
    setSelected((prev) => ({ ...prev, [name]: value }));
  };

  const getIsValidation = () => {
    if (isEdit) {
      return !(css !== initialState.css || html !== initialState.html);
    } else {
      return !(selected.city !== "" && selected.category !== "" && html !== "");
    }
  };

  useEffect(() => {
    getCategoryList()
      .then((res) => {
        setCategories(res);
      })
      .catch((err) => {
        err?.then((resp) => {
          notificationManage("danger", "Category list list retreival failed");
        });
      });
  }, []);

  const refreshLocalStorage = () => {
    setHtml("");
    setCss("");
  };

  const navigateToArticles = () => {
    history.push("/articles");
  };

  const handleSave = () => {
    setLoading(true);
    const data = {
      cityId: selected.city,
      category: selected.category,
      html: html,
      css: css,
    };
    saveArticle(data)
      .then((res) => {
        setLoading(false);
        refreshLocalStorage();
      })
      .catch((err) => {
        err?.then((resp) => {
          setLoading(false);
          notificationManage("danger", "Article creation failed");
        });
      });
  };

  const handleEdit = () => {
    setLoading(true);
    let data = {};
    css !== initialState.css && (data.css = css);
    html !== initialState.html && (data.html = html);
    editArticle(location.state.id, data)
      .then((res) => {
        setLoading(false);
        navigateToArticles();
      })
      .catch((err) => {
        err?.then((resp) => {
          setLoading(false);
          notificationManage("danger", "Article updation failed");
        });
      });
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      setSrcDoc(`${html}<style>${css}</style>`);
    }, 250);
    return () => clearTimeout(timeout);
  }, [html, css]);

  useEffect(() => {
    if (isEdit) {
      const { city, category, html, css } = location.state;
      setSelected({ city: city, category: category });
      setHtml(html);
      setCss(css);
      setInitialState({ html: html, css: css });
    } else {
      refreshLocalStorage();
    }
  }, [isEdit, location]);

  useEffect(() => {
    getCities()
      .then((res) => {
        setCities(res);
      })
      .catch((err) => {
        err?.then((resp) => {
          notificationManage("danger", "City list retrieval failed");
        });
      });
  }, []);

  return (
    currentUser.role === USER_ROLE.SUPER_ADMIN && (
      <div className="mt-4 code-editor">
        <div className="py-3 px-5 code-editor-header">
          <h3>Code Editor</h3>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam
            gravida, elit vel mollis maximus, odio orci hendrerit ante, ac
            faucibus eros ante id quam. Curabitur iaculis ultrices lobortis.
            Curabitur arcu justo, finibus nec nisi non, commodo laoreet leo. In
            nec fringilla dolor. Vivamus feugiat, enim vitae placerat
            ullamcorper, est mauris.
          </p>
        </div>
        <div className="row pb-3 px-5 mr-0">
          <div className="col-3">
            <select
              className="custom-select"
              name="city"
              disabled={isEdit}
              onChange={handleInput}>
              {!isEdit && (
                <option disabled selected hidden>
                  Select a city
                </option>
              )}
              {cities.map((city) => (
                <option
                  key={city.id}
                  value={city.id}
                  selected={isEdit && city.id === selected.city}>
                  {city.name}
                </option>
              ))}
            </select>
          </div>
          <div className="col-3">
            <select
              className="custom-select"
              name="category"
              disabled={isEdit}
              onChange={handleInput}>
              {!isEdit && (
                <option disabled selected hidden>
                  Select a category
                </option>
              )}
              {categories.map((category) => (
                <option
                  key={category.id}
                  value={category.tag}
                  selected={isEdit && category.tag === selected.category}>
                  {category.title}
                </option>
              ))}
            </select>
          </div>
          <div className="col pb-3 pr-0">
            <button
              className="btn btn-primary float-right px-4 ml-4"
              type="button"
              onClick={navigateToArticles}>
              All Articles
            </button>
            <button
              className="btn btn-primary float-right px-5"
              type="button"
              disabled={getIsValidation()}
              onClick={isEdit ? handleEdit : handleSave}>
              {isEdit ? "Edit" : "Save"}
              {loading && (
                <span className="spinner-border spinner-border-sm ml-2"></span>
              )}
            </button>
          </div>
        </div>
        <div className="py-3 px-5 code-editor-pane-top">
          <Editor
            displayName="HTML"
            language="xml"
            value={html}
            onChange={setHtml}
          />
          <Editor
            displayName="CSS"
            language="css"
            value={css}
            onChange={setCss}
          />
        </div>
        <div className="py-3 px-5 code-editor-pane-bottom">
          <iframe
            srcDoc={srcDoc}
            title="output"
            sandbox="allow-scripts"
            frameBorder="0"
            width="100%"
            height="100%"
          />
        </div>
      </div>
    )
  );
};

export default ArticleCreator;
