import { useEffect, useState } from "react";
import {
  HandThumbsDown,
  HandThumbsDownFill,
  HandThumbsUp,
  HandThumbsUpFill,
  Reply,
} from "react-bootstrap-icons";
import { getCurrentUser, voteForPostById } from "../../util/APIUtils";
import PopupLogin from "../popup-login/Popup-Login";
import "./Post-Comment.scss";
import { ACCESS_TOKEN } from "../../constants";
import { useVerified } from "../../context/UserContext";
import EmailVerificationModal from "../email-verification-modal/Email-verification-modal";
import { hideEmailCharacters, truncateName } from "../../util/Utils";
import { useHistory } from "react-router-dom";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import Cookies from "js-cookie";

const PostComment = (props) => {
  const {
    id,
    title,
    author,
    dateComment,
    interactions,
    comments,
    handleDataUpdate,
    currentUserDetails,
  } = props;
  const [actionStatus, setActionStatus] = useState({
    upVote: {
      value: 0,
      status: false,
    },
    downVote: {
      value: 0,
      status: false,
    },
  });
  const [tempActionStatus, setTempActionStatus] = useState({
    upVote: {
      value: 0,
      status: false,
    },
    downVote: {
      value: 0,
      status: false,
    },
  });
  const [loginRequired, setLoginRequired] = useState(false);
  const { verified, currentUser } = useVerified();
  const [requireVerification, setRequireVerification] = useState(false);
  const [clicked, isClicked] = useState(false);
  const commentWords = title.split(/(\s+)/);
  const history = useHistory();
  const [currentPageUrl, setCurrentPageUrl] = useState("");
  const [isDisabled, setIsDisabled] = useState(false);

  const postInteraction = (type, isClick) => {
    isClicked(isClick);
    getCurrentUser()
      .then(() => {
        if (!verified) {
          setRequireVerification(true);
        } else {
          voteForPostById(id, {
            type: type,
          }).then((res) => {
            handleDataUpdate();
            isClicked(false);
          });
        }
      })
      .catch(() => setLoginRequired(true));
  };

  useEffect(() => {
    setCurrentPageUrl(history.location.pathname);
  }, [history.location.pathname]);

  const handleHidePopupLogin = () => {
    setLoginRequired(!loginRequired);
  };

  const handleHideVerificationModal = () => {
    setRequireVerification(!requireVerification);
  };

  const countInteractions = (type) => {
    return interactions.filter((e) => {
      return e.type === type;
    }).length;
  };

  useEffect(() => {
    if (
      localStorage.getItem(ACCESS_TOKEN) &&
      JSON.parse(Cookies.get("isEmpty")) === true
    ) {
      setIsDisabled(true);
    }
  }, []);

  const urlLinkValidation = (word) => {
    const regex = new RegExp(/(https?:\/\/[^ ]*)/);
    return regex.test(word);
  };

  const setInteractionColor = (type) => {
    const isExist = localStorage.getItem(ACCESS_TOKEN)
      ? interactions.some(
          (interaction) =>
            interaction.type === type &&
            interaction.createdBy.id === currentUserDetails.id
        )
      : false;
    return isExist;
  };

  const handleActionStatus = () => {
    setActionStatus({
      upVote: {
        value: countInteractions("up_vote"),
        status: setInteractionColor("up_vote"),
      },
      downVote: {
        value: countInteractions("down_vote"),
        status: setInteractionColor("down_vote"),
      },
    });
  };

  const incVote = (No, isClicked) => {
    setTempActionStatus((prevState) => ({
      ...prevState,
      upVote: {
        value: prevState.upVote.value + No,
        status: true,
      },
      downVote: {
        ...prevState.downVote,
        status: false,
      },
    }));
  };

  const incVote2 = (No, isClicked) => {
    setTempActionStatus((prevState) => ({
      ...prevState,
      upVote: {
        ...prevState.upVote,
        status: false,
      },
      downVote: {
        value: prevState.downVote.value + No,
        status: true,
      },
    }));
  };

  const decVote = (No, isClicked) => {
    setTempActionStatus((prevState) => ({
      ...prevState,
      upVote: {
        value: prevState.upVote.value - No,
        status: false,
      },
    }));
  };

  const decVote2 = (No, isClicked) => {
    setTempActionStatus((prevState) => ({
      ...prevState,
      downVote: {
        value: prevState.downVote.value - No,
        status: false,
      },
    }));
  };

  useEffect(() => {
    setTempActionStatus({
      upVote: {
        value: actionStatus?.upVote?.value,
        status: actionStatus?.upVote?.status,
      },
      downVote: {
        value: actionStatus?.downVote?.value,
        status: actionStatus?.downVote?.status,
      },
    });
    handleActionStatus();
  }, [
    currentUserDetails,
    interactions,
    actionStatus.downVote.value,
    actionStatus.upVote.value,
  ]);

  return (
    <div>
      <PopupLogin
        show={loginRequired}
        onHide={() => {
          handleHidePopupLogin();
        }}
        name="Sign In"
        currentPageUrl={currentPageUrl}
      />
      <EmailVerificationModal
        isShow={requireVerification}
        handleVerificationModal={() => {
          handleHideVerificationModal();
        }}
        title="Verify your Email"
        description="Almost there! We have sent a verification email to"
        email={currentUser && hideEmailCharacters(currentUser.email)}
        footer="You need to verify your email address to use the services offered by Move2NewCity."
      />
      <div key={id} className="comment">
        <div className="comment-details">
          <p>{truncateName(author, 8)}</p>
          <p>{dateComment}</p>
        </div>
        <div className="comment-content">
          <p className="comment-content-reply">
            {commentWords.map((word) => {
              return urlLinkValidation(word) ? (
                <a
                  target="_blank"
                  className="comment-content-link"
                  href={word.includes("http") ? word : "//" + word}
                >
                  {word}
                </a>
              ) : (
                <>{word} </>
              );
            })}
          </p>
        </div>
        <div className="comment-action">
          <OverlayTrigger
            placement={"auto"}
            delay={{ show: 0, hide: 400 }}
            overlay={
              <Tooltip
                className={`${isDisabled ? "tooltip-extra-info" : "d-none"}`}
              >
                {isDisabled &&
                  "You can't perform this action because you have not completed your profile."}
              </Tooltip>
            }
          >
            <div
              className={
                clicked === true &&
                tempActionStatus?.upVote?.value &&
                localStorage.getItem(ACCESS_TOKEN)
                  ? "comment-action-item--active comment-action-item"
                  : "comment-action-item"
              }
            >
              <button
                onClick={() => {
                  !isDisabled && postInteraction("up_vote", true);
                }}
                className="comment-interaction-icon"
                disabled={isDisabled}
              >
                {tempActionStatus?.upVote?.status && !isDisabled ? (
                  <HandThumbsUpFill
                    color={"#EE4D67"}
                    onClick={() => {
                      if (localStorage.getItem(ACCESS_TOKEN) && !isDisabled) {
                        decVote(1, "ClickedVoted");
                      }
                    }}
                  />
                ) : (
                  <HandThumbsUp
                    onClick={() => {
                      if (localStorage.getItem(ACCESS_TOKEN) && !isDisabled) {
                        incVote(1, "ClickedUnVote");
                      }
                    }}
                  />
                )}
              </button>
              <p
                className={`${isDisabled ? "grey-vote-text" : "upvote-action"}`}
              >
                {tempActionStatus?.upVote?.value}
              </p>
            </div>
          </OverlayTrigger>
          <OverlayTrigger
            placement={"auto"}
            delay={{ show: 0, hide: 400 }}
            overlay={
              <Tooltip
                className={`${isDisabled ? "tooltip-extra-info" : "d-none"}`}
              >
                {isDisabled &&
                  "You can't perform this action because you have not completed your profile"}
              </Tooltip>
            }
          >
            <div
              className={
                clicked === true &&
                tempActionStatus?.downVote?.value &&
                localStorage.getItem(ACCESS_TOKEN)
                  ? "comment-action-item--active comment-action-item"
                  : "comment-action-item"
              }
            >
              <button
                onClick={() => {
                  !isDisabled && postInteraction("down_vote", true);
                }}
                className="comment-interaction-icon"
                disabled={isDisabled}
              >
                {tempActionStatus?.downVote?.status && !isDisabled ? (
                  <HandThumbsDownFill
                    color={"#EE4D67"}
                    onClick={() => {
                      if (localStorage.getItem(ACCESS_TOKEN) && !isDisabled) {
                        decVote2(1, "ClickedVoted");
                      }
                    }}
                  />
                ) : (
                  <HandThumbsDown
                    onClick={() => {
                      if (localStorage.getItem(ACCESS_TOKEN) && !isDisabled) {
                        incVote2(1, "ClickedUnVote");
                      }
                    }}
                  />
                )}
              </button>

              <p
                className={`${isDisabled ? "grey-vote-text" : "upvote-action"}`}
              >
                {tempActionStatus?.downVote?.value}
              </p>
            </div>
          </OverlayTrigger>
          {/* Not removing for probable future use */}
          {/* <div className="comment-action-item">
            <button className="comment-interaction-icon">
              <Reply />
            </button>
            <button className="comment-interaction-count">
              <p>{comments ? comments.length : 0}</p>
            </button>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default PostComment;
