import React, { useState } from "react";
import { createCommentForParent } from "../../util/APIUtils";
import { notificationManage } from "../../util/NotificationUtils";

function CreateComment(props) {
  const [comment, setComment] = useState({ id: null, text: "" });

  const handleChange = (key, value) => {
    setComment({
      ...comment,
      [key]: value,
    });
  };

  const handleSubmit = (evt) => {
    evt.preventDefault();

    const dataSet = {
      cityId: props.cityId,
      parentId: props.parentId,
      text: comment.text,
    };

    createCommentForParent(dataSet)
      .then((response) => {
        props.fncStatus(response);
        setComment({ id: null, text: "" });
        notificationManage("success", "Answer posted successfully");
      })
      .catch((err) => {
        err?.then((resp) => {
          notificationManage("danger", "Answer posting failed");
        });
      });
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-md-11 form-group">
            <label className="col-12">
              {props?.isReplying
                ? "Replying to " + props?.replyingTo
                : "Comment"}
            </label>
            <textarea
              type="text"
              required
              placeholder="Enter text"
              className="col-12 mx-2 form-control"
              autoComplete="off"
              name="desc"
              id="desc"
              value={comment?.text}
              onChange={(e) => handleChange("text", e.target.value)}></textarea>
          </div>
          <div className="col-md-1 d-flex align-items-center">
            <button type="submit" className="btn btn-primary">
              <i className="fa fa-plus"></i>
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}

export default CreateComment;
