import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import "./Forgot-Password-Modal.scss";
import { notificationManage } from "../../util/NotificationUtils";
import { handleEmailValidation, hideEmailCharacters } from "../../util/Utils";
import { requestPasswordReset } from "../../util/APIUtils";
import EmailVerificationModal from "../email-verification-modal/Email-verification-modal";

const ForgotPasswordModal = (props) => {
  const { open, onClose, onSend } = props;
  const icon = "/assets/img/recover/forgot-password-image.png";
  const [email, setEmail] = useState();
  const [confirmationEmail, setConfirmationEmail] = useState();
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [showError, setShowError] = useState(false)
  const [errorMessage, setErrorMessage] = useState()

  const handleInputChange = (e) => {
    setEmail(e.target.value)
    setShowError(false)
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setConfirmationEmail(email);
    if (!email) {
      setErrorMessage("Please fill this field");
      setShowError(true);
      return;
    } else if (!handleEmailValidation(email)) {
      setErrorMessage("A valid email address is required");
      setShowError(true);
      return;
    } else {
      requestPasswordReset(email)
        .then((response) => {
          setShowConfirmationModal(true);
        })
        .catch((error) => {
          error?.then((value) => {
            switch (value.status) {
              case 400:
                notificationManage(
                  "danger",
                  "You are not allowed to change the password"
                );
                break;
              case 404:
                window.location.href = "/signup";
                notificationManage(
                  "danger",
                  "This email is not associated with any account"
                );
                break;
              default:
                notificationManage("danger", "Something went wrong");
                break;
            }
          });
        })
        .finally(() => {
          onSend();
          Array.from(document.querySelectorAll("input")).forEach(
            (textarea) => (textarea.value = null)
          );
          setEmail(null);
        });
    }
  };

  const handleClose = (e) => {
    e.preventDefault();
    defaultClose()
  };
 const defaultClose = () => {
    setEmail(null);
    setConfirmationEmail(null);
    setErrorMessage(null);
    setShowError(false);
    onClose();
  };

  const handleHideConfirmationModal = () => {
    setShowConfirmationModal(!showConfirmationModal);
  };

  return (
    <>
      <EmailVerificationModal
        isShow={showConfirmationModal}
        handleVerificationModal={() => {
          handleHideConfirmationModal();
        }}
        title="Check your email"
        description="We have sent an email with password reset instructions to "
        email={confirmationEmail && hideEmailCharacters(confirmationEmail)}
        footer="Please check your email."
      />
      <Modal
        centered
        show={open}
        onHide={onClose}
        backdrop="true"
        className="forgot-password-modal"
        contentClassName="forgot-password-modal-content"
        dialogClassName="forgot-password-modal-dialog"
      >
        <Modal.Header className="forgot-password-modal-header" closeButton onHide={defaultClose}>
          Forgot Password
        </Modal.Header>
        <Modal.Body className="forgot-password-modal-body">
          <div className="forgot-password-modal-body-image-container">
            <img className="image" src={icon} alt="icon" />
          </div>
          <div className="forgot-password-modal-body-title">
            Forgot your password?
          </div>
          <div className="forgot-password-modal-body-content">
            <span className="forgot-password-modal-body-content-text">
              Enter the email address associated with your account to receive
              password reset instruction.
            </span>
          </div>
          <div>
            <div className="forgot-password-modal-body-form">
              <form className="email-form" onSubmit={(e) => handleSubmit(e)}>
                <div className="email-form-input">
                  <label className="email-form-input-label">Email *</label>
                  <input
                    type="text"
                    name="email"
                    className="form-control email-form-input-text"
                    placeholder="Enter your email address"
                    value={email}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="email-form-input-error-message">
                  {showError ? errorMessage : null}
                </div>
                <div className="email-form-submit">
                  <Button
                    type="submit"
                    className="btn btn-block btn-primary email-form-submit-button"
                  >
                    Send
                  </Button>
                </div>
              </form>
            </div>
            <div className="back-to-login">
              <Button
                className="mr-4 button back-to-login-button pl-11"
                variant="link"
                onClick={(e) => handleClose(e)}
              >
                Back to login
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ForgotPasswordModal;
