import React, { useState, useEffect } from "react";
import { BASE_URL } from "../../constants";
import {
  Accordion,
  Button,
  useAccordionToggle,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import {
  getCurrentUser,
  getProfile,
  getQuestionsByCityId,
  voteForPostById,
} from "../../util/APIUtils";
import "./Reply-Accordion.scss";
import { notificationManage } from "../../util/NotificationUtils";
import PopupLogin from "../popup-login/Popup-Login";
import EmailVerificationModal from "../email-verification-modal/Email-verification-modal";
import { useVerified } from "../../context/UserContext";
import { hideEmailCharacters } from "../../util/Utils";
import { ACCESS_TOKEN } from "../../constants";
import { isJWTExpired } from "../../util/JWTUtils";
import Cookies from "js-cookie";

export const InteractionAccordion = ({
  value,
  noOfInteractions: { noOfUpVotes, noOfDownVotes, noOfComments },
  replyEventHandlers: { replyChange, replySubmit },
  idValues: { currentUserId, cityId },
  activeCategory,
  searchQuery,
  filterUnapprovedPosts,
  setThreadList,
  currentProfile,
  reply,
  updateInteraction,
  reloadCurrentUser,
  replyStatus,
}) => {
  const [activeKey, setActiveKey] = useState({});
  const [requireLogin, setRequireLogin] = useState(false);
  const [isFriendDefaultChecked, setIsFriendDefaultChecked] = useState(false);
  const { verified, currentUser } = useVerified();
  const [requireVerification, setRequireVerification] = useState(false);
  const [currentUserProfile, setCurrentUserProfile] = useState(currentProfile);
  const accessToken = localStorage.getItem(ACCESS_TOKEN);
  const [isDisabled, setIsDisabled] = useState(false);

  useEffect(() => {
    const post = sessionStorage.getItem("postId")
      ? JSON.parse(sessionStorage.getItem("postId"))
      : null;
    if (currentUser && post && verified) {
      if (["up_vote", "down_vote"].includes(post.voteType)) {
        handleInteraction(post.id, post.voteType);
      }
      sessionStorage.removeItem("postId");
    }
  }, []);

  useEffect(() => {
    if (localStorage.getItem(ACCESS_TOKEN) && !currentUserProfile) {
      getCurrentUser().then((currentUser) => {
        getProfile(currentUser.id).then((currentUserProfile) => {
          setCurrentUserProfile(currentUserProfile);
          window.location.reload();
        });
      }).catch(console.warn);
    }
  }, [currentUserProfile, reloadCurrentUser]);

  const checkPreviouslyInteracted = (data, type) => {
    return data.interactions
      .filter((value) => value.type === type)
      .some((value) => value.createdBy.id === currentUserId);
  };

  const checkPreviouslyCommented = (data) => {
    if (data?.comments) {
      return data?.comments?.some(
        (value) => value?.createdBy?.id === currentUserId
      );
    }
  };

  useEffect(() => {
    if (
      localStorage.getItem(ACCESS_TOKEN) &&
      Cookies.get("isEmpty") &&
      JSON.parse(Cookies.get("isEmpty")) === true
    ) {
      setIsDisabled(true);
    }
  }, []);

  useEffect(() => {
    if (value) {
      handleClickToggle(value?.id, false);
    }
  }, [replyStatus]);

  const handleReplyClick = (e, id) => {
    replySubmit(id);
    checkLoggedIn(id, true, false);
  };

  const handleClickToggle = (id, isOpen) => {
    setActiveKey((prev) => ({
      ...prev,
      [id]: isOpen,
    }));
  };

  const handleInteraction = (id, voteType) => {
    voteForPostById(id, { type: voteType })
      .catch(() => {
        notificationManage("danger", "Something went wrong");
      })
      .finally(() => {
        getQuestionsByCityId(cityId, activeCategory, searchQuery).then(
          (data) => {
            data.forEach((value, index) => {
              let sortedComments = value.comments?.sort((a, b) => {
                return (
                  b.interactions.filter((value) => value.type === "up_vote")
                    .length -
                  a.interactions.filter((value) => value.type === "up_vote")
                    .length
                );
              });
              data[index].comments = sortedComments;
            });
            setThreadList(filterUnapprovedPosts(data, currentProfile?.id));
          }
        );
      });
  };

  const checkIsSessionTimeOut = () => {
    if (accessToken && isJWTExpired(accessToken)) {
      localStorage.removeItem(ACCESS_TOKEN);
      return true;
    }
    return false;
  };

  const checkLoggedIn = (id, isOpen, isInteraction, interactionType) => {
    if (checkIsSessionTimeOut()) {
      window.location.href = `${BASE_URL}/login`;
    } else {
      if (localStorage.getItem(ACCESS_TOKEN)) {
        verified && updateInteraction(id, isOpen, interactionType);
      }
      getCurrentUser()
        .then(() => {
          if (!verified) {
            setRequireVerification(true);
          } else {
            isInteraction
              ? handleInteraction(id, isOpen)
              : handleClickToggle(handleClickToggle(id, isOpen));
          }
        })
        .catch(() => {
          sessionStorage.setItem(
            "postId",
            JSON.stringify({ id, voteType: isOpen })
          );
          setIsFriendDefaultChecked(!isInteraction);
          setRequireLogin(true);
        });
    }
  };

  const handleHidePopupLogin = () => {
    setRequireLogin(false);
  };

  const handleHideVerificationModal = () => {
    setRequireVerification(!requireVerification);
  };

  const CustomToggle = ({ children, eventKey, callback }) => {
    const decoratedOnClick = useAccordionToggle(
      eventKey,
      () => callback && callback(eventKey)
    );
    let hasPreviouslyInteracted;
    let hasPreviouslyCommented;

    const VotingButton = ({ voteType }) => {
      hasPreviouslyInteracted = checkPreviouslyInteracted(value, voteType);
      return (
        <OverlayTrigger
          placement={"auto"}
          delay={{ show: 0, hide: 400 }}
          overlay={
            <Tooltip
              className={`${isDisabled ? "tooltip-extra-info" : "d-none"}`}
            >
              {isDisabled &&
                "You can't perform this action because you have not completed your profile."}
            </Tooltip>
          }
        >
          <div>
            <button
              className="d-flex border-0 bg-white thread-interaction mr-3 z-10"
              disabled={value.status !== "approved" || isDisabled}
              onClick={(e) => {
                decoratedOnClick();
                checkLoggedIn(value.id, voteType, true, value.type);
              }}
            >
              <i
                className={`${
                  hasPreviouslyInteracted
                    ? voteType === "up_vote"
                      ? "fas fa-thumbs-up has-interacted"
                      : "fas fa-thumbs-down has-interacted"
                    : voteType === "up_vote"
                    ? "far fa-thumbs-up"
                    : "far fa-thumbs-down"
                } far mr-3  fa-lg`}
                aria-hidden="true"
              ></i>
              <div
                className={`${hasPreviouslyInteracted && "has-interacted"} ${
                  voteType === "down_vote" && reply && "mr-4"
                }`}
              >
                {children}
              </div>
            </button>
          </div>
        </OverlayTrigger>
      );
    };

    const CommentButton = () => {
      hasPreviouslyCommented = checkPreviouslyCommented(value);

      return (
        <div className={`${reply && "d-none"}`}>
          {(currentUserProfile?.isFriend || !currentUserProfile) && (
            <OverlayTrigger
              placement={"auto"}
              delay={{ show: 0, hide: 400 }}
              overlay={
                <Tooltip
                  className={`${
                    hasPreviouslyCommented ||
                    (currentProfile && currentProfile.cityId !== cityId)
                      ? "tooltip-extra-info"
                      : "d-none"
                  }`}
                >
                  {hasPreviouslyCommented
                    ? "You can't reply because you have already replied"
                    : currentProfile &&
                      currentProfile.cityId !== cityId &&
                      "You can't reply because you are not from this city or you have not complete your profile"}
                </Tooltip>
              }
            >
              <button
                disabled={
                  hasPreviouslyCommented ||
                  (currentProfile && currentProfile.cityId !== cityId)
                }
                className="d-flex border-0 bg-white thread-interaction mr-3 "
                onClick={(e) => {
                  decoratedOnClick();
                  checkLoggedIn(value.id, !activeKey[value.id], false);
                }}
              >
                <>
                  <i className="fa fa-reply mr-3 fa-lg" aria-hidden="true"></i>
                  <div>{children}</div>
                </>
              </button>
            </OverlayTrigger>
          )}
        </div>
      );
    };

    switch (eventKey) {
      case "0":
        return <VotingButton voteType="up_vote" />;
      case "1":
        return <VotingButton voteType="down_vote" />;
      case "2":
        return <CommentButton />;
      default:
    }
  };

  return (
    <div>
      <PopupLogin
        show={requireLogin}
        onHide={() => {
          handleHidePopupLogin();
        }}
        isFriendDefaultChecked={isFriendDefaultChecked}
        name="Sign In"
        onSuccessfulSignIn={reloadCurrentUser}
        reloadCurrentUser={reloadCurrentUser}
      />
      <EmailVerificationModal
        isShow={requireVerification}
        handleVerificationModal={() => {
          handleHideVerificationModal();
        }}
        title="Verify your Email"
        description="Almost there! We have sent a verification email to"
        email={currentUser && hideEmailCharacters(currentUser?.email)}
        footer="You need to verify your email address to use the services offered by Move2NewCity."
      />
      <Accordion
        className="reply-accordion"
        activeKey={activeKey[value.id] ? "2" : ""}
      >
        <div className="row m-0 p-0">
          <CustomToggle eventKey="0">{noOfUpVotes}</CustomToggle>
          <CustomToggle eventKey="1">{noOfDownVotes}</CustomToggle>
          <CustomToggle eventKey="2">{noOfComments}</CustomToggle>
        </div>
        <Accordion.Collapse eventKey="2" id="two">
          <div>
            <div className="reply-accordion-content col p-0 pt-3">
              <p className="font-weight-bold mb-2">Write a comment</p>
              <textarea
                id="textarea"
                className="thread-comment-input p-1 pl-0"
                onChange={(e) => replyChange(e, value.id)}
              ></textarea>
            </div>
            <div className="col-10 mr-0 mt-1 ml-1 d-flex justify-content-end">
              <Button
                className="thread-comment-send px-0 mr-3 pr-1"
                variant="link"
                onClick={(e) => handleReplyClick(e, value.id)}
              >
                Send
              </Button>
            </div>
          </div>
        </Accordion.Collapse>
      </Accordion>
    </div>
  );
};
