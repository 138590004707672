import React from "react";
import { ChevronLeft, ChevronRight } from "react-bootstrap-icons";
import "./City-Template.scss";

const CityTemplate = () => {
  return (
    <div className="shadow rounded p-5 mt-4 mr-4 city-template">
      <div className="pb-4 city-template-name">
        <h1>[city name]</h1>
      </div>
      <div className="row ml-0">
        <div className="col d-flex justify-content-between align-items-center border border-dark city-template-banner">
          <ChevronLeft size={15} />
          [banners]
          <ChevronRight size={15} />
        </div>
        <div className="col-4 ml-3">[description]</div>
      </div>
    </div>
  );
};

export default CityTemplate;
