import "@fortawesome/fontawesome-free/css/all.css";
import React from "react";
import ReactDOM from "react-dom";
import ReactNotification from "react-notifications-component";
import "react-notifications-component/dist/theme.css";
import { BrowserRouter as Router } from "react-router-dom";
import App from "./App";
import "./custom-theme.scss";
import "./index.scss";
import reportWebVitals from "./reportWebVitals";
import * as Sentry from '@sentry/react';

Sentry.init({
  dsn: "https://6d5a7b25c35bf1818908ab7773f4834f@o4505742558494720.ingest.sentry.io/4505742559870976",
  integrations: [
    new Sentry.BrowserTracing({
      tracePropagationTargets: [
        "https://m2nc.dev.limarktech.com",
        "https://www.move2newcity.com",
        "https://move2newcity.com"
      ],
    }),
    new Sentry.Replay(),
  ]
});

ReactDOM.render(
  <Router>
    <ReactNotification />
    <App />
  </Router>,
  document.getElementById("root")
);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
