import { useState, useEffect } from "react";
import { Button, Container, Spinner, OverlayTrigger } from "react-bootstrap";
import { useParams, useHistory } from "react-router-dom";
import PopupLogin from "../../components/popup-login/Popup-Login";
import {
  changePassword,
  getPasswordResetToken,
  getCurrentUser,
} from "../../util/APIUtils";
import { notificationManage } from "../../util/NotificationUtils";
import "./Reset-Password.scss";
import { passwordPolicy, passwordState, validatePassword } from "../../util/Utils";
import Cookies from "js-cookie";

const resetPasswordImage = "/assets/img/recover/reset-password-image.png";

const ResetPassword = () => {
  const params = useParams();
  const history = useHistory();
  const passwordInitialState = {
    newPassword: null,
    confirmPassword: null,
  };
  const passwordVisibleState = {
    newPassword: false,
    confirmPassword: false,
  };
  const errorInitialState = {
    newPassword: false,
    confirmPassword: false,
  };
  const status = {
    success: "success",
    fail: "fail",
  };
  const screenWidth = window.matchMedia("(max-width: 992px)");
  const [password, setPassword] = useState(passwordInitialState);
  const [error, setError] = useState(errorInitialState);
  const [requireLogin, setRequireLogin] = useState(false);
  const [isShow, setIsShow] = useState(false);
  const [passwordVisible, setPasswordVisible] = useState(passwordVisibleState);

  useEffect(() => {
    localStorage.getItem("accessToken") &&
      getCurrentUser()
        .then(() => {
          history.push("/");
          notificationManage(
            "warning",
            "Please log out to change the password"
          );
        })
        .catch((error) => {});
  }, []);

  useEffect(() => {
    getPasswordResetToken(params?.token)
      .then((res) => {
        if (res.status !== 200) {
          setIsShow(status.fail)
        } else {
          res.json().then(ctx => {
            setIsShow(ctx?.used ? status.fail : status.success);
          })
        }
      })
      .catch((err) => {
        setIsShow(status.fail);
      });
  }, [params?.token, status.fail, status.success]);

  const handleInputValidations = (password) => {
    let updatedErrors = { ...error };
    updatedErrors.newPassword = Boolean(!password.newPassword);
    updatedErrors.confirmPassword = Boolean(!password.confirmPassword);
    if (password.newPassword) {
      updatedErrors.passwordMismatch = Boolean(
        password.newPassword !== password.confirmPassword
      );
    }
    setError(updatedErrors);
    return Object.values(updatedErrors).every((value) => value === false);
  };

  const updateErrorMessages = (field) => {
    const errorCp = error;
    errorCp[field] = false;
    if (field === "newPassword") {
      error.passwordValidation = false;
    }
    setError(errorCp);
  };

  const handleGoBack = (e) => {
    e.preventDefault();
    history.push("/");
  };

  const handleHidePopupLogin = () => {
    setRequireLogin(false);
  };

  const handleVisiblePassword = (type, isVisible) => {
    setPasswordVisible((prev) => ({
      ...prev,
      [type]: isVisible,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (handleInputValidations(password)) {
      changePassword(password.newPassword, params.token)
        .then(() => {
          Cookies.set("resetPassword", true);
          history.push("/");
          notificationManage(
            "success",
            "Successfully changed the password. Please login to continue"
          );
        })
        .catch((error) => {
          error?.then((value) => {
            switch (value.status) {
              case 404:
                notificationManage(
                  "danger",
                  "The token is already used or does not exist"
                );
                break;
              default:
                notificationManage("danger", "Something went wrong");
                break;
            }
          });
        })
        .finally(() => {
          Array.from(document.querySelectorAll("input")).forEach(
            (textarea) => (textarea.value = null)
          );
          setPassword(passwordInitialState);
          setError(errorInitialState);
        });
    }
  };

  const handleInputChange = (evt) => {
    const value = evt.target.value;
    const name = evt.target.name;
    setPassword({
      ...password,
      [name]: value,
    });
    updateErrorMessages(name);
    if (name === "confirmPassword") {
      updateErrorMessages("passwordMismatch");
    }
  };

  const passwordInvalidError = () => {
    const passState = validatePassword(password?.newPassword ?? "");
    const messageSty = passState !== passwordState.VALID_PASSWORD ? "error-label-visible" : "success-label-visible"
    return password?.newPassword && (
      <label
        for="newPassword"
        className={`${
          !error.newPassword
            ? `${messageSty} d-block m-0`
            : "error-label-invisible"
        }`}
      >
        {passState}
      </label>
    );
  };

  return (
    <>
      <div className="reset-password">
        <PopupLogin
          show={requireLogin}
          onHide={() => {
            handleHidePopupLogin();
          }}
          name="Sign In"
          type={"forgotPassword"}
        />
        <Container>
          {isShow === status.success ? (
            <div className="reset-password-container">
              {!screenWidth.matches && (
                <div className="reset-password-container-header">
                  <text>Reset Password</text>
                </div>
              )}
              <div className="reset-password-container-body">
                <img
                  src={resetPasswordImage}
                  alt="resetPasswordImg"
                  className="reset-password-img"
                />
                <text className="main-text">Create new password</text>
                <text className="sub-text">
                  Please choose your new password
                </text>
                <div className="reset-password-container-body-form">
                  <form className="password-form">
                    <div className="password-form-input">
                      <div className="new-password">
                        <label className="password-form-input-label">
                          New Password *
                          <OverlayTrigger
                            placement="right"
                            overlay={passwordPolicy}
                          >
                            <i className="fas fa-info-circle pl-1"></i>
                          </OverlayTrigger>
                        </label>

                        <input
                          type={
                            passwordVisible.newPassword ? "text" : "password"
                          }
                          name="newPassword"
                          className="form-control password-form-input-text"
                          placeholder="Enter your new password"
                          value={password.newPassword}
                          onChange={handleInputChange}
                          required
                        />
                        <div className="password-form-input-visible">
                          <i
                            className={`fas ${
                              passwordVisible.newPassword
                                ? "fa-eye"
                                : "fa-eye-slash"
                            } password-visible-icon`}
                            onClick={() =>
                              handleVisiblePassword(
                                "newPassword",
                                !passwordVisible.newPassword
                              )
                            }
                          ></i>
                        </div>
                        <label
                          for="newPassword"
                          className={`${
                            error.newPassword
                              ? "error-label-visible"
                              : "error-label-invisible"
                          }`}
                        >
                          <i className="fas fa-exclamation-circle"></i> Please
                          fill this field
                        </label>
                        {passwordInvalidError()}
                      </div>
                      <div className="confirm-password">
                        <label className="password-form-input-label">
                          Confirm Password *
                        </label>
                        <input
                          type={
                            passwordVisible.confirmPassword
                              ? "text"
                              : "password"
                          }
                          name="confirmPassword"
                          className="form-control password-form-input-text"
                          placeholder="Confirm your new password"
                          value={password.confirmPassword}
                          onChange={handleInputChange}
                          required
                        />
                        <div className="password-form-input-visible">
                          <i
                            className={`fas ${
                              passwordVisible.confirmPassword
                                ? "fa-eye"
                                : "fa-eye-slash"
                            } password-visible-icon`}
                            onClick={() =>
                              handleVisiblePassword(
                                "confirmPassword",
                                !passwordVisible.confirmPassword
                              )
                            }
                          ></i>
                        </div>
                        <label
                          for="confirmPassword"
                          className={`${
                            error.confirmPassword || error.passwordMismatch
                              ? "error-label-visible"
                              : "error-label-invisible"
                          }`}
                        >
                          <i className="fas fa-exclamation-circle"></i>{" "}
                          {(error.confirmPassword || error.passwordMismatch) &&
                          error.confirmPassword
                            ? "Please fill this field"
                            : error.passwordMismatch &&
                              "Please enter the same password"}
                        </label>
                        <label
                          for="confirmPassword"
                          className={`${
                            password.confirmPassword &&
                            password.newPassword === password.confirmPassword
                              ? "success-label-visible"
                              : "error-label-invisible"
                          }`}
                        >
                          {password.confirmPassword &&
                            password.newPassword === password.confirmPassword &&
                            "Password Matched"}
                        </label>
                      </div>
                    </div>
                    <div className="password-form-submit">
                      <Button
                        type="submit"
                        onClick={(e) => handleSubmit(e)}
                        className="btn btn-block btn-primary password-form-submit-button"
                      >
                        Save New Password
                      </Button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          ) : isShow === status.fail ? (
            <div className="reset-not-found">
              <div className="reset-not-found-content">
                <div className="reset-not-found-content-header">Oops !</div>
                <div className="reset-not-found-content-body">
                  Password reset url is invalid or has expired. <br /> Try
                  pasting the URL into your browser or requesting another
                  password reset url.
                </div>
                <div className="reset-not-found-content-button">
                  <Button onClick={(e) => handleGoBack(e)}>Go back</Button>
                </div>
              </div>
            </div>
          ) : (
            <div className="reset-not-found">
              <Spinner animation="border" variant="primary" />
            </div>
          )}
        </Container>
      </div>
    </>
  );
};

export default ResetPassword;
