import React, { useEffect, useState } from "react";
import { Container, Dropdown, Spinner } from "react-bootstrap";
import { Plus, Search } from "react-bootstrap-icons";
import Button from "react-bootstrap/Button";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import UsersForm from "../../components/users-form/Users-Form";
import UsersList from "../../components/users-list/Users-List";
import { getUsers } from "../../util/APIUtils";
import { notificationManage } from "../../util/NotificationUtils";
import { USER_ROLE } from "../../util/Utils";
import "./Manage-Users.scss";

const dropDownFilter = [
  {
    id: 0,
    name: "Active",
    value: "Active",
  },
  {
    id: 1,
    name: "Inactive",
    value: "Inactive",
  },
  {
    id: 2,
    name: "Super admins",
    value: "Super admin",
  },
  {
    id: 3,
    name: "Admins",
    value: "Admins",
  },
];

const ManageUsers = (props) => {
  const { history, currentUser } = props;
  const [users, setUsers] = useState([]);
  const [role, setRole] = useState(USER_ROLE.ADMIN);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [data, setData] = useState([]);
  const [isDataLoad, setIsDataLoad] = useState(true);
  const roles = ["Admins", "Users"];
  const [dropdownChoice, setDropdownChoice] = useState("Active");
  const [openAddUser, setOpenAddUser] = useState(false);

  const dropDownOptions = (selected) => {
    Array.from(document.querySelectorAll("input")).forEach(
      (textarea) => (textarea.value = "")
    );
    setDropdownChoice(selected);
  };

  const handleEdit = (user) => {
    history.push({ pathname: `/edit-user/${user.id}`, state: user });
  };

  const handleOpenAddUser = () => {
    setOpenAddUser(true);
  };

  const handleTabSelect = (activeKey) => {
    dropDownOptions(dropDownFilter[0].value);
    switch (activeKey) {
      case "Admins":
        setRole(USER_ROLE.ADMIN);
        break;
      case "Users":
        setRole(USER_ROLE.USER);
        break;
      default:
        break;
    }
  };

  const filterActiveInactive = (usersCopy, checkActive) => {
    usersCopy = usersCopy.filter(
      (usersCopy) => usersCopy.isActive === checkActive
    );
    return usersCopy;
  };

  const filterAdmin = (usersCopy, role) => {
    usersCopy = usersCopy.filter((usersCopy) => usersCopy.role === role);
    return usersCopy;
  };

  const upperCase = (str) => {
    let splitStr = str.toLowerCase().split(" ");
    for (let i = 0; i < splitStr.length; i++) {
      splitStr[i] =
        splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }
    return splitStr.join(" ");
  };

  const handleSearch = (event) => {
    const { value } = event.target;
    setSearchQuery(value);
    const filteredData = users.filter(
      (user) =>
        user.name?.toLowerCase()?.includes(value?.toLowerCase()) ||
        user.userName?.toLowerCase()?.includes(value?.toLowerCase()) ||
        user.email?.toLowerCase()?.includes(value?.toLowerCase())
    );
    setFilteredUsers(filteredData);
  };

  const loadUserList = () => {
    (currentUser.role === USER_ROLE.SUPER_ADMIN ||
      currentUser.role === USER_ROLE.ADMIN) &&
      getUsers(role)
        .then((res) => {
          setUsers(res);
        })
        .catch((err) => {
          err?.then((resp) => {
            notificationManage(
              "danger",
              `${
                role === USER_ROLE.ADMIN ? "Admin" : "User"
              } list retrieval failed`
            );
          });
        });
  };

  useEffect(() => {
    loadUserList();
  }, [role]);

  useEffect(() => {
    setIsDataLoad(true);
    setTimeout(() => {
      setIsDataLoad(false);
    }, 2000);
  }, [role]);

  useEffect(() => {
    setToDropdownChoice();
  }, [dropdownChoice, users]);

  const setToDropdownChoice = () => {
    if (users) {
      let usersCopy = [...users];
      switch (dropdownChoice) {
        case "Active":
          setData(filterActiveInactive(usersCopy, true));
          break;
        case "Inactive":
          setData(filterActiveInactive(usersCopy, false));
          break;
        case "Admins":
          setData(filterAdmin(usersCopy, USER_ROLE.ADMIN));
          break;
        case "Super admin":
          setData(filterAdmin(usersCopy, USER_ROLE.SUPER_ADMIN));
          break;
        default:
          break;
      }
    }
  };

  useEffect(() => {
    if (searchQuery.length === 0) {
      setToDropdownChoice();
    } else {
      setData(filteredUsers);
    }
  }, [filteredUsers, searchQuery]);

  return (
    props.currentUser.role === USER_ROLE.SUPER_ADMIN && (
      <>
        <Container className="users">
          <UsersForm
            openAddUser={openAddUser}
            setOpenAddUser={setOpenAddUser}
            isAdmin={true}
            loadUserList={loadUserList}
          />
          <div>
            <div className="col-xs-3 users-title-wrapper">
              <span className="ml-3 mr-2 users-isTitle">
                {role === USER_ROLE.ADMIN ? "Admins" : "Users"}
              </span>
            </div>
            <Tabs
              className="mt-5 tabs"
              defaultActiveKey="Admins"
              transition={false}
              onSelect={handleTabSelect}
            >
              {roles.map((item) => (
                <Tab
                  tabClassName="py-3 px-5 users-tab"
                  key={item}
                  eventKey={item}
                  title={item}
                >
                  <div className="row utility-row">
                    <div className="sortByTxt">Sort by:</div>
                    <div className=" col-3 UserSortBy">
                      <Dropdown className=" user-dropdown">
                        <Dropdown.Toggle className="user-dropdown-toggle">
                          {dropdownChoice}
                          <i className="fas fa-caret-down"></i>
                        </Dropdown.Toggle>
                        <Dropdown.Menu className={"user-dropdown-menu"}>
                          {dropDownFilter
                            .slice(0, role === USER_ROLE.USER ? 2 : 4)
                            .map((menuItem, index) => {
                              return (
                                <Dropdown.Item
                                  key={index}
                                  className={"user-dropdown-options"}
                                  onClick={(e) =>
                                    dropDownOptions(menuItem.value)
                                  }
                                >
                                  {menuItem.name}
                                </Dropdown.Item>
                              );
                            })}
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                    <div
                      className={
                        role === USER_ROLE.USER
                          ? "search-component-user col-3"
                          : "search-component col-3"
                      }
                    >
                      <div className="input-group users-search">
                        <div className="input-group-prepend">
                          <span className="input-group-text border-light bg-white">
                            <Search color="#11294B" />
                          </span>
                        </div>
                        <input
                          type="search"
                          className="form-control border-light"
                          placeholder={
                            role === USER_ROLE.USER
                              ? "Search user"
                              : "Search admin"
                          }
                          onChange={handleSearch}
                        />
                      </div>
                    </div>
                    {role !== USER_ROLE.USER && (
                      <div className="col-1 text-md-right users-button-holder">
                        <Button
                          className="users-button"
                          variant="primary"
                          onClick={handleOpenAddUser}
                        >
                          <Plus size={25} /> Add new admin
                        </Button>
                      </div>
                    )}
                  </div>
                  <hr className="separator mx-0 px-0 pb-0  mt-0" />
                  {isDataLoad ? (
                    <div className="loading-spinner">
                      <Spinner animation="border" variant="primary" />
                    </div>
                  ) : (
                    <UsersList
                      data={data}
                      role={role}
                      onEdit={handleEdit}
                      loadUserList={loadUserList}
                    />
                  )}
                </Tab>
              ))}
            </Tabs>
          </div>
        </Container>
      </>
    )
  );
};

export default ManageUsers;
