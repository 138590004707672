import { useEffect, useState } from "react";
import "./How-it-works.scss";
import { Card } from "react-bootstrap";
import Accordion from "react-bootstrap/Accordion";
import TextImageGrid from "../../components/text-image-grid/Text-image-grid";
import { scrollTop } from "../../util/Utils";
import { ENV, ENVIROMENTS } from "../../constants";
import { MetaTags } from "react-meta-tags";

const HowItWorks = () => {
  const howItWorks = [
    {
      title: "1. Search",
      description:
        "Newcomers can search for cities that they are interested in relocating to, by typing the city name on the search bar or selecting a city from the 'Top Cities' section and see useful questions posted by fellow Newcomers who are planning to move to that city and view answers provided by experts (Friends) from that city.",
      image: "/assets/img/how-it-works/1.png",
      cssClass: "",
    },
    {
      title: "2. Ask",
      description:
        " You can sign up as a Newcomer to connect with Friends from different cities around the world and get to know about their experience in that city.",
      image: "/assets/img/how-it-works/2.png",
      cssClass: "reverse-grid",
    },
    {
      title: "3. Schedule",
      description:
        "You can schedule calls with Friends living in different cities to get to know more about the city that you are planning to move to and clarify any issues or doubts regarding the city, which in return will help you in a smooth transition to the new city.",
      image: "/assets/img/how-it-works/3.png",
      cssClass: "",
    },
  ];

  const faqs = [
    {
      id: 1,
      type: "General FAQs",
      sections: [
        {
          id: 1,
          title: `<p>About Move2NewCity</p>`,
          items: [
            {
              id: 1,
              question: `<p>What is Move2NewCity?</p>`,
              answer: `<p>Move2NewCity is an online platform designed to assist individuals and families who are planning to move to a new city. It provides a wide range of resources and information to help make your relocation process smooth and stress-free. More importantly, Move2NewCity helps to connect with real people with lived experiences in the new city.</p>`,
            },
            {
              id: 2,
              question: `<p>What are the services provided by Move2NewCity?</p>`,
              answer: `<p>Move2NewCity facilitates newcomers to a new city by asking questions from friends who live in that city about areas such as accommodation, employment, education, food, healthcare, transportation, and many more.</p>`,
            },
            {
              id: 3,
              question: `<p>Is Move2NewCity a global platform?</p>`,
              answer: `<p>Currently, our primary focus is on facilitating migration to Australia, However, we are soon hoping to extend our services globally within the upcoming months.</p>`,
            },
            {
              id: 4,
              question: `<p>Is Move2NewCity free to use?</p>`,
              answer: `<p>Yes, Move2NewCity is entirely free to use. We aim to provide valuable information and assistance to make your relocation process as smooth as possible without any cost to you.</p>`,
            },
            {
              id: 5,
              question: `<p>Can I access Move2NewCity from any device, such as smartphones or tablets?</p>`,
              answer: `<p>The Move2NewCity website is fully optimized to ensure seamless access from any device, allowing you to browse it conveniently, anytime, anywhere.</p>`,
            },
            {
              id: 6,
              question: `<p>Can I search for specific cities on Move2NewCity.</p>`,
              answer: `<p>To search for available cities, please use the search bar. Enter the name of an available city you are interested in and you will receive detailed information about that location.</p>`,
            },
          ],
        },
        {
          id: 2,
          title: `<p>Login/Signup</p>`,
          items: [
            {
              id: 7,
              question: `<p>How can I sign up on Move2NewCity?</p>`,
              answer: `<p>You can sign up as a friend or newcomer using your Google, Facebook, or Email account.{'\n'}You cannot, however, use the same email address to sign up both as a friend and a newcomer.</p>`,
            },
            {
              id: 8,
              question: `<p>What are the rules for an email address to be considered valid when signing up for an account?</p>`,
              answer: `<p>The email address must follow the standard email address format, which is: username@domain.com</p>`,
            },
            {
              id: 9,
              question: `<p>What are the rules for a password to be considered valid when signing up for a account</p>`,
              answer: `<p>Passwords must be at least 8 characters long and contain a mix of uppercase and lowercase letters, numbers, and special characters. They should also be unique and changed regularly to decrease chances of account theft</p>`,
            },
          ],
        },
        {
          id: 3,
          title: `<p>Edit Account</p>`,
          items: [
            {
              id: 10,
              question: `<p>How can I edit my account details?</p>`,
              answer: `<p>To edit your account details, please follow the below steps:</p>
              <ul><li>Sign in to your account.</li>
              <li>Click on your profile icon.</li>
              <li>Click on ""Edit account"".</li>
              <li>Make the changes you want to your account details.</li>
              <li>Click on ""Save changes</li></ul>`,
            },
            {
              id: 11,
              question: `<p>Can I edit the categories I am interested in?</p>`,
              answer: `<p>To change interested categories:</p>
              <ul><li>Click on profile icon</li>
              <li>Click on edit account</li>
              <li>Add new catagories</li>
              <li>Click save</li></ul>`,
            },
            {
              id: 12,
              question: `<p>How can I update my personal information?</p>`,
              answer: `<p>You can update your personal information by clicking on the profile icon on the top right corner and selecting the ‘Edit Account’ option.</p>`,
            },
            {
              id: 13,
              question: `<p>Can i change my account email?</p>`,
              answer: `<p>We currently do not support email changes. If you are experiencing any problems, please contact us so that we can assist you.</p>`,
            },
          ],
        },
        {
          id: 4,
          title: `<p>Password</p>`,
          items: [
            {
              id: 14,
              question: `<p>How do I change my password?</p>`,
              answer: `<p>To change your password please follow the below steps:</p>
            <ul><li>Go to the login page and click on the ""Forgot Password?"" link.</li>
            <li>Enter your email address in the field provided.</li>
            <li>Click on the 'Send' button.</li>
            <li>Check your email for a message from us. The message will contain a link that you can click on to reset your password.</li>
            <li>Click on the link and enter a new password.</li>
            <li>Click on the 'Reset Password' button</li></ul>
            <p>Please make the necessary changes to the password</p>`,
            },
            {
              id: 15,
              question: `<p>What should I do if I cannot find or did not receive the link to change the password?</p>`,
              answer: `<p>Please check your spam folder to see if our email was sent there. If you do not find it, please contact us through the link provided so that we can assist you further.<a href="https://www.move2newcity.com/contact-us">https://www.move2newcity.com/contact-us</a></p>`,
            },
          ],
        },
        {
          id: 5,
          title: `<p>Privacy Policy and Terms of Services</p>`,
          items: [
            {
              id: 16,
              question: `<p>Is my personal information secure?</p>`,
              answer: `<p>Your privacy and security are of utmost importance to us. Any information that we hold about you is stored on secure servers that are protected in controlled facilities and accessing these details by you will always be authenticated by you with your consent.{'\n\n'}To learn more please read our Privacy Policy here: <a href="https://www.move2newcity.com/privacy-policy#user-consent">https://www.move2newcity.com/privacy-policy#user-consent</a></p>`,
            },
            {
              id: 17,
              question: `<p>How can I delete my account?</p>`,
              answer: `<p>Currently we don't allow deletion of accounts.</p>`,
            },
            {
              id: 18,
              question: `<p>What are Move2NewCity's Terms of Service and Privacy Policy </p>`,
              answer: `<p>You canview the 'Terms and Conditions and 'Privacy Policy here:</p>
              <ul><li>Terms and conditions: <a href="https://www.move2newcity.com/terms-and-conditions">https://www.move2newcity.com/terms-and-conditions</a></li>
              <li>Privacy Policy: <a href="https://www.move2newcity.com/privacy-policy">https://www.move2newcity.com/privacy-policy</a></li></ul>`,
            },
          ],
        },
        {
          id: 6,
          title: `<p>Support</p>`,
          items: [
            {
              id: 19,
              question: `<p>How can I get in touch with Move2NewCity for additional support or inquiries?</p>`,
              answer: `<p>If you have any questions, or feedback, or need further assistance, you can reach out to us through the "Contact Us" section at the bottom of our website or visit <a href="https://www.move2newcity.com/contact-us">https://www.move2newcity.com/contact-us</a> and fill out this form. We value your input and are always ready to help!</p>`,
            },
            {
              id: 20,
              question: `<p>How do I report inappropriate content or users?</p>`,
              answer: `<p>Our team of admins moderates all answers to ensure that they comply with our terms of service. However, if you see any inappropriate content or users, please report it to us through our contact us page. We will investigate the matter and take appropriate action.</p>`,
            },
          ],
        },
        {
          id: 7,
          title: `<p>Emails</p>`,
          items: [
            {
              id: 21,
              question: `<p>How do I unsubscribe from email notifications?</p>`,
              answer: `<p>To unsubscribe from an email, please click on the link at the bottom of the email. This will take you to a page where you can confirm your request to unsubscribe.</p>`,
            },
          ],
        },
      ],
    },
    {
      id: 2,
      type: "FAQs for Friend",
      sections: [
        {
          id: 1,
          title: `<p>About Friend</p>`,
          items: [
            {
              id: 1,
              question: `<p>Who is a friend?</p>`,
              answer: `<p>A friend is someone who is willing to help others and who is knowledgeable about a particular city. They are someone who can provide advice and assistance to newcomers, help them find their way around, and introduce them to new places and people. Friends are a valuable resource for newcomers, and they can make the process of settling into a new city much easier.</p>`,
            },
            {
              id: 2,
              question: `<p>What are the requirements to become a friend?</p>`,
              answer: `<p>To become a friend, you must be willing to share your lived experiences of a city with newcomers. You must also be knowledgeable about the city and be able to answer questions about it. You must be able to communicate effectively with newcomers.</p>`,
            },
            {
              id: 3,
              question: `<p>How can I become a top friend?</p>`,
              answer: `<p>Based on the upvotes and downvotes received for the answers given by you, our rating system will assign a star rating for each category that you have added answers to.</p>`,
            },
          ],
        },
        {
          id: 2,
          title: `<p>Answering Questions</p>`,
          items: [
            {
              id: 4,
              question: `<p>Why can't I answer a question?</p>`,
              answer: `<p>There are several reasons why you might not be able to post a question:</p>
              <ul><li>You haven't verified your email (If signed in using an email)</li>
              <li>You've answered a question on a city that you haven't registered for</li>
              <li>You've already replied once (Only one reply per question)</li></ul>`,
            },
            {
              id: 5,
              question: `<p>How many answers can I provide for a question asked by a Newcomer?</p>`,
              answer: `<p>You can provide only one answer per question. However, you can schedule calls with Newcomers to provide additional details regarding their questions. Not only will you be able to share your knowledge of a city but also will get the opportunity to earn a bit of extra income by having calls with Newcomers. </p>`,
            },
            {
              id: 6,
              question: `<p>Can I reply to questions about other cities?</p>`,
              answer: `<p>No</p>`,
            },
            {
              id: 7,
              question: `<p>Can I delete a reply?</p>`,
              answer: `<p>At the moment, we do not allow the deletion of answers. However before an answer is posted on the Move2NewCity platform, it will go under a stringent process of moderation to ensure that no duplicate or inappropriate content is published on the platform.</p>`,
            },
          ],
        },
        {
          id: 3,
          title: `<p>Availability and Calls</p>`,
          items: [
            {
              id: 8,
              question: `<p>How can I set my availability?</p>`,
              answer: `<p>To change your availability:</p><ul>
              <li>Click on your profile icon.</li>
              <li>Click on Availability.</li>
              <li>You can change your:</li>
                   <ul><li>Call duration</li>
                   <li>Time zone</li>
                   <li>Weekly work hours</li>
                   <li>Date overrides</li></ul></ul>`,
            },
            {
              id: 9,
              question: `<p>Can I reschedule a call?</p>`,
              answer: `<p>Currently, we do not allow friends to reschedule calls, however, friends can suggest new times.  </p>`,
            },
            {
              id: 10,
              question: `<p>Can I cancel a call?</p>`,
              answer: `<p>Currently, we do not allow friends to cancel calls.</p>`,
            },
            {
              id: 11,
              question: `<p>Can I host group calls?</p>`,
              answer: `<p>Currently, we do not allow users to host group calls</p>`,
            },
            {
              id: 12,
              question: `<p>What are the platforms that can be used to join a meeting?</p>`,
              answer: `<p>As of now, all meetings are hosted via Google Meets</p>`,
            },
            {
              id: 13,
              question: `<p>How am I going to get notified about a scheduled call?</p>`,
              answer: `<p>You will receive email notifications about upcoming calls.${"\n"}Furthermore, notifications will appear under the notification tab at the top right of the website. Simply click on it to keep up with meetings, upvotes, replies and general information.${"\n"}Notifications about calls will appear as a yellow call icon."</p>`,
            },
            {
              id: 14,
              question: `<p>Can I set reminders for calls?</p>`,
              answer: `<p>You can add up to two reminders to your calls. These reminders can be set for a specific time or date, and they will be sent to you as a notification. </p>`,
            },
            {
              id: 15,
              question: `<p>Can I add a call to my calendar?</p>`,
              answer: `<p>Meetings will be automatically added to the calendar associated with the email address you used to sign up.</p>`,
            },
          ],
        },
      ],
    },
    {
      id: 3,
      type: "FAQs for Newcomer",
      sections: [
        {
          id: 1,
          title: `<p>About Newcomer</p>`,
          items: [
            {
              id: 1,
              question: `<p>Who is a Newcomer?</p>`,
              answer: `<p>A newcomer is anyone who seeks assistance in moving to a new city. As a newcomer, you have the ability to ask questions and get one-on-one calls with friends, and be a part of an amazing community!</p>`,
            },
          ],
        },
        {
          id: 2,
          title: `<p>Asking Questions</p>`,
          items: [
            {
              id: 2,
              question: `<p>How can I ask questions on Move2NewCity?</p>`,
              answer: `<p>To ask a question:</p>
              <ul><li>Log in as a Newcomer.</li>
              <li>Select the city in which you require the information.</li>
              <li>You will be redirected to a page with questions and answers provided by different users within the system.</li>
              <li>Select the ‘Ask a Question’ button under the categories in the top right corner if you wish to ask a new question.</li></ul>`,
            },
            {
              id: 3,
              question: `<p>Why cant i ask a question?</p>`,
              answer: `<p>If you signed up using email, as opposed to verified options, you will not be able to ask questions until you verify your account through a link sent to your email</p>`,
            },
            {
              id: 4,
              question: `<p>Why is my question not posted?</p>`,
              answer: `<p>We always try to make sure that the content provided on our platform is accurate and up to date. Our content moderators are continuously monitoring the content on the platofrm before publishing. If your content is not published it could be due to one of the following reasons.</p>
              <li>Spam<li>
              <li>Inappropriate content</li>
              <li>Duplicate content</li>
              <li>Poor language quality</li>
              <li>Violation of public ethics</li></ul>`,
            },
            {
              id: 5,
              question: `<p>Can Friends view my details?</p>`,
              answer: `<p>Your personal information is not shared with friends. However, they will be able to see your name, email, address, image, and the questions asked by you. </p>`,
            },
            {
              id: 6,
              question: `<p>How can I delete a question?</p>`,
              answer: `<p>At the moment, we do not allow the deletion of questions. However before a question is posted on the Move2NewCity platform, it will go under a precise process of moderation to ensure that no duplicate or inappropriate content is published on the platform.</p>`,
            },
            {
              id: 7,
              question: `<p>How can I verify the credibility of answers provided by Friends?</p>`,
              answer: `<p>Our team of friends is committed to providing you with the best possible experience. To become a part of our team, you must agree to our terms and conditions, to be honest.  To uphold the highest quality standards, every response undergoes a rigorous review process led by our diligent panel of content moderators who conduct meticulous fact-checking before publication. We are dedicated to providing credible and trustworthy information for your benefit.</p>`,
            },
          ],
        },
        {
          id: 3,
          title: `<p>Scheduling Call</p>`,
          items: [
            {
              id: 8,
              question: `<p>Is Move2NewCity's call feature free to use?</p>`,
              answer: `<p>"The use of Move2NewCity's call feature is currently free for users to schedule and initiate calls.${"\n"}Yes, the use of Move2NewCity's call feature is currently free for users to use."</p>`,
            },
            {
              id: 9,
              question: `<p>How can I schedule a call?</p>`,
              answer: `<p>To schedule a call with a friend on our website, follow these steps:</p>
              <ul><li>Go to the home screen and enter the city you want to move to.</li>
              <li>You will see a list of friends from that city who can answer your questions.</li>
              <li>Click on a friend whose expertise matches your questions.</li>
              <li>Click on 'Schedule a call'.</li>
              <li>You will need to verify your email before scheduling a call.</li>
              <li>Write a brief description of the topics you want to cover in the call, enter the estimated - duration, and select a date and time.</li>
              <li>To manage your calls, click on the profile icon and then click on 'Scheduled calls.<li></ul>`,
            },
            {
              id: 10,
              question: `<p>Are there any restrictions on call durations?</p>`,
              answer: `<p>The maximum call duration is 45 minutes. We offer three standard call durations of 15, 30, and 45 minutes. If you need a call that is shorter than 15 minutes,</p>`,
            },
            {
              id: 11,
              question: `<p>Is it required for both me and my friend to have a Move2NewCity account in order to schedule a call?</p>`,
              answer: `<p>Yes, both you and your friend will need to have a Move2NewCity account in order to schedule a call.</p>`,
            },
            {
              id: 12,
              question: `<p>How can I reschedule a call?</p>`,
              answer: `<p>To reschedule a call as a newcomer:</p>
              <ul><li>Log in to your account and go to your user profile.</li>
              <li>Click on the 'Scheduled Calls' tab.</li>
              <li>Locate the call that you want to reschedule.</li>
              <li>Click on the 'Reschedule' button.</li>
              <li>Select a new date and time for the call.</li>
              <li>Click on the 'Reschedule Call' button</li></ul>`,
            },
            {
              id: 13,
              question: `<p>How can I cancel a call?</p>`,
              answer: `<p>To cancel a call as a newcomer:</p>
              <ul><li>Log in to your account and go to your user profile.</li>
              <li>Click on the 'Scheduled Calls' tab.</li>
              <li>Locate the call that you want to cancel.</li>
              <li>Click on the 'Cancel Call' button.</li>
              <li>Enter the reason why you are canceling the call.</li>
              <li>Click on the 'Yes' button to confirm the cancellation.</li></ul>`,
            },
          ],
        },
      ],
    },
  ];

  const [showIndex, setShowIndex] = useState(1);

  useEffect(() => {
    scrollTop();
  }, []);

  const toggle = (index) => {
    if (showIndex === index) {
      setShowIndex(null);
    } else {
      setShowIndex(index);
    }
  };

  return (
    <div className="page-how-it-works-container">
      <div className="how-it-works">
        <div className="how-it-works-title">How Move2NewCity Works?</div>
        <div>
          <TextImageGrid content={howItWorks} />
        </div>
        <div className="how-it-works-grid-line-header">
          Move2NewCity
          <div className=" text-image-grid-line"></div>
        </div>
        <div className="how-it-works-image4">
          <img src="/assets/img/how-it-works/4.jpg" alt="" />
        </div>
        <div className="faqs">
          <div className="faqs-title">FAQ</div>
          <div className="faqs-subtitle">
            Frequently asked questions about Move2NewCity
          </div>
          <Accordion
            className="faqs-accordion-panel"
            defaultActiveKey={showIndex}
            activeKey={showIndex}
          >
            {faqs.map((faq) => {
              return (
                <Card>
                  <Accordion.Toggle
                    eventKey={faq.id}
                    onClick={(e) => toggle(faq.id)}
                  >
                    <p className="m-0 faqs-type">{faq.type}</p>
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey={faq.id}>
                    <Card.Body className="d-flex flex-column">
                      {faq.sections.map((qa) => {
                        return (
                          <div className="d-flex flex-column w-100">
                            <div
                              className="faqs-header"
                              dangerouslySetInnerHTML={{ __html: qa.title }}
                            />
                            {qa.items.map((it) => {
                              return (
                                <div className="d-flex flex-column mb-1">
                                  <div
                                    className="faqs-question"
                                    dangerouslySetInnerHTML={{
                                      __html: it.question,
                                    }}
                                  />
                                  <div
                                    className="faqs-answer"
                                    dangerouslySetInnerHTML={{
                                      __html: it.answer,
                                    }}
                                  />
                                </div>
                              );
                            })}
                          </div>
                        );
                      })}
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
              );
            })}
          </Accordion>
        </div>
      </div>
      {ENV === ENVIROMENTS.PRODUCTION && (
        <MetaTags>
          <title>How It Works | Move2NewCity</title>
          <meta
            name="description"
            content="Learn more about the process to find friends as a newcomer or share your living experience in a city as a friend at Move2NewCity"
          />
          <meta
            name="keywords"
            content="how it works, move2newcity, settling in australia, settling with ease in australia, australian online community, australia"
          />
        </MetaTags>
      )}
    </div>
  );
};

export default HowItWorks;
