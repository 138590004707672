import React from "react";
import { Link } from "react-router-dom";
import { useState } from "react";
import AlertModal from "../alert-popup-modal/Alert-Modal";
import "./Footer.scss";

const logo = "/assets/img/logo.png";
const footerNavigation = [
  {
    name: "About Us",
    link: "/about-us",
  },
  {
    name: "Contact Us",
    link: "/contact-us",
  },
  {
    name: "How It Works",
    link: "/how-it-works",
  },
  {
    name: "Terms & Conditions",
    link: "/terms-and-conditions",
  },
  {
    name: "Become a Friend",
    link: "/become-a-friend",
  },
  {
    name: "Privacy Policy",
    link: "/privacy-policy",
  },
];

const socialLogins = [
  {
    name: "facebook",
    link: "https://www.facebook.com/move2newcity",
    image: <i className="fab fa-facebook-f social-media-icon" />,
  },
  {
    name: "linkedin",
    link: "https://www.linkedin.com/company/move2newcity/",
    image: <i className="fab fa-linkedin-in social-media-icon" />,
  },
  {
    name: "instagram",
    link: "https://www.instagram.com/move2newcity/",
    image: <i className="fab fa-instagram social-media-icon" />,
  },
];

const Footer = () => {
  const [showAlertModal, setShowAlertModal] = useState(false);
  const [link, setLink] = useState("/")
  
  const handleLinkClicks = (e) => {
    e.preventDefault();
    let link_ = e.target.closest("a").href;
    if (
      localStorage.getItem("isSave") !== "true" ||
      localStorage.getItem("emptyField") === "true"
    ) {
      setLink(link_)
      setShowAlertModal(true);
    } else {
      window.location.href = link_;
    }
  };

  return (
    <>
      <footer className="footer d-flex">
        <div className="footer-upper">
          <div className="footer-upper-left footer-upper-child">
            <img src={logo} alt="m2nc-logo" />
            {/*  hiding text temporarily
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras
              sollicitudin nulla in mauris ornare, eget blandit odio blandit.
              Proin semper eget augue eget iaculis.
            </p> 
            */}
          </div>
          <div className="footer-upper-right footer-upper-child">
            <p className="footer-upper-right-header">Explore</p>
            <svg className="footer-text-underline">
              <rect width="59px" height="1px" fill="#EE4D67" />
            </svg>
            <div className="footer-upper-right-content">
              {footerNavigation.map((item, index) => {
                return (
                  <a href={item.link} onClick={handleLinkClicks}>
                    <p>{item.name}</p>
                  </a>
                );
              })}
            </div>
          </div>
          <div className="footer-upper-social-media footer-upper-child">
            <p className="footer-upper-social-media-header">Find us on</p>
            <svg className="footer-upper-social-media-header-underline">
              <rect width="59px" height="1px" fill="#EE4D67" />
            </svg>
            <div className="footer-upper-social-media-content d-flex">
              {socialLogins.map((item) => {
                return (
                  <div className="footer-upper-social-media-content-item">
                    <Link
                      className="footer-upper-social-media-content-item-link"
                      to={{ pathname: item.link }}
                      target="_blank"
                    >
                      {item.image}
                    </Link>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <div className="footer-lower">
          <p className="footer-lower-text">Move2NewCity &copy; 2023</p>
        </div>
      </footer>
      <AlertModal
        showModal={showAlertModal}
        hideModal={() => setShowAlertModal(false)}
        id={""}
        type="Go back and Save"
        message="unsaved"
        process="Leave Without Saving"
        link={link}
      />
    </>
  );
};

export default Footer;
