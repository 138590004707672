import React, { useEffect } from "react";
import TextImageGrid from "../../components/text-image-grid/Text-image-grid";
import AboutUsAccordion from "../../components/about-us/about-us-accordion/About-us-accordion";
import AboutUsCarousel from "../../components/about-us/about-us-carousel/About-us-carousel";
import "./About-us.scss";
import { scrollTop } from "../../util/Utils";
import { MetaTags } from "react-meta-tags";
import { ENV, ENVIROMENTS } from "../../constants";

const AboutUs = () => {
  const about = [
    {
      title: "What is Move2NewCity",
      description: (
        <span>
          With Move2NewCity, you can find Friends who share lived experiences in
          the new city you are moving to. It is the starting point of the
          settlement process for you when moving to the new city.{" "}
          <b>Newcomers</b> can find <b>Friends</b> to gain knowledge and hear
          real stories to make their settlement experience seamless
        </span>
      ),
      image: "/assets/img/about-us/1.png",
      cssClass: "",
    },
    {
      title: "Our Mission",
      description: (
        <span>
          <b>Our Mission</b> is to provide people who move to new cities with
          the easiest and efficient way to make connections with people there
          for insightful tips and information.
        </span>
      ),
      image: "/assets/img/about-us/2.png",
      cssClass: "reverse-grid",
    },
    {
      title: "Our Vision",
      description: (
        <span>
          <b>Our Vision</b> is to be the single platform to connect people who
          move to a new city with the people with lived experiences in that
          city.
        </span>
      ),
      image: "/assets/img/about-us/3.png",
      cssClass: "",
    },
  ];

  useEffect(() => {
    scrollTop();
  }, []);

  return (
    <div className="about-us">
      <div className="about-us-title">About Move2NewCity</div>
      <div>
        <TextImageGrid content={about} />
      </div>
      {ENV === ENVIROMENTS.PRODUCTION && (
        <MetaTags>
          <title>About Us | Move2NewCity</title>
          <meta
            name="description"
            content="Find friends who share their experience in living in the new area you are moving to at Move2NewCity"
          />
          <meta
            name="keywords"
            content="about us, move2newcity, settling in australia, moving states in australia, online community australia, australia"
          />
        </MetaTags>
      )}
    </div>
  );
};

export default AboutUs;
