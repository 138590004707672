import React from "react";
import { Popover } from "react-bootstrap";

const FooterNotificationEmpty = () => {
  return (
    <Popover.Content>
      <div className="d-flex flex-row  align-self-center">No Notification</div>
    </Popover.Content>
  );
};

export default FooterNotificationEmpty;
