import Cookies from "js-cookie";
import { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import ReactGA from "react-ga4";
import { Redirect } from "react-router-dom";
import {
  ACCESS_TOKEN,
  AUTH_SIGNIN,
  AUTH_SIGNUP,
  ENV,
  ENVIROMENTS,
  ErrorMessages,
  REFRESH_TOKEN,
} from "../../../constants";
import { useVerified } from "../../../context/UserContext";
import { getCurrentUser, getProfile } from "../../../util/APIUtils";
import { handleRedirectUrlAfterLogin } from "../../../util/Utils";

const getUrlParameter = (name, props) => {
  name = name.replace(/[[]/, "\\[").replace(/[\]]/, "\\]");
  const regex = new RegExp("[\\?&]" + name + "=([^&#]*)");
  const results = regex.exec(props.location.search);

  return results === null
    ? ""
    : decodeURIComponent(results[1].replace(/\+/g, " "));
};

const OAuth2RedirectHandler = (props) => {
  const { verifyUser } = useVerified();
  const accessToken = getUrlParameter("accessToken", props);
  const refreshToken = getUrlParameter("refreshToken", props);
  const authState = getUrlParameter("state", props);
  const error = getUrlParameter("error", props);
  const email = getUrlParameter("email", props);
  const isFriendSocialLogin = localStorage.getItem("isFriendSocialLogin");
  const [isLoading, setIsLoading] = useState(false);
  const prevLoc = Cookies.get("storeLocation") ?? "/";
  const scheduleCall = Cookies.get("schedule-call");
  const askAQuestion = sessionStorage.getItem("ask-a-question");
  const post =
    sessionStorage.getItem("post-id") &&
    JSON.parse(sessionStorage.getItem("post-id"));

  useEffect(() => {
    if (authState !== "" && ENV === ENVIROMENTS.PRODUCTION) {
      ReactGA.event({
        category: "Social Authentication",
        action: `${authState?.toUpperCase()} - ${
          isFriendSocialLogin === "true" ? "Friend" : "Newcomer"
        }`,
      });
    }
  }, []);

  if (accessToken && refreshToken) {
    localStorage.setItem(ACCESS_TOKEN, accessToken);
    localStorage.setItem(REFRESH_TOKEN, refreshToken);
  }

  const validateError = (error) => {
    const url = (path) => {
      window.location.href = path;
    };

    if (
      authState === "" &&
      isFriendSocialLogin === "true" &&
      error === ErrorMessages.ALREADY_SIGNED_UP_NEWCOMER
    ) {
      url("/account-role-exist?role=false");
    } else if (
      authState === "" &&
      isFriendSocialLogin === "false" &&
      error === ErrorMessages.ALREADY_SIGNED_UP_NEWCOMER
    ) {
      url(`/social-login-exist?email=${email}`);
    } else if (
      authState === "" &&
      isFriendSocialLogin === "false" &&
      error === ErrorMessages.ALREADY_SIGNED_UP_FRIEND
    ) {
      url("/account-role-exist?role=true");
    } else if (
      authState === "" &&
      isFriendSocialLogin === "true" &&
      error === ErrorMessages.ALREADY_SIGNED_UP_FRIEND
    ) {
      url(`/social-login-exist?email=${email}`);
    } else if (
      AUTH_SIGNUP &&
      isFriendSocialLogin === "false" &&
      error === ErrorMessages.SIGNED_UP_WITH_LOCAL_FRIEND
    ) {
      url("/account-role-exist?role=true");
    } else if (
      AUTH_SIGNUP &&
      isFriendSocialLogin === "true" &&
      error === ErrorMessages.SIGNED_UP_WITH_LOCAL_FRIEND
    ) {
      url(`/social-login-exist?email=${email}`);
    } else if (
      AUTH_SIGNIN &&
      isFriendSocialLogin === "true" &&
      error === ErrorMessages.SIGNED_UP_WITH_LOCAL_FRIEND
    ) {
      url(`/social-login-exist?email=${email}`);
    } else if (
      AUTH_SIGNIN &&
      isFriendSocialLogin === "false" &&
      error === ErrorMessages.SIGNED_UP_WITH_LOCAL_FRIEND
    ) {
      url("/account-role-exist?role=true");
    } else if (
      AUTH_SIGNUP &&
      isFriendSocialLogin === "true" &&
      error === ErrorMessages.SIGNED_UP_WITH_LOCAL_NEWCOMER
    ) {
      url("/account-role-exist?role=false");
    } else if (
      AUTH_SIGNUP &&
      isFriendSocialLogin === "false" &&
      error === ErrorMessages.SIGNED_UP_WITH_LOCAL_NEWCOMER
    ) {
      url(`/social-login-exist?email=${email}`);
    } else if (
      AUTH_SIGNIN &&
      isFriendSocialLogin === "true" &&
      error === ErrorMessages.SIGNED_UP_WITH_LOCAL_NEWCOMER
    ) {
      url("/account-role-exist?role=false");
    } else if (
      AUTH_SIGNIN &&
      isFriendSocialLogin === "false" &&
      error === ErrorMessages.SIGNED_UP_WITH_LOCAL_NEWCOMER
    ) {
      url(`/social-login-exist?email=${email}`);
    } else if (
      AUTH_SIGNIN &&
      isFriendSocialLogin === null &&
      error === ErrorMessages.SIGNED_UP_WITH_LOCAL_NEWCOMER
    ) {
      url(`/social-login-exist?email=${email}`);
    } else if (
      AUTH_SIGNIN &&
      isFriendSocialLogin === null &&
      error === ErrorMessages.SIGNED_UP_WITH_LOCAL_FRIEND
    ) {
      url(`/social-login-exist?email=${email}`);
    } else if (
      AUTH_SIGNIN &&
      isFriendSocialLogin === null &&
      (error === ErrorMessages.SIGNED_UP_WITH_GOOGLE_NEWCOMER ||
        error === ErrorMessages.SIGNED_UP_WITH_FACEBOOK_NEWCOMER)
    ) {
      url(`/social-login-exist?email=${email}`);
    } else if (
      AUTH_SIGNIN &&
      isFriendSocialLogin === null &&
      (error === ErrorMessages.SIGNED_UP_WITH_GOOGLE_FRIEND ||
        error === ErrorMessages.SIGNED_UP_WITH_FACEBOOK_FRIEND)
    ) {
      url(`/social-login-exist?email=${email}`);
    } else if (
      AUTH_SIGNUP &&
      isFriendSocialLogin === null &&
      (error === ErrorMessages.SIGNED_UP_WITH_GOOGLE_NEWCOMER ||
        error === ErrorMessages.SIGNED_UP_WITH_FACEBOOK_NEWCOMER)
    ) {
      url(`/social-login-exist?email=${email}`);
    } else if (
      AUTH_SIGNUP &&
      isFriendSocialLogin === null &&
      (error === ErrorMessages.SIGNED_UP_WITH_GOOGLE_FRIEND ||
        error === ErrorMessages.SIGNED_UP_WITH_FACEBOOK_FRIEND)
    ) {
      url(`/social-login-exist?email=${email}`);
    } else if (
      AUTH_SIGNUP &&
      isFriendSocialLogin === "false" &&
      (error === ErrorMessages.SIGNED_UP_WITH_GOOGLE_FRIEND ||
        error === ErrorMessages.SIGNED_UP_WITH_FACEBOOK_FRIEND)
    ) {
      url("/account-role-exist?role=false");
    } else if (
      AUTH_SIGNUP &&
      isFriendSocialLogin === "true" &&
      (error === ErrorMessages.SIGNED_UP_WITH_GOOGLE_FRIEND ||
        error === ErrorMessages.SIGNED_UP_WITH_FACEBOOK_FRIEND)
    ) {
      url(`/social-login-exist?email=${email}`);
    } else if (
      AUTH_SIGNIN &&
      isFriendSocialLogin === "false" &&
      (error === ErrorMessages.SIGNED_UP_WITH_GOOGLE_FRIEND ||
        error === ErrorMessages.SIGNED_UP_WITH_FACEBOOK_FRIEND)
    ) {
      url("/account-role-exist?role=false");
    } else if (
      AUTH_SIGNIN &&
      isFriendSocialLogin === "true" &&
      (error === ErrorMessages.SIGNED_UP_WITH_GOOGLE_FRIEND ||
        error === ErrorMessages.SIGNED_UP_WITH_FACEBOOK_FRIEND)
    ) {
      url(`/social-login-exist?email=${email}`);
    } else if (
      AUTH_SIGNUP &&
      isFriendSocialLogin === "false" &&
      (error === ErrorMessages.SIGNED_UP_WITH_GOOGLE_NEWCOMER ||
        error === ErrorMessages.SIGNED_UP_WITH_FACEBOOK_NEWCOMER)
    ) {
      url(`/social-login-exist?email=${email}`);
    } else if (
      AUTH_SIGNUP &&
      isFriendSocialLogin === "true" &&
      (error === ErrorMessages.SIGNED_UP_WITH_GOOGLE_NEWCOMER ||
        error === ErrorMessages.SIGNED_UP_WITH_FACEBOOK_NEWCOMER)
    ) {
      url("/account-role-exist?role=false");
    } else if (
      AUTH_SIGNIN &&
      isFriendSocialLogin === "false" &&
      (error === ErrorMessages.SIGNED_UP_WITH_GOOGLE_NEWCOMER ||
        error === ErrorMessages.SIGNED_UP_WITH_FACEBOOK_NEWCOMER)
    ) {
      url(`/social-login-exist?email=${email}`);
    } else if (
      AUTH_SIGNIN &&
      isFriendSocialLogin === "true" &&
      (error === ErrorMessages.SIGNED_UP_WITH_GOOGLE_NEWCOMER ||
        error === ErrorMessages.SIGNED_UP_WITH_FACEBOOK_NEWCOMER)
    ) {
      url("/account-role-exist?role=false");
    } else if (
      authState === AUTH_SIGNIN &&
      isFriendSocialLogin === null &&
      (error === ErrorMessages.SIGNED_UP_WITH_GOOGLE_NEWCOMER ||
        error === ErrorMessages.SIGNED_UP_WITH_FACEBOOK_NEWCOMER)
    ) {
      url(`/social-login-exist?email=${email}`);
    } else if (
      authState === AUTH_SIGNIN &&
      isFriendSocialLogin === null &&
      (error === ErrorMessages.SIGNED_UP_WITH_GOOGLE_FRIEND ||
        error === ErrorMessages.SIGNED_UP_WITH_FACEBOOK_FRIEND)
    ) {
      url(`/social-login-exist?email=${email}`);
    } else if (
      authState === AUTH_SIGNUP &&
      isFriendSocialLogin === null &&
      (error === ErrorMessages.SIGNED_UP_WITH_GOOGLE_NEWCOMER ||
        error === ErrorMessages.SIGNED_UP_WITH_FACEBOOK_NEWCOMER)
    ) {
      url(`/social-login-exist?email=${email}`);
    } else if (
      authState === AUTH_SIGNUP &&
      isFriendSocialLogin === null &&
      (error === ErrorMessages.SIGNED_UP_WITH_GOOGLE_FRIEND ||
        error === ErrorMessages.SIGNED_UP_WITH_FACEBOOK_FRIEND)
    ) {
      url(`/social-login-exist?email=${email}`);
    } else if (
      authState === AUTH_SIGNUP &&
      isFriendSocialLogin === "false" &&
      (error === ErrorMessages.SIGNED_UP_WITH_GOOGLE_FRIEND ||
        error === ErrorMessages.SIGNED_UP_WITH_FACEBOOK_FRIEND)
    ) {
      url("/account-role-exist?role=false");
    } else if (
      authState === AUTH_SIGNUP &&
      isFriendSocialLogin === "true" &&
      (error === ErrorMessages.SIGNED_UP_WITH_GOOGLE_FRIEND ||
        error === ErrorMessages.SIGNED_UP_WITH_FACEBOOK_FRIEND)
    ) {
      url(`/social-login-exist?email=${email}`);
    } else if (
      authState === AUTH_SIGNIN &&
      isFriendSocialLogin === "false" &&
      (error === ErrorMessages.SIGNED_UP_WITH_GOOGLE_FRIEND ||
        error === ErrorMessages.SIGNED_UP_WITH_FACEBOOK_FRIEND)
    ) {
      url("/account-role-exist?role=false");
    } else if (
      authState === AUTH_SIGNIN &&
      isFriendSocialLogin === "true" &&
      (error === ErrorMessages.SIGNED_UP_WITH_GOOGLE_FRIEND ||
        error === ErrorMessages.SIGNED_UP_WITH_FACEBOOK_FRIEND)
    ) {
      url(`/social-login-exist?email=${email}`);
    } else if (
      authState === AUTH_SIGNUP &&
      isFriendSocialLogin === "false" &&
      (error === ErrorMessages.SIGNED_UP_WITH_GOOGLE_NEWCOMER ||
        error === ErrorMessages.SIGNED_UP_WITH_FACEBOOK_NEWCOMER)
    ) {
      url(`/social-login-exist?email=${email}`);
    } else if (
      authState === AUTH_SIGNUP &&
      isFriendSocialLogin === "true" &&
      (error === ErrorMessages.SIGNED_UP_WITH_GOOGLE_NEWCOMER ||
        error === ErrorMessages.SIGNED_UP_WITH_FACEBOOK_NEWCOMER)
    ) {
      url("/account-role-exist?role=false");
    } else if (
      authState === AUTH_SIGNIN &&
      isFriendSocialLogin === "false" &&
      (error === ErrorMessages.SIGNED_UP_WITH_GOOGLE_NEWCOMER ||
        error === ErrorMessages.SIGNED_UP_WITH_FACEBOOK_NEWCOMER)
    ) {
      url(`/social-login-exist?email=${email}`);
    } else if (
      authState === AUTH_SIGNIN &&
      isFriendSocialLogin === "true" &&
      (error === ErrorMessages.SIGNED_UP_WITH_GOOGLE_NEWCOMER ||
        error === ErrorMessages.SIGNED_UP_WITH_FACEBOOK_NEWCOMER)
    ) {
      url("/account-role-exist?role=false");
    } else if (
      `${AUTH_SIGNIN} && ${error === ErrorMessages.SIGN_UP_BEFORE_LOGIN}`
    ) {
      url(`/signup`);
    }
  };

  if (authState === AUTH_SIGNIN) {
    let stateHandled = false;
    localStorage.getItem(ACCESS_TOKEN) &&
      getCurrentUser().then((currentUser) => {
        currentUser &&
          getProfile(currentUser?.id)
            .then((currentUserProfile) => {
              if (!currentUserProfile?.isFriend) {
                if (scheduleCall !== undefined) {
                  window.location.href = `${scheduleCall}`;
                } else if (
                  (askAQuestion || post) &&
                  currentUserProfile?.cityId
                ) {
                  window.location.href = `/city/${currentUserProfile?.cityId}`;
                } else {
                  window.location.href = `${prevLoc}`;
                }
              } else {
                stateHandled = true;
                window.location.href = `${prevLoc}`;
              }
            })
            .finally(() => {
              if (scheduleCall !== undefined && !stateHandled) {
                window.location.href = `${scheduleCall}`;
              } else {
                window.location.href = `${prevLoc}`;
              }
            });
      }).catch(console.warn);
  }

  if (error) {
    validateError(error);
  }

  if (accessToken && refreshToken) {
    const newProps = { ...props, verifyUser };
    return (
      !isLoading && (
        <Redirect
          to={{
            pathname: handleRedirectUrlAfterLogin(newProps),
            state: { from: props.location },
          }}
        />
      )
    );
  }

  if (isLoading) {
    return (
      <Spinner
        as="span"
        animation="border"
        size="sm"
        role="status"
        aria-hidden="true"
        className="mr-2 pr-1"
      />
    );
  }
};

export default OAuth2RedirectHandler;
