import "./Whos-Friend-Category.scss";
import { ReactComponent as Friends } from "../home-whos-friend-category/Icons/friends.svg";
import { ReactComponent as Schedule } from "../home-whos-friend-category/Icons/schedule-call.svg";
import { ReactComponent as Post } from "../home-whos-friend-category/Icons/see-posts.svg";
import { S3_BASE_URL } from "../../constants";
import { useHistory } from "react-router";

const friendBannerImg = `${S3_BASE_URL}/images/become-friend-banner.png`;

export default function WhosAFriendCategory() {
  const history = useHistory();

  const handleOnClick = () => {
    history.push("/become-a-friend");
  };

  return (
    <div className="main_container">
      <div className="who-a-friend-title">Who's a Friend?</div>
      <svg className="svg-line" width="100px" height="8px">
        <rect width="59px" height="4px" fill="#EE4D67" />
      </svg>
      <div className="who-a-friend-description pb-1 mt-2">
        A FRIEND is a person who lives in a city and has various lived
        experiences in that city. This can include knowledge, information and
        know-how on different areas such as accommodation, education and
        transportation. A FRIEND is someone who is willing to share this
        knowledge with a NEWCOMER to help them settle in.
      </div>
      <div className="who-a-friend-description pt-1 pb-2 mt-4">
        As a ‘Newcomer’ you can,
      </div>
      <div className="align-container pt-2">
        <div className="row mr-0 ml-0 newcomer-content">
          <div className="col-4 pl-0 pr-0 align-container-icon">
            <Friends className="align-container-icon-holder icon-friends" />
            <div className="align-container-icon-text">
              Select a friend you would like to connect with
            </div>
          </div>
          <div className="col-4 pl-0 align-container-icon">
            <Post className="align-container-icon-holder icon-post" />
            <div className="align-container-icon-text">See what they post</div>
          </div>
          <div className="col-3 align-container-icon mr-0 px-0">
            <Schedule className="align-container-icon-holder icon-schedule mr-0 pr-0" />
            <div className="align-container-icon-text align-items-center">
              Schedule calls with them
            </div>
          </div>
        </div>
        <div className="Header-friends-button">Become a friend</div>
        <div className="image-holder-with-text">
          <img
            src={friendBannerImg}
            alt="Become a friend banner"
            className="icon-banner"
          />
          <div className="icon-banner-container d-flex">
            <div className="col-5"></div>
            <div className="icon-banner-text col-6 mx-0 pt-1">
              Earn rewards by sharing your knowledge about your home city
            </div>
          </div>
        </div>
        <div className="friend-button py-4">
          <button
            onClick={() => {
              handleOnClick();
            }}
            type="button"
            className="btn btn-primary btn-lg btn-block friend-button">
            Become a friend
          </button>
        </div>
      </div>
    </div>
  );
}
