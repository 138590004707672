import { useContext, useEffect, useState } from "react";
import {
  AccordionContext,
  Container,
  Dropdown,
  useAccordionToggle,
} from "react-bootstrap";
import QuestionItem from "../../components/approve-questions-item/Approve-Question-Item";
import EditAndApproveModal from "../../components/approve-questions-modals/EditAndApproveModal";
import RejectionModal from "../../components/approve-questions-modals/RejectionModal";
import {
  getAllQuestionsByStatus,
  getCategoryList,
  reviewPost,
  updatePostById,
} from "../../util/APIUtils";
import { notificationManage } from "../../util/NotificationUtils";
import { USER_ROLE } from "../../util/Utils";
import { utilSortDate } from "../../util/UtilSort";
import "./ReviewPosts.scss";

const ReviewPosts = ({ currentUser }) => {
  const [allQuestions, setAllQuestions] = useState({
    title: "All categories",
    tag: "allCategories",
  });
  const [questionsData, setQuestionsData] = useState({
    questions: [],
    answers: [],
  });
  const [editQuestion, setEditQuestion] = useState({
    title: null,
    text: null,
  });
  const [questionsList, setQuestionsList] = useState([]);
  const [checkedQuestions, setCheckedQuestions] = useState([]);
  const [isAllQuestionsSelected, setIsAllQuestionsSelected] = useState(false);
  const [isRejectionModalOpen, setIsRejectionModalOpen] = useState(false);
  const [isEditAndApproveModalOpen, setIsEditAndApproveModalOpen] =
    useState(false);
  const [rejection, setRejection] = useState({
    id: null,
    title: null,
    text: null,
    isSingleItem: true,
    reason: null,
    customReason: "Other",
  });
  const [categorizeBy, setCategorizeBy] = useState(allQuestions);
  const [sortBy, setSortBy] = useState("Latest questions");
  const [reviewMode, setReviewMode] = useState("Questions");
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    loadCategories();
    loadQuestionsList();
  }, []);

  useEffect(() => {
    setSortBy(
      reviewMode === "Questions" ? "Latest questions" : "Latest answers"
    );
  }, [reviewMode]);

  useEffect(() => {
    let filteredData = {
      questions: null,
      answers: null,
    };
    let sortedData = {
      questions: null,
      answers: null,
    };
    if (categorizeBy.tag !== allQuestions.tag) {
      filteredData.questions = questionsData.questions.filter((value) => {
        return value.tags.includes(categorizeBy.tag);
      });
      filteredData.answers = questionsData.answers.filter((value) => {
        return value.parentTags.includes(categorizeBy.tag);
      });
    }
    const copyOfData = {
      questions: questionsData.questions.slice(),
      answers: questionsData.answers.slice(),
    };
    sortedData.questions = utilSortDate(
      categorizeBy.tag === allQuestions.tag
        ? copyOfData.questions
        : filteredData.questions,
      "createdAt",
      sortBy === "Latest questions" || sortBy === "Latest answers" ? -1 : 1
    );
    sortedData.answers = utilSortDate(
      categorizeBy.tag === allQuestions.tag
        ? copyOfData.answers
        : filteredData.answers,
      "createdAt",
      sortBy === "Latest questions" || sortBy === "Latest answers" ? -1 : 1
    );

    switch (reviewMode) {
      case "Questions":
        setQuestionsList(sortedData.questions);
        break;
      case "Answers":
        setQuestionsList(sortedData.answers);
        break;
      default:
        break;
    }
  }, [reviewMode, questionsData, sortBy, categorizeBy]);

  const loadCategories = () => {
    getCategoryList()
      .then((data) => {
        setCategories(data);
      })
      .catch(() =>
        notificationManage(
          "danger",
          "Something went wrong while retrieving categories"
        )
      );
  };

  const loadQuestionsList = () => {
    getAllQuestionsByStatus("pending")
      .then((data) => {
        let questions = [];
        let answers = [];
        data.forEach((value) => {
          value.parentId ? answers.push(value) : questions.push(value);
        });
        setQuestionsData({
          questions: questions,
          answers: answers,
        });
      })
      .catch((err) =>
        notificationManage(
          "danger",
          `Something went wrong loading the posts: ${err}`
        )
      );
  };

  const updateCheckedQuestions = (id) => {
    setCheckedQuestions(
      checkedQuestions.includes(id)
        ? (checkedQuestions) =>
            checkedQuestions.filter((existingId) => existingId !== id)
        : (checkedQuestions) => [...checkedQuestions, id]
    );
  };

  const bulkHandleReviewAction = (isApproved, rejectionReason) => {
    let promiseArray = [];

    isAllQuestionsSelected
      ? questionsList.forEach((question) => {
          promiseArray.push(
            reviewPost(question?.id, isApproved, rejectionReason)
          );
        })
      : checkedQuestions.forEach((questionId) => {
          promiseArray.push(
            reviewPost(questionId, isApproved, rejectionReason)
          );
        });

    Promise.allSettled(promiseArray)
      .then((response) => {
        let successCount = 0;
        let rejectCount = 0;
        response.forEach((value) => {
          value.status === "fulfilled" ? successCount++ : rejectCount++;
        });
        notificationManage(
          rejectCount > 0 ? "danger" : "success",
          `${successCount} successes, ${rejectCount} fails`
        );
        checkedQuestions.length = 0;
      })
      .finally(() => {
        loadQuestionsList();
      });
  };

  const handleReviewAction = (id, isApproved, rejectionReason) => {
    const formData = {
      message: `${
        rejectionReason === "Other" ? rejection?.customReason : rejectionReason
      }`,
    };
    reviewPost(id, isApproved, formData)
      .then(() =>
        notificationManage(
          "success",
          `Post was ${isApproved ? "approved" : "rejected"}`
        )
      )
      .catch(() => {
        notificationManage("danger", "Something went wrong");
      })
      .finally(() => {
        checkedQuestions.length = 0;
        loadQuestionsList();
      });
  };

  const openRejectionModal = (id, title, text, singleRejection) => {
    setIsRejectionModalOpen(true);
    setRejection({
      id: id,
      title: title,
      text: text,
      isSingleItem: singleRejection,
    });
  };

  const handleRejectionReasonClick = (e) => {
    setRejection((prev) => ({
      ...prev,
      reason: e,
    }));
  };

  const handleQuestionEditChange = (e) => {
    setEditQuestion((prev) => ({
      ...prev,
      [e.target.id]: e.target.value,
    }));
  };

  const handleQuestionEditSubmit = () => {
    const formData = {
      title:
        reviewMode === "Questions" ? editQuestion?.title : editQuestion?.text,
      text: reviewMode === "Questions" ? editQuestion?.text : null,
    };

    updatePostById(rejection.id, formData)
      .then(() => {
        reviewPost(rejection.id, true).finally(() => {
          loadQuestionsList();
        });
        notificationManage("success", "Post was updated");
        setIsEditAndApproveModalOpen(false);
        setIsRejectionModalOpen(false);
      })
      .catch((err) => {
        notificationManage("danger", `Something went wrong`);
      });
  };

  const ContextAwareToggle = ({ children, eventKey, callback, reason }) => {
    const activeEventKey = useContext(AccordionContext);
    const decoratedOnClick = useAccordionToggle(
      eventKey,
      () => callback && callback(eventKey)
    );
    const isCurrentEventKey = activeEventKey === eventKey;

    return (
      <button
        type="button"
        style={{
          backgroundColor: "transparent",
          borderStyle: "none",
          padding: 0,
        }}
        onClick={() => {
          decoratedOnClick();
          handleRejectionReasonClick(reason);
        }}>
        {children}
      </button>
    );
  };

  const rejectionModalProps = {
    isRejectionModalOpen,
    setIsRejectionModalOpen,
    rejection,
    setRejection,
    ContextAwareToggle,
    setIsEditAndApproveModalOpen,
    handleReviewAction,
    bulkHandleReviewAction,
  };

  const editAndApproveProps = {
    isEditAndApproveModalOpen,
    setIsEditAndApproveModalOpen,
    handleQuestionEditChange,
    rejection,
    handleQuestionEditSubmit,
    reviewMode,
  };

  return (
    (currentUser.role === USER_ROLE.ADMIN ||
      currentUser.role === USER_ROLE.SUPER_ADMIN) && (
      <>
        <RejectionModal rejectionModalProps={rejectionModalProps} />
        <EditAndApproveModal editAndApproveProps={editAndApproveProps} />

        <Container className="approve-questions">
          <div className="top-component">
            <div className="first-row">
              <div className="left">
                <button
                  className={`left-buttons ${
                    reviewMode === "Questions" && "left-buttons-active"
                  }`}
                  onClick={() => setReviewMode("Questions")}>
                  Questions
                </button>
                <button
                  className={`left-buttons ${
                    reviewMode === "Answers" && "left-buttons-active"
                  }`}
                  onClick={() => setReviewMode("Answers")}>
                  Answers
                </button>
              </div>
              <div className="right">
                <div>{reviewMode}</div>
              </div>
            </div>
            <div className="second-row">
              <div className="left">
                <div
                  className={`left-items-selected ${
                    !isAllQuestionsSelected &&
                    !checkedQuestions.length &&
                    "left-items-selected-blurred"
                  }`}>
                  {isAllQuestionsSelected ? "All" : checkedQuestions.length}{" "}
                  items selected
                </div>
                <button
                  className={`left-buttons left-buttons-approve-all ${
                    !isAllQuestionsSelected &&
                    !checkedQuestions.length &&
                    "left-buttons-approve-all-blurred"
                  }`}
                  disabled={!isAllQuestionsSelected && !checkedQuestions.length}
                  onClick={() => bulkHandleReviewAction(true)}>
                  <i className="fas fa-check" />
                  Approve all
                </button>
                <button
                  className={`left-buttons left-buttons-reject-all ${
                    !isAllQuestionsSelected &&
                    !checkedQuestions.length &&
                    "left-buttons-reject-all-blurred"
                  }`}
                  disabled={!isAllQuestionsSelected && !checkedQuestions.length}
                  onClick={() => openRejectionModal(null, null, null, false)}>
                  <i className="fas fa-times" />
                  Reject all
                </button>
              </div>
              <div className="right">
                <div className="right-dropdown">
                  Sort by:
                  <Dropdown className="right-dropdown-button">
                    <Dropdown.Toggle className="right-dropdown-button-selection">
                      {sortBy}
                      <i className="fas fa-caret-down"></i>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      {(reviewMode === "Questions"
                        ? ["Latest questions", "Oldest questions"]
                        : ["Latest answers", "Oldest answers"]
                      ).map((menuItem, index) => {
                        return (
                          <Dropdown.Item
                            key={index}
                            className={"latest-posts-options"}
                            value={menuItem}
                            onClick={() => setSortBy(menuItem)}>
                            {menuItem}
                          </Dropdown.Item>
                        );
                      })}
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
                <div className="right-dropdown">
                  <Dropdown className="right-dropdown-button">
                    <Dropdown.Toggle className="right-dropdown-button-selection">
                      {categorizeBy.title}
                      <i className="fas fa-caret-down"></i>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      {[allQuestions]
                        .concat(categories)
                        .map((category, index) => {
                          return (
                            <Dropdown.Item
                              key={index}
                              onClick={() => {
                                setCategorizeBy(category);
                              }}>
                              {category.title}
                            </Dropdown.Item>
                          );
                        })}
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
            </div>
          </div>
          <hr />
          <div className="bottom-component">
            <div className="questions-flex">
              <div className="questions-flex-header">
                <div className="questions-flex-header-checkbox">
                  <i
                    className={`${
                      isAllQuestionsSelected
                        ? "fas fa-check-square"
                        : "far fa-square"
                    } fa-lg`}
                    onClick={() =>
                      setIsAllQuestionsSelected(!isAllQuestionsSelected)
                    }></i>
                </div>
                <div className="questions-flex-header-user">User</div>
                <div className="questions-flex-header-question">
                  {reviewMode}
                </div>
                <div className="questions-flex-header-waited">Waited</div>
                <div className="questions-flex-header-action">Action</div>
              </div>
              {questionsList.map((question) => {
                const currentDate = new Date();
                const createdAt = new Date(question.createdAt);

                return (
                  <div className="questions-flex-item">
                    <QuestionItem
                      id={question.id}
                      isChecked={
                        isAllQuestionsSelected ||
                        checkedQuestions.includes(question.id)
                      }
                      selectAllQuestions={isAllQuestionsSelected}
                      checkedQuestions={checkedQuestions}
                      user={question.createdBy}
                      title={
                        reviewMode === "Questions"
                          ? question.title
                          : question.parentTitle
                      }
                      text={
                        reviewMode === "Questions"
                          ? question.text
                          : question.title
                      }
                      waited={Math.round(
                        (currentDate.getTime() - createdAt.getTime()) / 60000
                      )}
                      updateCheckedQuestions={updateCheckedQuestions}
                      handleReviewAction={handleReviewAction}
                      openRejectionModal={openRejectionModal}
                      reviewMode={reviewMode}
                    />
                  </div>
                );
              })}
            </div>
          </div>
        </Container>
      </>
    )
  );
};

export default ReviewPosts;
