import jwt_decode from "jwt-decode";

export const isJWTExpired = (token) => {
  const decodedHeader = jwt_decode(token);
  const currentTime = new Date().getTime() / 1000;
  return (decodedHeader.exp - currentTime) / 60 <= 0;
};

export const checkRefreshToken = (token) => {
  const decodedHeader = jwt_decode(token);
  const currentTime = new Date().getTime() / 1000;
  return (decodedHeader.exp - currentTime) / 60 <= 3;
};
