import React from "react";
import { PencilFill, Trash } from "react-bootstrap-icons";
import "./Users-List.scss";
import { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import { API_BASE_URL } from "../../constants";
import { SuspendUser } from "../../util/APIUtils";
import UsersForm from "../users-form/Users-Form";
import { notificationManage } from "../../util/NotificationUtils";
import { editUser, deleteAdminById } from "../../util/APIUtils";
import ConfirmationModal from "../confirmation-modal/Confirmation-Modal";

const UsersList = (props) => {
  const dropDownFilter = [
    {
      id: 0,
      name: "Active",
      value: "active",
    },
    {
      id: 1,
      name: "Deactive",
      value: "deactive",
    },
  ];
  const { data, role, loadUserList } = props;
  const [editUsers, setEditUsers] = useState("");
  const [individual, setIndividual] = useState([]);
  const [deleting, setDeleting] = useState(false);
  const [isSuspend, setIsSuspend] = useState(false);
  const [deleteUser, setDeleteUser] = useState([]);
  const [suspendUser, setSuspendUser] = useState([]);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [dropDownClicked, setDropDownClicked] = useState("");
  const [isDeleteSuspend, setIsDeleteSuspend] = useState("");
  const [openAddUser, setOpenAddUser] = useState(false);
  const [formValue, setFormValue] = useState({
    name: "",
    email: "",
    password: "",
    isActive: null,
    role: "",
  });
  let colour = "";
  let TextColour = "";

  const getStatus = (user) => {
    switch (role) {
      case "ROLE_ADMIN":
        if (user.isActive) {
          colour = " #4cca7641";
          TextColour = "#58C47C";
          return "Active";
        }
        colour = " #c7c8cc28";
        TextColour = "#929292";
        return "Deactive";

      case "ROLE_SUPER_ADMIN":
        if (user.isActive) {
          colour = " #4cca7641";
          return "Active";
        }
        colour = " #c7c8cc28";
        return "Deactive";

      case "ROLE_USER":
        if (user.isFriend) {
          return "Friend";
        }
        return "Seeker";

      default:
        break;
    }
  };

  const getUserType = (user) => {
    switch (user) {
      case "ROLE_ADMIN":
        return "Admin";
      case "ROLE_USER":
        return "User";
      case "ROLE_SUPER_ADMIN":
        return "Super Admin";
      default:
        break;
    }
  };

  const handleOnChangeInput = (key, value, user, isDropDownClicked) => {
    setFormValue((prevState) => ({
      ...prevState,
      [key]: value,
    }));
    setIndividual(user);
    setDropDownClicked(isDropDownClicked);
  };

  useEffect(() => {
    if (dropDownClicked !== "") {
      handleUnblock(individual);
    }
  }, [dropDownClicked]);

  const onUserEditor = (user) => {
    setOpenAddUser(true);
    setEditUsers(user);
  };
  const onUserDelete = (deleteUser) => {
    setDeleting(true);
    deleteAdminById(deleteUser.id)
      .then(notificationManage("success", `User  ${deleteUser.name} deleted`))
      .finally(() => {
        setDeleting(false);
        loadUserList();
        setShowConfirmation(false);
        setDeleteUser("");
      });
  };

  const getDeleteModal = (user, isDelete) => {
    setShowConfirmation(true);
    setIsDeleteSuspend(isDelete);
    setDeleteUser(user);
  };
  const getSuspendModal = (user, isSuspend) => {
    setShowConfirmation(true);
    setIsDeleteSuspend(isSuspend);
    setSuspendUser(user);
  };

  const getSuspendUser = (userId) => {
    SuspendUser(userId)
      .then(notificationManage("success", "User Suspended"))
      .catch((err) => {
        err?.then((resp) => {
          notificationManage("danger", "User Suspend Update failed");
        });
      })
      .finally(() => {
        setIsSuspend(false);
        setShowConfirmation(false);
        setSuspendUser("");
      });
  };
  const handleUnblock = (user) => {
    const formData = new FormData();
    formValue.isActive !== user.isActive &&
      formData.append("isActive", formValue.isActive);
    editUser(user.id, formData)
      .then(notificationManage("success", "update made"))
      .catch((err) => {
        err?.then((resp) => {
          notificationManage("danger", "User status Update failed");
        });
      })
      .finally(() => {
        loadUserList();
        setDropDownClicked("");
      });
  };
  return (
    <>
      <UsersForm
        openAddUser={openAddUser}
        setOpenAddUser={setOpenAddUser}
        editUserInfo={editUsers}
        isAdmin={false}
        loadUserList={loadUserList}
      />
      <ConfirmationModal
        open={showConfirmation}
        isDeleting={isDeleteSuspend === "Delete" ? deleting : isSuspend}
        body={
          isDeleteSuspend === "Delete"
            ? `Are you sure you want delete ${deleteUser.name}?`
            : `Are you sure you want Suspend ${suspendUser.name}?`
        }
        action="Yes"
        onAction={
          isDeleteSuspend === "Delete"
            ? () => onUserDelete(deleteUser)
            : () => getSuspendUser(suspendUser.id)
        }
        onClose={() => setShowConfirmation(false)}
      />
      <div className="table-user-wrapper">
        <div className="table-headers">
          <div className="row mt-1 ml-2 p-4">
            {role === "ROLE_USER" ? (
              <span className="col-3 table-headers-row">User</span>
            ) : (
              <span className="col-3 table-headers-row">User</span>
            )}
            <span className="col-3 table-headers-row">Email</span>
            {role === "ROLE_SUPER_ADMIN" || role === "ROLE_ADMIN" ? (
              <>
                <span className="col-2 table-headers-row">Status</span>
                <span className="col-1 mr-4 table-headers-row">Admin Type</span>
              </>
            ) : (
              <span className="col-3 ml-8 table-headers-row">User Type</span>
            )}
            <span className="col-2 mr-2 table-headers-row">Actions</span>
          </div>
        </div>
        {data
          .filter((usersCopy) => usersCopy.isSoftDeleted === false)
          .map((user) => {
            return (
              <div className="card border-light p-4 item" key={user.id}>
                <div className="row">
                  {role === "ROLE_USER" ? (
                    <span className="col-3">
                      <div className="row user-name">
                        <div className="col-2  ml-3">
                          {user.imageUrl || user.image ? (
                            <img
                              className={`${
                                user.imageUrl || user.image ? "gradient" : ""
                              } users-card-image`}
                              alt={user.name}
                              src={
                                user.image !== null
                                  ? `${API_BASE_URL}/v1/users/${user.id}/image`
                                  : user.imageUrl
                              }
                            />
                          ) : (
                            <div className="users-card-image-text-avatar">
                              <span>
                                {user.userName
                                  ? user.userName[0].toUpperCase()
                                  : user.name && user.name[0].toUpperCase()}
                              </span>
                            </div>
                          )}
                        </div>
                        <div className="col-8 ml-4 unified-users-var">
                          {user.userName ? user.userName : user.name}
                        </div>
                      </div>
                    </span>
                  ) : (
                    <span className="col-3">
                      <div className="row user-name">
                        <div className="col-2  ml-3">
                          {user.imageUrl || user.image ? (
                            <img
                              className={`${
                                user.imageUrl || user.image ? "gradient" : ""
                              } users-card-image`}
                              alt={user.name}
                              src={
                                user.image !== null
                                  ? `${API_BASE_URL}/v1/users/${user.id}/image`
                                  : user.imageUrl
                              }
                            />
                          ) : (
                            <div className="users-card-image-text-avatar">
                              <span>
                                {user.name && user.name[0]?.toUpperCase()}
                              </span>
                            </div>
                          )}
                        </div>
                        <div className="col-8 ml-4 unified-users-var">
                          {user.name}
                        </div>
                      </div>
                    </span>
                  )}
                  <span className="col-3 unified-users-var">{user.email}</span>
                  {(role === "ROLE_SUPER_ADMIN" || role === "ROLE_ADMIN") && (
                    <div className="col-2 status-dropdown">
                      <div className="edit-friends-profile-custom-icon"></div>
                      {user.isActive === true ? (
                        <i
                          className="status-dropdown-circle-dot fa fa-circle"
                          aria-hidden="true"></i>
                      ) : (
                        <i
                          className="status-dropdown-circle-clear fa fa-circle"
                          aria-hidden="true"></i>
                      )}
                      <select
                        type="user"
                        defaultValue={getStatus(user)}
                        className="status-dropdown-toggle"
                        style={{
                          backgroundColor: `${colour}`,
                          color: `${TextColour}`,
                        }}
                        onChange={(status) => {
                          status.target.value === "Active"
                            ? handleOnChangeInput(
                                "isActive",
                                true,
                                user,
                                "true"
                              )
                            : handleOnChangeInput(
                                "isActive",
                                false,
                                user,
                                "true"
                              );
                        }}>
                        {dropDownFilter.map((status) => {
                          return (
                            <option selected={`selected`}>
                              {status?.name}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  )}
                  {role === "ROLE_SUPER_ADMIN" || role === "ROLE_ADMIN" ? (
                    <>
                      <span className="col-1 unified-users-var">
                        {getUserType(user.role)}
                      </span>
                      <span className="col-3  unified-users-var-button">
                        <div>
                          <Button
                            className=" edit-user-button"
                            onClick={() => onUserEditor(user)}>
                            <PencilFill
                              className="edit-user-icon"
                              color="#FFFFFF"
                              size={15}
                            />
                            Edit
                          </Button>
                          <Button
                            className="delete-user-button"
                            onClick={() => getDeleteModal(user, "Delete")}>
                            <Trash className="delete-user-icon" size={15} />
                            Delete
                          </Button>
                        </div>
                      </span>
                    </>
                  ) : (
                    <>
                      <span className="col-3  unified-users-var-user">
                        {getStatus(user)}
                      </span>
                      <span className="col-2 ml-5 d-flex unified-users-var-button">
                        <Button
                          className="suspend-button"
                          onClick={() => getSuspendModal(user, "Suspend")}>
                          Suspend
                        </Button>
                      </span>
                    </>
                  )}
                </div>
              </div>
            );
          })}
      </div>
    </>
  );
};

export default UsersList;
