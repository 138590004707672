import { Accordion, Button, Form, Modal } from "react-bootstrap";
import "../../layouts/review/ReviewPosts.scss";

const RejectionModal = (props) => {
  const {
    isRejectionModalOpen,
    setIsRejectionModalOpen,
    rejection,
    setRejection,
    ContextAwareToggle,
    setIsEditAndApproveModalOpen,
    handleReviewAction,
    bulkHandleReviewAction,
  } = props.rejectionModalProps;
  return (
    <Modal
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      className="approve-questions-modal"
      contentClassName="approve-questions-modal-content"
      centered
      show={isRejectionModalOpen}
      onHide={() => setIsRejectionModalOpen(false)}>
      <Modal.Header
        className="approve-questions-modal-header"
        closeButton></Modal.Header>
      <Modal.Body className="approve-questions-modal-body">
        <p className="approve-questions-modal-body-title">{`${
          rejection.isSingleItem
            ? "Are you sure you want to reject this?"
            : "Are you sure you want to reject the selected items?"
        }`}</p>
        <p className="approve-questions-modal-body-form-heading">
          Reasons for rejection:
        </p>
        <Form className="approve-questions-modal-body-form-body">
          <Accordion defaultActiveKey="0">
            {[
              "Spam",
              "Duplicate",
              "Poor language quality",
              "Violation of publication ethics",
              "Other",
            ].map((option) => {
              return (
                <div className="approve-questions-modal-body-form-group">
                  <div>
                    <div>
                      <ContextAwareToggle
                        eventKey={option === "Other" ? "1" : "0"}
                        reason={option}>
                        <div>
                          <Form.Check
                            checked={rejection.reason === option}
                            custom
                            type="checkbox"
                            label={option}
                            className="approve-questions-modal-body-form-item"
                            id={`rejection-reason-${option}`}
                            readOnly
                          />
                        </div>
                      </ContextAwareToggle>
                    </div>
                    <Accordion.Collapse eventKey="1">
                      <div className="">
                        {option === "Other" && (
                          <Form.Control
                            required
                            as="textarea"
                            placeholder="Give Reasons"
                            className={`approve-questions-modal-body-form-item-textarea ${
                              rejection.isSingleItem
                                ? ""
                                : "approve-questions-modal-body-form-item-textarea-disabled"
                            }`}
                            onChange={(e) => {
                              setRejection((prev) => ({
                                ...prev,
                                customReason: e.target.value,
                              }));
                            }}
                          />
                        )}
                      </div>
                    </Accordion.Collapse>
                  </div>

                  {option === "Poor Language Quality" &&
                    rejection.reason === "Poor Language Quality" && (
                      <div
                        className={`approve-questions-modal-body-form-edit-and-approve${
                          rejection.isSingleItem ? "" : "-disabled"
                        }`}
                        onClick={() =>
                          rejection.isSingleItem &&
                          setIsEditAndApproveModalOpen(true)
                        }>
                        Edit and Approve{" "}
                        <i className="fas fa-angle-double-right"></i>
                      </div>
                    )}
                </div>
              );
            })}
          </Accordion>
        </Form>
      </Modal.Body>
      <Modal.Footer className="approve-questions-modal-footer">
        <div>
          <Button
            className={`approve-questions-modal-footer-button approve-questions-modal-footer-button${
              rejection.reason ? "" : "-disabled"
            }`}
            disabled={!rejection.reason}
            onClick={() => {
              rejection.isSingleItem
                ? handleReviewAction(rejection.id, false, rejection.reason)
                : bulkHandleReviewAction(false, rejection.reason);
              setIsRejectionModalOpen(false);
            }}>
            Yes, Reject
          </Button>
          <Button
            className="approve-questions-modal-footer-button"
            onClick={() => setIsRejectionModalOpen(false)}>
            No, Go back
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default RejectionModal;
