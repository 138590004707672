import moment from "moment";
import { useEffect, useRef, useState } from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { getAvailableTimeSlots } from "../../util/APIUtils";
import { notificationManage } from "../../util/NotificationUtils";
import { convertTimesByTimeZone } from "../../util/Utils";
import CalendarModal from "../calendar/CalendarModal";
import "./Appointment-Calendar.scss";

const AppointmentCalendar = ({
  setSelectedNewDate,
  setSelectedTimeNew,
  scheduleDate,
  friendId,
  selectedTimeZoneNew,
  friendTimeZone,
  friendSchedularId,
  selectDurationNew,
}) => {
  const [calendarDate, setCalendarDate] = useState(new Date());
  const [timeSlots, setTimeSlots] = useState([]);
  const [selectedTime, setSelectedTime] = useState("");
  const [fullDayName, setFullDayName] = useState("");
  const [scheduleDateNew, setScheduleDateNew] = useState("");
  const [isReceivedTimeSlots, setIsReceivedTimeSlots] = useState(false);
  const [availableTimeSlotsDays, setAvailableTimeSlotsDays] = useState();
  const [isLoading, setIsLoading] = useState(true); // Add isLoading state
  const data = useRef();
  const start = moment(calendarDate).format("YYYY-MM-DD");
  const end = moment(calendarDate).format("YYYY-MM-DD");

  useEffect(() => {
    const duration = parseInt(selectDurationNew.split(" ")[0]);
    if (duration && friendSchedularId) {
      getAvailableTimeSlots(
        friendSchedularId,
        start,
        end,
        duration,
        selectedTimeZoneNew
      )
        .then((response) => {
          response?.days[0]?.slots?.length > 0
            ? setIsReceivedTimeSlots(true)
            : setIsReceivedTimeSlots(false);
          setAvailableTimeSlotsDays(response);
        })
        .catch((error) => {
          console.error(error);
          notificationManage(
            "danger",
            "Oops! Something went wrong. Please try again!"
          );
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [calendarDate, selectDurationNew, selectedTimeZoneNew, friendSchedularId]);

  useEffect(() => {
    setFullDayName(moment(calendarDate).format("dddd").toLowerCase());
    setSelectedNewDate(calendarDate);
  }, [calendarDate]);

  useEffect(() => {
    if (!data.current) return;
    const filteredBusinessHours = data.current.businessHours.filter(
      (day) => day.day === fullDayName
    );
    if (!filteredBusinessHours.length) return;
    const formattedTimeSlots = [];
    filteredBusinessHours[0].timeSlots.forEach(({ startTime, endTime }) => {
      const { startTime: newStartTime, endTime: newEndTime } =
        convertTimesByTimeZone(
          startTime,
          endTime,
          friendTimeZone,
          selectedTimeZoneNew
        );
      const [startHour, startMin] = newStartTime.split(":");
      const [endHour, endMin] = newEndTime.split(":");
      for (let i = startHour; i < endHour; i++) {
        formattedTimeSlots.push(
          `${i % 12 || 12}:${startMin} ${i < 12 ? "AM" : "PM"}`
        );
      }
      setIsReceivedTimeSlots(true);
      setTimeSlots(formattedTimeSlots);
    });
  }, [fullDayName, data.current, selectedTimeZoneNew, friendTimeZone]);

  useEffect(() => {
    setSelectedTimeNew(selectedTime);
  }, [selectedTime]);

  const handleTimeSlotClick = (timeSlot) => {
    setSelectedTime(timeSlot);
  };

  return (
    <div className="appointment-calendar-content">
      <div className="appointment-calendar-content-calendar">
        <CalendarModal
          setCalendarDate={setCalendarDate}
          scheduleDateNew={scheduleDateNew}
          friendId={friendId}
          setSelectedTime={setSelectedTime}
        />
      </div>
      <div className="appointment-calendar-content-time-slots">
        <div className="appointment-calendar-content-time-slots-title">
          <p>Choose available time slot</p>
        </div>
        {isLoading ? ( // Render skeleton when isLoading is true
          <SkeletonTheme color="#DEDEDE" highlightColor="#D2DDEE">
            <div className="time-slots-skelton">
              <p className="time-slots-skelton-line font-weight-bold mb-auto">
                <Skeleton className="time-slots-skelton-line-inside" />
              </p>
              <p className="time-slots-skelton-line font-weight-bold mb-auto">
                <Skeleton className="time-slots-skelton-line-inside" />
              </p>
              <p className="time-slots-skelton-line font-weight-bold mb-auto pb-5">
                <Skeleton className="time-slots-skelton-line-inside" />
              </p>
            </div>
          </SkeletonTheme>
        ) : (
          <div
            className={`appointment-calendar-content-time-slots-times ${
              !isReceivedTimeSlots &&
              !availableTimeSlotsDays?.days[0]?.slots?.length
                ? "appointment-calendar-content-time-slots-times-hide-grid"
                : ""
            }`}
          >
            {isReceivedTimeSlots &&
            availableTimeSlotsDays?.days[0]?.slots?.length ? (
              availableTimeSlotsDays.days[0].slots.map((timeSlot) => (
                <button
                  key={timeSlot}
                  className={`btn time-slot-table-cell justify-content-center align-items-center text-align-center ${
                    selectedTime === timeSlot
                      ? "appointment-calendar-selected-time-slot"
                      : ""
                  }`}
                  onClick={() => handleTimeSlotClick(timeSlot)}
                  disabled={
                    moment().tz(selectedTimeZoneNew).format("YYYY-MM-DD") ===
                      moment(calendarDate).format("YYYY-MM-DD") &&
                    moment().tz(selectedTimeZoneNew).format("HH:mm") >
                      timeSlot?.slice(0, 5)
                  }
                >
                  {timeSlot?.slice(0, 5)}
                </button>
              ))
            ) : (
              <p className="appointment-calendar-content-time-slots-times-text">
                Sorry, there are no available timeslots for this day.
              </p>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default AppointmentCalendar;
