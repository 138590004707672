import React, { useEffect, useMemo, useState } from "react";
import {
  Button,
  Container,
  Dropdown,
  Form,
  FormControl,
} from "react-bootstrap";
import { PencilFill, Plus, Trash } from "react-bootstrap-icons";
import ConfirmationModal from "../../components/confirmation-modal/Confirmation-Modal";
import ManageCityModal from "../../components/manage-city-modal/Manage-City-Modal";
import { API_BASE_URL } from "../../constants";
import { deleteCity, getCities } from "../../util/APIUtils";
import { notificationManage } from "../../util/NotificationUtils";
import { USER_ROLE } from "../../util/Utils";
import "./Cities.scss";

const Cities = (props) => {
  const { currentUser } = props;

  const filterOption = {
    RECENTLY_ADDED: "Recently added",
    CITY_NAME: "City name",
    COUNTRY_NAME: "Country name",
  };
  const dropdownMenuItems = [
    ["Recently added", filterOption.RECENTLY_ADDED],
    ["City name", filterOption.CITY_NAME],
    ["Country name", filterOption.COUNTRY_NAME],
  ];
  const type = {
    ADD_CITY: "Add new city",
    EDIT_CITY: "Edit City",
  };

  const [cities, setCities] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredCities, setFilteredCities] = useState([]);
  const [displayCities, setDisplayCities] = useState([]);
  const [open, setOpen] = useState(false);
  const [cityToDelete, setCityToDelete] = useState("");
  const [deleted, setDeleted] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [openManageCity, setOpenManageCity] = useState(false);
  const [manageCityData, setManageCityData] = useState({
    type: null,
    city: null,
  });
  const citiesCopy = useMemo(() => [...cities.reverse()], [cities]);
  const [dropdownValue, setDropdownValue] = useState(
    filterOption.RECENTLY_ADDED
  );
  const [searchValue, setSearchValue] = useState("");

  const handleAddEditCity = (city, modalType) => {
    setManageCityData((prevState) => ({
      ...prevState,
      type: modalType,
      city: city,
    }));
    OpenManageCity();
  };

  const getDeleteConfirmation = (city) => {
    setCityToDelete(city);
    setOpen(true);
  };

  const handleDelete = (city) => {
    setDeleting(true);
    deleteCity(city.id)
      .then((res) => {
        notificationManage("success", "City deleted successfully");
        handleStateAfterDelete();
        setDeleted(!deleted);
      })
      .catch((err) => {
        handleStateAfterDelete();
        notificationManage("danger", "City deletion failed");
      });
  };

  const handleStateAfterDelete = () => {
    loadCityList();
    setDeleting(false);
    setOpen(false);
  };

  const handleSearchChange = (event) => {
    const { value } = event.target;
    setSearchValue(value);
    setFilteredCities(
      cities.filter(
        (city) =>
          city.name.toLowerCase().startsWith(value.toLowerCase()) ||
          city.country.toLowerCase().startsWith(value.toLowerCase())
      )
    );
  };

  const compareBy = (key) => {
    return (first, second) => {
      if (first[key] < second[key]) return -1;
      if (first[key] > second[key]) return 1;
      return 0;
    };
  };

  const handleSort = (key) => {
    let temp = [...cities];
    temp.sort(compareBy(key));
    setDisplayCities(temp);
    setSearchValue("");
  };

  const OpenManageCity = () => {
    setOpenManageCity(true);
  };

  const handleCloseManageCity = () => {
    setOpenManageCity(false);
  };

  const loadCityList = () => {
    getCities()
      .then((res) => {
        setCities(res);
      })
      .catch(() => {
        notificationManage("danger", "City list retrieval failed");
      });
  };

  useEffect(() => {
    if (filteredCities.length === 0 && searchQuery.length === 0) {
      setDisplayCities(cities);
    } else {
      setDisplayCities(filteredCities);
    }
  }, [filteredCities, cities, searchQuery]);

  useEffect(() => {
    loadCityList();
  }, []);

  useEffect(() => {
    if (cities) {
      switch (dropdownValue) {
        case filterOption.RECENTLY_ADDED:
          setDisplayCities(citiesCopy);
          break;
        case filterOption.CITY_NAME:
          handleSort("name");
          break;
        case filterOption.COUNTRY_NAME:
          handleSort("country");
          break;
        default:
          break;
      }
    }
  }, [cities, citiesCopy, dropdownValue]);

  return (
    currentUser.role === USER_ROLE.SUPER_ADMIN && (
      <>
        <ConfirmationModal
          open={open}
          isDeleting={deleting}
          body={`Are you sure you want delete ${cityToDelete.name}?`}
          action="Yes"
          onAction={() => handleDelete(cityToDelete)}
          onClose={() => setOpen(false)}
          currentUser={currentUser}
        />
        <ManageCityModal
          open={openManageCity}
          setOpenManageCity={setOpenManageCity}
          onClose={handleCloseManageCity}
          manageCityData={manageCityData}
          loadCityList={loadCityList}
          cities={cities}
        />
        <div className="manage-city">
          <Container className="manage-city-container">
            <div className="d-flex mt-4 pt-2">
              <div className="bg-white manage-city-search-bar-container col-9">
                <Form className="manage-city-search-bar d-flex">
                  <span className="bg-white border-0 manage-city-search-icon">
                    <i className="fa fa-search fa-lg pl-2"></i>
                  </span>
                  <FormControl
                    type="search"
                    placeholder="Search cities"
                    className="px-3 border-0 placeholder manage-city-search-bar"
                    aria-label="Search"
                    value={searchValue}
                    onChange={handleSearchChange}
                  ></FormControl>
                </Form>
              </div>
              <div className="manage-city-title d-flex col-3">Cities</div>
            </div>
            <div className="d-flex justify-content-between align-items-center manage-city-sort">
              <div
                className="d-inline-flex align-items-center mt-3 manage-city-sort-title"
                id="sort-by"
              >
                Sort by:
                <Dropdown className="ml-3">
                  <Dropdown.Toggle className="latest-posts-toggle">
                    {dropdownValue}
                    <i className="fas fa-caret-down pull-right"></i>
                  </Dropdown.Toggle>
                  <Dropdown.Menu className={"latest-posts-menu"}>
                    {dropdownMenuItems.map((menuItem, index) => {
                      return (
                        <Dropdown.Item
                          key={index}
                          className={"latest-posts-options"}
                          value={menuItem[1]}
                          onClick={() => setDropdownValue(menuItem[1])}
                        >
                          {menuItem[0]}
                        </Dropdown.Item>
                      );
                    })}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
              <div>
                <Button
                  className="mt-2 add-city-button"
                  variant="primary"
                  onClick={() => handleAddEditCity(null, type.ADD_CITY)}
                >
                  <Plus size={25} /> Add new city
                </Button>
              </div>
            </div>
          </Container>
          <hr className="separator mx-0 px-0 pb-0  mt-0" />
          <Container>
            <div className="manage-city-display">
              <div className="d-flex manage-city-display-header my-4 pt-2">
                <div className="col-3 manage-city-display-header-image">
                  Image
                </div>
                <div className="col-3 manage-city-display-header-country">
                  Country
                </div>
                <div className="col-3 manage-city-display-header-name">
                  City name
                </div>
                <div className="col-3 manage-city-display-header-action">
                  Action
                </div>
              </div>
              {displayCities
                .filter((citiesCopy) => citiesCopy.isActive === true)
                .map((city) => (
                  <div
                    className="card manage-city-display-card border-light p-4 my-2 mb-4 item"
                    key={city.id}
                  >
                    <div className="row">
                      <span className="col-3">
                        <img
                          src={`${API_BASE_URL}/v1/cities/${
                            city.id
                          }/image?${Date.now()}`}
                          alt="city"
                          className="manage-city-display-card-image ml-4"
                        />
                      </span>
                      <span className="col-3 manage-city-display-card-country">
                        {city.country}
                      </span>
                      <span className="col-3 manage-city-display-card-city">
                        {city.name}
                      </span>
                      <div className="col-3 manage-city-display-card-action">
                        <Button
                          className="edit-city-button p-2"
                          onClick={() =>
                            handleAddEditCity(city, type.EDIT_CITY)
                          }
                        >
                          <PencilFill
                            className="edit-city-icon pb-1"
                            color="#FFFFFF"
                            size={19}
                          />
                          Edit
                        </Button>
                        <Button
                          className="delete-city-button p-2"
                          onClick={() => getDeleteConfirmation(city)}
                        >
                          <Trash className="delete-city-icon" size={20} />
                          Delete
                        </Button>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </Container>
        </div>
      </>
    )
  );
};

export default Cities;
