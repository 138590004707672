import "./No-Friends-Found.scss";
import { S3_BASE_URL } from "../../constants";
const searchingGif = `${S3_BASE_URL}/images/no-friends-found-searching.gif`;

export const NoFriendsFound = ({ setNoFriendsFoundVisible, message }) => {
  return (
    <div className="d-flex no-friends">
      <img
        src={searchingGif}
        alt="Loading..."
        className="no-friends-searching"
      />
      <div className="no-friends-text">{message}</div>
      <i
        className="fas fa-times no-friends-close"
        onClick={() => setNoFriendsFoundVisible(false)}
      ></i>
    </div>
  );
};
