import React from "react";
import "./Terms-And-Conditions.scss";

function TermsAndConditions() {
  const termsAndConditionContent = [
    {
      id: "move2NewCity-terms-and-conditions",
      mainTitle: "Move2NewCity’s Terms and Conditions",
      points: [
        "Move2NewCity provides a platform to provide services between Newcomers and Friends. These Terms of Use governs your relationship with Move2NewCity and your use of the Move2NewCity Platform and/or Services.",
        "By using or accessing the Move2NewCity Platform and/or Services, you agree to be bound by this User Agreement of Terms of Conditions, which constitute a binding contract between you and Move2NewCity. You must be at least 18 years of age in order to create an Account and use the Site.",
        "Move2NewCity reserves the right to revise and update the Terms of Use at any time.  You should periodically review these Terms of Use.  Your continued use of the Site and/or the Services constitutes Your acceptance of, and agreement to, any revised Terms of Use.",
      ],
    },
    {
      id: "disclaimer",
      mainTitle: "DISCLAIMER",
      description: [
        {
          numb: 1,
          topic: (
            <b>
              Move2NewCity is a platform to provide services between Newcomers
              and Friends.
            </b>
          ),
        },
        {
          numb: 2,
          topic:
            " By using Move2NewCity, You agree and acknowledge that any engagement is between Newcomers and Friends only, and that Move2NewCity is not a party to any such engagement.",
        },
        {
          numb: 3,
          topic:
            "  By using Move2NewCity, You agree to the limitations of liability, warranties and indemnities set out in clause 9.",
        },
      ],
    },
    {
      id: "agreement",
      mainTitle: "AGREEMENT",
      description: [
        {
          id: "defined-terms",
          numb: 1,
          topic:
            "Defined terms: In these Terms of Use, capitalised terms are defined below.",
          isSubtitle: true,
          content: [
            "“Registered” means your have signed up with Move2NewCity;",
            "“Members” refer to Users who are Registered with Move2NewCity, primarily “Newcomers” and “Friends;",
            <span>
              “User Agreement” means the agreement between Move2NewCity and each
              User of the Site which sets out the terms and conditions in
              respect to the Site, and is located at
              <a
                className="custom-link"
                target="_blank"
                href="/terms-and-conditions">
                move2newcity.com/terms-and-condition
              </a>
            </span>,
            "“Users” refer to Members and Visitors; and",
            "“Visitors” refer to individuals who visit and browse the Site but not Registered.",
          ],
        },
        {
          id: "eligibility-to-use-site",
          numb: 2,
          topic: "Eligibility to use the Site and Services",
          isSubtitle: true,
          content: [
            <div>
              By using Move2NewCity Site and/or Services, You acknowledge,
              warrant and represent to Move2NewCity that you satisfy the
              Eligibility Criteria:
              <ul>
                <li>You are at least 18 years old;</li>
                <li>
                  {" "}
                  You will abide by:
                  <ul>
                    <li>all the terms and conditions of this Agreement;</li>
                    <li>
                      all of Move2NewCity policies as posted on the Site from
                      time to time
                    </li>
                    <li>
                      the Member Standards and refrain from engaging in
                      Prohibited Conduct as set out in clause 8 below; and
                    </li>
                    <li>
                      the Payment Terms set out in clause 10 below. You agree
                      and acknowledge that all payments relating to Move2NewCity
                      must be made through the payment channels provided or
                      specified by Move2NewCity, and in accordance with this
                      Agreement;
                    </li>
                  </ul>
                </li>
                <li>
                  You:
                  <ul>
                    <li>
                      have been convicted of any serious criminal offence, or
                      any criminal offence involving violence, assault, fraud or
                      dishonesty, any sexual offence, or any offence relating to
                      the safety of children or vulnerable persons, any offence
                      relating to workplace health and safety, or any offence
                      which may reasonably be considered relevant to the
                      provision or receipt of Move2NewCity Services;
                    </li>
                    <li>
                      is presently the subject of a complaint, claim,
                      investigation, or other legal action, charge, proceedings
                      or other litigation (Investigation or Legal Action) in
                      respect of Relevant Criminal Conduct;
                    </li>
                  </ul>
                </li>
                <li>
                  {" "}
                  any information provided by You is true and not misleading;
                  and
                </li>
                <li>
                  You will act in good faith and not misuse the Site or the
                  Services so as to cause harm or damage to Move2NewCity or any
                  other Member.
                </li>
              </ul>
            </div>,
            " If You do not satisfy all of the Eligibility Criteria, You must not create a Member Account or use the Site and/or Services as a Member, but may access the Site as a Visitor. A Visitor may browse the Site in accordance with this Agreement, but will not have access to Member Services unless they become a Member.",
          ],
        },
        {
          id: "become-a-member",
          numb: 3,
          topic: "Becoming a Member",
          isSubtitle: true,
          content: [
            "To use the Member Services, you must set up a Member Account. To do so, You will be required to provide specific information, and nominate Account Credentials to access Your Account.",
            <div>
              In setting up or using a Member Account, You:
              <ul>
                <li>
                  warrant that all information You provide to Us is true,
                  accurate, current and complete, and You agree to maintain and
                  promptly update such information to keep the information true,
                  accurate, current and complete;
                </li>
                <li>
                  agree that you will not transfer or share your Account
                  Credentials with any other person;
                </li>
                <li>agree not to register for more than one Member Account;</li>
                <li>
                  {" "}
                  are solely responsible for:
                  <ul>
                    <li>
                      maintaining the confidentiality of Your Account
                      Credentials;
                    </li>
                    <li>
                      any and all use of Your Account Credentials and Your
                      Account;
                    </li>
                    <li>
                      all activities that occur under or in connection with Your
                      Account Credentials or Your Account; and
                    </li>
                    <li>
                      any act or omission of any Users who access the Site
                      and/or Services under Your Account Credentials.
                    </li>
                  </ul>
                </li>
                <li>
                  {" "}
                  acknowledge and agree that:
                  <ul>
                    <li>
                      We rely on Account Credentials to know whether Users
                      accessing the Site and using the Services are authorised
                      to do so; and
                    </li>
                    <li>
                      if someone (whether or not you) accesses our Site and/or
                      Services using Your Account Credentials, We will rely on
                      the Account Credentials, and assume that it is You who is
                      accessing the Site and/or Services
                    </li>
                  </ul>
                </li>
              </ul>
            </div>,
          ],
        },
        {
          id: "member-verification",
          numb: 4,
          topic: "Member Verification",
          isSubtitle: true,
          content: [
            "In creating Your Account or otherwise seeking to become a Member, You agree and consent to Move2NewCity conducting the Verification Checks at any time in its sole and absolute discretion, and agree to provide Move2NewCity with such information or documentation as it reasonably requests in order to do so, and authorise Move2NewCity to conduct any such Verification Checks.",
          ],
        },
        {
          id: "content-and-your-site",
          numb: 5,
          topic: " Content and Your use of the Site",
          isSubtitle: true,
          content: [
            <div>
              <b> Prohibited Content:</b> Content of any kind that:
              <ul>
                <li>
                  is offensive, defamatory, distressing, harmful, insulting,
                  intimidating, menacing, harassing, discriminatory, unlawful,
                  false or misleading, content of any kind;
                </li>
                <li> poses, or may pose, a risk to any person;</li>
                <li>
                  {" "}
                  threatens, harasses, humiliates or ridicules any person, or is
                  otherwise unlawful, or encourages any such activity;
                </li>
                <li>
                  is posted, uploaded, published or disseminated without the
                  lawful owner’s permission, or breaches any obligation of
                  confidentiality;
                </li>
                <li>
                  contains any false or misleading statements, representations
                  or advertising, (including but not limited to representations
                  about the Member’s identity, qualifications, experience or
                  reputation);
                </li>
                <li>
                  {" "}
                  advertises or promotes goods or services other than resources
                  that will facilitate the settlement experience;
                </li>
                <li>
                  is personal information about a person and is posted,
                  uploaded, published or disseminated without that person’s
                  prior consent or in breach of any privacy law, or is contrary
                  to Move2NewCity Privacy Policy;
                </li>
                <li>
                  infringes another person’s copyright, trade mark or any other
                  intellectual property right;
                </li>
                <li>
                  includes a person’s Account Credentials or is directed to
                  accessing a person’s Member Account other than in accordance
                  with this Agreement or without the account holder’s consent;
                </li>
                <li>
                  may cause Move2NewCity to breach any law or otherwise expose
                  Move2NewCity to liability;
                </li>
                <li>
                  {" "}
                  is or includes any virus, worm, trojan, or other malicious or
                  unauthorised code or disabling feature to or via the Site or
                  the Services, or which may breach the requirements or cause
                  the loss of services of Move2NewCity internet service
                  providers or other suppliers;
                </li>
                <li>
                  is likely to interfere with or disrupt the proper and intended
                  operation of the Site or the Services, or is, or contains any
                  means of automatically searching or mining data from the Site;
                  or
                </li>
                <li>
                  {" "}
                  is contrary to any of Move2NewCity’s policies or code of
                  conduct, is prohibited (Prohibited Content).
                </li>
              </ul>
            </div>,
            <div>
              <b> Content posted, created or sourced by/from others:</b> When
              You access the Site and/or Services, You may obtain access to
              Content posted by other Members or third parties. You agree and
              acknowledge that:
              <ul>
                <li>
                  Move2NewCity provides a platform only, that acts as a forum
                  for venue for the online distribution and publication of
                  Content, and Move2NewCity does not verify that Content;
                </li>
                <li>
                  Move2NewCity makes no warranties or representations (either
                  express or implied) whatsoever in relation to the Content, its
                  accuracy, completeness, quality, currency or otherwise, and is
                  not responsible for any Content;
                </li>
                <li>
                  the Content posted by others may include Prohibited Content;
                </li>
                <li>
                  Move2NewCity may, but is not obliged to, take any action it
                  considers (in its sole and absolute discretion) appropriate
                  with respect to Content that it considers is Prohibited
                  Content, may create liability for Move2NewCity, harm
                  Move2NewCity’s business operation or reputation, cause
                  Move2NewCity to lose the services of its suppliers; and
                </li>
                <li>
                  Move2NewCity reserves the right to reject or modify Content,
                  at its discretion.
                </li>
              </ul>
            </div>,
            <div>
              <b> Content you post:</b> You are solely responsible for all
              Content that You post, email or otherwise make available on the
              Site and/or Services. You:
              <ul>
                <li>
                  grant Move2NewCity a non-exclusive, royalty-free, fully paid,
                  worldwide perpetual license that is capable of being
                  sub-licensed, in respect of all of Your copyright and other
                  intellectual property rights related to Content that You post
                  or contribute to the Site;
                </li>
                <li>
                  agree that any such Content or any derivative works thereof,
                  may be disseminated, distributed, publicly displayed,
                  reproduced, used, sublicensed, posted, or published by
                  Move2NewCity, and searched, displayed, printed or otherwise
                  used or exploited by our Visitors and Members;
                </li>
                <li>must not post or upload any Prohibited Content; and</li>
                <li>
                  warrant that none of the Content you post or upload is
                  Prohibited Content or infringes the rights (including but not
                  limited to copyright and other intellectual property rights)
                  of any person.
                </li>
              </ul>
            </div>,
            <div>
              <b>Feedback, Reputation and Reviews:</b> By using the Site and
              posting feedback (including a review) <b>(“User Feedback”)</b>,
              You grant Us an unrestricted, worldwide, royalty-free,
              sub-licensable licence to use, reproduce, publish, communicate to
              the public, modify, and adapt Your User Feedback posted on the
              Site for the purpose of publishing that User Feedback on the Site
              and as otherwise permitted by this Agreement.
              <ul>
                <li>
                  You may use any Feedback that is about You provided that you
                  use it in a way that is not misleading or deceptive and is in
                  accordance with Move2NewCity’s policies as posted on the Site
                  from time to time.
                </li>
              </ul>
            </div>,
          ],
        },
        {
          id: "intellectual-property",
          numb: 6,
          topic: "Intellectual property",
          isSubtitle: true,
          content: [
            <div>You agree and acknowledge that the Agreement and Our Technology are our intellectual property and/or licensed to Us under Australian and international copyright or trade mark laws, and may be subject to other intellectual property and proprietary rights and laws <b>(Our IP)</b>. Title to Our IP at all times remains with us or the licensor, and nothing in this Agreement is, or is intended to be, a transfer of, or to create, any rights (including any licence) in Our IP.</div>,
            "Our Technology and Our IP must not be copied, modified, reproduced, republished, posted, transmitted, sold, offered for sale, or redistributed in any way without our prior written permission, and the prior written permission of any applicable licensors. You must abide by all copyright notices, information, or restrictions contained in or attached to any of Our Technology and Our IP.",
            <div>
              Certain of the names, logos, and other materials displayed on the
              Site or in the Services constitute trademarks, trade names,
              service marks or logos (<b>“Marks”</b>) of Move2NewCity or other
              entities. You are not authorised to use any Marks, and ownership
              will at all times remain with Us or those other entities who own
              the Marks.
            </div>,
            "Any use of third party software provided in connection with the Site and/or Services will be governed by relevant third party licenses and not by this Agreement",
          ],
        },
        {
          id: " newcomer-and-friend-engagement",
          numb: 7,
          topic: " Newcomer and Friend Engagement",
          isSubtitle: true,
          content: [
            <div>
              Engagement is between Newcomer and Friend:
              <ul>
                <li>
                  Move2NewCity is a platform that allows Newcomers to connect
                  with Friends. Move2NewCity provides a platform only and does
                  not engage or employ Friends.
                </li>
                <li>
                  <u>
                    {" "}
                    When a Newcomer engages a Friend through the Move2Newcity
                    for a paid session, the Newcomer and Friend decides on the
                    engagement with the consent of each other directly.
                  </u>{" "}
                  Move2NewCity is not a party to that engagement.
                </li>
                <li>
                  The Friend solely decides whether to accept the engagement
                  request from a Newcomer, and the Newcomer solely decides
                  whether to engage with a Friend.
                </li>
              </ul>
            </div>,
            <div>
              {" "}
              <b>
                {" "}
                No relationship of employment, contracting or franchise, by
                Move2NewCity:{" "}
              </b>{" "}
              Nothing in this Agreement or Your use of the Site and/or Services
              is intended to give rise to any such relationship, or any
              relationship of partnership or joint venture.
              <p>Move2NewCity does not:</p>
              <ul>
                <li>
                  operate a referral service and does not recommend or endorse
                  any particular Member; or
                </li>
                <li>
                  provide advice regarding the quality or suitability of any
                  particular Member; or
                </li>
                <li>
                  participate in any interactions or arrangements between
                  Newcomers and Friends;
                </li>
                <li>
                  involve or control the engagement with each other, including
                  regulating or managing any laws or any occupational health and
                  safety laws; or
                </li>
                <li>
                  set or determine the agreed level of payment for and/or
                  remuneration for the provision of the services.
                </li>
              </ul>
            </div>,
            <div>
              <b>
                {" "}
                By using Move2NewCity’s Site and/or Services, You acknowledge
                and agree that Move2NewCity:
              </b>
              <ul>
                <li>
                  acts only as an intermediary facilitating the provision of
                  selected information between Members;
                </li>
                <li>will rely upon the information provided by Members;</li>
                <li>
                  {" "}
                  has no control over and is not responsible for any acts or
                  omissions of any Users on or off Move2NewCity’s Site and/or
                  Services;
                </li>
                <li>
                  makes no representation or warranty regarding the quality of
                  any Services or any other services provided by any Member, or
                  the accuracy or reliability of any information provided by any
                  Member on its Site and/or Services; and
                </li>
                <li>
                  is not responsible for the interactions between Members.
                </li>
              </ul>
            </div>,
          ],
        },
        {
          id: "members-standard-integrity",
          numb: 8,
          topic: "Member Standards and integrity of the Site and Services",
          isSubtitle: true,
          content: [
            <div>
              Member Standards: It is our goal to make access to our Site and
              Services a positive experience for Visitors and all Members. All
              Members are required to abide by the following standards to ensure
              the integrity of the Services and their fair and proper use:
              <ul>
                <li>
                  Correspondence between Members is for the sole purpose of
                  connecting Newcomers and Friends for purposes relating to
                  settlement Services.
                </li>
                <li>
                  Members to report any incidents of abuse or neglect of a
                  child, an elder or a person with a disability to the relevant
                  state authority and to Move2NewCity (except where prohibited
                  by law).
                </li>
                <li>
                  Members must not reproduce, duplicate, copy, sell, resell or
                  exploit any portion of the Site and/or Services, use the Site
                  or Services, or access the Site and/or Services for any
                  purposes other than for which the Site and/or Services are
                  being provided to them.
                </li>
              </ul>
            </div>,
            <div>
              <b> Prohibited Conduct:</b> Members must not do any of the
              following:
              <ul>
                <li>
                  make use of the Platform to recruit or subcontract any other
                  Member for the provision of services of a similar nature to
                  the Move2NewCity;
                </li>
                <li>
                  contact another Member for any purpose other than as set out
                  in this Agreement;
                </li>
                <li>
                  harass, intimidate or otherwise engage in illegal or offensive
                  behaviour with respect to any other Member, or use the Site to
                  stalk another person;
                </li>
                <li>
                  conduct or promote any illegal activities while using the Site
                  and/or Services;
                </li>
                <li> upload, distribute or print any Prohibited Content;</li>
                <li>
                  {" "}
                  use the Site and/or Services to generate unsolicited email
                  advertisements or spam;
                </li>
                <li>
                  allow, enable, or otherwise support the transmission of mass
                  unsolicited, commercial advertising or solicitations via
                  e-mail (spam);
                </li>
                <li>
                  misuse or attempt to interfere with the Site and/or Services,
                  including by:
                  <ul>
                    <li>
                      attempting to reverse engineer or jeopardise the correct
                      functioning of the Site, or otherwise attempt to derive
                      the source code of the software (including the tools,
                      methods, processes, and infrastructure) that enables or
                      underlies the Site, or attempt to gain access to secured
                      portions of the Site and/or Services to which they do not
                      possess access rights;
                    </li>
                    <li>
                      uploading or transmitting to the Site any form of virus,
                      worm, trojan horse, or other malicious code;
                    </li>
                    <li>
                      interfering in any way with the proper functioning of the
                      Site and/or Services or interfere with or disrupt any
                      servers or networks connected to the Site and/or Services,
                      or disobey any requirements, procedures, policies or
                      regulations of networks connected to the Site and/or
                      Services;
                    </li>
                    <li>
                      using any high volume automatic, electronic or manual
                      process to access, search, harvest, “screen scrape”,
                      monitor, “mine” information from the Site and/or Services
                      (including without limitation robots, spiders or scripts)
                      or copy any static or dynamic web page on the Site or the
                      content contained on any such web page for commercial use
                      without Move2NewCity’s prior express written permission;
                    </li>
                    <li>
                      mirror or frame the Site or any content, place pop-up
                      windows over its pages, or otherwise affect the display of
                      its pages; and / or
                    </li>
                  </ul>
                </li>
                <li>
                  {" "}
                  impersonate any person or entity, or otherwise misrepresent an
                  affiliation with a person or entity.
                </li>
              </ul>
            </div>,
          ],
        },
        {
          id: "limitation-liability-indemnity",
          numb: 9,
          topic: "Limitation of warranty, liability, and indemnity",
          isSubtitle: true,
          content: [
            <div>
              <b> Consumer and other statutory rights not affected:</b> Nothing
              in this Agreement limits any right or obligation that You may have
              under the Australian Consumer Law <b>(ACL)</b>, including any
              consumer guarantee or right to refund, or any other right under
              any law, to the extent that it cannot lawfully be excluded or
              limited.
            </div>,
            <div>
              <b>Use of Site and Services at Your sole risk:</b> You agree and
              acknowledge that, subject to clause 9.1, Your use of the Site
              and/or Services, and any material or information downloaded or
              otherwise obtained through the use of the Site and/or Services, is
              at Your sole discretion and risk. You are solely responsible and
              liable for any damage, including (without limitation) damage to
              Your computer or loss of data that results from the download of
              such material and/or information. You agree and acknowledge that:
              <ul>
                <li>
                  Move2NewCity’s Services, the Site and all Content on it are
                  provided on an “as is” and “as available” basis, and (to the
                  extent permitted by law or as otherwise provided in this
                  Agreement) without any warranty or representation of any kind
                  either express or implied;
                </li>
                <li>
                  You assume all responsibility in connection with choosing any
                  Member through the Site; and
                </li>
                <li>
                  no advice or information, whether oral or written, obtained by
                  You from Us through the Site, Services, or otherwise will
                  create any warranty, representation or guarantee not expressly
                  stated in this Agreement.
                </li>
              </ul>
            </div>,
            <div>
              <b> Exclusion / limitation of warranty:</b> To the fullest extent
              permitted by law, Move2NewCity disclaims and excludes all
              warranties and representations of any kind, whether express or
              implied, in respect of the Site and the Services, including but
              not limited to any warranty:
              <ul>
                <li>
                  {" "}
                  as to title, quality, merchantability, fitness for a
                  particular use or purpose, non-infringement, operability,
                  condition, quiet enjoyment, value, accuracy of data and system
                  integration;
                </li>
                <li>
                  that the Site and/or Services will meet Your requirements;
                </li>
                <li>
                  that the Site will be accurate, reliable, uninterrupted,
                  secure or error-free;
                </li>
                <li>
                  . that the Site, or the server that makes it available, will
                  be uninterrupted, timely, secure, free of viruses, errors or
                  other harmful components;
                </li>
                <li>
                  as to the results that may be obtained from the use of the
                  Site and/or Services; and
                </li>
                <li>
                  that defects in the Site and/or Services will be corrected.
                </li>
              </ul>
            </div>,
            <div>
              {" "}
              <b>No liability for conduct of other Members:</b> To the fullest
              extent permitted by law, Move2NewCity expressly disclaims, and You
              expressly release Move2NewCity, and each of its Affiliates, from
              any and all liability whatsoever for any damages, suits, claims
              and/or controversies that have arisen or may arise from and/or in
              any way relate to any acts or omissions of Members on or off the
              Site.
            </div>,
            <div>
              <b> Exclusion of liability:</b> To the fullest extent permitted by
              law, Move2NewCity and each of its Affiliates will not be liable to
              You or any third party, whether in contract, warranty, tort
              (including negligence and strict liability), statute, or
              otherwise, for:
              <ul>
                <li>
                  . any injury, loss or damage suffered by any Member or User;
                </li>
                <li>
                  any advice, treatment or other Services rendered by any Member
                  or User, or for any malpractice claims;
                </li>
                <li>
                  any other claims that may arise directly or indirectly from
                  the provision of any other actions of any Member or User;
                </li>
                <li>
                  any indirect, special, incidental, consequential or exemplary
                  damages that may be incurred by You;
                </li>
                <li>
                  any loss of income, revenue, goodwill, business, profits
                  and/or data (whether direct or indirect) that may be incurred
                  by You;
                </li>
                <li>
                  any claim, damage, or loss which may be incurred by You
                  arising from, out of or related to:
                  <ul>
                    <li>
                      Your use of, or access to, or the inability to use or to
                      access, the Site and/or Services;
                    </li>
                    <li>
                      any suspension and/or termination of your use of and/or
                      Your access to the Site and/or Services;
                    </li>
                    <li>Your transactions involving the Site;</li>
                    <li>any act or omission of any Member or User;</li>
                    <li>
                      Your breach of this Agreement, or any contravention of any
                      law or rights of a third party by You;
                    </li>
                    <li>
                      any statements and/or representations (including
                      misstatements or misrepresentations) made by any Member
                      contained in any Content;
                    </li>
                    <li>
                      any statements and/or representations made by Move2NewCity
                      in reliance of any warranties and/or representations made
                      by Members or other third parties;
                    </li>
                    <li>
                      any of Your Content and/or information that You submit,
                      post or transmit through the Site and/or Services;
                    </li>
                    <li>
                      the quality, accuracy, reliability, timeliness or
                      completeness of any background check or verification
                      provided (by Move2NewCity or through any third party
                      verification services) to You in connection with the Site,
                      the Services;
                    </li>
                    <li>
                      the use of information provided by any third party
                      verification service;
                    </li>
                    <li>
                      transactions between You and any third party merchants or
                      service providers;
                    </li>
                    <li>
                      any information appearing on third party merchant or
                      service provider sites or any other site linked to our
                      Site;
                    </li>
                    <li>
                      any viruses, trojan horses, worms, time bombs, cancelbots
                      or other similar harmful or deleterious programming
                      routines input by You into the Site and/or Services; and
                    </li>
                    <li>any disputes that arise between Members.</li>
                  </ul>
                </li>
              </ul>
            </div>,
            <div>
              <b>
                {" "}
                General limitation of liability and exclusion of consequential
                loss:
              </b>{" "}
              Subject to clause 9.1, and to the fullest extent permitted by law,
              all liability of Move2NewCity, and each of its Affiliates, to the
              Member:
              <ul>
                <li>
                  is limited to the refund of amounts paid by the Member to
                  Move2NewCity for Services in the 12 months prior to the date
                  on which the liability arose;
                </li>
                <li>
                  excludes any liability for any indirect, consequential or pure
                  economic loss; and
                </li>
                <li>
                  is reduced to the extent that the liability arises out of the
                  negligent or wrongful act or omission of the Member or any
                  third party,
                </li>
              </ul>
            </div>,
            <div>
              <b> Indemnities given by Members:</b> You agree to indemnify,
              defend and hold Move2NewCity and its Affiliates harmless from and
              against any and all third party claims, liabilities, demands,
              proceedings, losses, costs, expenses, fees (including reasonable
              attorneys’ fees and court costs) and damages (actual, special and
              consequential) of every kind and nature, known and unknown,
              incurred by Move2NewCity or any of its Affiliates or made by any
              third party due to or arising out of, or in any way in connection
              with:
              <ul>
                <li>
                  Your breach of this Agreement, or any contravention of any law
                  or rights of a third party by You;
                </li>
                <li>Your use of the Site and the Services;</li>
                <li>
                  any advice, treatment or other Services You provide as a
                  Member (if applicable), including any malpractice claims;
                </li>
                <li>Your transactions involving the Site;</li>
                <li>
                  any of Your Content and/or information that You submit, post
                  or transmit through the Site and/or Services;
                </li>
                <li>
                  any statement or representation you make to, or any conduct
                  you engage in towards, another Member;
                </li>
                <li>
                  any claim arising out of any employment relationship,
                  including but not limited to any industrial instrument and/or
                  statutory entitlement, including superannuation obligations.
                </li>
              </ul>
            </div>,
            <div>
              <b> Acknowledgment by Member:</b> You agree and acknowledge that
              the exclusions and limitations of liability and warranty in, and
              the indemnity given by You under, this clause 9 are reasonable to
              protect the legitimate interests of Move2NewCity in circumstances
              where:
              <ul>
                <li>Move2NewCity acts as a platform only; and</li>
                <li>
                  any Member engagement is between You and the other Members (as
                  applicable).
                </li>
              </ul>
            </div>,
            <div>
              <b>Bar to claims brought in breach of this clause:</b>{" "}
              Move2NewCity and any of its Affiliates may plead this Agreement in
              bar to any claim, action, proceeding, class action or suit brought
              by You against Move2NewCity or any of its Affiliates in any
              proceeding or claim commenced in breach of this clause 9.
            </div>,
          ],
        },
        {
          id: "payments",
          numb: 10,
          topic: "Payment terms, fees and payment processing",
          isSubtitle: true,
          content: [
            <div>
              <b> Fees payable by Newcomer: Newcomer</b> will pay Move2NewCity:
              <ul>
                <li>
                  the Fee, which Move2NewCity will accept on behalf of a Friend;
                </li>
                <li>the Platform Fee; and</li>
                <li>
                  any other costs reasonably incurred by Move2NewCity (including
                  costs incurred by another Affiliate) in enforcing its rights
                  under this User Agreement.
                </li>
              </ul>
            </div>,
            <div>
              <b> Fees and Friends: </b> The Friend agrees to:
              <ul>
                <li>receive the Agreed Rate less the Platform Fee;</li>
                <li>
                  pay any other costs reasonably incurred by Move2NewCity
                  (including costs incurred by Affiliates) in enforcing its
                  rights under this Agreement.
                </li>
              </ul>
            </div>,
            <div>
              <b>Changes to fees:</b> Move2NewCity reserves the right, at any
              time, to amend its fees and/or billing methods as notified on the
              Site.
            </div>,

            <div>
              <b> Payment processing and handling of funds: </b> You agree and
              acknowledge that:
              <ul>
                <li>
                  Move2NewCity is not responsible for, and will not reimburse
                  any fees incurred by You from Your bank or other financial
                  institution, including without limitation credit card
                  interests, overdraft charges, insufficient funds charges,
                  interest charges, or finance charges, which may have occurred
                  as a result of charges debited or withdrawn by Move2NewCity
                  (or its Affiliate);
                </li>
                <li>
                  Move2NewCity may commingle and deposit funds transferred to
                  Move2NewCity in accordance with the terms and conditions of
                  this Agreement <b> (Held Funds)</b> with other funds of
                  Move2NewCity in any account, at any institution, or in any
                  other manner Move2NewCity may decide in its sole discretion
                  from time to time;
                </li>
                <li>
                  You will not receive interest or other earnings on funds that
                  Move2NewCity (or an Affiliate) handles or holds on Your
                  behalf, and Move2NewCity (or its Affiliate) may receive
                  interest on those funds;
                </li>
                <li>
                  Move2NewCity has no obligation to hold any Held Funds in a
                  separate account;
                </li>
                <li>
                  nothing in this Agreement gives rise to any security interest
                  or makes a secured creditor of Move2NewCity (or, where
                  applicable, another Affiliate); and
                </li>
                <li>
                  <b>third party payment processing:</b> Move2NewCity may use
                  third party payment processing services to process bank
                  account information and payments. Move2NewCity expressly
                  disclaims any and all liability for any claims or damages
                  related to Move2NewCity’s use of third party payment
                  processing services and such transfers, including without
                  limitation, any damage that may result should any such
                  information be released to any third parties.
                </li>
              </ul>
            </div>,
            <div>
              <b>Authorisation of Move2NewCity to collect payment:</b>The Friend
              authorises Move2NewCity to collect payment from the Newcomer
              (including by debiting the nominated bank account or credit card
              or other method agreed between Move2NewCity and the Newcomer), or
              to collect payment from the Newcomers nominated funding party, the
              Platform Fee or any other fees payable to Move2NewCity.
            </div>,
            "Each Member is solely responsible for the accuracy of the bank account or credit card details they provide to Move2NewCity. Move2NewCity is not liable for any funds incorrectly deposited or not deposited due to inaccurate or incomplete bank details or credit card details provided by a Member.",
            "If Your existing nominated bank account or credit card is cancelled, suspended or is otherwise not usable, You must immediately provide Move2NewCity with details for an alternative Nominated Bank account or Credit Card which can be used to meet Your obligations under this Agreement.",
            <div>
              <b> Invoices and payment:</b>
              <ul>
                <li>
                  Where the Newcomer has nominated payment by credit or debit
                  card or direct debit of a bank account, Move2NewCity will
                  deduct payment based on the engagement duration and published
                  rate at the time of the engagement.
                </li>
                <li>
                  All invoices issued under this Agreement will be processed 24
                  hours, and time is of the essence.
                </li>
                <li>
                  Move2NewCity will provide to the Friends copies of Invoices
                  which are issued under this Agreement on their behalf, and
                  remit to the Friend the Fees that have been collected from the
                  Newcomer on their behalf.
                </li>
                <li>
                  Move2NewCity reserves the right to correct any errors in the
                  payment of Move2NewCity fees even if it has already requested
                  and/or received payment.
                </li>
              </ul>
            </div>,
            <div>
              <b> Failure to make payment:</b> If you fail to pay an amount in
              accordance with this Agreement (for example, if your Nominated
              Credit Card is declined), Your Account may be suspended and you
              will not be able to engage with Friends.
            </div>,
          ],
        },

        {
          id: "ant-avoidance",
          numb: 11,
          topic: "Anti-avoidance terms",
          isSubtitle: true,
          content: [
            <div>
              . The conduct referred to in this clause is{" "}
              <b> Avoidance Conduct</b>.
            </div>,
            <div>
              You are strictly prohibited from:
              <ul>
                <li>
                  avoiding or diverting fees otherwise payable to Move2NewCity,
                  including (without limitation) any Friend Fee or Platform Fee;
                  or
                </li>
                <li>
                  encouraging or soliciting any Member to avoid making payments
                  through the Site or paying the Friend Fee or Platform Fee.
                </li>
              </ul>
            </div>,
            <div>
              Friends must not
              <ul>
                <li>
                  provide or seek to provide Services to any person to whom the
                  Newcomer was introduced by the Site, or provided Services, in
                  the previous 12 months other than via the Site and in
                  accordance with this Agreement; or
                </li>
                <li>
                  directly invoice a Newcomer, or accept any payment from a
                  Newcomer other than through the Site, for the provision of
                  Services. Each Friend must account to Move2NewCity for any
                  amount received from a Friend in breach of this clause.
                </li>
              </ul>
            </div>,
            " You must immediately notify Move2NewCity if a Member requests that you make or receive payment directly or through any channels other than those provided or specified by Move2NewCity as contemplated by this Agreement.",
            <div>
              In the event of any breach of this clause 11, You agree to pay
              Move2NewCity the greater of:
              <ul>
                <li>
                  the total fees that would have been payable to Move2NewCity
                  under the terms and conditions of this Agreement had the
                  Services been organised via the Site in accordance with the
                  terms of this Agreement; or
                </li>
                <li>$5,000.</li>
              </ul>
            </div>,
            " You agree that the amounts set out in this clause 11 are a genuine pre-estimate of Move2NewCity’s potential loss should a Newcomer or Friend breach this clause.",
          ],
        },
        {
          id: "promotional-offers",
          numb: 12,
          topic: "Promotional offers",
          isSubtitle: true,
          content: [
            "We may run promotional offers from time to time on the Site. The terms of any such promotion will be posted on the Site. Unless otherwise indicated, We may establish and modify, in our sole discretion, the terms of such offers and end such offers at any time.",
          ],
        },
        {
          id: "Suspension-termination",
          numb: 13,
          topic: "Suspension / termination",
          isSubtitle: true,
          content: [
            <div>
              We may at any time, by giving You notice in accordance with clause
              18:
              <ul>
                <li>
                  immediately modify, suspend or discontinue (for a reasonable
                  period of time) access to the Site and/or Services, if it is
                  reasonably necessary for Move2NewCity to carry out any
                  maintenance, updates or business operations; and/or
                </li>
                <li>
                  immediately modify, limit, suspend or terminate Your Account,
                  Your access or Your use of the Site and/or Services, if We
                  consider that You have posted any Prohibited Content, engaged
                  in any Prohibited Conduct or are in breach of this Agreement.
                </li>
              </ul>
            </div>,
            <div>
              You agree that Move2NewCity will not be liable to You or any other
              party arising out of any:
              <ul>
                <li>
                  suspension or termination of Your access to the Site and/or
                  Services, or deletion of Your Account or Your Content; or
                </li>
                <li>
                  <p>
                    {" "}
                    modification or discontinuance of the Site and/or Services,
                  </p>{" "}
                  <p>in accordance with the terms of this Agreement.</p>
                </li>
              </ul>
            </div>,
            " Suspension or termination of Your Account will in no way modify, change or void any payment obligations You may have incurred through Your use of the Site and/or any Services, whether such obligation is to Move2NewCity (or another Affiliate) or a third party.",
            "Move2NewCity may without notice and in its sole and absolute discretion remove any invitation to treat from a Member from the Site.",
            " Either party may terminate this agreement by notice at any time for any reason.",
          ],
        },
        {
          id: "dispute-resolution",
          numb: 14,
          topic: "Dispute resolution",
          isSubtitle: true,
          content: [
            "Any disputes arising out of or in connection with the Services received by a Newcomer must be resolved directly between the Newcomer and the Friend.",
            "We may, but have no obligation to, monitor or request information regarding disputes that You may have with Users, Members including Friends and Newcomers.",
            ". If a dispute arises between You and Move2NewCity arising out of this Agreement, our goal is to address Your concerns and, if We are unable to do so to Your satisfaction, to provide You with a means of resolving the dispute quickly.",
            <div>
              For any claim against Move2NewCity:
              <ul>
                <li>
                  either party may elect to resolve the dispute in a cost
                  effective manner through a binding non-appearance-based
                  mediation.
                </li>
                <li>
                  ) in the event of an unsuccessful mediation, and in
                  circumstances where both parties to any dispute agree in
                  writing, arbitration may be initiated through an established
                  alternative dispute resolution <b>(ADR)</b> provider in
                  Australia, which is to be selected by You from a panel of
                  established ADR providers that Move2NewCity will provide to
                  You.
                </li>
                <li>
                  The ADR provider and the parties must comply with the
                  following rules:
                  <ul>
                    <li>
                      the arbitration shall be conducted by telephone, online
                      and/or be solely based on written submissions, the
                      specific manner shall be chosen by the party initiating
                      the arbitration;
                    </li>
                    <li>
                      the arbitration shall not involve any personal appearance
                      by the parties or witnesses unless otherwise mutually
                      agreed by the parties.
                    </li>
                  </ul>
                </li>
              </ul>
            </div>,
            "If the parties elect arbitration under this clause You must not commence proceedings until the arbitration has been determined, provided that nothing in this clause prevents any party from seeking urgent injunctive relief. This Agreement may be pleaded in bar to any proceeding commenced in breach of this clause.",
            "Move2NewCity may recover its legal fees and costs (including on an indemnity basis) arising out of any breach of this clause, provided that Move2NewCity has notified You in writing and You have failed to promptly withdraw that claim.",
            "If any proceeding by or against You is commenced under any provision of any bankruptcy or insolvency law, Move2NewCity will be entitled to recover all reasonable costs or expenses (including reasonable legal fees and expenses) incurred in connection with the enforcement of this Agreement.",
          ],
        },
        {
          id: "force-majeure",
          numb: 15,
          topic: "Force majeure",
          isSubtitle: true,
          content: [
            "Neither We nor our licensors or Affiliates are liable for any delay or failure in performance resulting directly or indirectly from acts of nature, forces, or causes beyond our reasonable control, including, without limitation, internet failures, computer equipment failures, telecommunication equipment failures, other equipment failures, electrical power failures, strikes, labour disputes, riots, insurrections, civil disturbances, shortages of labour or materials, fires, floods, storms, explosions, acts of god, pandemic, war, governmental actions, orders of domestic or foreign courts or tribunals, or non-performance of third parties.",
          ],
        },
        {
          id: "general",
          numb: 15,
          topic: "General",
          isSubtitle: true,
          content: [
            <div>
              <b>Notices:</b>{" "}
              <p>
                We will give notice and provide communications to You by email,
                by posting on the Site, or other reasonable electronic means.
                You must give notice to Us in writing via
              </p>{" "}
              <p>
                email to{" "}
                <a className="custom-link" href="mailto: info@move2newcity.com">
                  info@move2newcity.com
                </a>{" "}
                or as otherwise expressly provided. Please report any violations
                of this Agreement to{" "}
                <a className="custom-link" href="mailto: info@move2newcity.com">
                  info@move2newcity.com
                </a>{" "}
              </p>
            </div>,
            <div>
              <b> Electronic communications:</b> You consent to receiving
              communications from Us in an electronic form, and agree that all
              terms and conditions, Agreements, notices, documents, disclosures,
              and other communications (“<b>Communications</b>”) that We provide
              to You electronically satisfy any legal requirement that such
              Communications would satisfy if it were in writing. Your consent
              to receive Communications and do business electronically, and our
              Agreement to do so, applies to all of Your interactions and
              transactions with Us.
            </div>,
            <div>
              <b> No third party beneficiaries:</b> You understand and agree
              that, except as otherwise expressly provided in this Agreement,
              there shall be no third party beneficiaries to this Agreement.
            </div>,
            <div>
              <b> Governing law and jurisdiction:</b>This Agreement and the
              relationship between You and Move2NewCity, will be governed by the
              laws of the Australia. The parties agree to the non-exclusive
              jurisdiction of the Federal Court of Australia, and of courts
              entitled to hear appeals from those Courts.
            </div>,
            <div>
              <b>Assignment:</b>You must not transfer, assign or delegate Your
              right and/or duties under this Agreement to anyone else and any
              attempted assignment or delegation is void. We may assign or
              novate this Agreement at any time by giving notice to You in
              accordance with clause 16.1 and without obtaining Your consent.
            </div>,
            <div>
              <b>Headings:</b>Headings and paragraph titles are for convenience
              only and do not form part of the terms of this Agreement.
            </div>,
            <div>
              <b>Delay and waiver:</b> Any delay or failure by Move2NewCity to
              exercise or enforce any right or provision of this Agreement will
              not constitute a waiver of such right or provision. No waiver by
              Us will have effect unless such waiver is set forth in writing,
              signed by Us, nor will any such waiver of any breach or default
              constitute a waiver of any subsequent or similar breach or
              default.
            </div>,
            <div>
              <b>Whole agreement:</b>This Agreement constitutes the entire
              Agreement between You and Move2NewCity with respect to its subject
              matter, and supersedes all prior oral or written understandings,
              communications or agreements.
            </div>,
            <div>
              <b> Severability</b> If for any reason a court holds any provision
              of this Agreement, or portion thereof, to be unenforceable, that
              provision of the Agreement will be read down to the minimum extent
              necessary so as to give full effect to the intent of the parties,
              and the remainder of this Agreement will continue in full force
              and effect
            </div>,
            <div>
              <b>Survival: </b>All provisions that by their nature survive
              expiration or termination of this Agreement shall so survive,
              including without limitation, clauses 9 and 16, all other
              limitations on liability explicitly set forth herein and our
              proprietary rights in and to the Site, Content provided by Us, Our
              Technology and the Services.
            </div>,
            <div>
              <b>Amendments: </b>We may amend the terms of this Agreement from
              time to time by publishing the amended terms to Our website or by
              giving electronic notice to You. It is Your sole responsibility to
              check the Site from time to time to view any amendments to this
              Agreement. The amendment takes effect upon your express
              acknowledgement or when you continuing to access and/or use the
              Site and/or Services.
            </div>,
          ],
        },
      ],
    },
  ];

  return (
    <div className="terms-condition">
      <div className="terms-condition-main-header">Terms and Conditions</div>
      <div className="terms-condition-last-updated">
        <p>Last Updated: </p> 26 January 2022
      </div>
      <div className="terms-condition-content-header">INTRODUCTION</div>
      <svg className="terms-condition-svg-line-ta">
        <rect className="rect" fill="#EE4D67" />
      </svg>
      <div className="terms-condition-introduction-content">
        Welcome to Move2NewCity! This page contains the Terms and Conditions of
        Move2NewCity. Please carefully read the following terms and conditions.
        You are agreeing to be bound by these terms and conditions and our terms
        of use policy by visiting our site or signing up as a member.
      </div>
      <div className="terms-condition-content-header">TABLE OF CONTENTS</div>
      <svg className="terms-condition-svg-line-ta">
        <rect className="rect" fill="#EE4D67" />
      </svg>
      <div className="terms-condition-content-list">
        {termsAndConditionContent.map((titleItem) => (
          <div className="terms-condition-content-list-information">
            <div className="information-title">
              <div>
                <svg className="title-svg">
                  <rect className="rect" fill="#EE4D67" />
                </svg>
              </div>
              <div className="information-title-text">
                <a href={`#${titleItem.id}`}> {titleItem.mainTitle}</a>
              </div>
            </div>
            {titleItem.subTitles &&
              titleItem.subTitles.map((subTitle) => (
                <div className="information-subtitle">
                  <div>
                    <svg className="subtitle-svg">
                      <rect className="rect" fill="#EE4D67" />
                    </svg>
                  </div>
                  <div className="information-subtitle-text">
                    <a href={`#${subTitle.id}`}>{subTitle.title}</a>
                  </div>
                </div>
              ))}
            {titleItem.description &&
              titleItem.description
                .filter((desc) => desc.isSubtitle === true)
                .map((desc) => (
                  <div className="information-subtitle">
                    <div>
                      <svg className="subtitle-svg">
                        <rect className="rect" fill="#EE4D67" />
                      </svg>
                    </div>
                    <div className="information-subtitle-text">
                      <a href={`#${desc.id}`}>{desc.topic}</a>
                    </div>
                  </div>
                ))}
          </div>
        ))}
      </div>
      <div className="terms-condition-content-body">
        <ol className="terms-condition-content-body-list">
          {termsAndConditionContent.map((item, key) => (
            <div className="terms-condition-content-body-item">
              <div className="main-title">
                <div className="terms-condition-content-header">
                  <li id={item.id}>{item.mainTitle}</li>
                </div>
                <svg className="terms-condition-svg-line-ta">
                  <rect className="rect" fill="#EE4D67" />
                </svg>
              </div>
              {item.description && (
                <div className="terms-condition-content-body-item-topic">
                  {item.description.map((desc, descKey) => (
                    <div className="terms-condition-content-body-item-topic-element">
                      <div
                        id={desc.id}
                        className={`${
                          desc.isSubtitle ? "subtitle" : "ordered-list"
                        }`}>
                        <span className="ordered-list-number">
                          {desc.numb} {"."}
                        </span>
                        <span className="ordered-list-text"> {desc.topic}</span>
                      </div>
                      {desc.content && (
                        <ul className="terms-condition-content-body-item-topic-list">
                          {desc.content.map((content, contentKey) => (
                            <li
                              className="terms-condition-content-body-item-topic-list-element pt-1"
                              key={contentKey}>
                              {content}
                            </li>
                          ))}
                        </ul>
                      )}
                    </div>
                  ))}
                </div>
              )}
              {item.data && (
                <div className="terms-condition-content-body-item-data">
                  {item.data}
                </div>
              )}
              {item.subTitles && (
                <div className="terms-condition-content-body-item-subtitles">
                  {item.subTitles.map((subTitle, subTitleKey) => (
                    <div
                      id={subTitle.id}
                      className="terms-condition-content-body-item-subtitles-main">
                      <ul>
                        <div
                          key={subTitleKey}
                          className="terms-condition-content-body-item-subtitles-main-title">
                          <li> {subTitle.title}</li>
                        </div>
                        {subTitle.text && (
                          <div className="terms-condition-content-body-item-subtitles-main-text">
                            {subTitle.text}
                          </div>
                        )}
                        {subTitle.content && (
                          <div className="terms-condition-content-body-item-subtitles-main-content">
                            <ul>
                              {subTitle.content.map((cont, contKey) => (
                                <li
                                  className="terms-condition-content-body-item-subtitles-main-content-list-element"
                                  key={contKey}>
                                  {cont}
                                </li>
                              ))}
                            </ul>
                          </div>
                        )}
                      </ul>
                    </div>
                  ))}
                </div>
              )}
              {item.points && (
                <div className="terms-condition-content-body-item-points">
                  <ul className="terms-condition-content-body-item-points-list">
                    {item.points.map((point, key) => (
                      <li
                        key={key}
                        className="terms-condition-content-body-item-points-list-item">
                        {point}
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
          ))}
        </ol>
      </div>
    </div>
  );
}

export default TermsAndConditions;
