import React, { useState } from "react";
import { Button, Container } from "react-bootstrap";
import { postDemoRequest } from "../../util/APIUtils";
import { emailRegex } from "../../util/Regex";
import "./Request-Demo.scss";
import { ENV, ENVIROMENTS } from "../../constants";
import { MetaTags } from "react-meta-tags";
import ReactGA from "react-ga4";

const RequestDemo = () => {
  const bodyImage = "/assets/img/recover/forgot-password-image.png";
  const errorInitialState = {
    emptyField: false,
    wrongFormat: false,
  };
  const [email, setEmail] = useState(null);
  const [requestLink, setRequestLink] = useState(false);
  const [error, setError] = useState(errorInitialState);

  const demoLinks = [
    {
      title: "Newcomer",
      cssClass: "newComer",
      description:
        "A Newcomer is someone who is planning to relocate to a different city. You will get the opportunity to post questions and schedule one-to-one calls related to the city and get answers from experts who have lived experiences in the city.",
      link: "https://t.maze.co/80956084",
    },
    {
      title: "Friend",
      cssClass: "friend",
      description:
        "A Friend is an individual with lived experience in a specific city. Sign-up and share your lived experiences about a city by answering questions posted on your expert categories. You can also schedule one-to-one calls with Newcomers and be rewarded for making their settlement process easier.",
      link: "https://t.maze.co/80948990",
    },
  ];

  const handleInputValidation = () => {
    let updatedError = { ...error };
    updatedError.emptyField = Boolean(!email);
    updatedError.wrongFormat = !emailRegex.test(email);
    setError(updatedError);
    return Object.values(updatedError).every((value) => value === false);
  };

  const handleDemoRequest = (e) => {
    e.preventDefault();
    if (handleInputValidation()) {
      postDemoRequest(email)
        .then(() => {
          setRequestLink(true);
        })
        .catch((err) => {
          // TODO - add error messages after implementing the email template
        });
    }
  };

  const handleInputChange = (evt) => {
    setEmail(evt.target.value);
  };

  return (
    <main className="request-demo">
      <Container>
        {!requestLink ? (
          <>
            <div className="request-demo-main-header">
              <h1>Interactive Demo</h1>
            </div>
            <div className="request-demo-body">
              <div className="request-demo-body-content">
                <div className="request-demo-body-content-image">
                  <img src={bodyImage} alt="request-demo-img" />
                </div>
                <p className="request-demo-body-content-text">
                  Want to be a part of the newest and largest online community
                  which connects individuals who are planning to relocate to a
                  new city with people with lived experiences?
                </p>
                <p className="request-demo-body-content-text">
                  Type in your email address to continue with your free
                  interactive demo.
                </p>
                <p className="request-demo-body-content-text-email">Email*</p>
                <div className="request-demo-body-content-form">
                  <div className="request-demo-body-content-form-input">
                    <input
                      name="email"
                      className="form-control form-input-text"
                      placeholder="e.g: steve@limarktech.com"
                      value={email}
                      onChange={handleInputChange}
                      required
                    />
                    <label
                      for="email"
                      className={`${
                        error.emptyField || error.wrongFormat
                          ? "error-label-visible"
                          : "error-label-invisible"
                      }`}>
                      <i className="fas fa-exclamation-circle"></i>
                      {error.emptyField
                        ? "Please fill this field"
                        : error.wrongFormat && "Enter valid Email"}
                    </label>
                  </div>
                  <Button
                    className="send-button"
                    onClick={(e) => handleDemoRequest(e)}>
                    Send
                  </Button>
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="demo-link-main-header">
              <h1>Are you a Newcomer or a Friend?</h1>
            </div>
            <div className="demo-link-body">
              <div className="demo-link-body-content">
                {demoLinks.map((item, key) => {
                  return (
                    <div
                      key={key}
                      onClick={() => {
                        ReactGA.event({
                          category: "Static page",
                          action: `Demo request for a ${item.title}`,
                        });
                      }}
                      className={`demo-link-body-content-card ${item.cssClass}`}
                    >
                      <p className="demo-link-body-content-card-title">
                        {item.title}
                      </p>
                      <p className="demo-link-body-content-card-text">
                        {item.description}
                      </p>
                      <div className="demo-link-body-content-card-footer">
                        <a target="_blank" rel="noreferrer" href={item.link}>
                          View Demo
                        </a>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </>
        )}
      </Container>
      {ENV === ENVIROMENTS.PRODUCTION && (
        <MetaTags>
          <title>Request Demo | Move2NewCity</title>
          <meta
            name="description"
            content="Insert your email and enjoy a seamless experience of how Move2NewCity works through our interactive demo"
          />
          <meta
            name="keywords"
            content="request demo, move2newcity, settling in australia, moving to australia, relocating within australia, australia"
          />
        </MetaTags>
      )}
    </main>
  );
};
export default RequestDemo;
