import React, { useEffect, useState } from "react";
import { Container, Navbar, Nav } from "react-bootstrap";
import { Link } from "react-router-dom";
import { USER_ROLE } from "../../util/Utils";
import "./Admin-Header.scss";

const AdminHeader = (props) => {
  const location = props.location;
  const menuItems = [
    { title: "Approval", link: "/review", isActive: false },
    { title: "Manage Cities", link: "/cities", isActive: false },
    { title: "Manage Users", link: "/users", isActive: false },
    { title: "Manage Events", link: "/events", isActive: false },
    { title: "Inquiries", link: "/inquiries", isActive: false },
  ];
  const [adminMenu, setAdminMenu] = useState(menuItems);

  useEffect(() => {
    let menuItemsCopy = [...menuItems];
    menuItemsCopy.forEach((item) => {
      if (location.pathname === item.link) {
        item.isActive = true;
        setAdminMenu(menuItemsCopy);
      } else {
        setAdminMenu(menuItems);
      }
    });
  }, [location.pathname]);

  return (
    <div>
      <Navbar
        className="admin-header"
        collapseOnSelect
        expand="lg"
        variant="dark">
        <Container className="admin-header-container">
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse
            className="admin-header-nav-collapse"
            id="basic-navbar-nav">
            <Nav className="me-auto admin-header-nav">
              {adminMenu.map((item, key) => {
                return (
                  <Nav.Link
                    key={key}
                    as={Link}
                    disabled={
                      props.currentUser.role === USER_ROLE.ADMIN &&
                      item !== adminMenu[0]
                    }
                    to={item.link}
                    className={`${
                      item.isActive && "active-state"
                    } admin-header-nav-item`}>
                    {item.title}
                  </Nav.Link>
                );
              })}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  );
};

export default AdminHeader;
