import { saveAs } from "file-saver";
import { createEvent } from "ics";
import React, { useEffect, useReducer } from "react";
import { Modal } from "react-bootstrap";
import timeZones from "../../util/TimeZones";
import "./Add-To-Calender-Modal.scss";

const AddToCalenderModal = ({
  showModal,
  hideModal,
  eventDate,
  startTime,
  endTime,
  eventTitle,
}) => {
  const getAttendeeTimeZone = () => {
    const date = new Date();
    return date.toString().split("(")[1].split(")")[0];
  };

  const getTimeZoneOffset = (getAttendeeTimeZone, timeZones) => {
    const timeZoneValue = getAttendeeTimeZone();

    const timeZoneObj = timeZones.find((timeZone) => {
      return timeZone.value === timeZoneValue;
    });

    const offsetHours = Math.floor(Math.abs(timeZoneObj?.offset));
    const offsetMinutes = Math.abs(
      Math.round((timeZoneObj?.offset - offsetHours) * 60)
    );

    return {
      hours: timeZoneObj?.offset < 0 ? -offsetHours : offsetHours,
      minutes: offsetMinutes,
    };
  };

  const initialState = {
    dates: "",
    eventStart: [],
    duration: { hours: 0, minutes: 0 },
    newDate: "",
    newStartTime: "",
    newEndTime: "",
  };

  const reducer = (state, action) => {
    switch (action.type) {
      case "SET_EVENT_DATA":
        return {
          ...state,
          ...action.payload,
        };
      default:
        return state;
    }
  };

  const [eventData, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    if (eventDate) {
      const [startHour, startMinute, startSecond] = startTime
        .split(":")
        .map(Number);
      const [endHour, endMinute, endSecond] = endTime.split(":").map(Number);

      const eventStart = [
        ...eventDate.split("-").map(Number),
        startHour + getTimeZoneOffset(getAttendeeTimeZone, timeZones).hours,
        startMinute + getTimeZoneOffset(getAttendeeTimeZone, timeZones).minutes,
      ];

      const date = new Date(eventDate);
      const day = date.toLocaleString("default", { weekday: "long" });
      const month = date.toLocaleString("default", { month: "short" });
      const dayNumber = date.toLocaleString("default", { day: "2-digit" });
      const newDate = `${day} ${dayNumber} ${month}`;
      const newStartTime = startTime?.slice(0, 5);
      const newEndTime = endTime?.slice(0, 5);
      const durationHours = endHour - startHour;
      const durationMinutes = endMinute - startMinute;

      const duration = {
        hours: durationHours,
        minutes: durationMinutes,
      };

      const startDateTime = new Date(eventDate);
      startDateTime.setHours(startHour, startMinute, startSecond);
      const endDateTime = new Date(eventDate);
      endDateTime.setHours(endHour, endMinute, endSecond);
      const startDateString = startDateTime.toISOString().replace(/[:-]/g, "");
      const endDateString = endDateTime.toISOString().replace(/[:-]/g, "");

      dispatch({
        type: "SET_EVENT_DATA",
        payload: {
          dates: `${startDateString}Z/${endDateString}Z`,
          eventStart,
          duration,
          newDate,
          newStartTime,
          newEndTime,
        },
      });
    }
  }, [eventDate, startTime, endTime]);

  const addToGoogleCalendar = () => {
    window.open(
      `https://calendar.google.com/calendar/render?action=TEMPLATE&dates=${eventData.dates}&text=${eventTitle}`,
      "_blank"
    );
  };

  const addToOutlookCalendar = () => {
    window.open(
      `https://outlook.live.com/calendar/0/deeplink/compose?allday=false&enddt=${eventData.eventDate}T${eventData.endTime}%2B00%3A00&path=%2Fcalendar%2Faction%2Fcompose&rru=addevent&startdt=${eventDate}T${startTime}%2B00%3A00&subject=${eventTitle}`,
      "_blank"
    );
  };

  const addToYahooCalendar = () => {
    window.open(
      `https://calendar.yahoo.com/?dur=&et=${eventDate.replace(
        /[^0-9]/g,
        ""
      )}T${endTime.replace(/[^0-9]/g, "")}Z&st=${eventDate.replace(
        /[^0-9]/g,
        ""
      )}T${startTime.replace(/[^0-9]/g, "")}Z&title=${eventTitle}&v=60`,
      "_blank"
    );
  };

  const addToOther = () => {
    createEvent(
      {
        title: eventTitle,
        start: eventData.eventStart,
        duration: eventData.duration,
      },
      (error, value) => {
        const blob = new Blob([value], { type: "text/plain;charset=utf-8" });
        saveAs(blob, `${eventTitle}.ics`);
      }
    );
  };

  return (
    <Modal
      centered
      backdrop="static"
      show={showModal}
      onHide={hideModal}
      className="modal-add-to-calendar"
    >
      <Modal.Header className="modal-header-close-btn fw-bolder" closeButton>
        <Modal.Title className="modal-title-calender mt-3 ml-4">
          Add to calendar
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <div className="modal-body-content-header-calender">
          {eventData.newDate} at {eventData.newStartTime} -{" "}
          {eventData.newEndTime}
        </div>
        <div className="modal-body-content">
          <div className="modal-body-content-calender d-flex mt-3 mb-4">
            <div className="icon-container-icon mr-4">
              <i class="fab fa-google"></i>
            </div>
            <div className="icon-container-text" onClick={addToGoogleCalendar}>
              Add to Google calendar
            </div>
          </div>
          <div className="modal-body-content-calender d-flex mt-3 mb-4">
            <div className="icon-container-icon mr-4">
              <i class="fab fa-yahoo"></i>
            </div>
            <div className="icon-container-text" onClick={addToYahooCalendar}>
              Add to Yahoo calendar
            </div>
          </div>
          <div className="modal-body-content-calender d-flex mt-3 mb-4">
            <div className="icon-container-icon mr-4">
              <i class="fab fa-microsoft"></i>
            </div>
            <div className="icon-container-text" onClick={addToOutlookCalendar}>
              Add to Outlook calendar
            </div>
          </div>
          <div className="modal-body-content-calender d-flex mt-3 mb-4">
            <div className="icon-container-icon mr-4">
              <i class="far fa-calendar-plus"></i>
            </div>
            <div className="icon-container-text" onClick={addToOther}>
              Other
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};
export default AddToCalenderModal;
