import React, { useContext, useEffect, useState } from "react";
import {
  Accordion,
  AccordionContext,
  Button,
  Modal,
  ToggleButton,
  ToggleButtonGroup,
  useAccordionToggle,
} from "react-bootstrap";
import "./Search-Modal.scss";
import { postAnalytics } from "../../util/APIUtils";
import { ANALYTICS, ENV, ENVIROMENTS, USER_IDENTIFIER } from "../../constants";
import Cookies from "js-cookie";

const SearchModal = (props) => {
  const {
    setCustomSearch
  } = props;
  const [showModal, setShowModal] = useState(false);
  const [isInvalid, setIsInvalid] = useState(false);
  const [visitorDetails, setVisitorDetails] = useState({
    personType: null,
    preferredCategories: [],
    professionType: null,
    settlementType: null,
    city: null,
  });

  const settlementStage = ["Pre-arrival", "Post-arrival"];
  const professionType = {
    STUDENT: "student",
    PROFESSIONAL: "professional",
    FAMILY_WITH_KIDS: "familyWithKids",
    FAMILY_WITHOUT_KIDS: "familyWithoutKids",
  };
  const userType = {
    PERSON_TYPE: "personType",
    PROFESSION_TYPE: "professionType",
    PREFERRED_CATEGORIES: "preferredCategories",
    SETTLEMENT_TYPE: "settlementType",
  };
  const migratingType = {
    INDIVIDUAL: "Individual",
    FAMILY: "Family",
  };

  useEffect(() => {
    setShowModal(props.showModal);
  }, [props]);

  const handleModalDone = () => {
    if (handleFormValidation()) {
      setIsInvalid(false);
      setShowModal(false);
      visitorDetails["city"] = props.city.id;
      localStorage.setItem("visitorDetails", JSON.stringify(visitorDetails));
      // Post User Preference Analytics
      if (ENV === ENVIROMENTS.PRODUCTION) {
        postAnalytics({
          userIdentifier: Cookies.get(USER_IDENTIFIER) ?? "anonymous",
          analyticType: ANALYTICS.USER_PREFERENCE,
          migrationStatus: visitorDetails?.settlementType,
          familyOrIndividualStatus: visitorDetails?.personType,
          categories: visitorDetails?.preferredCategories ?? [],
          settlementStage: visitorDetails?.professionType,
        }).finally(() => {
          props.history.push(`/city/${props.city.id}`);
        });
      } else {
        props.history.push(`/city/${props.city.id}`);
      }
    } else {
      setIsInvalid(true);
    }
  };

  const handleFormValidation = () => {
    return (
      visitorDetails.personType &&
      visitorDetails.preferredCategories.length > 0 &&
      visitorDetails.professionType &&
      visitorDetails.settlementType
    );
  };

  const handleModalSkip = () => {
    localStorage.removeItem("visitorDetails");
    setShowModal(false);
    props.history.push(`/city/${props.city.id}`);
  };

  const visitorDetailChange = (e, attrib) => {
    visitorDetails[attrib] = e;
    setVisitorDetails(visitorDetails);
    setIsInvalid(false);
  };

  const CustomToggle = ({ children, eventKey, callback }) => {
    const currentEventKey = useContext(AccordionContext);

    const decoratedOnClick = useAccordionToggle(
      eventKey,
      () => callback && callback(eventKey)
    );

    const handleSelect = () => {
      decoratedOnClick();
      delete visitorDetails.personType;
      delete visitorDetails.professionType;
      setMigrantType({
        student: false,
        professional: false,
        family_with_kids: false,
        family_without_kids: false,
      });
    };

    const isCurrentEventKey = currentEventKey === eventKey;

    return (
      <button
        variant="outline-info"
        className={`grid-item btn ${
          isCurrentEventKey ? "active " : ""
        }btn-outline-info ml-2`}
        onClick={() => {
          handleSelect();
        }}>
        {children}
      </button>
    );
  };

  const [migrantType, setMigrantType] = useState({
    student: false,
    professional: false,
    family_with_kids: false,
    family_without_kids: false,
  });

  const updateType = (keyToSet) => {
    Object.keys(migrantType).forEach((keysToReset) => {
      setMigrantType((prev) => ({
        ...prev,
        [keysToReset]: false,
        [keyToSet]: true,
      }));
    });
  };

  const handleVisitorDetailChange = (professionData, e, migratingData) => {
    updateType(professionData);
    visitorDetailChange(e.target.value, userType.PROFESSION_TYPE);
    visitorDetailChange(migratingData, userType.PERSON_TYPE);
  };

  const handleModalClose = () => {
    setShowModal(false);
    setIsInvalid(false);
    setCustomSearch({setCustomSearch: false})
  };

  return (
    <Modal
      className="custom-search-modal"
      dialogClassName="search-info-popup"
      show={showModal}
    onHide={() => {
      handleModalClose();
    }}
      size="lg"
      centered
      keyboard={false}>
      <Modal.Header className="custom-search-modal-header" closeButton>
        <Modal.Title className="custom-search-modal-header-title">
          Tell us more about you
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="custom-search-modal-body">
        <Accordion>
          <div className="row">
            <div className="col-12">
              <div className="row">
                <div className="col-2 category-heading">
                  Migrating as a *
                </div>
                <div className="col-10  button-div">
                  <CustomToggle eventKey="0">Individual</CustomToggle>
                  <CustomToggle eventKey="1">Family</CustomToggle>
                </div>
              </div>
              <Accordion.Collapse eventKey="0" id="one">
                <div className="row mt-1">
                  <div className="col-2 category-heading-sub">Individual *</div>
                  <div className="col-10 button-div">
                    <button
                      variant="outline-info"
                      value="Student"
                      className={`${
                        migrantType.student ? "active " : ""
                      } grid-item btn btn-outline-info ml-2`}
                      onClick={(e) => {
                        handleVisitorDetailChange(
                          professionType.STUDENT,
                          e,
                          migratingType.INDIVIDUAL
                        );
                      }}>
                      Student
                    </button>
                    <button
                      variant="outline-info"
                      value="Professional"
                      className={`${
                        migrantType.professional ? "active " : ""
                      } grid-item btn btn-outline-info ml-2`}
                      onClick={(e) => {
                        handleVisitorDetailChange(
                          professionType.PROFESSIONAL,
                          e,
                          migratingType.INDIVIDUAL
                        );
                      }}>
                      Professional
                    </button>
                  </div>
                </div>
              </Accordion.Collapse>
              <Accordion.Collapse eventKey="1" id="two">
                <div className="row mt-1">
                  <div className="col-2 category-heading-sub">Family *</div>
                  <div className="col-9 button-div">
                    <button
                      variant="outline-info"
                      value="Option 1" // Replace with finalized option from Design Team
                      className={`${
                        migrantType.family_with_kids ? "active " : ""
                      } grid-item-lg btn btn-outline-info ml-2`}
                      onClick={(e) => {
                        handleVisitorDetailChange(
                          professionType.FAMILY_WITH_KIDS,
                          e,
                          migratingType.FAMILY
                        );
                      }}>
                      Family with kids
                    </button>
                    <button
                      variant="outline-info"
                      value="Option 2" // Replace with finalized option from Design Team
                      className={`${
                        migrantType.family_without_kids ? "active " : ""
                      } grid-item-lg btn btn-outline-info ml-2`}
                      onClick={(e) => {
                        handleVisitorDetailChange(
                          professionType.FAMILY_WITHOUT_KIDS,
                          e,
                          migratingType.FAMILY
                        );
                      }}>
                      Family without kids
                    </button>
                  </div>
                </div>
              </Accordion.Collapse>
            </div>
          </div>
        </Accordion>
        <div className="row category">
          <div className="col-2 category-heading">Categories *</div>
          <div className="col-10 button-div">
            <ToggleButtonGroup
              onChange={(e) =>
                visitorDetailChange(e, userType.PREFERRED_CATEGORIES)
              }
              type="checkbox"
              className="toggle-button-group">
              {props.categories.map((item, key) => {
                return (
                  <ToggleButton
                    key={key}
                    variant="outline-info"
                    value={item.tag}
                    className="grid-item">
                    {item.title}
                  </ToggleButton>
                );
              })}
            </ToggleButtonGroup>
          </div>
        </div>
        <div className="row">
          <div className="col-2 category-heading settlement-stage">
            Settlement Stage *
          </div>
          <div className="col-10 button-div">
            <ToggleButtonGroup
              onChange={(e) => visitorDetailChange(e, userType.SETTLEMENT_TYPE)}
              type="radio"
              name="settlementType">
              {settlementStage.map((item, key) => {
                return (
                  <ToggleButton
                    key={key}
                    type="radio"
                    variant="outline-info"
                    value={item}
                    className="grid-item">
                    {item}
                  </ToggleButton>
                );
              })}
            </ToggleButtonGroup>
          </div>
        </div>
        {isInvalid && (
          <div className="col-12 d-flex error-message">
            <div className="col-1 error-message-section-1"></div>
            <div className="col-11 ml-5 error-message-section-2">
              <i
                className="fa fa-exclamation-circle mr-2"
                aria-hidden="true"></i>
              Please select at least one option of each
            </div>
          </div>
        )}
      </Modal.Body>
      <Modal.Footer className="d-flex justify-content-start custom-search-modal-footer">
        <div className="col-2 footer-content-upper"></div>
        <div className="col-9 d-flex justify-content-between footer-content">
          <Button
            className="done-button"
            variant="primary"
            onClick={handleModalDone}>
            Done
          </Button>
          <Button className="skip-button " onClick={handleModalSkip}>
            SKIP <i className="fa fa-angle-double-right" aria-hidden="true"></i>
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default SearchModal;
