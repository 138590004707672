import React, { useContext, useState, useEffect } from "react";
import { getCurrentUser } from "../util/APIUtils";

const UserContext = React.createContext();

export const UserProvider = ({ children }) => {
  const [userVerification, setUserVerification] = useState(true);
  const [currentUser, setCurrentUser] = useState();
  const [history, setHistory] = useState();
  const [location, setLocation] = useState();

  useEffect(() => {
    if (!currentUser) {
      getCurrentUser().then((response) => {
        setCurrentUser(response);
        verifyUser(response);
      }).catch(console.warn);
    }
  }, [currentUser]);

  const verifyUser = (currentUser) => {
    if (currentUser && !currentUser.emailVerified && currentUser.isSuspend) {
      setUserVerification(false);
    }
    setCurrentUser(currentUser);
  };

  return (
    <>
      <UserContext.Provider
        value={{
          userVerification,
          currentUser,
          setCurrentUser,
          setUserVerification,
          verifyUser,
          history,
          setHistory,
          location,
          setLocation,
        }}>
        {children}
      </UserContext.Provider>
    </>
  );
};

export const useVerified = () => {
  const {
    userVerification,
    currentUser,
    setCurrentUser,
    setUserVerification,
    verifyUser,
    history, 
    setHistory,
    location,
    setLocation
  } = useContext(UserContext);
  return {
    verified: userVerification,
    currentUser,
    setCurrentUser,
    setUserVerification,
    verifyUser,
    history, 
    setHistory,
    location,
    setLocation
  };
};
