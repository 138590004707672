import React, { useState } from "react";
import Cookies from "js-cookie";
import { Link } from "react-router-dom";
import "./CookieMessage.scss";
import { XLg } from "react-bootstrap-icons";

const CookieMessage = () => {
  const [acceptedCookies, setAcceptedCookies] = useState(
    Cookies.get("acceptedCookies")
  );

  const acceptCookies = () => {
    Cookies.set("acceptedCookies", "true");
    setAcceptedCookies(true);
  };

  const hideCookieMessage = () => {
    setAcceptedCookies(true);
  };

  return (
    !acceptedCookies && (
      <div className="cookie-message">
        <div className="cookie-message-card">
          <button
            className="cookie-message-card-close"
            onClick={hideCookieMessage}
          >
            <XLg />
          </button>
          <p className="main-message">
            We use cookies on our website to give you the most relevant
            experience by remembering your preferences and repeat visits.
            <br />
            By clicking “Accept All”, you consent to the use of ALL the cookies.
          </p>
          <p className="more-info">
            For more information, go to{" "}
            <Link to="/privacy-policy">Privacy Policy</Link>.
          </p>
          <button
            className="cookie-message-card-accept"
            onClick={acceptCookies}
          >
            Accept All
          </button>
        </div>
      </div>
    )
  );
};

export default CookieMessage;
