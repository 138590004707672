import { ExclamationCircle } from "react-bootstrap-icons";
import Modal from "react-bootstrap/Modal";
import "./Alert-Box.scss";

const AlertBox = (props) => {
  const { title, body, showAlertBox, setShowAlertBox, type } = props;

  const handleClose = () => {
    setShowAlertBox(false);
    sessionStorage.removeItem("ask-a-question");
  };

  return (
    <>
      <Modal
        centered
        show={showAlertBox}
        onHide={handleClose}
        backdrop={true}
        className="alert-box"
      >
        <Modal.Header className="alert-box-header" closeButton></Modal.Header>
        <Modal.Body className="alert-box-body">
          <div>
            <div className="alert-box-body-title">
              <p>{title}</p>
            </div>
            <div className="alert-box-body-image-container">
              <ExclamationCircle className="image" />
            </div>
            <div className="alert-box-body-description">
              <div className="alert-box-body-description-text">
                <p>{body}</p>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AlertBox;
