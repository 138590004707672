import React from "react";

const Payment = () => {
  return (
    <div>
      <p>Payment</p>
    </div>
  );
};

export default Payment;
