import React, { useEffect, useState } from "react";
import {
  deletePostById,
  EditPostById,
  voteForPostById,
} from "../../util/APIUtils";
import CreateComment from "../create-comment/Create-Comment";
import "./Thread-Comment.scss";

function ThreadComment(props) {
  const [comment, setComments] = useState(props?.comments);
  const [viewComments, setViewComments] = useState(null);
  const [commentParent, setCommentParent] = useState(null);
  const [editMode, setEditMode] = useState(null);
  const viewAll = true;

  useEffect(() => {
    setComments(props?.comments);
  }, [props?.comments]);

  const fncStatus = (values) => {
    props.fncStatus(values);
  };

  const voteForPost = (type, item) => {
    voteForPostById(item.id, { type: type }).then((response) => {
      props.fncStatus(response);
    });
  };

  const upVoteCountForItem = (item) => {
    let upVotes = 0;
    item?.interactions?.forEach((interaction) => {
      if (interaction.type === "up_vote") {
        upVotes++;
      }
    });
    return <div>{upVotes > 0 && <span>{upVotes}</span>}</div>;
  };

  const downVoteCountForItem = (item) => {
    let downVotes = 0;
    item?.interactions?.forEach((interaction) => {
      if (interaction.type === "down_vote") {
        downVotes++;
      }
    });
    return <div>{downVotes > 0 && <span>{downVotes}</span>}</div>;
  };

  const deleteComment = (item) => {
    deletePostById(item.id).then((response) => {
      props.fncStatus(response);
    });
  };

  const showCommentsSection = (item) => {
    setViewComments(item);
  };

  const calculateTime = (time) => {
    const msDiff = new Date().getTime() - new Date(time).getTime(); //Future date - current date
    const minutesDiff = Math.floor(msDiff / (1000 * 60));
    let timeDiffernce = 0;
    if (minutesDiff > 60) {
      const hourDiff = Math.trunc(minutesDiff / 60);
      if (hourDiff > 24) {
        timeDiffernce = Math.trunc(hourDiff / 24) + " days ago";
      } else {
        timeDiffernce = hourDiff + " hours ago";
      }
    } else {
      timeDiffernce = minutesDiff + " minutes ago";
    }
    return timeDiffernce;
  };

  const onSetCommentParent = (item) => {
    setCommentParent(item);
  };

  const handleSubmit = (evt) => {
    evt.preventDefault();

    const dataSet = {
      cityId: props.cityId,
      parentId: props.parentId,
      text: editMode.text,
    };

    EditPostById(editMode.id, dataSet).then((response) => {
      props.fncStatus(response);
      setEditMode(null);
    });
  };

  const handleChange = (key, value) => {
    setEditMode({
      ...editMode,
      [key]: value,
    });
  };

  return (
    <div className="thread-comment-section">
      {viewAll &&
        comment &&
        comment?.map((item, key) => {
          return (
            <div key={key}>
              {editMode?.id !== item.id && (
                <div className="col-md-12 px-0" key={key}>
                  <div className="row my-2 mx-3 comment-row">
                    <div className="col-md-12 d-flex">
                      <span>
                        <i className="fa fa-comment mr-2"></i>
                        {item?.text}
                        <button
                          type="button"
                          className="btn btn-light"
                          onClick={(e) => {
                            setEditMode(item);
                          }}>
                          <i className="fa fa-edit"></i>
                        </button>
                        <button
                          type="button"
                          className="btn btn-light action-color-blue"
                          onClick={(e) => {
                            onSetCommentParent(item);
                          }}>
                          Reply
                        </button>
                      </span>
                    </div>
                    <div className="col-md-12 d-flex justify-content-start">
                      <span className="action-color-blue">
                        {item?.createdBy.name}{" "}
                      </span>
                      <span className="created-time ml-3">
                        {calculateTime(item?.createdAt)}
                      </span>
                    </div>
                    <div className="row w-100">
                      <div className="col-md-6 d-flex justify-content-start align-items-center">
                        <div className="mr-2 d-flex align-items-center">
                          <button
                            type="button"
                            className="btn btn-light"
                            onClick={(e) => {
                              voteForPost("up_vote", item);
                            }}>
                            <i className="fas fa-thumbs-up icon-thumbs-up"></i>
                          </button>
                          <span className="ml-1 icon-thumbs-up">
                            {upVoteCountForItem(item)}
                          </span>
                        </div>
                        <div className="mr-2 d-flex align-items-center">
                          <button
                            type="button"
                            className="btn btn-light"
                            onClick={(e) => {
                              voteForPost("down_vote", item);
                            }}>
                            <i className="fas fa-thumbs-down"></i>
                          </button>
                          <span className="ml-1 icon-vote-count">
                            {downVoteCountForItem(item)}
                          </span>
                        </div>
                      </div>
                      <div className="col-md-6 d-flex justify-content-end align-items-center">
                        {item?.comments?.length > 0 && (
                          <div>
                            <button
                              type="button"
                              className={
                                viewComments?.id === item.id
                                  ? "btn btn-light mr-1 active"
                                  : "btn btn-light mr-1"
                              }
                              onClick={(e) => {
                                showCommentsSection(item);
                              }}>
                              <i className="fa fa-comments"></i>
                            </button>
                            <span className="icon-vote-count mr-1">
                              {item?.comments?.length}
                            </span>
                          </div>
                        )}
                        <button
                          type="button"
                          className="btn btn-light"
                          onClick={(e) => {
                            deleteComment(item);
                          }}>
                          <i className="fa fa-trash"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                  <div>
                    {item?.comments?.length > 0 &&
                      viewComments?.id === item.id && (
                        <div className="pl-3">
                          <ThreadComment
                            comments={item?.comments}
                            parentId={item?.id}
                            fncStatus={fncStatus}></ThreadComment>
                        </div>
                      )}
                  </div>
                </div>
              )}
              {editMode?.id === item.id && (
                <form onSubmit={handleSubmit}>
                  <div className="row mx-2">
                    <div className="col-md-11 form-group">
                      <label className="col-12">Comment</label>
                      <textarea
                        type="text"
                        required
                        placeholder="Enter text"
                        className="col-12 mx-2 form-control"
                        autoComplete="off"
                        name="desc"
                        id="desc"
                        value={editMode?.text}
                        onChange={(e) =>
                          handleChange("text", e.target.value)
                        }></textarea>
                    </div>
                    <div className="col-md-1 d-flex align-items-center">
                      <button type="submit" className="btn btn-secondary">
                        <i className="fa fa-refresh"></i>
                      </button>
                    </div>
                  </div>
                </form>
              )}
            </div>
          );
        })}
      {commentParent?.id && (
        <div className="mt-4 px-5">
          <CreateComment
            cityId={props.cityId}
            parentId={commentParent.id}
            fncStatus={fncStatus}
            isReplying={true}
            replyingTo={commentParent.createdBy.name}></CreateComment>
        </div>
      )}
    </div>
  );
}

export default ThreadComment;
