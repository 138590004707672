import React from "react";
import "./Rating-Star.scss";

const RatingStar = (props) => {
  const { colorMode, count, commentCounts } = props;
  const starCount = Math.round(count);
  const rows = [];

  for (let i = 1; i <= 5; i++) {
    if (i <= starCount) {
      rows.push(
        <i
          key={i}
          aria-hidden="true"
          className={`fas fa-star star pr-1 ${colorMode}`}></i>
      );
    } else {
      rows.push(
        <i
          key={i}
          aria-hidden="true"
          className={`far fa-star star pr-1 ${colorMode}`}></i>
      );
    }
  }

  return (
    <div className="star-container">
      {rows}{" "}
      <div className="star-container-comment-count">({commentCounts || 0})</div>
    </div>
  );
};

export default RatingStar;
