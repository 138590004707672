export const CITY_META_DATA = [
    {
        name: "Adelaide",
        title: "Moving to Adelaide | Move2NewCity | South Australia",
        description: "Learn more about moving to Adelaide through the experiences and tips shared by friends residing in Adelaide at Move2NewCity",
        keywords: "moving to adelaide, migrating to south australia, relocating to south australia, relocating to adelaide, settling in adelaide, settling in south australia, adelaide, australia"
    },
    {
        name: "Sydney",
        title: "Moving to Sydney Australia | Move2NewCity | New South Wales",
        description: "Discover insightful tips about moving to Sydney Australia by connecting with people in Sydney who have lived experiences at Move2NewCity",
        keywords: "moving to sydney australia, relocating to sydney, moving to new south wales, settling in sydney, migrating to sydney, migrating to new south wales, new south wales, sydney, australia"
    },
    {
        name: "Melbourne",
        title: "Moving to Melbourne Australia | Move2NewCity | Victoria",
        description: "Make your journey of moving to Melbourne Australia with ease through the insights shared by friends from Melbourne at Move2NewCity",
        keywords: "moving to melbourne australia, migrating to victoria, settling in melbourne, moving to melbourne, relocating to melbourne, relocating to victoria, victoria, melbourne, australia"
    },
    {
        name: "Brisbane",
        title: "Moving to Brisbane | Move2NewCity | Queensland",
        description: "Get useful information about moving to Brisbane from people who share lived experiences in Brisbane at Move2NewCity",
        keywords: "movinng to brisbane, migration queensland, settling in queensland, relocating to queensland, migrating to brisbane, settling in brisbane, relocating to brisbane, queensland, brisbane, australia"
    }
]