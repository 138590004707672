import "primeflex/primeflex.css";
import "primeicons/primeicons.css";
import { Calendar } from "primereact/calendar";
import "primereact/resources/primereact.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import { useEffect, useState } from "react";
import "./CalendarModal.scss";

const CalendarModal = (props) => {
  const {
    setCalendarDate,
    scheduleDateNew,
    friendId,
    isOverride,
    setOverrideDates,
    setSelectedTime,
    setSelectedDates,
  } = props;
  const [date, setDate] = useState(new Date());
  const [dates, setDates] = useState([]);

  const handleSelect = (e) => {
    setDates(e.value);
    isOverride && setSelectedDates(e.value);
    setOverrideDates && setOverrideDates(e.value);
    !isOverride && setSelectedTime("");
  };

  useEffect(() => {
    scheduleDateNew && setDate(new Date(scheduleDateNew));
  }, [scheduleDateNew]);

  return (
    <div className="calendar-container">
      <div className="card flex justify-content-center p2">
        <Calendar
          value={dates}
          onSelect={(e) => setCalendarDate(e.value)}
          onChange={(e) => handleSelect(e)}
          showWeek={false}
          inline
          minDate={new Date()}
          selectionMode={isOverride ? "multiple" : "single"}
        />
      </div>
    </div>
  );
};

export default CalendarModal;
