import React, { useEffect, useState } from "react";
import Thread from "../../../components/thread/Thread";
import { getPostById } from "../../../util/APIUtils";
import "./View-Thread.scss";

const ViewThread = (props) => {
  const { id } = props.match.params;
  const [thread, setThread] = useState([]);
  useEffect(() => {
    getPostById(id).then((item) => {
      setThread(item);
    });
  }, [id]);

  return (
    <div className="container-fluid view-thread-container mt-5">
      <h2 className="thread-title">View thread</h2>
      <div className="w-100 mt-3 p-2 shadow">
        <Thread
          article={thread}
          k
          cityId={id}
          currentUser={props.currentUser}></Thread>
      </div>
    </div>
  );
};

export default ViewThread;
