import React, { useEffect, useState } from "react";
import { ToggleButton, ToggleButtonGroup } from "react-bootstrap";
import CreateThread from "../../../components/create-thread/Create-Thread";
import ThreadList from "../../../components/thread-list/Thread-List";
import { getCategoryList } from "../../../util/APIUtils";
import { notificationManage } from "../../../util/NotificationUtils";
import "./Manage-Thread.scss";

const ManageThread = (props) => {
  const [status, setStatus] = useState(null);
  const { id } = props.match.params;
  const [searchString, setSearchString] = useState(props.match.params.category);
  const [categories, setCategories] = useState([]);

  const fncStatus = (values) => {
    setStatus(values);
  };

  useEffect(() => {
    setSearchString(props.match.params.category);
  }, [props.match.params.category]);

  useEffect(() => {
    getCategoryList()
      .then((res) => {
        setCategories(res);
      })
      .catch((err) => {
        err?.then((resp) => {
          notificationManage("danger", "Category list list retreival failed");
        });
      });
  }, []);

  const createSearhString = (e) => {
    setSearchString(e);
  };

  return (
    <div className="mt-3 manage-threads-container">
      <div className="d-flex justify-content-center align-items-center mb-5">
        <ToggleButtonGroup
          onChange={(e) => createSearhString(e)}
          type="checkbox"
          defaultValue={searchString}>
          {categories.map((item, key) => {
            return (
              <ToggleButton key={key} variant="outline-info" value={item.tag}>
                {item.title}
              </ToggleButton>
            );
          })}
        </ToggleButtonGroup>
      </div>
      <ThreadList
        status={status}
        cityId={id}
        currentUser={props.currentUser}
        searchString={searchString}></ThreadList>
      <div className="container">
        <div className="col-md-12 mt-3 thread-container mt-3 mb-3">
          <CreateThread fncStatus={fncStatus} cityId={id}></CreateThread>
        </div>
      </div>
    </div>
  );
};

export default ManageThread;
