import React from "react";
import { Button, Container } from "react-bootstrap";
import "./Account-Already-Exist-For-Role.scss";
import { useHistory, useLocation } from "react-router-dom";
import { BASE_URL, USER_TYPES } from "../../constants";
import { capitalize } from "lodash";

const AccountRoleExist = () => {
  const history = useHistory();
  const handleGoBack = () => {
    window.location.href = `${BASE_URL}`;
  };
  const handleSignIn = () => {
    history.push("/login");
  };
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const existingRole = urlParams.get("role") === "true";

  return (
    <Container className="account-role-container">
      <div className="account-role-exist">
        <div className="account-role-exist-content">
          <div className="account-role-exist-content-header">
            User already exists with a different role !
          </div>
          <div className="account-role-exist-content-body">
            Oops, it looks like you already have a{" "}
            {existingRole ? USER_TYPES.FRIEND : USER_TYPES.NEWCOMER} account
            with us. To continue using our service, please sign in as a{" "}
            <span className="account-role-exist-content-bold">
              {existingRole
                ? capitalize(USER_TYPES.FRIEND)
                : capitalize(USER_TYPES.NEWCOMER)}
            </span>{" "}
            user. If you want to sign up as a{" "}
            <span className="account-role-exist-content-bold">
              {existingRole
                ? capitalize(USER_TYPES.NEWCOMER)
                : capitalize(USER_TYPES.FRIEND)}
            </span>
            , please use a different email address.
          </div>
          <div className="account-role-exist-content-button">
            <Button onClick={() => handleSignIn()}>Sign In</Button>
          </div>
          <div
            className="account-role-exist-content-link"
            onClick={() => handleGoBack()}>
            Return to {BASE_URL}
          </div>
        </div>
      </div>
    </Container>
  );
};
export default AccountRoleExist;
