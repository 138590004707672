import React, { useEffect, useState } from "react";
import { Button, Dropdown, Modal, Spinner } from "react-bootstrap";
import { PencilFill, TrashFill } from "react-bootstrap-icons";
import { API_BASE_URL } from "../../constants";
import { createEvent, editEvent, getCities } from "../../util/APIUtils";
import { notificationManage } from "../../util/NotificationUtils";
import UploadFiles from "../drag-and-drop-upload/Upload-File";
import "./Add-Event-Modal.scss";
import { CustomMenu, CustomToggle } from "./Custom-Dropdown";

const AddNewEventModal = (props) => {
  const [imageFile, setImageFile] = useState([]);
  const [listOfCities, setListOfCities] = useState([]);
  const [showLoader, setShowLoader] = useState(false);
  const [image, setImage] = useState({ file: null, objectUrl: null });
  const [isCreateNewEvent, setIsCreateNewEvent] = useState(true);
  const eventInitialState = {
    name: null,
    image: null,
    cityIdList: [],
    startDate: null,
    endDate: null,
    description: null,
  };
  const errorInitialState = {
    name: false,
    image: false,
    cities: false,
    startDate: false,
    endDate: false,
    description: false,
    dateMismatch: false,
  };
  const [event, setEvent] = useState(eventInitialState);
  const [error, setError] = useState(errorInitialState);
  const [initialEvent, setInitialEvent] = useState({});

  useEffect(() => {
    getCities().then((data) => {
      setListOfCities(
        data.filter(
          (value) => value.country === "Australia" // Filtering to display only Australian cities as per requirement
        )
      );
    });
  }, []);

  useEffect(() => {
    if (!isCreateNewEvent) {
      setImage((prevState) => ({
        ...prevState,
        objectUrl: `${API_BASE_URL}/v1/events/${props.editEvent.id}/image`,
      }));
    }
  }, [isCreateNewEvent, props.editEvent.id]);

  useEffect(() => {
    if (props.editEvent.name) {
      const editEvent = props.editEvent;
      setIsCreateNewEvent(false);

      let eventData = {
        name: editEvent.name,
        image: editEvent.image,
        cityIdList: editEvent.cities.map((value) => value.id),
        startDate: timeFormatter(new Date(editEvent.startDate)),
        endDate: timeFormatter(new Date(editEvent.endDate)),
        description: editEvent.description,
      };

      setEvent(eventData);
      setInitialEvent(eventData);
    }
  }, [props]);

  useEffect(() => {
    if (imageFile.length) {
      handleEventFormChange(null, "image", imageFile[0]);
    }
  }, [imageFile]);

  const handleEventFormChange = (e, field, value) => {
    if (e) {
      e.preventDefault();
    }
    setEvent((prev) => ({
      ...prev,
      [field]: value,
    }));
    updateErrorMessages(field);
    if (field === "endDate") {
      updateErrorMessages("dateMismatch");
    }
  };

  const updateErrorMessages = (field) => {
    setError((prev) => ({
      ...prev,
      [field]: false,
    }));
  };

  const handleDeleteImage = () => {
    setImageFile([]);
    setEvent((prev) => ({
      ...prev,
      image: null,
    }));
    setImage(null);
  };

  const handleClose = () => {
    setImageFile([]);
    setImage(null);
    setEvent(eventInitialState);
    props.setEditEvent(props.editEventInitialState);
    setError(errorInitialState);
    props.toggleModal("addEventModal");
    setIsCreateNewEvent(true);
    props.refresh();
  };

  const checkValidation = (event) => {
    let updatedErrors = { ...error };
    updatedErrors.name = Boolean(!event.name);
    updatedErrors.image = Boolean(!event.image);
    updatedErrors.cities = Boolean(!event.cityIdList.length);
    updatedErrors.startDate = Boolean(!event.startDate);
    updatedErrors.endDate = Boolean(!event.endDate);
    updatedErrors.description = Boolean(!event.description);
    updatedErrors.dateMismatch =
      new Date(event.startDate) > new Date(event.endDate) &&
      JSON.stringify(event.startDate) !== JSON.stringify(event.endDate);
    setError(updatedErrors);
    return Object.values(updatedErrors).every((value) => value === false);
  };

  const handleOnSubmit = () => {
    if (checkValidation(event)) {
      setShowLoader(true);
      const formData = new FormData();
      Object.entries(event).forEach((value) => {
        formData.append(value[0], value[1]);
      });
      if (!isCreateNewEvent && image) {
        formData.delete("image");
      }
      isCreateNewEvent
        ? createEvent(formData)
            .then(() => {
              notificationManage("success", "Event created successfully");
            })
            .catch(() => {
              notificationManage("danger", "Something went wrong");
            })
            .finally(() => {
              setShowLoader(false);
              handleClose();
            })
        : editEvent(props.editEvent.id, formData)
            .then(() => {
              notificationManage("success", "Event edited successfully");
            })
            .catch(() => {
              notificationManage("danger", "Something went wrong");
            })
            .finally(() => {
              setShowLoader(false);
              handleClose();
            });
    }
  };

  const ImageOverlay = () => {
    return (
      <div className="preview-overlay">
        <PencilFill
          fill="#FFFFFF"
          size={20}
          className="preview-overlay-edit"
          onClick={() => handleDeleteImage()}
        ></PencilFill>
        <TrashFill
          fill="#FFFFFF"
          size={20}
          className="preview-overlay-delete"
          onClick={() => handleDeleteImage()}
        ></TrashFill>
      </div>
    );
  };

  const timeFormatter = (d) => {
    const incoming = new Date(d);
    return `${incoming.getFullYear().toString().padStart(4, 0)}-${parseInt(
      incoming.getMonth() + 1
    )
      .toString()
      .padStart(2, 0)}-${incoming
      .getDate()
      .toString()
      .padStart(2, 0)}T${incoming
      .getHours()
      .toString()
      .padStart(2, 0)}:${incoming
      .getMinutes()
      .toString()
      .padStart(2, 0)}:${incoming
      .getSeconds()
      .toString()
      .padStart(2, 0)}.${incoming
      .getMilliseconds()
      .toString()
      .padStart(3, 0)}Z`;
  };

  return (
    <Modal
      show={props.show}
      onHide={handleClose}
      backdrop="static"
      dialogClassName="event-dialog custom-dialog-width"
      contentClassName="event-content"
      centered
    >
      <Modal.Header className="event-header" closeButton>
        <Modal.Title className="event-title">
          {isCreateNewEvent ? "Add new event" : "Edit existing event"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="event-body">
        <div>
          <div className="event-image">
            <div className="dropzone">
              {(!isCreateNewEvent && image?.objectUrl) ||
              (!isCreateNewEvent && imageFile?.length) ? (
                <>
                  <img
                    src={
                      image?.objectUrl ? image.objectUrl : imageFile[0].preview
                    }
                    className="preview"
                    alt="eventImg"
                  />
                  <ImageOverlay />
                </>
              ) : imageFile.length ? (
                <>
                  <img
                    src={imageFile[0].preview}
                    className="preview"
                    alt="eventImg"
                  />
                  <ImageOverlay />
                </>
              ) : (
                <div className="upload-image">
                  <UploadFiles type="event image" setImageFile={setImageFile} />
                  <div
                    className={`${
                      error.image
                        ? "error-label-visible"
                        : "error-label-invisible"
                    }`}
                  >
                    <i class="fas fa-exclamation-circle"></i> Upload an image
                    file here
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="event-row">
            <div>
              <label for="name">Name *</label>
              <input
                id="name"
                type="text"
                defaultValue={event.name}
                placeholder="Concert"
                onSelect={(e) =>
                  handleEventFormChange(e, "name", e.target.value)
                }
              />
              <label
                for="name"
                className={`${
                  error.name ? "error-label-visible" : "error-label-invisible"
                }`}
              >
                <i class="fas fa-exclamation-circle"></i> Enter a name
              </label>
            </div>
            <div>
              <label for="cities">Cities *</label>
              <Dropdown>
                <Dropdown.Toggle as={CustomToggle} id="cities">
                  {event.cityIdList.length
                    ? `${event.cityIdList.length} ${
                        event.cityIdList.length === 1 ? "city" : "cities"
                      } selected`
                    : "Select cities"}
                </Dropdown.Toggle>

                <Dropdown.Menu
                  listOfCities={listOfCities}
                  event={event}
                  setEvent={setEvent}
                  updateErrorMessages={updateErrorMessages}
                  as={CustomMenu}
                  className="event-dropdown-menu"
                />
              </Dropdown>
              <label
                for="cities"
                className={`${
                  error.cities ? "error-label-visible" : "error-label-invisible"
                }`}
              >
                <i class="fas fa-exclamation-circle"></i> Select at least one
                city
              </label>
            </div>
          </div>
          <div className="event-row">
            <div>
              <label for="startDate">Start date *</label>
              <input
                id="startDate"
                type="date"
                defaultValue={event.startDate?.slice(
                  0,
                  event.startDate.indexOf("T")
                )}
                onChange={(e) =>
                  handleEventFormChange(
                    e,
                    "startDate",
                    timeFormatter(new Date(e.target.value))
                  )
                }
              />
              <label
                for="startDate"
                className={`${
                  error.startDate
                    ? "error-label-visible"
                    : "error-label-invisible"
                }`}
              >
                <i class="fas fa-exclamation-circle"></i> Enter the start date
              </label>
            </div>
            <div>
              <label for="endDate">End date *</label>
              <input
                id="endDate"
                type="date"
                placeholder="DATE"
                defaultValue={event.endDate?.slice(
                  0,
                  event.endDate.indexOf("T")
                )}
                onChange={(e) =>
                  handleEventFormChange(
                    e,
                    "endDate",
                    timeFormatter(new Date(e.target.value))
                  )
                }
              />
              <label
                for="endDate"
                className={`${
                  error.endDate || error.dateMismatch
                    ? "error-label-visible"
                    : "error-label-invisible"
                }`}
              >
                <i class="fas fa-exclamation-circle"></i>{" "}
                {error.endDate || error.dateMismatch
                  ? error.endDate
                    ? "Enter the end date"
                    : error.dateMismatch
                    ? "End date should be after the start date"
                    : ""
                  : ""}
              </label>
            </div>
          </div>
          <div className="event-row">
            <div className="event-row-description">
              <label for="description" className="description">
                Description *
              </label>
              <textarea
                id="description"
                placeholder="Give a small description about the event"
                defaultValue={event.description}
                onSelect={(e) =>
                  handleEventFormChange(e, "description", e.target.value)
                }
              />
              <label
                for="description"
                className={`${
                  error.description
                    ? "error-label-visible"
                    : "error-label-invisible"
                }`}
              >
                <i class="fas fa-exclamation-circle"></i> Enter a description
              </label>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer className="event-footer">
        <Button
          variant="secondary"
          className="event-save-button mr-auto"
          disabled={JSON.stringify(initialEvent) === JSON.stringify(event)}
          onClick={() => handleOnSubmit()}
        >
          {showLoader && (
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
              className="mr-2 pr-1"
            />
          )}
          Save
        </Button>
        <Button
          variant="primary"
          onClick={() => handleClose()}
        >
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddNewEventModal;
