import { useEffect, useState } from "react";
import RatingStar from "../rating-star/Rating-Star";
import "./ContributionDetails.scss";

const ContributionDetails = ({
  showContributingDetails,
  userDetails,
  handleShowContributingDetails,
  windowWidth,
}) => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    if (windowWidth && windowWidth < 688) {
      setIsMobile(true);
    }
  }, [windowWidth]);
  return (
    <div className="friend-contribution-details-card-body card-body">
      {isMobile && !showContributingDetails ? (
        <div className="card-manage">
          {userDetails.keys?.slice(0, 2).map((item, key) => {
            return (
              <div key={key} className="form-group row">
                <label className="col-sm-7 col-form-label">{item}</label>
                <div className="col-sm-5 col-form-star px-0 start-container">
                  <RatingStar
                    count={userDetails.values[key]}
                    commentCounts={userDetails.commentCounts[key]}
                    colorMode="blackMode"
                  />
                </div>
              </div>
            );
          })}
        </div>
      ) : (
        <div className="card-manage">
          {userDetails.keys?.map((item, key) => {
            return (
              <div key={key} className="form-group row">
                <label className="col-sm-7  col-form-label">{item}</label>
                <div className="col-sm-5  col-form-star px-0 start-container">
                  <RatingStar
                    count={userDetails.values[key]}
                    commentCounts={userDetails.commentCounts[key]}
                    colorMode="blackMode"
                  />
                </div>
              </div>
            );
          })}
        </div>
      )}
      {isMobile && (
        <div className="card-text-2 float-right">
          {!showContributingDetails ? (
            <button
              type="button"
              className="btn btn-link friend-contribution-details-see-more-button"
              onClick={handleShowContributingDetails}
            >
              See More
            </button>
          ) : (
            <button
              type="button"
              className="btn btn-link friend-contribution-details-hide-button"
              onClick={handleShowContributingDetails}
            >
              Hide Details
            </button>
          )}
        </div>
      )}
    </div>
  );
};

export default ContributionDetails;
