import React from "react";
import { Button, Container } from "react-bootstrap";
import "./Social-Login-Existing-Account.scss";
import { useHistory, useLocation } from "react-router-dom";
import { BASE_URL } from "../../constants";

const SocialLoginExist = () => {
  const history = useHistory();
  const handleGoBack = () => {
    window.location.href = `${BASE_URL}`;
  };
  const handleSignIn = () => {
    history.push("/login");
  };
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const email = urlParams.get("email");

  return (
    <Container className="social-login-container">
      <div className="social-login-exist">
        <div className="social-login-exist-content">
          <div className="social-login-exist-content-header">
            Looks like you already have an account !
          </div>
          <div className="social-login-exist-content-body">
            There's already an account with your email address{" "}
            <span className="social-login-exist-content-bold">{email}</span>.
            You can{" "}
            <span className="social-login-exist-content-bold">Sign In</span> to
            this account by entering your password from here.If you have created the account using Google or Facebook, please log in using the respective buttons.
          </div>
          <div className="social-login-exist-content-button">
            <Button onClick={() => handleSignIn()}>Sign In</Button>
          </div>
          <div
            className="social-login-exist-content-link"
            onClick={() => handleGoBack()}>
            Return to {BASE_URL}
          </div>
        </div>
      </div>
    </Container>
  );
};
export default SocialLoginExist;
