import { useEffect, useState } from "react";
import "./FriendDescription.scss";

const FriendDescription = ({
  showDetails,
  userDetails,
  truncateDescription,
  handleShowDetails,
  windowWidth,
}) => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    if (windowWidth && windowWidth < 688) {
      setIsMobile(true);
    }
  }, [windowWidth]);
  return (
    <div className="friend-description-container">
      <div className="left-panel-header-text-2">Description</div>
      <div className="main-card-2">
        <div className="card-body pb-5">
          {isMobile ? (
            <div className="card-text-2">
              {showDetails &&
                userDetails.description &&
                userDetails.description}
              {!showDetails &&
                userDetails.description &&
                isMobile &&
                truncateDescription(userDetails.description)}
            </div>
          ) : (
            <div className="card-text-2">
              {userDetails.description && userDetails.description}
            </div>
          )}
          {isMobile && (
            <div className="card-text-2 float-right">
              {!showDetails ? (
                <button
                  type="button"
                  className="btn btn-link friend-description-see-more-button"
                  onClick={handleShowDetails}
                >
                  See More
                </button>
              ) : (
                <button
                  type="button"
                  className="btn btn-link friend-description-hide-button"
                  onClick={handleShowDetails}
                >
                  Hide Details
                </button>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default FriendDescription;
