import { Modal } from "react-bootstrap";
import "./SuccessfulSignUp.scss";

const SuccessfulSignUp = ({ show, onHide }) => {
  return (
    <Modal
      size="sm"
      show={show}
      onHide={onHide}
      className="successful-sign-up"
      dialogClassName="successful-sign-up-dialog"
      centered>
      <Modal.Header className="successful-sign-up-header" closeButton />
      <Modal.Body className="successful-sign-up-body">
        <div className="title">Successfully Signed Up</div>
        <div className="icon">
          <i className="far fa-check-circle fa-2x"></i>
        </div>
        <div className="description">
          To edit your profile, please go to the "<span>Edit Account</span>"
          page
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default SuccessfulSignUp;
